import { t } from 'i18next';
import {
  PreviewNextInvoiceFragment,
  DiscountType,
  SubscriptionPreviewDiscount,
  SubscriptionMaximumSpendDiscount,
} from '@stigg-types/apiTypes';
import { SectionTitle } from './SectionTitle';
import { ChargeRow } from './ChargeRow';

type DiscountsSectionProps = Pick<PreviewNextInvoiceFragment, 'discount'> & {
  discountDetails: SubscriptionPreviewDiscount | SubscriptionMaximumSpendDiscount | null | undefined;
};

export function DiscountsSection({ discount, discountDetails }: DiscountsSectionProps) {
  if (!discount || discount?.amount === 0 || !discountDetails) {
    return null;
  }

  const discountPercent = discountDetails?.type === DiscountType.Percentage ? `${discountDetails?.value}%` : undefined;

  return (
    <>
      <SectionTitle title={t('subscriptions.breakdown.discountSectionTitle')} />

      <ChargeRow
        description={
          discountDetails?.name
            ? `${discountDetails.name}${discountPercent ? ` (${discountPercent})` : ''}`
            : discountPercent ?? t('subscriptions.breakdown.discount')
        }
        charge={discount}
      />
    </>
  );
}
