import { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import pluralize from 'pluralize';
import { AnimatePresence } from 'framer-motion';
import { Button, Box, Checkbox, Icon, Form, GridFlex, Text, FormProps } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import { FormikHelpers, FormikTouched } from 'formik';
import {
  AggregationFunction,
  ConditionOperation,
  Feature,
  FeatureStatus,
  FeatureType,
  MeterType,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createFeatureAction } from '../../featuresSlice';
import { HelpTooltip } from '../../../../components/HelpTooltip';
import { generateRefIdFromName } from '../../../../components/DisplayNameTextField';
import { FeatureTypeSelectionBox, ReportUsageType } from './FeatureTypeSelectionBox';
import { Dialog } from '../../../../components/Dialog';
import { FiltersAndAggregation } from './FiltersAndAggregation';
import { validationSchema } from './featureFormValidation';
import { validateFeatureRefId } from '../../mutations';
import { UnitTransformationSwitch } from '../UnitTransformationSwitch';
import { FeatureUnitLabels } from '../FeatureUnitLabels';
import { FeatureUnitTransformation } from '../FeatureUnitTransformation';

type SideNavCreateFeatureProps = {
  onClose: () => void;
  onSubmit: (createAnotherFeatureSelected: boolean, feature: Feature) => void;
  preventNavigateAfterCreate?: boolean;
  featureName?: string | null;
  hideCreateAnotherFeature?: boolean;
};

export type Condition = {
  field: string;
  operation: ConditionOperation;
  value?: string | null;
};

export type Filter = {
  conditions: Condition[];
};

export type Aggregation = {
  function: AggregationFunction;
  field?: string | null;
};

export type CreateFeatureFormFields = {
  displayName: string;
  refId: string;
  description: string;
  featureType: FeatureType | null;
  featureUnits: string | null;
  featureUnitsPlural: string | null;
  transformedUnits: string | null;
  transformedUnitsPlural: string | null;
  isUnitTransformationOpen: boolean;
  divideBy: number | null;
  meterType: MeterType | undefined;
  reportUsageType: ReportUsageType | undefined;
  filters: Filter[];
  aggregation: Aggregation | null;
};

const onFeatureTypeSelect = (
  setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
  featureType: FeatureType,
  meterType?: MeterType | null,
  reportUsageType?: ReportUsageType | null,
) => {
  setFieldValue('featureType', featureType, true);
  setFieldValue('meterType', meterType, true);
  if (!reportUsageType && meterType === MeterType.Incremental) {
    setFieldValue('reportUsageType', ReportUsageType.REPORT_USAGE, true);
  }
};

export const FEATURE_TYPES_ITEMS = () => [
  {
    key: FeatureType.Boolean,
    featureType: FeatureType.Boolean,
    meterType: undefined,
    description: t('features.booleanDescription'),
    exampleDescription: t('features.booleanExampleDescription'),
  },
  {
    key: FeatureType.Number,
    featureType: FeatureType.Number,
    meterType: MeterType.None,
    description: t('features.configurationDescription'),
    exampleDescription: t('features.configurationExampleDescription'),
  },
  {
    key: MeterType.Incremental,
    featureType: FeatureType.Number,
    meterType: MeterType.Incremental,
    description: t('features.meteredDescription'),
    exampleDescription: t('features.meteredExampleDescription'),
  },
];

export function onSubmitFiltersAndAggregation(values: { filters: Filter[]; aggregation: Aggregation | null }) {
  const { aggregation, filters } = values;
  if (aggregation?.function === AggregationFunction.Count) {
    aggregation.field = undefined;
  }
  if (filters) {
    filters.forEach((filter) => {
      filter.conditions.forEach((condition) => {
        if ([ConditionOperation.IsNull, ConditionOperation.IsNotNull].includes(condition.operation)) {
          condition.value = undefined;
        }
      });
    });
  }
  return { filters, aggregation };
}

export const DEFAULT_CONDITION = {
  field: 'eventName',
  operation: ConditionOperation.Equals,
};

export function SideNavCreateFeature({
  onClose,
  onSubmit,
  featureName,
  preventNavigateAfterCreate,
  hideCreateAnotherFeature,
}: SideNavCreateFeatureProps) {
  const { usageTransformation: usageTransformationEnabled } = useFlags<FeatureFlags>();
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isRefIdFieldOpen, setIsRefIdFieldOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [createAnotherFeatureSelected, setCreateAnotherFeatureSelected] = useState(false);
  const [isUnitLabelsFieldOpen, setIsUnitLabelsFieldOpen] = useState(false);
  const [items, setItems] = useState(FEATURE_TYPES_ITEMS());
  const initialValues = {
    displayName: featureName || '',
    refId: featureName ? generateRefIdFromName(featureName, t('features.refIdPrefix')) : '',
    description: '',
    featureType: null,
    featureUnits: featureName ? pluralize.singular(featureName) : '',
    featureUnitsPlural: featureName ? pluralize.plural(featureName) : '',
    meterType: undefined,
    isUnitTransformationOpen: false,
    aggregation: {
      function: AggregationFunction.Count,
    },
    filters: [
      {
        conditions: [DEFAULT_CONDITION],
      },
    ],
  } as CreateFeatureFormFields;

  const isIdUnique = async (refId?: string) => {
    if (!currentEnvironmentId || !refId) {
      return { featureExists: false };
    }

    // in case of a failure don't show any error
    try {
      const results = await validateFeatureRefId(currentEnvironmentId, refId, { dispatch });
      return results;
    } catch (e) {
      return { featureExists: false };
    }
  };

  const validateForm: FormProps<CreateFeatureFormFields>['validate'] = async (values) => {
    const errors: any = {};

    if (isEmpty(values.refId)) {
      return errors;
    }

    const { featureExists, featureStatus } = await isIdUnique(values.refId);
    if (featureExists) {
      if (featureStatus === FeatureStatus.Suspended) {
        errors.refId = t('features.yup.refIdNotUniqueArchived');
      } else {
        errors.refId = t('features.yup.refIdNotUnique');
      }
    }

    return errors;
  };

  // TODO: extract and reuse in sideNavFeatureDetails component
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleSubmit = async (
    values: CreateFeatureFormFields,
    formikHelpers: Pick<FormikHelpers<CreateFeatureFormFields>, 'validateForm' | 'resetForm' | 'setTouched'>,
    fromDialog?: boolean,
  ) => {
    const isReportEvents = values.reportUsageType === ReportUsageType.REPORT_EVENTS;
    if (!fromDialog && isReportEvents) {
      formikHelpers.setTouched({ filters: undefined });

      setIsUnitLabelsFieldOpen(false);
      setIsFiltersOpen(true);
      await formikHelpers.validateForm();
      return;
    }

    if (values.featureType === FeatureType.Boolean) {
      values.featureUnits = null;
      values.featureUnitsPlural = null;
    }
    const { filters, aggregation } = onSubmitFiltersAndAggregation(values);
    const newFeature = await dispatch(
      createFeatureAction({
        featureData: {
          displayName: values.displayName,
          description: values.description,
          featureUnits: values.isUnitTransformationOpen ? values.transformedUnits : values.featureUnits,
          featureUnitsPlural: values.isUnitTransformationOpen
            ? values.transformedUnitsPlural
            : values.featureUnitsPlural,
          unitTransformation:
            values.isUnitTransformationOpen && values.divideBy
              ? {
                  divide: values.divideBy,
                  featureUnits: values.featureUnits,
                  featureUnitsPlural: values.featureUnitsPlural,
                }
              : undefined,
          refId: values.refId || generateRefIdFromName(values.displayName, t('features.refIdPrefix')),
          featureType: values.featureType!,
          meterType: values.meterType,
          meter:
            isReportEvents && filters && aggregation
              ? {
                  filters,
                  aggregation,
                }
              : undefined,
        },
        navigateToEntity: !createAnotherFeatureSelected && !preventNavigateAfterCreate,
      }),
    );

    if (createAnotherFeatureSelected) {
      setItems(FEATURE_TYPES_ITEMS());
      formikHelpers.resetForm();
    }

    onSubmit(createAnotherFeatureSelected, newFeature.payload as Feature);
  };

  const handleChangePrefillFeatureUnits = (
    e: any,
    setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
  ) => {
    if (!isEmpty(e.target.value)) {
      setFieldValue('featureUnits', pluralize.singular(e.target.value).toLowerCase(), false);
    }
    if (!isEmpty(e.target.value)) {
      setFieldValue('featureUnitsPlural', pluralize.plural(e.target.value).toLowerCase(), false);
    }
  };
  const handleSingularUnitsChange = (
    e: any,
    setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
    touched: FormikTouched<CreateFeatureFormFields>,
  ) => {
    setFieldValue('featureUnits', e.target.value);
    if (!touched.featureUnitsPlural && !isEmpty(e.target.value)) {
      setFieldValue('featureUnitsPlural', pluralize.plural(e.target.value));
    }
  };

  const handleTransformedSingularUnitsChange = (
    e: any,
    setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
    touched: FormikTouched<CreateFeatureFormFields>,
  ) => {
    setFieldValue('transformedUnits', e.target.value);
    if (!touched.transformedUnitsPlural && !isEmpty(e.target.value)) {
      setFieldValue('transformedUnitsPlural', pluralize.plural(e.target.value));
    }
  };

  return (
    <GridFlex.Column $fullHeight>
      <Form
        withStickyFooter
        footerSx={{ paddingBottom: 0 }}
        disableSaveOnNoChanges
        rowSpacing={4}
        submitButtonText={(values: CreateFeatureFormFields) =>
          values.reportUsageType === ReportUsageType.REPORT_EVENTS ? 'Next' : t('sharedComponents.createSaveButton')
        }
        saveEndIcon={(values: CreateFeatureFormFields) =>
          values.reportUsageType === ReportUsageType.REPORT_EVENTS ? (
            <Icon type="reactFeather" icon="ArrowRight" size={18} />
          ) : null
        }
        overrideIsValid={(values: CreateFeatureFormFields, formProps) =>
          values.reportUsageType === ReportUsageType.REPORT_EVENTS ? true : formProps.isValid
        }
        onCancel={onClose}
        initialValues={initialValues}
        validationSchema={validationSchema(isFiltersOpen, isIdUnique, setIsRefIdFieldOpen)}
        validate={validateForm}
        onSubmit={handleSubmit}
        footerComponent={
          hideCreateAnotherFeature ? null : (
            <Checkbox
              checked={createAnotherFeatureSelected}
              onChange={(e) => setCreateAnotherFeatureSelected(e.target.checked)}
              label={t('features.createAnotherFeature')}
            />
          )
        }
        fields={({ values, setFieldValue }) => [
          {
            id: 'displayName',
            type: 'text',
            label: t('features.name'),
            autoFocus: true,
            placeholder: t('features.name'),
            helpTooltipText: t('features.featureNameHelpTooltip'),
            handleChange: ({ setFieldValue, setFieldTouched, validateForm, values }) => {
              return (e) => {
                setFieldValue('displayName', e.target.value, false);
                const refId = e.target.value ? generateRefIdFromName(e.target.value, t('features.refIdPrefix')) : '';
                setFieldValue('refId', refId, false);
                setFieldTouched('refId', true, false);
                setFieldTouched('displayName', true, false);
                handleChangePrefillFeatureUnits(e, setFieldValue);
                void validateForm({
                  ...values,
                  refId,
                  displayName: e.target.value,
                });
              };
            },
          },
          {
            type: 'collapse',
            hideIcon: true,
            title: isDescriptionOpen ? null : (
              <GridFlex.RowCenter>
                <Icon type="reactFeather" icon={isDescriptionOpen ? 'Minus' : 'Plus'} color="primary.main" size={16} />
                <Text.B2 ml={2} color="primary.main">
                  {isDescriptionOpen ? t('features.removeDescription') : t('features.addDescription')}
                </Text.B2>
              </GridFlex.RowCenter>
            ),
            onClick: () => setIsDescriptionOpen((prevState) => !prevState),
            open: isDescriptionOpen,
            fields: [
              {
                id: 'description',
                type: 'text',
                optional: true,

                placeholder: t('features.descriptionForm'),
                textFieldProps: { multiline: true, maxRows: 2 },
              },
            ],
          },
          {
            type: 'collapse',
            hideIcon: true,
            titleGridSx: { position: 'relative' },
            title: (
              <GridFlex.RowCenter $fullWidth justifyContent="space-between">
                <GridFlex.RowCenter>
                  <Text.B2 color="secondary">{t('features.featureId')}</Text.B2>
                  <HelpTooltip $maxWidth={250}>
                    <Text.B2 color="primary">{t('features.refidInputHelper')}</Text.B2>
                  </HelpTooltip>
                  {isRefIdFieldOpen ? null : (
                    <Text.B2 color="secondary" ml={2}>
                      {values.refId || t('features.autoGenerated')}
                    </Text.B2>
                  )}
                </GridFlex.RowCenter>
                {!isRefIdFieldOpen && (
                  <GridFlex.RowCenter sx={{ position: 'absolute', right: 0 }}>
                    <Button variant="text">{t('sharedComponents.edit')}</Button>
                  </GridFlex.RowCenter>
                )}
              </GridFlex.RowCenter>
            ),
            onClick: () => setIsRefIdFieldOpen((prevState) => !prevState),
            open: isRefIdFieldOpen,
            fields: [
              {
                id: 'refId',
                type: 'text',
                optional: true,
                placeholder: t('features.autoGenerated'),
                textFieldProps: { multiline: true, maxRows: 2 },
              },
            ],
          },
          {
            type: 'custom',
            render: ({ setFieldValue, setFieldTouched, values }) => (
              <GridFlex.Column $fullWidth>
                <Text.B2 mb={2} color="secondary">
                  {t('features.FeatureTypeSelect')}
                </Text.B2>
                <GridFlex.Column $fullWidth rowGap={2} sx={{ position: 'relative' }}>
                  <AnimatePresence>
                    {items.map((item) => (
                      <FeatureTypeSelectionBox
                        key={item.key}
                        featureType={item.featureType}
                        meterType={item.meterType}
                        reportUsageType={values.reportUsageType}
                        description={item.description}
                        exampleDescription={item.exampleDescription}
                        isSelected={values.featureType === item.featureType && values.meterType === item.meterType}
                        onReportUsageClick={(reportUsageType: ReportUsageType) => {
                          setFieldValue('reportUsageType', reportUsageType, true);
                          setTimeout(() => {
                            setFieldTouched('reportUsageType', true);
                          }, 0);
                        }}
                        onClick={() => {
                          setItems(
                            FEATURE_TYPES_ITEMS().filter(
                              (i) => i.featureType === item.featureType && i.meterType === item.meterType,
                            ),
                          );
                          onFeatureTypeSelect(setFieldValue, item.featureType, item.meterType, values.reportUsageType);
                          setTimeout(() => {
                            setFieldTouched('featureType', true);
                          }, 0);
                        }}
                        onReset={() => {
                          setItems(FEATURE_TYPES_ITEMS());
                          setFieldValue('featureType', null, true);
                          setFieldValue('meterType', undefined, true);
                          setFieldValue('reportUsageType', undefined, true);
                          setTimeout(() => {
                            setFieldTouched('featureType', true);
                          }, 0);
                        }}
                      />
                    ))}
                  </AnimatePresence>
                </GridFlex.Column>
              </GridFlex.Column>
            ),
          },
          {
            type: 'collapse',
            hideIcon: true,
            titleGridSx: { position: 'relative' },
            hide: (values: CreateFeatureFormFields) =>
              values.featureType !== FeatureType.Number || values.reportUsageType === ReportUsageType.REPORT_EVENTS,
            title: (
              <GridFlex.Column>
                <GridFlex.RowCenter mb={2}>
                  <Text.B2 color="secondary">{t('features.unitLabel')}</Text.B2>
                  {!values.featureUnits && !isUnitLabelsFieldOpen && (
                    <Text.B2 color="secondary" ml={2}>
                      {t('features.autoGenerated')}
                    </Text.B2>
                  )}
                  {!isUnitLabelsFieldOpen && (
                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Button variant="text">{t('sharedComponents.edit')}</Button>
                    </Box>
                  )}
                </GridFlex.RowCenter>

                {values.featureUnits && !isUnitLabelsFieldOpen && (
                  <GridFlex.RowCenter>
                    <Text.B2 color="secondary">
                      One{' '}
                      <Text.B2 display="inline" color="primary">
                        {values.featureUnits}
                      </Text.B2>{' '}
                      <Text.B2 display="inline" color="disabled">
                        •
                      </Text.B2>{' '}
                      Many{' '}
                      <Text.B2 display="inline" color="primary">
                        {values.featureUnitsPlural}
                      </Text.B2>
                    </Text.B2>
                  </GridFlex.RowCenter>
                )}
              </GridFlex.Column>
            ),
            onClick: () => setIsUnitLabelsFieldOpen((prevState) => !prevState),
            open: isUnitLabelsFieldOpen,
            fields: [
              {
                type: 'custom',
                render: ({ renderTextField, setFieldValue, touched }) => (
                  <GridFlex.RowCenter alignItems="flex-start" $fullWidth justifyContent="space-between" columnGap={4}>
                    {renderTextField('featureUnits', undefined, {
                      placeholder: t('features.unitsLong'),
                      onChange: (e) => handleSingularUnitsChange(e, setFieldValue, touched),
                    })}
                    {renderTextField('featureUnitsPlural', undefined, {
                      placeholder: t('features.unitsPluralLong'),
                    })}
                  </GridFlex.RowCenter>
                ),
              },
            ],
          },
          {
            type: 'collapse',
            open: values.isUnitTransformationOpen,
            hide: (values: CreateFeatureFormFields) =>
              !usageTransformationEnabled ||
              values.meterType !== MeterType.Incremental ||
              values.reportUsageType === ReportUsageType.REPORT_EVENTS,
            hideIcon: true,
            titleTextProps: {
              sx: { width: '100%' },
            },
            title: (
              <UnitTransformationSwitch
                checked={values.isUnitTransformationOpen}
                onChange={(isChecked) => setFieldValue('isUnitTransformationOpen', isChecked)}
              />
            ),
            fields: [
              {
                type: 'custom',
                render: ({ renderTextField, setFieldValue, touched }) => (
                  <FeatureUnitLabels
                    renderTextField={renderTextField}
                    handleSingularChange={(e) => handleTransformedSingularUnitsChange(e, setFieldValue, touched)}
                  />
                ),
              },
              {
                type: 'custom',
                render: ({ renderTextField, values }) => (
                  <FeatureUnitTransformation
                    renderTextField={renderTextField}
                    divideBy={values.divideBy}
                    featureUnits={values.featureUnits}
                    featureUnitsPlural={values.featureUnitsPlural}
                    transformedUnits={values.transformedUnits}
                  />
                ),
              },
            ],
          },
          {
            type: 'custom',
            render: (formRenderProps) => (
              <Dialog
                disableCloseOnEscapeKey
                fullScreen
                padding={0}
                content={
                  <FiltersAndAggregation
                    onSubmit={() => handleSubmit(formRenderProps.values, formRenderProps, true)}
                    onCancel={onClose}
                    formRenderProps={formRenderProps}
                  />
                }
                isOpen={isFiltersOpen}
                onCancel={() => {
                  setIsFiltersOpen(false);
                }}
                aria-labelledby="filters-and-aggregations"
              />
            ),
          },
        ]}
      />
    </GridFlex.Column>
  );
}
