import { AddonFragment, PlanFragment, PricingType } from '@stigg-types/apiTypes';
import { Icon } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { getPackageTypeDisplay } from '../../../../../common/getPackageTypeDisplay';
import { priceIconByPriceType } from '../../../../utils/priceTypeIcons';

export function getPricingTypesList(aPackage: PlanFragment | AddonFragment) {
  const paidPricingViewSubtitle = t(`pricing.paidPricingViewSubtitle.${aPackage.type}`);

  const items = [
    {
      value: PricingType.Paid,
      icon: <Icon icon={priceIconByPriceType[PricingType.Paid]} />,
      title: t('pricing.paidPricingType'),
      subtitle: paidPricingViewSubtitle,
      notes: [paidPricingViewSubtitle],
    },
    {
      value: PricingType.Custom,
      icon: <Icon icon={priceIconByPriceType[PricingType.Custom]} />,
      title: t('pricing.customPrice'),
      subtitle: t('pricing.customDescription', { packageType: getPackageTypeDisplay(aPackage) }),
      notes: [
        t('pricing.customPlanPriceVaries'),
        t('pricing.customManuallySubscription'),
        t('pricing.customNotPropagatedToBilling'),
        t('pricing.customPaywallMessage'),
      ],
    },
  ];

  if (aPackage.type === 'Plan') {
    items.unshift({
      value: PricingType.Free,
      icon: <Icon icon={priceIconByPriceType[PricingType.Free]} />,
      title: t('pricing.freePricingType'),
      subtitle: t('pricing.freePricingViewSubtitle'),
      notes: [t('pricing.freePricingViewSubtitle'), t('pricing.freeSubtitle')],
    });
  }

  return items;
}
