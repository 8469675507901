import { styled } from '@stigg-theme';
// eslint-disable-next-line no-restricted-imports
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import { Box } from '@stigg-components';

export const StyledAccordion = styled(MuiAccordion)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};

  &:not(:last-child) {
    border-bottom: 0;
  }

  & > .Mui-disabled {
    background-color: ${({ theme }) => theme.itamar.palette.grey[50]};
    opacity: 1;
  }

  &:first-child > .Mui-disabled {
    border-top-left-radius: ${({ theme }) => theme.itamar.border.radius};
    border-top-right-radius: ${({ theme }) => theme.itamar.border.radius};
  }

  &:last-child > .Mui-disabled {
    border-bottom-left-radius: ${({ theme }) => theme.itamar.border.radius};
    border-bottom-right-radius: ${({ theme }) => theme.itamar.border.radius};
  }
`;

export const StyledAccordionSummary = styled(MuiAccordionSummary)`
  &:not(.Mui-disabled) {
    padding: ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(8)};
  }
  &:not(.Mui-disabled).Mui-expanded {
    padding: ${({ theme }) => theme.spacing(8)} ${({ theme }) => theme.spacing(8)};
  }
  &.Mui-disabled {
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(8)};
  }

  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }
`;

export const StyledAccordionDetails = styled(MuiAccordionDetails)<{ $withPaddingTop?: boolean }>`
  padding: ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(8)};
  padding-top: ${({ $withPaddingTop, theme }) => ($withPaddingTop ? theme.spacing(6) : 0)};
`;

export const StatusIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  border-right: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  padding-right: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(3)};
`;
