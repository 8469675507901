import { createSlice } from '@reduxjs/toolkit';
import { Member, MemberStatus, User } from '@stigg-types/apiTypes';
import { fetchUser } from './queries/getUser';
import { registerMember } from '../accounts/mutations/registerMembers';
import { addAccountAction, hideGettingStartedPageAction } from '../accounts/accountsSlice';
import { resendEmailVerification } from './mutations';
import { updateUser } from './mutations/updateUser';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface AuthState {
  isLoading: boolean;
  isRegisteringMember: boolean;
  isUpdatingUser: boolean;
  user: User | null;
  currentMember: Member | null;
}

const initialState: AuthState = {
  isLoading: false,
  isUpdatingUser: false,
  isRegisteringMember: false,
  user: null,
  currentMember: null,
};

const fetchUserAction = createAppAsyncThunk('fetchUser', fetchUser);
const registerMemberAction = createAppAsyncThunk('registerMember', registerMember);
const resendEmailVerificationAction = createAppAsyncThunk('resendEmailVerification', resendEmailVerification);
const updateUserAction = createAppAsyncThunk('updateUser', updateUser);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
        state.currentMember = action.payload.memberships.find(
          (member: Member) => member.memberStatus === MemberStatus.Registered,
        );
      }
      state.isLoading = false;
    });
    builder.addCase(fetchUserAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addAccountAction.fulfilled, (state, { payload }) => {
      if (payload && state.user) {
        state.user.memberships.push(payload);
      }
    });
    builder.addCase(registerMemberAction.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isRegisteringMember = false;
    });
    builder.addCase(registerMemberAction.rejected, (state) => {
      state.isRegisteringMember = false;
    });
    builder.addCase(registerMemberAction.pending, (state) => {
      state.isRegisteringMember = true;
    });

    builder.addCase(hideGettingStartedPageAction.fulfilled, (state) => {
      if (state?.currentMember) {
        state.currentMember.hideGettingStartedPage = true;
      }
    });

    builder.addCase(updateUserAction.pending, (state) => {
      state.isUpdatingUser = true;
    });
    builder.addCase(updateUserAction.fulfilled, (state, action) => {
      if (state.user) {
        state.user.name = action.meta.arg.name;
        state.user.department = action.meta.arg.department;
      }

      state.isUpdatingUser = false;
    });
    builder.addCase(updateUserAction.rejected, (state) => {
      state.isUpdatingUser = false;
    });
  },
});

export { fetchUserAction, registerMemberAction, resendEmailVerificationAction, updateUserAction };
export default authSlice.reducer;
