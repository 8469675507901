import get from 'lodash/get';
import { ReactNode } from 'react';
import {
  drawFormFields,
  FormProps,
  FormRenderProps,
  GridFlex,
  Text,
  Icon,
  IconButton,
  Icons,
  IconType,
  Button,
} from '@stigg-components';
import { FormikHelpers } from 'formik';
import { SubscriptionFormFields } from '../SubscriptionForm.types';

export type AdditionalInputActionParams = Pick<
  FormikHelpers<SubscriptionFormFields>,
  'setFieldValue' | 'setFieldTouched'
>;

export type AdditionalInputLayoutProps = {
  title?: string;
  subtitle?: string;
  isSet?: boolean;
  setIcon: Icons;
  setIconType?: IconType;
  unsetIcon?: Icons;
  unsetIconType?: IconType;
  setLabel: string;
  unsetLabel?: string;
  buttonLabel?: string;
  fields?: FormProps<SubscriptionFormFields>['fields'];
  inputComponent?: ReactNode;
  onSet?: (args: AdditionalInputActionParams) => void;
  onUnset?: (args: AdditionalInputActionParams) => void;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
};

function FormInput({
  onClick,
  fields,
  formRenderProps,
  inputComponent,
}: Pick<AdditionalInputLayoutProps, 'fields' | 'inputComponent' | 'formRenderProps'> & {
  onClick?: () => void;
}) {
  const { errors, touched } = formRenderProps;
  const fieldId = fields?.[0].id;
  const fieldError = get(errors, fieldId);
  const fieldTouched = get(touched, fieldId);

  return (
    <GridFlex.RowMiddle>
      {fields ? drawFormFields(fields, formRenderProps) : null}
      {inputComponent}
      {!!onClick && (
        <IconButton onClick={onClick}>
          <Icon icon="Close" />
        </IconButton>
      )}
      {fieldError && fieldTouched ? <Text.B2 color="error">{fieldError}</Text.B2> : null}
    </GridFlex.RowMiddle>
  );
}

function FormSetButton({ label, onClick }: { label?: string; onClick?: () => void }) {
  return (
    <GridFlex.Row>
      <Button
        variant="outlined"
        sx={{ justifyContent: 'left' }}
        fullWidth
        onClick={onClick}
        disabled={!onClick}
        startIcon={<Icon icon="Plus" />}>
        {label}
      </Button>
    </GridFlex.Row>
  );
}

export function AdditionalInputLayout({
  title,
  subtitle,
  isSet,
  setIcon,
  setIconType,
  unsetIcon,
  unsetIconType,
  setLabel,
  unsetLabel,
  buttonLabel,
  fields,
  inputComponent,
  onSet,
  onUnset,
  formRenderProps,
}: AdditionalInputLayoutProps) {
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const handleUnset = !onUnset
    ? undefined
    : () => {
        onUnset?.({ setFieldValue, setFieldTouched });
      };

  const handleSet = !onSet
    ? undefined
    : () => {
        onSet?.({ setFieldValue, setFieldTouched });
      };

  return (
    <GridFlex.Column gap={2}>
      {title && <Text.H6>{title}</Text.H6>}
      {subtitle && <Text.B2>{subtitle}</Text.B2>}
      <GridFlex.RowCenter minHeight={40}>
        <GridFlex.RowCenter gap={2} minWidth={150}>
          <Icon
            type={isSet ? setIconType : unsetIconType}
            icon={isSet ? setIcon : unsetIcon || setIcon}
            color="default"
          />
          <Text.B2>{isSet ? setLabel : unsetLabel || setLabel}</Text.B2>
        </GridFlex.RowCenter>
        {isSet ? (
          <FormInput
            onClick={handleUnset}
            fields={fields}
            inputComponent={inputComponent}
            formRenderProps={formRenderProps}
          />
        ) : (
          <FormSetButton label={buttonLabel} onClick={handleSet} />
        )}
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
