import { GridFlex, Text, Icon, ExternalLink } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { TooltipFields } from '../../../../components/InformationTooltip';

export function SingleSubscriptionsLabel({ disabled }: { disabled: boolean }) {
  return (
    <GridFlex.RowCenter gap={1}>
      <Icon icon={disabled ? 'SingleSubscriptionDisabled' : 'SingleSubscription'} type="custom" size={35} />
      <Text.B2 color={disabled ? 'disabled' : 'primary'}>{t('products.singleActiveSubscriptions')}</Text.B2>
    </GridFlex.RowCenter>
  );
}
export function MultipleSubscriptionsLabel({ disabled }: { disabled: boolean }) {
  return (
    <GridFlex.RowCenter gap={1}>
      <Icon icon={disabled ? 'MultipleSubscriptionsDisabled' : 'MultipleSubscriptions'} type="custom" size={35} />
      <Text.B2 color={disabled ? 'disabled' : 'primary'}>{t('products.multipleActiveSubscriptions')}</Text.B2>
    </GridFlex.RowCenter>
  );
}

export function SubscriptionTypeTooltipContent({ multipleSubscriptions }: { multipleSubscriptions: boolean }) {
  return (
    <GridFlex.Column gap={2}>
      <Text.B2>
        {multipleSubscriptions
          ? t('products.multipleActiveSubscriptionsTooltip')
          : t('products.singleActiveSubscriptionsTooltip')}
      </Text.B2>
      <ExternalLink label={t('sharedComponents.learnMore')} url="https://docs.stigg.io/docs/multiple-subscriptions" />
    </GridFlex.Column>
  );
}

export function createSubscriptionTypeTooltipFields(multipleSubscriptions: boolean): TooltipFields {
  return {
    placement: 'top',
    maxWidth: 250,
    title: <SubscriptionTypeTooltipContent multipleSubscriptions={multipleSubscriptions} />,
  };
}

export function createNotSupportedByAwsProductTooltipFields(): TooltipFields {
  return {
    placement: 'top',
    maxWidth: 250,
    title: (
      <GridFlex.Column>
        <Text.B2>{t('products.multipleActiveSubscriptionsNotSupportedByAwsProducts')}</Text.B2>
      </GridFlex.Column>
    ),
  };
}
