import { Button, drawFormFields, Field, FormRenderProps, Grid, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { ProductListItemFragment } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { ExperimentPreset } from './ExperimentWizard';
import { CollapsePageCard } from '../../../../components/CollapsePageCard';

export function DetailsCard({
  formRenderProps,
  products,
  setVariationCardOpen,
  variationCardOpen,
}: {
  formRenderProps: FormRenderProps<ExperimentPreset>;
  products: ProductListItemFragment[];
  setVariationCardOpen: () => void;
  variationCardOpen: boolean;
}) {
  const fields: Field<ExperimentPreset>[] = [
    {
      id: 'productId',
      type: 'select',

      label: 'What product should this experiment cover',
      hide: () => products.length < 2,
      values: products.map((product: ProductListItemFragment) => ({
        value: product.id,
        displayValue: <Text.B2>{product.displayName}</Text.B2>,
      })),
    },
    {
      autoFocus: true,
      label: t('experiments.wizard.experimentDetailsNameLabel'),
      type: 'text',
      id: 'name',

      placeholder: t('experiments.useCases.customerJourney.wizard.experimentDetailsNamePlaceholder'),
    },
    {
      label: t('experiments.wizard.experimentDetailsDescription'),
      optional: true,
      type: 'text',
      id: 'description',

      placeholder: t('experiments.wizard.experimentDetailsDescriptionPlaceholder'),
    },
  ];

  const nextButtonDisabled =
    !isEmpty(formRenderProps.errors.name) ||
    !isEmpty(formRenderProps.errors.productId) ||
    !isEmpty(formRenderProps.errors.description);

  return (
    <CollapsePageCard contentOpen cardTitle={<Text.H6>{t('experiments.wizard.experimentDetailsTitle')}</Text.H6>}>
      <GridFlex.Column container rowSpacing={3}>
        {drawFormFields(fields, formRenderProps)}
        {!variationCardOpen && (
          <Grid item alignSelf="end">
            <Button variant="contained" $outlined onClick={setVariationCardOpen} disabled={nextButtonDisabled}>
              {t('sharedComponents.nextButton')}
            </Button>
          </Grid>
        )}
      </GridFlex.Column>
    </CollapsePageCard>
  );
}
