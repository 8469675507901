import {
  Box,
  DottedText,
  drawFormFields,
  Field,
  FormRenderProps,
  GridFlex,
  InformationTooltip,
  Text,
} from '@stigg-components';
import { t } from 'i18next';
import {
  PackageStatus,
  Plan,
  PricingType,
  ProductPlanFragment,
  SubscriptionStartSetup,
  TrialPeriodUnits,
} from '@stigg-types/apiTypes';
import React, { useEffect, useMemo } from 'react';
import * as S from './CustomerJourney.style';
import { LinkToCreatePlan, LinkToEditPlan } from '../../../../packages/common/components/PlanTooltipsCollection';
import { TooltipFields } from '../../../../../components/InformationTooltip';
import { CustomerJourneyPlan } from './types';

export type SubscriptionStartSetupFormFields = {
  subscriptionStartSetup?: SubscriptionStartSetup;
  subscriptionStartTrialPlanId?: string;
  subscriptionStartFreePlanId?: string;
  defaultTrialConfig?: ProductPlanFragment['defaultTrialConfig'];
  trialUnits?: TrialPeriodUnits;
};

export const SubscriptionStartSetupFormFieldsNames: (keyof SubscriptionStartSetupFormFields)[] = [
  'subscriptionStartSetup',
  'subscriptionStartTrialPlanId',
  'subscriptionStartFreePlanId',
];

type SubscriptionStartSetupConfigurationProps = {
  productPlans: CustomerJourneyPlan[];
  productId: string;
  formRenderProps: FormRenderProps<SubscriptionStartSetupFormFields>;
  readonly?: boolean;
  readonlyHideDottedText?: boolean;
  readonlyTooltip?: TooltipFields;
  highlight?: boolean;
};

function formatSubscriptionStartSetup(subscriptionStartSetup: SubscriptionStartSetup) {
  switch (subscriptionStartSetup) {
    case SubscriptionStartSetup.PlanSelection:
      return t('products.customerJourney.subscriptionStartsPlanSelection');
    case SubscriptionStartSetup.TrialPeriod:
      return t('products.customerJourney.subscriptionStartsTrialPeriod');
    case SubscriptionStartSetup.FreePlan:
      return t('products.customerJourney.subscriptionStartsFreePlan');
    default:
      return `${subscriptionStartSetup}`;
  }
}

function computeTrialPeriodPlanItemOptions(plan: CustomerJourneyPlan): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  if (plan.status === PackageStatus.Draft) {
    return {
      disabled: true,
      tooltipTitle: <Text.B2>{t('products.customerJourney.publishDraftToChoseIt')}</Text.B2>,
    };
  }
  if (!plan.defaultTrialConfig) {
    return {
      disabled: true,
      tooltipTitle: <LinkToEditPlan plan={plan} i18nKey="products.customerJourney.planDoesNotHaveTrialPeriod" />,
    };
  }
  return { disabled: false };
}

function computeTrialPeriodFields(
  productPlans: CustomerJourneyPlan[],
  values: SubscriptionStartSetupFormFields,
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionStartSetupFormFields>[] {
  const selectedPlan = productPlans.find((plan) => plan.id === values.subscriptionStartTrialPlanId);

  return [
    {
      type: 'layout',
      label: t('products.customerJourney.selectTrialPlan'),
      labelGridProps: { sx: { display: 'flex', alignItems: 'center' } },
      fields: [
        {
          id: 'subscriptionStartTrialPlanId',
          type: 'select',
          restProps: {
            width: 180,
          },
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: productPlans
            .filter((plan) => plan.pricingType !== PricingType.Free)
            .map((plan) => {
              const { disabled, tooltipTitle } = computeTrialPeriodPlanItemOptions(plan);

              return {
                value: plan.id,
                disabled,
                tooltip: {
                  title: tooltipTitle || undefined,
                },
                displayValue: (
                  <Text.B2 color="primary">
                    {t(
                      plan.status === PackageStatus.Draft
                        ? 'sharedComponents.planDraftSuffix'
                        : 'sharedComponents.planSuffix',
                      { planName: plan.displayName },
                    )}
                  </Text.B2>
                ),
              };
            }),
        },
      ],
    },
    {
      type: 'layout',
      label: t('products.customerJourney.trialPeriod'),
      hide: () => !selectedPlan || !values.defaultTrialConfig,
      fields: [
        {
          type: 'custom',
          render: ({ values }) => (
            <Box>
              <InformationTooltip
                arrow
                placement="right"
                title={
                  (!readonly && (
                    <LinkToEditPlan plan={selectedPlan!} i18nKey="products.customerJourney.editPlanTrialPeriod" />
                  )) ||
                  ''
                }>
                <Box display="inline">
                  <DottedText display="inline">
                    {values.defaultTrialConfig!.duration}{' '}
                    {values.defaultTrialConfig!.units === TrialPeriodUnits.Month
                      ? t('pricing.trials.months')
                      : t('pricing.trials.days')}
                  </DottedText>
                </Box>
              </InformationTooltip>
            </Box>
          ),
        },
      ],
    },
  ];
}

function computeFreePlanItemOptions(plan: CustomerJourneyPlan): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  if (plan.status === PackageStatus.Draft) {
    return {
      disabled: true,
      tooltipTitle: <Text.B2>{t('products.customerJourney.publishDraftToChoseIt')}</Text.B2>,
    };
  }
  return { disabled: false };
}

function computeFreePlanFields(
  productPlans: CustomerJourneyPlan[],
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionStartSetupFormFields>[] {
  return [
    {
      type: 'layout',
      label: t('products.customerJourney.selectFreePlan'),
      labelGridProps: { sx: { display: 'flex', alignItems: 'center' } },
      fields: [
        {
          id: 'subscriptionStartFreePlanId',
          type: 'select',
          restProps: {
            width: 180,
          },
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: productPlans
            .filter((plan) => plan.pricingType === PricingType.Free)
            .map((plan) => {
              const { disabled, tooltipTitle } = computeFreePlanItemOptions(plan);

              return {
                value: plan.id,
                disabled,
                tooltip: {
                  title: tooltipTitle || undefined,
                },
                displayValue: (
                  <Text.B2 color="primary">
                    {t(
                      plan.status === PackageStatus.Draft
                        ? 'sharedComponents.planDraftSuffix'
                        : 'sharedComponents.planSuffix',
                      { planName: plan.displayName },
                    )}
                  </Text.B2>
                ),
              };
            }),
        },
      ],
    },
  ];
}

function computeSubscriptionStartSetupItemOptions(
  subscriptionStartSetup: SubscriptionStartSetup,
  productPlans: CustomerJourneyPlan[],
  productRefId: string,
): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  const publishPlans = productPlans.filter((x) => (x as Plan).status === PackageStatus.Published);
  const hasFreePlans = publishPlans.some((x) => (x as Plan).pricingType === PricingType.Free);
  const hasNonFreePlans = publishPlans.some((x) => (x as Plan).pricingType !== PricingType.Free);

  if (subscriptionStartSetup === SubscriptionStartSetup.FreePlan && !hasFreePlans) {
    return {
      disabled: true,
      tooltipTitle: (
        <LinkToCreatePlan productId={productRefId} i18nKey="products.customerJourney.noFreePlansForStartPlan" />
      ),
    };
  }

  if (subscriptionStartSetup === SubscriptionStartSetup.TrialPeriod && !hasNonFreePlans) {
    return {
      disabled: true,
      tooltipTitle: (
        <LinkToCreatePlan productId={productRefId} i18nKey="products.customerJourney.noPaidPlansForStartPlan" />
      ),
    };
  }

  return { disabled: false };
}

function computeFields(
  productPlans: CustomerJourneyPlan[],
  productId: string,
  subConfigurationFields: Field<SubscriptionStartSetupFormFields>[],
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionStartSetupFormFields>[] {
  return [
    {
      type: 'inline',
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline">
              {t('products.customerJourney.subscriptionStartsAt')}
            </Text.B2>
          ),
        },
        {
          id: 'subscriptionStartSetup',
          type: 'select',
          variant: 'inline',
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: [
            SubscriptionStartSetup.PlanSelection,
            SubscriptionStartSetup.TrialPeriod,
            SubscriptionStartSetup.FreePlan,
          ].map((subscriptionStartSetup) => {
            const { disabled, tooltipTitle } = computeSubscriptionStartSetupItemOptions(
              subscriptionStartSetup,
              productPlans,
              productId,
            );

            return {
              value: subscriptionStartSetup,
              disabled,
              tooltip: {
                title: tooltipTitle || undefined,
              },
              displayValue: formatSubscriptionStartSetup(subscriptionStartSetup),
            };
          }),
        },
      ],
    },
    ...subConfigurationFields,
  ];
}

function useFormFields(
  productPlans: CustomerJourneyPlan[],
  productId: string,
  { values }: FormRenderProps<SubscriptionStartSetupFormFields>,
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
) {
  const subscriptionStartsTrialPeriodFields = useMemo(
    () => computeTrialPeriodFields(productPlans, values, readonly, readonlyHideDottedText, readonlyTooltip),
    [productPlans, values, readonly, readonlyHideDottedText, readonlyTooltip],
  );
  const subscriptionStartsFreePlanFields = useMemo(
    () => computeFreePlanFields(productPlans, readonly, readonlyHideDottedText, readonlyTooltip),
    [productPlans, readonly, readonlyHideDottedText, readonlyTooltip],
  );
  const subConfigurationFields = useMemo(() => {
    switch (values.subscriptionStartSetup) {
      case SubscriptionStartSetup.TrialPeriod:
        return subscriptionStartsTrialPeriodFields;
      case SubscriptionStartSetup.FreePlan:
        return subscriptionStartsFreePlanFields;
      default:
        return [];
    }
  }, [values.subscriptionStartSetup, subscriptionStartsTrialPeriodFields, subscriptionStartsFreePlanFields]);

  return useMemo<Field<SubscriptionStartSetupFormFields>[]>(
    () =>
      computeFields(productPlans, productId, subConfigurationFields, readonly, readonlyHideDottedText, readonlyTooltip),
    [productPlans, productId, subConfigurationFields, readonly, readonlyHideDottedText, readonlyTooltip],
  );
}

function useAutoTrialPeriodFiller(
  productPlans: CustomerJourneyPlan[],
  { values, setFieldValue }: FormRenderProps<SubscriptionStartSetupFormFields>,
) {
  useEffect(() => {
    if (values.subscriptionStartSetup === SubscriptionStartSetup.TrialPeriod) {
      const selectedPlan = productPlans.find((plan) => plan.id === values.subscriptionStartTrialPlanId);
      setFieldValue('defaultTrialConfig', selectedPlan?.defaultTrialConfig);
    }
  }, [values.subscriptionStartSetup, values.subscriptionStartTrialPlanId, productPlans, setFieldValue]);
}

export function SubscriptionStartSetupConfiguration({
  productPlans,
  formRenderProps,
  productId,
  readonly = false,
  readonlyHideDottedText = false,
  readonlyTooltip,
  highlight,
}: SubscriptionStartSetupConfigurationProps) {
  const fields = useFormFields(
    productPlans,
    productId,
    formRenderProps,
    readonly,
    readonlyHideDottedText,
    readonlyTooltip,
  );

  useAutoTrialPeriodFiller(productPlans, formRenderProps);

  const content = <GridFlex.Column>{drawFormFields(fields, formRenderProps)}</GridFlex.Column>;

  return highlight ? (
    <S.StyledBox $isHighlight={highlight} mt={4}>
      {content}
    </S.StyledBox>
  ) : (
    content
  );
}
