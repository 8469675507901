import { AddonGroupAddonFragment, BillingCadence, PlanCompatibleAddonFragment } from '@stigg-types/apiTypes';
import { GridFlex, Icon, Link, OptionsDropdown, Text, TwoLinesLayout, HeadCell } from '@stigg-components';
import { t } from 'i18next';
import { Trash2 } from 'react-feather';
import isNil from 'lodash/isNil';
import { Navigation } from '../../../navigation/useNavigation';
import { getBillingPeriodText } from '../../common/components/getBillingPeriodText';
import { isCompatibleAddonSingleInstance } from '../../common/packageUtils';

export const createHeadCells = (
  navigation: Navigation,
  removeAddonFromAddonGroup: (addonId: string) => void,
): Array<HeadCell<AddonGroupAddonFragment, any>> => [
  {
    id: 'id',
    alignment: 'left',
    label: t('addons.addonGroups.addonsTableColumns.addon'),
    render: (entity: AddonGroupAddonFragment) => {
      const isOneOff = entity.prices?.some((price) => price.billingCadence === BillingCadence.OneOff);
      return (
        <GridFlex.RowCenter>
          <Icon type="custom" icon={isOneOff ? 'OneOff' : 'Recurring'} />
          <GridFlex.Column ml={4} rowGap={1}>
            <Link
              onClick={() => {
                navigation.navigateTo(navigation.appRoutes.addonPage(entity.refId));
              }}>
              {entity.displayName}
            </Link>
            <Text.Caption color="secondary">{entity.refId}</Text.Caption>
          </GridFlex.Column>
        </GridFlex.RowCenter>
      );
    },
  },
  {
    id: 'type',
    alignment: 'left',
    label: t('addons.addonGroups.addonsTableColumns.addonType'),
    render: (entity: AddonGroupAddonFragment) => (
      <Text.B2>
        {isCompatibleAddonSingleInstance(entity as PlanCompatibleAddonFragment)
          ? t('packages.singleAddonType')
          : t('packages.multiAddonType')}
      </Text.B2>
    ),
  },
  {
    id: 'priceType',
    alignment: 'left',
    label: t('addons.addonGroups.addonsTableColumns.priceType'),
    render: (entity: AddonGroupAddonFragment) => {
      const billingPeriodText = getBillingPeriodText(entity.prices || []);
      return (
        <TwoLinesLayout
          firstRow={
            isNil(entity.pricingType) ? t(`pricing.pricingTypeNotSet`) : t(`pricing.pricingType.${entity.pricingType}`)
          }
          secondRow={billingPeriodText}
          secondLineColor="secondary"
        />
      );
    },
  },
  {
    id: 'options',
    alignment: 'right',
    width: 56,
    maxWidth: 36 + 8,
    label: '',
    render: (entity: AddonGroupAddonFragment) => {
      const onRemove = () => {
        removeAddonFromAddonGroup(entity.refId);
      };
      return (
        <OptionsDropdown
          options={[
            {
              icon: Trash2,
              text: t('plans.removeAddon'),
              onClick: onRemove,
            },
          ]}
        />
      );
    },
  },
];
