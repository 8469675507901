import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Toolbar, GridFlex } from '@stigg-components';
import { FeatureFlags } from '@stigg-types/featureFlags';
import isEmpty from 'lodash/isEmpty';
import { MenuAndBreadcrumbs, TasksProgress, usePollSubscriptionMigrationTasks } from './components';
import * as S from './Navbar.style';
import { useCurrentImportTask } from './components/tasks/useCurrentImportTask';
import { CommandBarLauncher } from './components/commandbar/CommandBarLauncher';

const TASKS_POLLING_INTERVAL_MS = 1000;

const Navbar = ({
  onDrawerToggle,
  isDrawerExpanded,
  drawerWidth,
  marginTop,
}: {
  isDrawerExpanded: boolean;
  drawerWidth: number;
  marginTop?: number;
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
}) => {
  const { migrationTaskPollingInterval, commandBar: commandBarEnabled } = useFlags<FeatureFlags>();
  const subscriptionMigrationTasks = usePollSubscriptionMigrationTasks(
    migrationTaskPollingInterval || TASKS_POLLING_INTERVAL_MS,
  );
  const inProgressImportTask = useCurrentImportTask();

  return (
    <S.AppBar $drawerWidth={drawerWidth} $isDrawerExpanded={isDrawerExpanded} $top={marginTop}>
      <Toolbar>
        <GridFlex.RowSpaceBetween container>
          <MenuAndBreadcrumbs onDrawerToggle={onDrawerToggle} isDrawerExpanded={isDrawerExpanded} />
          <GridFlex.RowCenter mr={8}>
            <GridFlex.Item>
              {(!isEmpty(subscriptionMigrationTasks) || inProgressImportTask) && (
                <TasksProgress
                  subscriptionMigrationTasks={subscriptionMigrationTasks}
                  importTask={inProgressImportTask}
                />
              )}
            </GridFlex.Item>
            {commandBarEnabled ? (
              <GridFlex.Item ml={2}>
                <CommandBarLauncher />
              </GridFlex.Item>
            ) : null}
          </GridFlex.RowCenter>
        </GridFlex.RowSpaceBetween>
      </Toolbar>
    </S.AppBar>
  );
};

export default Navbar;
