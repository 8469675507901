import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import {
  BillingCadence,
  BillingModel,
  BillingPeriod,
  CouponFragment,
  CouponType,
  Currency,
  Money,
  PriceFragment,
  PriceTierFragment,
  SubscriptionCouponDataFragment,
  TiersMode,
} from '@stigg-types/apiTypes';
import { EMPTY_CHAR } from '@stigg-components';
import isNil from 'lodash/isNil';
import { getPriceAndPeriodFormat } from '../../../../../packages/pricing/utils/priceFormatUtils';
import { currencyPriceFormatter } from '../../../../../packages/pricing/components/currency/currencyUtils';
import { SubscriptionBillableFeatures } from './subscriptionBillableFeatures';
import { findTierByQuantity, getPrice } from '../../../../../packages/pricing/utils/getPrice';
import { isBulkTiers, isQuantityInFirstTier } from '../../../../../packages/pricing/utils/priceTiersUtils';
import { numberFormatter } from '../../../../../../utils/numberUtils';
import { FreeItem } from './useSubscriptionPriceBreakdown';

type AddonPrice = { price?: PriceFragment | null; quantity?: number; displayName: string; addonId: string };

type ShowStartsAtProps = {
  hasMultipleCharges: boolean;
  hasPayAsYouGoPrice: boolean;
  hasTieredPrice: boolean;
  hasPlanMinimum: boolean;
  hasChargeWithMinQuantity: boolean;
  billableFeatures?: SubscriptionBillableFeatures;
};

const freePrice: PriceFragment = {
  id: 'free-shadow-price',
  price: {
    amount: 0,
    currency: Currency.Usd,
  },
  billingCadence: BillingCadence.Recurring,
  billingModel: BillingModel.FlatFee,
  billingPeriod: BillingPeriod.Monthly,
  isOverridePrice: false,
};

function calculatePriceWithDiscount(
  price: number,
  coupon?: CouponFragment | null,
  subscriptionCoupon?: SubscriptionCouponDataFragment | null,
) {
  if (!coupon && !subscriptionCoupon) {
    return price;
  }

  const discountValue = coupon?.discountValue || subscriptionCoupon?.discountValue || 0;
  if (coupon || (subscriptionCoupon && subscriptionCoupon.type === CouponType.Percentage)) {
    return price * ((100 - discountValue) / 100);
  }
  if (subscriptionCoupon?.type === CouponType.Fixed) {
    return price - discountValue;
  }

  return price;
}

export type PlanPriceWithQuantity = { price: PriceFragment; unitQuantity: number };

export type PlanBreakdown = {
  prices: PlanPriceWithQuantity[];
  pricesAfterDiscount: PlanPriceWithQuantity[];
  total: number;
};

export type GetPriceBreakdownProps = {
  planPrices: PriceFragment[];
  addonsPrices: AddonPrice[];
  billableFeatures?: SubscriptionBillableFeatures;
  coupon?: CouponFragment | null;
  subscriptionCoupon?: SubscriptionCouponDataFragment | null;
  withZeroQuantityPrices?: boolean;
  paidOneOffAddons?: AddonPrice[];
  freeItems?: FreeItem[] | null;
  minimumSpend?: number | null;
};

export type PriceBreakdown = {
  total: number;
  subtotal: number;
  oneOffTotal: number;
  oneOffSubtotal: number;
  minimumSpend?: number | null;
  addonsBreakDown: any;
  planBreakdown: PlanBreakdown;
  billingPeriod: BillingPeriod;
  hasUsageBasedPrice: boolean;
  hasPayAsYouGoPrice: boolean;
  withStartsAt: boolean;
  currency: Currency;
};

function shouldShowStartsAt({
  hasMultipleCharges,
  hasPayAsYouGoPrice,
  hasTieredPrice,
  hasPlanMinimum,
  hasChargeWithMinQuantity,
  billableFeatures,
}: ShowStartsAtProps) {
  // plan context
  if (isNil(billableFeatures)) {
    return hasMultipleCharges || hasTieredPrice || hasPlanMinimum || hasChargeWithMinQuantity;
  }

  // subscription context
  return hasPayAsYouGoPrice;
}

function getAddonsTotal(addonsPrices: AddonPrice[]) {
  return addonsPrices.reduce(
    ({ recurringAddonsTotal, oneOffAddonsTotal }, addon) => {
      const { price, quantity } = addon;
      if (price && quantity) {
        if (price?.billingCadence === BillingCadence.OneOff) {
          oneOffAddonsTotal += getPrice(price).amount * quantity;
        } else {
          recurringAddonsTotal += getPrice(price).amount * quantity;
        }
      }
      return { recurringAddonsTotal, oneOffAddonsTotal };
    },
    { recurringAddonsTotal: 0, oneOffAddonsTotal: 0 },
  );
}

function ignorePaidOneOffAddons(addonsPrices: AddonPrice[], paidOneOffAddons: AddonPrice[]): AddonPrice[] {
  return addonsPrices.flatMap((addon) => {
    const { price, quantity } = addon;
    if (!price?.price || !quantity) {
      return [];
    }
    const paidAddon =
      addon.price?.billingCadence === BillingCadence.OneOff &&
      paidOneOffAddons.find((paidAddon) => paidAddon.price?.id === addon.price?.id);
    if (paidAddon) {
      const { quantity: paidQuantity } = paidAddon;
      if (!paidQuantity || paidQuantity >= quantity) {
        return [];
      }
      const newAddon = cloneDeep(addon);
      newAddon.quantity = quantity - paidQuantity;

      return [newAddon];
    }
    return [addon];
  });
}

// why don't we just pass the subscription/addons/plan and handle prices, billable features, etc'?
export const getPriceBreakdown = ({
  planPrices,
  addonsPrices,
  billableFeatures,
  coupon,
  subscriptionCoupon,
  withZeroQuantityPrices,
  paidOneOffAddons,
  freeItems,
  minimumSpend,
}: GetPriceBreakdownProps): PriceBreakdown => {
  let planTotal = 0;
  let hasUsageBasedPrice = false;
  let hasPayAsYouGoPrice = false;
  let hasTieredPrice = false;
  let hasChargeWithMinQuantity = false;

  const billingPeriod = planPrices?.[0]?.billingPeriod;
  const planPricesWithQuantity = compact(
    planPrices.map((planPrice) => {
      if (planPrice.minUnitQuantity) {
        hasChargeWithMinQuantity = true;
      }

      if (planPrice.billingModel === BillingModel.UsageBased) {
        hasUsageBasedPrice = true;
        hasPayAsYouGoPrice = true;
        return { price: planPrice, unitQuantity: 1 };
      }

      if (planPrice.billingModel === BillingModel.FlatFee) {
        const pricePoint = getPrice(planPrice);
        planTotal += pricePoint.amount;
        return { price: planPrice, unitQuantity: 1 };
      }

      if (planPrice.billingModel === BillingModel.PerUnit) {
        hasUsageBasedPrice = true;
        hasTieredPrice = hasTieredPrice || !!planPrice.tiers;
        const unitQuantity =
          billableFeatures?.[planPrice.feature?.refId || ''] ||
          planPrice.minUnitQuantity ||
          (withZeroQuantityPrices ? 0 : 1);
        const pricePoint = getPrice(planPrice, { unitQuantity, totalPrice: true });
        planTotal += pricePoint.amount;

        return { price: planPrice, unitQuantity };
      }

      return null;
    }),
  );

  let pricesAfterDiscount;
  if (coupon || subscriptionCoupon) {
    pricesAfterDiscount = planPricesWithQuantity.map(({ price: planPrice, unitQuantity }) => {
      const priceAfterDiscount = cloneDeep(planPrice);
      const pricePoint = getPrice(priceAfterDiscount, { unitQuantity });
      pricePoint.amount = calculatePriceWithDiscount(pricePoint.amount, coupon, subscriptionCoupon);

      return { price: priceAfterDiscount, unitQuantity };
    });
  }

  const planBreakdown: PlanBreakdown = {
    prices: planPricesWithQuantity,
    pricesAfterDiscount,
    total: planTotal,
  };
  const updatedAddonsPrices = ignorePaidOneOffAddons(addonsPrices, paidOneOffAddons ?? []);
  const withFreeAddons: AddonPrice[] =
    freeItems && freeItems.length > 0
      ? updatedAddonsPrices.flatMap((addon) => {
          const { price, quantity, addonId } = addon;
          if (!price?.price || !quantity) {
            return addon;
          }
          const freeAddon = freeItems?.find((freeItem) => freeItem.addonId === addonId);
          if (freeAddon) {
            const remainingPaidQuantity = quantity - freeAddon.quantity;
            return [
              {
                ...addon,
                price: freePrice,
                quantity: freeAddon.quantity,
              },
              ...(remainingPaidQuantity > 0 ? [{ ...addon, quantity: remainingPaidQuantity }] : []),
            ];
          }
          return addon;
        })
      : updatedAddonsPrices;
  const { recurringAddonsTotal, oneOffAddonsTotal } = getAddonsTotal(withFreeAddons);
  const addonsBreakDown = withFreeAddons
    .flatMap((addon) => {
      const { price, quantity } = addon;
      if (!price?.price || !quantity) {
        return null;
      }

      return {
        price,
        addon,
        quantity,
        total: price.price.amount * quantity,
      };
    })
    .filter((x) => !!x);

  const itemsTotal = planTotal + recurringAddonsTotal + (minimumSpend || 0);
  const hasMultipleCharges = planPrices.length > 1;

  const totalWithDiscount = calculatePriceWithDiscount(itemsTotal, coupon, subscriptionCoupon);

  const hasPlanMinimum = !!minimumSpend;

  const withStartsAt = shouldShowStartsAt({
    hasMultipleCharges,
    hasPayAsYouGoPrice,
    hasTieredPrice,
    hasPlanMinimum,
    hasChargeWithMinQuantity,
    billableFeatures,
  });

  return {
    total: totalWithDiscount,
    subtotal: itemsTotal,
    minimumSpend,
    oneOffTotal: calculatePriceWithDiscount(oneOffAddonsTotal, coupon, subscriptionCoupon),
    oneOffSubtotal: oneOffAddonsTotal,
    withStartsAt,
    addonsBreakDown,
    planBreakdown,
    hasUsageBasedPrice,
    hasPayAsYouGoPrice,
    billingPeriod,
    currency: getPrice(planPrices?.[0]).currency,
  };
};

export function formatPricePerUnit({
  unitQuantity,
  billingPeriod,
  billingCadence,
  billingModel,
  showMonthlyPriceVariation,
  totalAmount,
  currency,
  blockSize,
  pricePoint,
  priceTier,
}: {
  unitQuantity: number;
  billingPeriod: BillingPeriod;
  billingCadence: BillingCadence;
  billingModel: BillingModel;
  showMonthlyPriceVariation: boolean;
  totalAmount: number;
  currency: Currency;
  blockSize?: number | null;
  pricePoint?: Money | null;
  priceTier?: PriceTierFragment | null;
}) {
  const aBlockSize = blockSize || 1;
  const isPerUnit = billingModel === BillingModel.PerUnit;
  const featureUnits =
    unitQuantity && (isPerUnit || unitQuantity > 1)
      ? `${numberFormatter(unitQuantity)} ${aBlockSize > 1 ? 'for' : 'x'} `
      : '';
  let billingPeriodString = '';

  let unitPrice = pricePoint?.amount || totalAmount / unitQuantity;
  let flatPrice: number | undefined;
  if (priceTier) {
    flatPrice = priceTier.flatPrice?.amount;
    unitPrice = priceTier.unitPrice?.amount || 0;
  }

  if (
    billingPeriod === BillingPeriod.Annually &&
    showMonthlyPriceVariation &&
    billingCadence === BillingCadence.Recurring
  ) {
    billingPeriodString = ' x 12 months';
    unitPrice /= 12;
    flatPrice = flatPrice ? flatPrice / 12 : undefined;
  }

  const formattedFlatPrice = flatPrice
    ? currencyPriceFormatter({
        amount: flatPrice,
        currency,
        options: { withCodePostfix: false },
      })
    : null;
  const formattedUnitPrice = currencyPriceFormatter({
    amount: unitPrice,
    currency,
    options: { withCodePostfix: false },
  });
  const formattedTotalPrice = currencyPriceFormatter({
    amount: totalAmount,
    currency,
    options: { withCodePostfix: false },
  });

  return `${featureUnits}${formattedUnitPrice}${billingPeriodString}${
    formattedFlatPrice ? ` + ${formattedFlatPrice}` : ''
  }${billingPeriodString} ${billingPeriodString || featureUnits ? ` =  ${formattedTotalPrice}` : ''}`;
}

export const getAddonPriceBreakdownString = (
  addonBreakdown: { price: PriceFragment; addon: AddonPrice; quantity: number; total: number },
  showMonthlyPriceVariation: boolean,
) => {
  const { price, quantity: unitQuantity } = addonBreakdown;
  const { billingPeriod, billingCadence, billingModel, blockSize, price: pricePoint, tiers } = price;
  const { amount: totalAmount, currency } = getPrice(price, {
    unitQuantity,
    totalPrice: true,
  });
  let priceTier: PriceTierFragment | undefined;
  if (tiers) {
    priceTier = findTierByQuantity(tiers, unitQuantity);
  }

  return formatPricePerUnit({
    unitQuantity,
    billingPeriod,
    billingCadence,
    billingModel,
    showMonthlyPriceVariation,
    totalAmount,
    currency,
    blockSize,
    pricePoint,
    priceTier,
  });
};

export const getPlanPriceBreakdownString = ({
  price,
  unitQuantity,
  showMonthlyPriceVariation,
  totalPrice = true,
}: {
  price: PriceFragment;
  unitQuantity: number;
  showMonthlyPriceVariation: boolean;
  totalPrice?: boolean;
}) => {
  const { billingPeriod, billingCadence, billingModel, blockSize, price: pricePoint, tiers } = price;
  const isPerUnit = billingModel === BillingModel.PerUnit;

  if (billingModel === BillingModel.UsageBased) {
    return `${getPriceAndPeriodFormat(price, { shortFormat: true })}`;
  }

  if (isPerUnit && unitQuantity === 0) {
    return EMPTY_CHAR;
  }

  const { amount, currency } = getPrice(price, { unitQuantity, totalPrice });
  const formattedTotalPrice = currencyPriceFormatter({
    amount,
    currency,
    options: { withCodePostfix: false },
  });

  if (
    isBulkTiers(price.tiers) ||
    (price.tiersMode === TiersMode.Graduated && !isQuantityInFirstTier(price.tiers, unitQuantity))
  ) {
    return `${numberFormatter(unitQuantity)} for ${formattedTotalPrice}`;
  }
  let priceTier: PriceTierFragment | undefined;
  if (tiers) {
    priceTier = findTierByQuantity(tiers, unitQuantity);
  }

  return formatPricePerUnit({
    unitQuantity,
    billingPeriod,
    billingCadence,
    billingModel,
    showMonthlyPriceVariation,
    totalAmount: amount,
    currency,
    blockSize,
    pricePoint,
    priceTier,
  });
};
