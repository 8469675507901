import { gql } from '@apollo/client';
import { t } from 'i18next';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const GET_USER = gql`
  query GetUser {
    currentUser {
      ...UserFragment
    }
  }
  fragment UserFragment on User {
    id
    email
    name
    department
    intercomToken
    memberships {
      id
      serviceApiKey
      memberStatus
      hideGettingStartedPage
      account {
        id
        displayName
        accountStatus
      }
      cubejsToken
      customerToken
    }
  }
`;

async function fetchUser(_: void, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query({
        query: GET_USER,
        fetchPolicy: 'network-only',
      });
      return response.data?.currentUser;
    },
    { failureMessageHandler: () => t('accounts.api.failToFetchUser') },
    dispatch,
  );
}

export { fetchUser };
