import { t } from 'i18next';
import get from 'lodash/get';
import { useEffect } from 'react';
import { CustomTable, Text, TextField, FormRenderProps } from '@stigg-components';
import { BillingModel, PaymentCollectionMethod, PriceFragment } from '@stigg-types/apiTypes';
import { SubscriptionFormFields } from '../SubscriptionForm.types';
import { calculateUnitQuantityTextFromPriceFragments } from '../../../../../../packages/common/components/pricingTypeViews/calculateUnitQuantity';
import { ChargeTitle } from '../../../../../../packages/pricing/components/SetPriceWizard/components/ChargeTitle';
import { ChargeType } from '../../../../../../packages/pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';
import { useChargesSort } from '../../useChargeSort';
import { TierSelection } from './TierSelection';
import { isBulkTiers } from '../../../../../../packages/pricing/utils/priceTiersUtils';
import { SubscriptionCharge } from './SubscriptionCharge';

type ChargesTableProps = {
  charges: PriceFragment[];
  height?: number;
  formProps: FormRenderProps<SubscriptionFormFields>;
  showPriceByMonth?: boolean;
};

export function ChargesTable({ charges, formProps, height, showPriceByMonth }: ChargesTableProps) {
  const { values, errors, touched, setFieldValue, handleBlur } = formProps;
  const { paymentCollectionMethod, billableFeatures } = values;

  const sortedCharges = useChargesSort(charges);

  const handleChargeQuantityChange = (charge: PriceFragment, value: number | null | undefined) => {
    setFieldValue(`billableFeatures[${charge.feature?.refId}]`, value);
  };

  useEffect(() => {
    setFieldValue('billableFeaturesPriceOverride', {});
  }, [setFieldValue, values.billingCountryCode, values.billingPeriod]);

  return (
    <CustomTable
      maxHeight={height}
      rowHeight={65}
      withRowHover={false}
      label=""
      data={sortedCharges}
      headCells={[
        {
          alignment: 'left',
          id: 'charge-name',
          label: t('pricing.charge'),
          width: 190,
          render: (charge) => {
            return (
              <ChargeTitle
                charge={{
                  type: charge.billingModel === BillingModel.FlatFee ? ChargeType.BasePrice : ChargeType.UsageBased,
                  feature: charge.feature || null,
                }}
                disableIcon
              />
            );
          },
        },
        {
          alignment: 'left',
          disableVerticalPadding: false,
          id: 'charge-quantity',
          label: t('entitlements.quantity'),
          width: 220,
          render: (charge) => {
            if (charge.billingModel === BillingModel.FlatFee) {
              return '1';
            }

            if (charge.billingModel === BillingModel.UsageBased) {
              return <Text.B2>{t('pricing.billingType.types.USAGE_BASED')}</Text.B2>;
            }

            const featureRefId = charge.feature?.refId;
            if (!featureRefId) {
              return null;
            }

            const isTiersBulk = !!charge.tiersMode && isBulkTiers(charge.tiers);
            if (isTiersBulk) {
              return (
                <TierSelection
                  charge={charge}
                  onSelection={(quantity) => {
                    handleChargeQuantityChange(charge, quantity);
                  }}
                  selectedTier={get(billableFeatures, featureRefId)}
                />
              );
            }

            const shouldHavePerUnitCaption =
              (charge.minUnitQuantity && charge.minUnitQuantity > 1) || charge.maxUnitQuantity;

            return (
              <TextField
                type="number"
                name={`billableFeatures[${featureRefId}]`}
                value={get(billableFeatures, featureRefId)}
                placeholder={t('unitQuantity.quantityPlaceholder')}
                touched={!!get(touched, `billableFeatures[${featureRefId}]`)}
                error={!!get(errors, `billableFeatures[${featureRefId}]`)}
                errorText={get(errors, `billableFeatures[${featureRefId}]`)}
                dataTestId={`charge-quantity-${featureRefId}`}
                fullWidth
                onChange={(event) =>
                  handleChargeQuantityChange(
                    charge,
                    event.target.value ? Number(event.target.value) || undefined : undefined,
                  )
                }
                onBlur={handleBlur}
                captionText={
                  shouldHavePerUnitCaption
                    ? calculateUnitQuantityTextFromPriceFragments(
                        charge,
                        undefined,
                        charge?.feature?.featureUnitsPlural,
                      )
                    : undefined
                }
              />
            );
          },
        },
        {
          alignment: 'left',
          id: 'charge-cost',
          label: t('pricing.price'),
          render: (charge, idx, hovering) => {
            return paymentCollectionMethod === PaymentCollectionMethod.None ? (
              <Text.B2>{t('pricing.customValue')}</Text.B2>
            ) : (
              <SubscriptionCharge
                billableFeatures={billableFeatures}
                charge={charge}
                showPriceByMonth={showPriceByMonth}
                hovering={hovering}
                formProps={formProps}
              />
            );
          },
        },
      ]}
    />
  );
}
