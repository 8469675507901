import React, { useState } from 'react';
import { BillingCadence } from '@stigg-types/apiTypes';
import {
  IconButton,
  Icon,
  CustomTable,
  FormRenderProps,
  Grid,
  GridFlex,
  HeadCell,
  Text,
  TextField,
} from '@stigg-components';
import { t } from 'i18next';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormikHandlers, FormikState, getIn } from 'formik';
import {
  SubscriptionAddon,
  SubscriptionFormFields,
} from '../../../../../customers/components/customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm.types';
import { AddonType } from '../../../../addons/components/AddonTypeValue';
import { getBillingCadenceIconTooltip } from '../../../../pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';
import { SubscriptionAddonPrice } from '../../../../../customers/components/customerPage/customerSubscriptions/subscriptionForm/components/SubscriptionAddonPrice';
import { getPriceAndPeriodFormat } from '../../../../pricing/utils/priceFormatUtils';

export function AddedAddonsList({
  addons,
  height,
  remove,
  handleChange,
  handleBlur,
  touched,
  errors,
  withPrice = false,
  withQuantity = false,
  withAddonId = true,
  showMonthlyPriceVariation = false,
  formProps,
}: {
  addons: SubscriptionAddon[];
  height?: number;
  withPrice?: boolean;
  withQuantity?: boolean;
  withAddonId?: boolean;
  showMonthlyPriceVariation?: boolean;
  remove: (index: number, addon: SubscriptionAddon) => void;
  handleChange?: FormikHandlers['handleChange'];
  handleBlur?: FormikHandlers['handleBlur'];
  touched?: FormikState<{ addons: SubscriptionAddon[] }>['touched'];
  errors?: FormikState<{ addons: SubscriptionAddon[] }>['errors'];
  formProps: FormRenderProps<SubscriptionFormFields>;
}) {
  const { addonPriceOverride: isAddonPriceOverrideEnabled } = useFlags<FeatureFlags>();
  const addonFieldName = (index: number) => `addons[${index}].quantity`;
  const [isInEditMode, setIsInEditMode] = useState<Record<string, boolean>>({});

  const createHeadCells = (): Array<HeadCell<SubscriptionAddon, any>> => [
    {
      id: 'refId',
      alignment: 'left',
      visible: withAddonId,
      label: t('plans.addonId'),
      render: (addon: SubscriptionAddon) => <Text.B2 mr={2}>{addon.refId}</Text.B2>,
    },
    {
      id: 'displayName',
      alignment: 'left',
      label: t('plans.addonName'),
      width: 190,
      render: (addon: SubscriptionAddon) => {
        const billingCadence = addon.price?.billingCadence;
        return (
          <GridFlex.RowCenter gap={4}>
            {billingCadence && getBillingCadenceIconTooltip({ billingCadence, selected: false })}
            <Grid item>
              <Text.B2>{addon.displayName}</Text.B2>
            </Grid>
          </GridFlex.RowCenter>
        );
      },
    },
    {
      id: 'quantity',
      alignment: 'left',
      visible: withQuantity,
      disableVerticalPadding: false,
      width: 220,
      label: t('subscriptions.addonQuantity'),
      render: (addon: SubscriptionAddon, index: number) => {
        const isSingleAddonType: boolean = addon.addonType === AddonType.SINGLE;
        return (
          <TextField
            type="number"
            autoFocus
            name={addonFieldName(index)}
            value={addon.quantity}
            onChange={handleChange}
            disabled={isSingleAddonType}
            touched={getIn(touched, addonFieldName(index))}
            error={getIn(errors, addonFieldName(index))}
            errorText={getIn(errors, addonFieldName(index))}
            placeholder={t('unitQuantity.quantityPlaceholder')}
            onBlur={handleBlur}
          />
        );
      },
    },
    {
      id: 'price',
      alignment: 'left',
      visible: withPrice,
      label: t('subscriptions.price'),
      render: (addon: SubscriptionAddon, _, hovering) =>
        addon.price ? (
          isAddonPriceOverrideEnabled ? (
            <SubscriptionAddonPrice
              isInEditMode={isInEditMode[addon.refId]}
              setIsInEditMode={(isInEdit) =>
                setIsInEditMode((prevState) => ({ ...prevState, [addon.refId]: isInEdit }))
              }
              addon={addon}
              formProps={formProps}
              showPriceByMonth={showMonthlyPriceVariation}
              hovering={hovering}
            />
          ) : (
            <Text.B2 color="secondary" whiteSpace="pre">
              {getPriceAndPeriodFormat(addon.price, {
                shortFormat: true,
                reversedPeriod: showMonthlyPriceVariation && addon.price.billingCadence === BillingCadence.Recurring,
              })}
            </Text.B2>
          )
        ) : (
          <Text.B2 color="secondary" whiteSpace="pre">
            {t('subscriptions.customPricing')}
          </Text.B2>
        ),
    },
    {
      id: 'remove',
      alignment: 'right',
      width: 50,
      render: (addon: SubscriptionAddon, index: number) => (
        <IconButton onClick={() => remove && remove(index, addon)} disabled={isInEditMode[addon.refId]}>
          <Icon type="reactFeather" icon="Trash2" color={isInEditMode[addon.refId] ? 'disabled' : 'active'} size={18} />
        </IconButton>
      ),
    },
  ];

  return <CustomTable rowHeight={65} label="Addons" maxHeight={height} headCells={createHeadCells()} data={addons} />;
}
