import React, { useState, Suspense, useEffect } from 'react';
import { Edit2, ExternalLink, Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import stringify from 'json-stable-stringify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  GridFlex,
  Button,
  OptionsDropdown,
  Text,
  Grid,
  Link,
  Icon,
  TwoLinesLayout,
  PageCard,
  EmptyCardContent,
} from '@stigg-components';
import { useModal, useQueryParam } from '@stigg-common';
import { ApiKeyType, Environment } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { ReactComponent as JSONDocSvg } from '@assets/icons/JSONDoc.svg';
import omit from 'lodash/omit';
import Loader from '../../../components/Loader';
import Table, { HeadCell } from '../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useSwitchEnvironment } from '../../navigation/useSwitchEnvironment';
import config from '../../../env.config';
import {
  addEnvironmentAction,
  archiveEnvironmentAction,
  dumpEnvironmentProductCatalogAction,
  updateEnvironmentAction,
} from '../accountsSlice';
import { EnvironmentApiKey } from './EnvironmentApiKey';
import EditEnvironment, { EnvironmentFormFields } from './EnvironmentDetailsForm';
import { DeleteEnvironment } from './DeleteEnvironment';
import { EnvironmentSticklight, ENVIRONMENT_DEFAULT_COLOR } from './EnvironmentSwitch';
import { ClientSideSecurityIndicator } from './ClientSideSecurityIndicator';
import { ClientSideSecurityDrawer } from './ClientSideSecurityDrawer';
import { useNavigation } from '../../navigation/useNavigation';
import { Duplicate } from '../../../components/icon/CustomIcons';
import { MergeEnvironmentsDrawer } from './mergeEnvironments/MergeEnvironmentsDrawer';
import { EnvironmentTypeIcon } from './EnvironmentTypeIcon';

const GenerateSandbox = React.lazy(() => import('./sandboxGenerator/GenerateSandbox'));

const headCells = ({
  onEditClick,
  onDeleteClick,
  onMergeClick,
  onSetClientSideSecurity,
  shouldShowOpenDemo,
  currentEnvironmentId,
  clientSideHardeningUxEnabled,
  onDumpClick,
}: {
  onEditClick: (environment: Environment) => void;
  onDeleteClick: (environment: Environment) => void;
  onMergeClick: (environment: Environment) => void;
  onSetClientSideSecurity: (environment: Environment) => void;
  shouldShowOpenDemo?: boolean;
  currentEnvironmentId?: string;
  clientSideHardeningUxEnabled?: boolean;
  onDumpClick: (environment: Environment) => Promise<void>;
}): Array<HeadCell<Environment, any>> => {
  const cells: Array<HeadCell<Environment, any>> = [
    {
      id: 'name',
      alignment: 'left',
      label: t('accounts.environmentName'),
      render: (environment) => (
        <GridFlex.RowSpaceBetween>
          <GridFlex.RowCenter>
            <EnvironmentSticklight $color={environment.color || ENVIRONMENT_DEFAULT_COLOR} sx={{ height: 42 }} />
            <GridFlex.Column data-stigg-environment-id={environment.id}>
              <TwoLinesLayout firstRow={environment.displayName} secondRow={environment.description || ''} />
              {currentEnvironmentId === environment.id ? (
                <Text.B2 $bold overrideColor={environment.color || ENVIRONMENT_DEFAULT_COLOR}>
                  {t('account.selectedEnvironment')}
                </Text.B2>
              ) : null}
            </GridFlex.Column>
          </GridFlex.RowCenter>

          <EnvironmentTypeIcon environment={environment} />
        </GridFlex.RowSpaceBetween>
      ),
    },
    {
      id: 'serverApiKey',
      alignment: 'left',
      label: t('accounts.serverApiKey'),
      render: (environment) => {
        const serverApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Server);
        return serverApiKey ? (
          <EnvironmentApiKey apiKey={serverApiKey} truncateApiKey={clientSideHardeningUxEnabled} />
        ) : (
          ''
        );
      },
    },
    {
      id: 'clientApiKey',
      alignment: 'left',
      label: t('accounts.clientSdkKey'),
      render: (environment) => {
        const clientApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Client);
        return clientApiKey ? (
          <EnvironmentApiKey apiKey={clientApiKey} truncateApiKey={clientSideHardeningUxEnabled} />
        ) : (
          ''
        );
      },
    },
    {
      id: 'options',
      alignment: 'right',
      width: 56,
      maxWidth: 36 + 8,
      label: '',
      render: (environment) => {
        const clientApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Client);
        const serverApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Server);
        return (
          <OptionsDropdown
            options={[
              {
                icon: Edit2,
                text: t('customers.edit'),
                onClick: () => {
                  onEditClick(environment);
                },
              },
              {
                icon: Duplicate,
                onClick: () => {
                  onMergeClick(environment);
                },
                text: t('accounts.copyMergeEnvironments.CTA'),
              },
              {
                icon: JSONDocSvg,
                text: t('accounts.dumpEnvironmentCTA'),
                onClick: () => {
                  void onDumpClick(environment);
                },
              },
              {
                icon: Trash2,
                text: t('sharedComponents.delete'),
                onClick: () => {
                  onDeleteClick(environment);
                },
              },
              ...(shouldShowOpenDemo
                ? [
                    {
                      icon: ExternalLink,
                      text: t('accounts.openDemoApp'),
                      onClick: () => {
                        const configs = [
                          `environmentName=${environment.slug}`,
                          `serverSdkKey=${serverApiKey?.token}`,
                          `clientSdkKey=${clientApiKey?.token}`,
                          `signingToken=${environment.signingToken}`,
                        ];
                        window.open(`${config.demoBaseUrl}?${configs.join('&')}`, '_blank');
                      },
                    },
                  ]
                : []),
            ]}
          />
        );
      },
    },
  ];

  if (clientSideHardeningUxEnabled) {
    const clientSideSecurityCell: HeadCell<Environment, any> = {
      id: 'clientSideSecurity',
      alignment: 'left',
      label: t('accounts.clientSideSecurity'),
      render: (environment: Environment) => (
        <ClientSideSecurityIndicator
          enabled={environment.hardenClientAccessEnabled}
          onClick={() => onSetClientSideSecurity(environment)}
        />
      ),
    };

    cells.splice(3, 0, clientSideSecurityCell);
  }

  return cells;
};

export default function AccountEnvironments() {
  const dispatch = useAppDispatch();
  const switchEnvironment = useSwitchEnvironment();
  const { clientSideHardeningUx: clientSideHardeningUxEnabled, openEnvironmentDemo: shouldShowOpenDemo } =
    useFlags<FeatureFlags>();
  const [EditEnvironmentModal, setIsEditOpen] = useModal({ title: t('accounts.editEnv'), width: 650 });
  const [AddEnvironmentModal, setIsAddOpen] = useModal({ title: t('accounts.addEnv'), width: 650 });
  const [DeleteEnvironmentModal, setIsDeleteOpen] = useModal({ title: t('accounts.deleteEnv') });
  const [GenerateDemoModal, setIsGenerateDemoOpen] = useModal({ title: t('accounts.generateSandboxEnvironment') });
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentMember = useSelector((state: RootState) => state.authReducer.currentMember);
  const isLoadingEnvironments = useSelector((state: RootState) => state.accountReducer.isLoadingEnvironments);
  const [currentEnvironment, setCurrentEnvironment] = useState<Environment | undefined>();
  const [currentClientSideSecurityEnv, setCurrentClientSideSecurityEnv] = useState<Environment | null>(null);
  const [currentMergedEnvironment, setCurrentMergedEnvironment] = useState<Environment | null>(null);
  const { value: environmentQueryParam } = useQueryParam('clientSideSecuritySettings');
  const { navigateTo, appRoutes } = useNavigation();

  useEffect(() => {
    const environment = environments.find((env) => env.slug === environmentQueryParam) || null;
    setCurrentClientSideSecurityEnv(environment);
  }, [environmentQueryParam, setCurrentClientSideSecurityEnv, environments]);

  const handleAddSubmit = async (values: EnvironmentFormFields) => {
    try {
      const newEnvironment = await dispatch(addEnvironmentAction({ environment: values })).unwrap();
      setIsAddOpen(false);
      switchEnvironment(newEnvironment.id);
    } catch (err) {
      // handle error silently and prevent unhandled promise rejection
    }
  };

  const handleEditSubmit = async (values: EnvironmentFormFields) => {
    if (currentEnvironment?.id) {
      await dispatch(
        updateEnvironmentAction({ environmentId: currentEnvironment.id, updatePayload: omit(values, 'type') }),
      );
    }
    setIsEditOpen(false);
  };
  const handleDeleteSubmit = async () => {
    if (currentEnvironment?.id) {
      await dispatch(archiveEnvironmentAction({ environmentId: currentEnvironment.id }));
    }
    setIsDeleteOpen(false);
  };
  const onEditClick = (env: Environment) => {
    setCurrentEnvironment(env);
    setIsEditOpen(true);
  };
  const onDeleteClick = (env: Environment) => {
    setCurrentEnvironment(env);
    setIsDeleteOpen(true);
  };
  const onCloseEditDialog = () => {
    setIsEditOpen(false);
  };
  const onCloseAddDialog = () => {
    setIsAddOpen(false);
  };
  const onCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const onDumpClick = async (env: Environment) => {
    if (!currentMember?.serviceApiKey) {
      console.warn('No API key for logged in user');
      return;
    }

    const dump = await dispatch(dumpEnvironmentProductCatalogAction({ environmentSlug: env.slug })).unwrap();
    const content = stringify(dump, { space: 2 });
    const blob = new Blob([content], { type: 'application/octet-stream' });
    const blobUrl = window.URL.createObjectURL(blob);
    const tempAnchor = document.createElement('a');
    tempAnchor.href = blobUrl;
    tempAnchor.style.display = 'none';
    tempAnchor.setAttribute('download', `${env.displayName}.json`);
    document.body.appendChild(tempAnchor);
    tempAnchor.click();
    setTimeout(() => {
      document.body.removeChild(tempAnchor);
      window.URL.revokeObjectURL(blobUrl);
    }, 500);
  };

  const onSetClientSideSecurity = (environment: Environment | null) => {
    const slug = environment?.slug;
    navigateTo(appRoutes.settingsPage({ clientSideSecurityEnvironmentSlug: slug }), { isGlobal: true });
  };

  let content: React.ReactElement;

  if (isLoadingEnvironments) {
    content = <Loader />;
  } else if (isEmpty(environments)) {
    content = (
      <EmptyCardContent>
        <Text.Sub2>{t('accounts.environmentSectionEmpty')}</Text.Sub2>
        <Link variant="body2" ml={1} onClick={() => setIsAddOpen(true)}>
          {t('accounts.environmentSectionEmptyCTA')}
        </Link>
      </EmptyCardContent>
    );
  } else {
    content = (
      <Table
        rowHeight={100}
        rowColor={(environment) => (environment?.id === currentEnvironmentId ? '#EBEDF3' : 'white')}
        headCells={headCells({
          onEditClick,
          onDeleteClick,
          onSetClientSideSecurity,
          shouldShowOpenDemo,
          currentEnvironmentId,
          clientSideHardeningUxEnabled,
          onDumpClick,
          onMergeClick: setCurrentMergedEnvironment,
        })}
        label={t('accounts.environmentsTitle')}
        data={environments}
      />
    );
  }

  return (
    <PageCard>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Text.H3>{t('accounts.environmentsTitle')}</Text.H3>
        </Grid>
        <Grid item>
          {shouldShowOpenDemo && (
            <Button
              data-testid="generate-sandbox-button"
              $outlined
              color="secondary"
              onClick={() => setIsGenerateDemoOpen(true)}
              startIcon={<Icon icon="Add" />}
              sx={{ marginRight: 2 }}>
              {t('accounts.generateSandbox')}
            </Button>
          )}
          <Button $outlined color="primary" onClick={() => setIsAddOpen(true)} startIcon={<Icon icon="Add" />}>
            {t('accounts.add')}
          </Button>
        </Grid>
      </Grid>
      <Grid container flexDirection="column" mt={3} mb={10}>
        <Grid item>
          <Text.Sub2>{t('accounts.setApiKey')}</Text.Sub2>
        </Grid>
      </Grid>

      {content}

      <EditEnvironmentModal>
        <EditEnvironment onSubmit={handleEditSubmit} onCancel={onCloseEditDialog} environment={currentEnvironment} />
      </EditEnvironmentModal>

      <AddEnvironmentModal>
        <EditEnvironment onSubmit={handleAddSubmit} onCancel={onCloseAddDialog} />
      </AddEnvironmentModal>

      <DeleteEnvironmentModal>
        <DeleteEnvironment
          onSubmit={handleDeleteSubmit}
          environment={currentEnvironment}
          onCancel={onCloseDeleteDialog}
        />
      </DeleteEnvironmentModal>

      {shouldShowOpenDemo && (
        <GenerateDemoModal>
          <Suspense fallback={<Loader />}>
            <GenerateSandbox environments={environments} />
          </Suspense>
        </GenerateDemoModal>
      )}
      <ClientSideSecurityDrawer
        open={!!currentClientSideSecurityEnv}
        onClose={() => onSetClientSideSecurity(null)}
        environment={currentClientSideSecurityEnv}
      />
      <MergeEnvironmentsDrawer
        open={!!currentMergedEnvironment}
        onClose={() => {
          setCurrentMergedEnvironment(null);
        }}
        environment={currentMergedEnvironment}
      />
    </PageCard>
  );
}
