import round from 'lodash/round';
import { Skeleton, TableRow as MuiTableRow, TableCell as MuiTableCell } from '@stigg-components';
import * as S from '../Table.style';
import { SkeletonOptions, TableBodyComponentProps } from '../Table.types';
import { TableRow } from './TableRow';

function TableRowSkeletonLoader({
  rowHeight = 53,
  colSpan,
  skeletonOptions = {},
}: {
  skeletonOptions?: SkeletonOptions;
  colSpan: number;
  rowHeight?: number;
}) {
  const { backgroundColor } = skeletonOptions;

  return (
    <MuiTableRow>
      <MuiTableCell colSpan={colSpan}>
        <Skeleton
          sx={{ bgcolor: (theme) => backgroundColor || theme.itamar.palette.action.hover }}
          animation="wave"
          height={round(rowHeight * 0.4)}
        />
      </MuiTableCell>
    </MuiTableRow>
  );
}

export const TableBody = <TEntity, TCell extends keyof TEntity>({
  headerComponent,
  isHeaderComponentChild,
  headerComponentOptions,
  rowHeight,
  withRowHover,
  headCells,
  cellsInNewRow,
  data,
  collapsableComponentOptions,
  onRowClick,
  rowColor,
  hasBorder,
  shouldHighlightRow,
  highlighDelay,
  disable,
  enableRowSelection,
  onToggleItemSelection,
  infiniteScrollOptions,
  isLoading,
  skeletonOptions,
  isRowDisabled,
  isLoadingMoreData,
  isCellDisabled,
  isDraggingEnabled,
  withDragHandle,
  placeholder,
}: TableBodyComponentProps<TEntity, TCell>) => {
  return (
    <S.TableBody $withBottomBorder={isDraggingEnabled && !hasBorder} $hasBorder={hasBorder} $disable={disable}>
      {headerComponent && (
        <S.HeaderComponentRow hover $backgroundColorActive={headerComponentOptions?.backgroundColorActive}>
          <S.TableCell $disablePadding colSpan={headCells.length + 1}>
            {headerComponent}
          </S.TableCell>
        </S.HeaderComponentRow>
      )}
      {data &&
        !isLoading &&
        data.map((item, rowIndex) => {
          const extraRows =
            cellsInNewRow && cellsInNewRow.map(({ render }) => render(item, rowIndex)).filter((value) => !!value);
          return (
            <>
              <TableRow
                key={rowIndex}
                item={item}
                rowIndex={rowIndex}
                headCells={headCells}
                rowHeight={rowHeight}
                withRowHover={withRowHover}
                collapsableComponentOptions={collapsableComponentOptions}
                onRowClick={onRowClick}
                rowColor={rowColor}
                isHeaderComponentChild={isHeaderComponentChild}
                shouldHighlightRow={shouldHighlightRow}
                highlighDelay={highlighDelay}
                enableRowSelection={enableRowSelection}
                onToggleItemSelection={onToggleItemSelection}
                isRowDisabled={isRowDisabled}
                isCellDisabled={isCellDisabled}
                isDraggingEnabled={isDraggingEnabled}
                withDragHandle={withDragHandle}
                disableBorder={!!extraRows?.length || (hasBorder && rowIndex === data.length - 1)}
              />
              {extraRows}
            </>
          );
        })}
      {placeholder}

      {((infiniteScrollOptions && infiniteScrollOptions.hasMore && isLoadingMoreData) || isLoading) && (
        <>
          {[...Array(skeletonOptions?.rowsCount || 5)].map((_, i) => (
            <TableRowSkeletonLoader
              key={i}
              colSpan={enableRowSelection || !!collapsableComponentOptions ? headCells.length + 1 : headCells.length}
              rowHeight={rowHeight}
              skeletonOptions={skeletonOptions}
            />
          ))}
        </>
      )}
    </S.TableBody>
  );
};
