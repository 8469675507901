/* eslint-disable no-restricted-imports */
import styled from 'styled-components/macro';

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Fab as MuiFab,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Switch,
  InputAdornment as MuiInputAdornment,
  ButtonGroup as MuiButtonGroup,
} from '@mui/material';
import { HelpOutlineOutlined, Timer as MuiTimer } from '@mui/icons-material/';
import { Eye, EyeOff, Clock as FeatherClock } from 'react-feather';
import { spacing } from '@mui/system';
import { getTextColor, TextColor } from '@stigg-theme';

export type { BoxProps } from '@mui/material';

const SpacedCard = styled(MuiCard)(spacing);
export const Card = styled(SpacedCard)<{ $withError?: boolean }>`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme, $withError }) =>
    $withError ? theme.itamar.palette.error.main : theme.itamar.border.borderColor};
  width: 100%;
`;

export const CollapseCard = styled(Card)<{ $withError?: boolean; $withClosed?: boolean }>`
  border-color: ${({ theme, $withError }) =>
    $withError ? theme.itamar.palette.error.main : theme.itamar.border.borderColor};
  background: ${({ theme, $withClosed }) =>
    $withClosed ? theme.itamar.palette.action.selected : theme.itamar.palette.white};
`;

export const HighlightedCard = styled(Card)`
  border-color: ${({ theme }) => theme.itamar.palette.primary.outlinedRestingBackground};
  background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground2};
`;

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const SpacedDivider = styled(MuiDivider)(spacing);
export const Divider = styled(SpacedDivider)`
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;
export const BoldDivider = styled(SpacedDivider)`
  border-color: ${({ theme }) => theme.itamar.palette.other.boldDivider};
`;

export const Fab = styled(MuiFab)(spacing);
export const Box = styled(MuiBox)(spacing);
const SpacedPaper = styled(MuiPaper)(spacing);
export const Paper = styled(SpacedPaper)`
  width: 100%;
`;
export const Alert = styled(MuiAlert)(spacing);

export const FadeBox = styled(Box)`
  transition: opacity 0.3s;
  opacity: 1;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const VisibilityOnIcon = styled(Eye)`
  stroke-opacity: 0.54;
  stroke-width: 2px;
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;
export const VisibilityOffIcon = styled(EyeOff)`
  stroke-opacity: 0.54;
  stroke-width: 2px;
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

export const HelpIcon = styled(HelpOutlineOutlined)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

export const StyledSwitch = styled(Switch)`
  & .Mui-checked {
    color: ${({ theme }) => theme.itamar.palette.primary.main};
  }
  & .MuiSwitch-thumb {
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }
`;

const SpacedTimerIcon = styled(MuiTimer)(spacing);
export const TimerIcon = styled(SpacedTimerIcon)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
  margin-right: 4px;
  margin-left: 4px;
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const SpacedClockIcon = styled(FeatherClock)(spacing);
export const ClockIcon = styled(SpacedClockIcon)<{ $color?: TextColor }>`
  color: ${({ $color }) => $color && getTextColor($color)} !important;
  margin-right: 4px;
  margin-left: 4px;
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

export const StartAdornment = styled(MuiInputAdornment)`
  &,
  & > * {
    color: ${({ theme }) => theme.itamar.palette.action.active};
  }
  padding: ${({ theme }) => theme.spacing(1)};
  padding-right: 0;
  margin-right: 10px;
`;

export const EndAdornment = styled(MuiInputAdornment)`
  &,
  & > * {
    color: ${({ theme }) => theme.itamar.palette.action.active};
  }
  padding: ${({ theme }) => theme.spacing(1)};
  padding-left: 0;
  margin-left: 0;
`;

export const ButtonGroup = styled(MuiButtonGroup)(spacing);
