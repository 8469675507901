import { GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import ImportCustomersOverview from '@assets/images/integrations/import-customers-overview.png';

export function ImportCustomersOverviewStep() {
  return (
    <GridFlex.Column>
      <Text.H3 mb={1}>{t('integrations.overviewStepHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={12}>
        {t('integrations.importCustomersOverviewSubtitle')}
      </Text.B2>
      <img width="100%" src={ImportCustomersOverview} alt="import customers overview" />
    </GridFlex.Column>
  );
}
