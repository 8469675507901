import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables,
  ErrorCode,
  PricingType,
  SubscriptionInput,
} from '@stigg-types/apiTypes';
import omit from 'lodash/omit';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { navigateTo } from '../../navigation/actions';
import { appRoutes } from '../../navigation/useNavigation';
import { LimitExceededAlert } from '../../../components/LimitExceededAlert';

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($input: SubscriptionInput!) {
    createSubscription(subscription: $input) {
      subscriptionId
    }
  }
`;

type SubscriptionData = Omit<SubscriptionInput, 'environmentId'> & { subscriptionPricingType: PricingType };

async function createSubscription(
  subscriptionInput: SubscriptionData,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const subscriptionData = {
        ...omit(subscriptionInput, 'subscriptionPricingType'),
        environmentId: accountReducer.currentEnvironmentId,
      };
      const response = await apolloClient.mutate<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>({
        mutation: CREATE_SUBSCRIPTION,
        variables: { input: subscriptionData },
      });

      const newSubscription = response.data?.createSubscription;
      if (newSubscription) {
        dispatch(navigateTo(appRoutes.subscriptionPage(newSubscription.subscriptionId)));
      }
    },
    {
      successMessage: t('subscriptions.api.successCreate'),
      failureMessageHandler: () => t('subscriptions.api.createFailed'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('subscriptions.api.subscriptionIdAlreadyExists'),
        // currently relying on the generic name but probably should at some extend it to be more specific
        [ErrorCode.InvalidArgumentError]: t('subscriptions.api.customerHasToBeSubscribedToSameCurrency'),
        [ErrorCode.TooManySubscriptionsPerCustomer]: t('subscriptions.api.tooManySubscriptionsPerCustomer'),
        [ErrorCode.AddonDependencyMissingError]: t('subscriptions.api.addonDependencyMissingError'),
        [ErrorCode.PackageGroupMinItemsError]: t('subscriptions.api.packageGroupMinItemError'),
        [ErrorCode.CustomerHasNoEmailAddress]: t('subscriptions.api.customerHasNoEmailAddress'),
        [ErrorCode.SubscriptionInvoiceStatusError]: t('subscriptions.api.subscriptionInvoiceStatusError'),
        [ErrorCode.EntitlementLimitExceededError]: (
          <LimitExceededAlert
            alertMessage={
              subscriptionInput.subscriptionPricingType === PricingType.Custom
                ? t('subscriptions.api.customSubscriptionsEntitlementLimitExceeded')
                : t('subscriptions.api.paidSubscriptionsEntitlementLimitExceeded')
            }
          />
        ),
      },
    },
    dispatch,
  );
}

export { createSubscription };
