import { Components } from '@mui/material/styles/components';
import { palette } from './itamar';
import typography from './typography';

const components: Components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-input.Mui-disabled': {
          color: palette.text.disabled,
          '-webkit-text-fill-color': palette.text.disabled,
        },
        radius: '10px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${palette.other.outlineBorder}`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${palette.other.outlineBorder}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
        },
        '&.Mui-disabled': {
          color: 'red',
          backgroundColor: palette.grey[50],
        },
        color: 'palette.other.outlineBorder',
        transition: '0.1s',
        ...typography.body2,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      thumb: {
        // todo:fix this #colors
        // boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
        boxShadow: '0 0 10px #001E6C1f',
      },
    },
  },
};
export default components;
