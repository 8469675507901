// eslint-disable-next-line no-restricted-imports
import { Theme, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import { GridFlex, Text, EmptyCardContent, PageCard, ReloadButton } from '@stigg-components';
import { t } from 'i18next';
import { sortBy, extend, isNumber, isEmpty } from 'lodash';
import {
  AccessDeniedReason,
  CustomerSubscriptionResourceFragment,
  EntitlementDataFragment,
  EntitlementSummaryFragment,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { formatDate } from '@stigg-common';
import Table from '../../../../../components/table/Table';
import { headCells } from './headCells';
import { headCellsCollapsibleSummary } from './headCellsCollapsibleSummary';
import { SelectCustomerResource } from '../SelectCustomerResource';

export const FEATURE_TYPE_CELL_WIDTH = 50;
export const VALUE_CELL_WIDTH = 280;

const SOURCE_ICON_WIDTH = 45;
const SOURCE_ICONS_MAX_AMOUNT = 3;
export const SOURCE_ICONS_WIDTH = SOURCE_ICON_WIDTH * SOURCE_ICONS_MAX_AMOUNT;
export const SOURCE_CELL_WIDTH = 220;
export const COLLAPSABLE_SOURCE_CELL_WIDTH = SOURCE_CELL_WIDTH + 70;

export type SummaryWithFeature = EntitlementSummaryFragment & { feature?: any };

export function isEntitlementBudgetExceeded(entitlement: EntitlementDataFragment) {
  return !entitlement.isGranted && entitlement.accessDeniedReason === AccessDeniedReason.BudgetExceeded;
}

export function CustomerEntitlementsSummary({
  entitlements,
  isLoading,
  reloadEntitlementsAction,
  entitlementsLastUpdated,
  activePlanIds,
  trialPlanIds,
  selectedResource,
  onUpdateResource,
}: {
  entitlements: EntitlementDataFragment[];
  isLoading: boolean;
  reloadEntitlementsAction: () => void;
  entitlementsLastUpdated?: Date | null;
  activePlanIds: string[];
  trialPlanIds: string[];
  selectedResource?: CustomerSubscriptionResourceFragment;
  onUpdateResource?: (resource: CustomerSubscriptionResourceFragment) => void;
}) {
  const theme = useTheme();
  const timezone = moment.tz.guess();
  // Sort by price entitlement so we show them first
  const sortedEntitlements = sortBy(entitlements, (a) => !a.summaries?.some((x) => !!x.priceEntitlement));
  const focusedProductId = selectedResource?.plan.product.refId;
  const isSubscriptionActive =
    selectedResource && [SubscriptionStatus.Active, SubscriptionStatus.InTrial].includes(selectedResource.status);

  const formattedLastUpdated = entitlementsLastUpdated
    ? formatDate(entitlementsLastUpdated, { timezone, withTime: true })
    : null;

  return (
    <PageCard cardProps={{ paddingTop: 0 }}>
      <GridFlex.Row gap={2} alignItems="center" mb={1}>
        {formattedLastUpdated && (
          <Text.Sub2 fontSize={12} color="tertiary">
            {t('customers.entitlementUsageSectionLastUpdated', { lastUpdated: formattedLastUpdated })}
          </Text.Sub2>
        )}
        <ReloadButton
          isLoading={isLoading && !!entitlementsLastUpdated}
          onClick={reloadEntitlementsAction}
          variant="inline"
        />
      </GridFlex.Row>

      {onUpdateResource && selectedResource && (
        <SelectCustomerResource selectedResource={selectedResource} onUpdateResource={onUpdateResource} />
      )}

      {!isLoading && (!isSubscriptionActive || !selectedResource) ? (
        <EmptyCardContent>
          <Text.Sub2>{t('customers.summarySectionNoActiveSubscription')}</Text.Sub2>
        </EmptyCardContent>
      ) : !isLoading && isEmpty(entitlements) ? (
        <EmptyCardContent>
          <Text.Sub2>{t('customers.summarySectionEmpty')}</Text.Sub2>
        </EmptyCardContent>
      ) : (
        <GridFlex.Column>
          <Table
            tableLayout="auto"
            isLoading={isEmpty(entitlements)}
            skeletonOptions={{ rowsCount: 3 }}
            label={t('customers.subscriptionsTableLabel')}
            headCells={headCells(activePlanIds, trialPlanIds, focusedProductId)}
            data={sortedEntitlements || []}
            rowColor={(entitlement) => {
              const isPriceEntitlement = entitlement?.summaries?.some((x) => !!x.priceEntitlement);

              if (isPriceEntitlement && entitlement && isEntitlementBudgetExceeded(entitlement)) {
                return theme.itamar.palette.error.background;
              }

              return isPriceEntitlement ? '#E9F4FE' : null;
            }}
            collapsableComponentOptions={{
              shouldCollapseRow: (entity: EntitlementDataFragment) => {
                // having one addon (one summary) with more than 0 quantity should also expand to show the calculation
                if (entity.summaries) {
                  return entity.summaries.length > 1 || isNumber(entity.summaries[0]?.addonQuantity);
                }
                return false;
              },
              collapsableComponent: ({ summaries, feature }: EntitlementDataFragment) => {
                return (
                  <Table
                    hasBorder={false}
                    backgroundColor={(theme: Theme) => theme.itamar.palette.grey[100]}
                    headlessTable
                    tableLayout="auto"
                    label={t('customers.subscriptionsTableLabel')}
                    headCells={headCellsCollapsibleSummary(activePlanIds, trialPlanIds, focusedProductId)}
                    rowHeight={53}
                    data={summaries?.map((summary) => extend({}, summary, { feature })) || []}
                  />
                );
              },
            }}
          />
        </GridFlex.Column>
      )}
    </PageCard>
  );
}
