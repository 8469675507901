import { FormikHelpers } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import pluralize from 'pluralize';
import { Collapse, Text, GridFlex, CustomRenderProps } from '@stigg-components';
import { CreateFeatureFormFields } from './SidenavCreateFeature';
import { UpdateFeatureFields } from '../featureDetails/FeatureDetailsTab';
import { UnitTransformationSwitch } from '../UnitTransformationSwitch';
import { FeatureUnitLabels } from '../FeatureUnitLabels';
import { FeatureUnitTransformation } from '../FeatureUnitTransformation';

export function MeterUnitLabels<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  formRenderProps,
  readonly,
}: {
  formRenderProps: CustomRenderProps<T>;
  readonly?: boolean;
}) {
  const { usageTransformation: usageTransformationEnabled } = useFlags<FeatureFlags>();
  const { renderTextField, setFieldValue, values } = formRenderProps;
  const handleSingularUnitsChange = (
    e: any,
    setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
  ) => {
    setFieldValue('featureUnits', e.target.value);
    if (!isEmpty(e.target.value)) {
      setFieldValue('featureUnitsPlural', pluralize.plural(e.target.value));
    }
  };
  const handleTransformedSingularUnitsChange = (
    e: any,
    setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
  ) => {
    setFieldValue('transformedUnits', e.target.value);
    if (!isEmpty(e.target.value)) {
      setFieldValue('transformedUnitsPlural', pluralize.plural(e.target.value));
    }
  };

  return (
    <GridFlex.Column>
      <GridFlex.RowCenter mb={1}>
        <Text.B2 color="secondary">{t('features.unitLabel')}</Text.B2>
      </GridFlex.RowCenter>
      <GridFlex.Row alignItems="flex-start" columnGap={4} maxWidth={530}>
        {renderTextField('featureUnits', undefined, {
          placeholder: t('features.unitsLong'),
          onChange: (e) => handleSingularUnitsChange(e, setFieldValue),
          disabled: readonly,
        })}
        {renderTextField('featureUnitsPlural', undefined, {
          placeholder: t('features.unitsPluralLong'),
          disabled: readonly,
        })}
      </GridFlex.Row>

      {usageTransformationEnabled && (
        <>
          <GridFlex.RowCenter $fullWidth mt={4}>
            <UnitTransformationSwitch
              checked={values.isUnitTransformationOpen}
              onChange={(isChecked) => setFieldValue('isUnitTransformationOpen', isChecked)}
            />
          </GridFlex.RowCenter>

          <Collapse in={values.isUnitTransformationOpen}>
            <FeatureUnitLabels
              renderTextField={renderTextField}
              handleSingularChange={(e) => handleTransformedSingularUnitsChange(e, setFieldValue)}
            />

            <FeatureUnitTransformation
              renderTextField={renderTextField}
              divideBy={values.divideBy}
              featureUnits={values.featureUnits}
              featureUnitsPlural={values.featureUnitsPlural}
              transformedUnits={values.transformedUnits}
            />
          </Collapse>
        </>
      )}
    </GridFlex.Column>
  );
}
