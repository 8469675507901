import { GridFlex, Divider, Text } from '@stigg-components';
import { PreviewNextInvoiceFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type SectionTitleProps = {
  title?: string;
  marginTop?: number;
};

type ChargesListProps = {
  items: PreviewNextInvoiceFragment['lines'];
  title?: string;
  marginTop?: number;
};

function SectionTitle({ title, marginTop = 4 }: SectionTitleProps) {
  return (
    <GridFlex.RowMiddle item mb={4} mt={marginTop}>
      {title ? (
        <Text.Caption color="secondary" pr={2}>
          {title}
        </Text.Caption>
      ) : null}
      <Divider my={2} sx={{ flex: 1 }} />
    </GridFlex.RowMiddle>
  );
}

export function ChargesList({ items, title, marginTop }: ChargesListProps) {
  if (!items.length) {
    return null;
  }

  return (
    <>
      {title ? <SectionTitle title={title} marginTop={marginTop} /> : null}

      <GridFlex.Column container gap={4} marginTop={title ? undefined : marginTop}>
        {items.map((line, index) => (
          <ChargeRow
            key={index}
            description={line.description || ''}
            costDescription={line.costDescription || ''}
            chargeItem={line}
            breakdown={line.lines}
          />
        ))}
      </GridFlex.Column>
    </>
  );
}
