import { Divider, Grid, GridFlex, PageHeader } from '@stigg-components';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Users } from 'react-feather';
import { useSelector } from 'react-redux';
import { Customer, CustomerListFragment } from '@stigg-types/apiTypes';
import { useSearchPlaceholder } from '@stigg-common';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { EmptyList } from '../../../components/EmptyList';
import Search from '../../../components/Search';
import createPageChangeFunc from '../../../components/table/gqlTableHelper';
import Table from '../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';
import { archiveCustomerByIdAction, fetchCustomersAction, updateCustomerAction } from '../customersSlice';
import { CustomerCreateDialog } from './createCustomer/CustomerCreateDialog';
import { CustomerUpdateDialog } from './customerPage/CustomerUpdateDialog';
import { UpdateCustomerFormFields } from './customerPage/CustomerUpdateForm';
import { headCells } from './headCells';
import { CustomerFilters } from './CustomerFilters';
import { CUSTOMER_PLAN_FILTER_OPTIONS, SUBSCRIPTION_STATUS_FILTER_OPTIONS } from './CustomerFiltersDefinitions';
import { useQueryParamsArray } from '../../common/useQueryParamsArray';
import { CustomerFilterChipProps } from './CustomerFilterChip';

export const PLAN_PRICE_QUERY_STRING_PARAM = 'plan';
export const SUBSCRIPTION_STATUS_QUERY_STRING_PARAM = 'status';

type DispatchedThunk = ReturnType<AsyncThunkAction<unknown, unknown, any>>;

const getCustomerFilterOptionsValues = <T extends string>(options: CustomerFilterChipProps<T>['options']): T[] =>
  options.reduce<T[]>(
    (acc, option) => [
      ...acc,
      ...(option.type === 'option' ? [option.value] : option.options.map(({ value }) => value)),
    ],
    [],
  );

function Customers() {
  const navigation = useNavigation();
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(0);
  const searchPlaceholder = useSearchPlaceholder({
    placeholder: t('customers.searchPlaceholder'),
    searchParams: t('customers.searchPlaceholderSearchParams'),
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [customer, setCustomer] = useState<CustomerListFragment | null>(null);
  const currentSearchThunkRef = useRef<DispatchedThunk | null>(null);
  const { pageInfo, totalCount, edges } = useSelector((state: RootState) => state.customersReducer.customers);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.customersReducer.isLoading);
  const dispatch = useAppDispatch();

  const [planPriceFilter, setPlanPriceFilter] = useQueryParamsArray(
    PLAN_PRICE_QUERY_STRING_PARAM,
    useMemo(() => getCustomerFilterOptionsValues(CUSTOMER_PLAN_FILTER_OPTIONS), []),
  );

  const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useQueryParamsArray(
    SUBSCRIPTION_STATUS_QUERY_STRING_PARAM,
    useMemo(() => getCustomerFilterOptionsValues(SUBSCRIPTION_STATUS_FILTER_OPTIONS), []),
  );

  const pageChangeFunc = createPageChangeFunc(
    dispatch,
    pageNum,
    pageInfo,
    setPageNum,
    fetchCustomersAction,
    useMemo(
      () => ({
        environmentId: currentEnvironmentId,
        search,
        pricingTypes: planPriceFilter,
        subscriptionStatuses: subscriptionStatusFilter,
      }),
      [currentEnvironmentId, search, planPriceFilter, subscriptionStatusFilter],
    ),
  );

  const onUpdate = (updatePayload: UpdateCustomerFormFields) => {
    if (customer?.id) {
      void dispatch(updateCustomerAction(updatePayload));
    }
    setEditDialogOpen(false);
  };

  const onEditClick = (customer: CustomerListFragment) => {
    setCustomer(customer);
    setEditDialogOpen(true);
  };
  const onCreateClick = () => {
    setCreateDialogOpen(true);
  };
  const onDeleteClick = (customer: CustomerListFragment) => {
    setCustomer(customer);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && customer?.id) {
      await dispatch(archiveCustomerByIdAction(customer.customerId));
    }
    setDeleteDialogOpen(false);
  };

  const onSearch = useCallback(
    (searchTerm: string) => {
      setSearch(searchTerm);
      setPageNum(0);
      currentSearchThunkRef.current?.abort();
      currentSearchThunkRef.current = dispatch(
        fetchCustomersAction({
          search: searchTerm,
          environmentId: currentEnvironmentId || '',
          pricingTypes: planPriceFilter,
          subscriptionStatuses: subscriptionStatusFilter,
        }),
      );
    },
    [dispatch, currentEnvironmentId, planPriceFilter, subscriptionStatusFilter, currentSearchThunkRef],
  );

  useEffect(() => {
    if (!currentEnvironmentId) {
      return;
    }

    setPageNum(0);
    void dispatch(
      fetchCustomersAction({
        environmentId: currentEnvironmentId,
        pricingTypes: planPriceFilter,
        subscriptionStatuses: subscriptionStatusFilter,
      }),
    );
  }, [dispatch, currentEnvironmentId, planPriceFilter, subscriptionStatusFilter]);

  const filterApplied = useMemo(
    () => !isEmpty(search) || planPriceFilter.length > 0 || subscriptionStatusFilter.length > 0,
    [search, planPriceFilter, subscriptionStatusFilter],
  );

  return (
    <>
      <PageHeader title={t('customers.customers')} buttonTitle={t('customers.new')} buttonClick={onCreateClick} />
      <Divider mt={4} />
      <GridFlex.Column container mb={10}>
        <Grid my={5}>
          <Grid width={400} maxWidth={400} alignItems="flex-start">
            <Search
              variant="outlined"
              placeholder={searchPlaceholder}
              handleSearchFunc={onSearch}
              searchedStr={search}
              shouldMaskFromHjAndFs
              searchOnChange
            />
          </Grid>

          <CustomerFilters
            planPriceFilter={planPriceFilter}
            setPlanPriceFilter={setPlanPriceFilter}
            subscriptionStatusFilter={subscriptionStatusFilter}
            setSubscriptionStatusFilter={setSubscriptionStatusFilter}
          />
        </Grid>
        {isEmpty(edges) && !isLoading ? (
          <EmptyList
            title={filterApplied ? t('customers.emptySearchText') : t('customers.emptyStateText')}
            linkText={filterApplied ? '' : t('customers.emptyStateTextActionLinkText')}
            onLinkClick={onCreateClick}
            icon={Users}
          />
        ) : (
          <Table
            isLoading={isLoading}
            headCells={headCells(onEditClick, onDeleteClick)}
            label={t('customers.table')}
            pageChangeFunc={pageChangeFunc}
            pageInfo={pageInfo}
            data={(isLoading ? [] : edges).map((item) => item.node)}
            pageNum={pageNum}
            totalCount={totalCount}
            onRowClick={(customer: Customer) => navigation.navigateTo(`/customers/${customer.customerId}`)}
          />
        )}
      </GridFlex.Column>
      <CustomerCreateDialog open={createDialogOpen} setOpen={setCreateDialogOpen} />
      {customer && (
        <CustomerUpdateDialog
          customer={customer}
          onUpdate={onUpdate}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
        />
      )}
      <ConfirmationDialog
        confirmationButtonText={t('customers.archive')}
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('customers.deleteDialogTitle')}
        content={t('customers.dialogDelete')}
      />
    </>
  );
}

export default Customers;
