import { Add as AddIcon } from '@mui/icons-material';
import { AwsChip, isActiveSubscriptionUtil } from '@stigg-common';
import {
  Button,
  DateText,
  EmptyCardContent,
  Grid,
  GridFlex,
  HighlightText,
  Icon,
  Link,
  LongText,
  PageCard,
  SubscriptionsOptionsDropdown,
  Text,
} from '@stigg-components';
import {
  BillingPeriod,
  BudgetConfigurationInput,
  CustomerResponseFragment,
  CustomerSubscriptionDataFragment,
  PaymentCollection,
  PaymentCollectionMethod,
  PlanFragment,
  PlanListFragment,
  PricingType,
  SubscriptionAddonInput,
  SubscriptionDataFragment,
  SubscriptionEntitlementInput,
  SubscriptionScheduleStatus,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import moment from 'moment';
import { Dialog } from '../../../../../components/Dialog';
import createPageChangeFunc from '../../../../../components/table/gqlTableHelper';
import Table, { HeadCell } from '../../../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { useNavigation } from '../../../../navigation/useNavigation';
import { getResetPeriodData } from '../../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.utils';
import { SubscriptionAtExperiment } from '../../../../packages/common/components/SubscriptionAtExperiment';
import {
  createSubscriptionAction,
  fetchCustomerSubscriptionsAction,
  fetchEntitlementsByRefIdAction,
  fetchNonActiveSubscriptionsCountAction,
  triggerSubscriptionUsageSyncAction,
  updateSubscriptionAction,
} from '../../../customersSlice';
import { mapSubscriptionFormEntitlementsToSubscriptionEntitlementsUpdate } from '../../subscriptions/subscriptionEntitlements/subscriptionEntitlementsUtils';
import { OutdatedSubscriptionSign } from '../../subscriptions/subscriptionInfo/components/OutdatedSubscriptionSign';
import { useCancelFutureUpdatesDialog } from '../../subscriptions/hooks';
import { usePollCustomerSubscriptionPaymentProcessing } from '../usePollCustomerSubscriptionPaymentProcessing';
import { CancelSubscriptionForm } from './CancelSubscriptionForm';
import { getPriceBreakdown, mapSubscriptionToBillableFeatures } from './priceBreakdown';
import { SubscriptionForm } from './subscriptionForm/SubscriptionForm';
import { SubscriptionFormFields } from './subscriptionForm/SubscriptionForm.types';
import { SubscriptionPrice } from './SubscriptionPrice';
import { SubscriptionResourceLabel } from './SubscriptionResourceLabel';
import { ExtraSubscriptionStatuses, SubscriptionStatusChip } from './SubscriptionStatusChip';
import { TrialSubscriptionIndicatorIcon } from './TrialSubscriptionIndicatorIcon';
import Search from '../../../../../components/Search';
import {
  convertAddonPriceOverrideToInput,
  convertPriceOverrideToInput,
  generateSubscriptionRefId,
  mapBillableFeaturesToBillableFeaturesInput,
  mapMinimumSpendInput,
} from './subscriptionForm/SubscriptionForm.utils';
import { sortCharges } from './useChargeSort';
import { hasFutureUpdates } from '../../subscriptions/utils/futureUpdates.utils';
import { isInvoicePendingPayment } from '../../subscriptions/subscriptionInfo/components/paymentCollection/PaymentCollectionBanner.utils';
import { getConfirmedEntitlements } from '../../../../entitlements/components/entitlementSettings/types';

const isAwsMarketplaceManagedSubscription = (subscription: CustomerSubscriptionDataFragment) =>
  !!subscription?.plan?.awsMarketplacePlanDimension;

const createHeadCells = (
  onCancelSubscriptionClick: (entity: CustomerSubscriptionDataFragment) => void,
  onUpdateSubscriptionClick: (entity: CustomerSubscriptionDataFragment) => void,
  onCancelSubscriptionScheduleClick: (entity: CustomerSubscriptionDataFragment) => void,
  onTriggerSubscriptionUsageSync: (entity: CustomerSubscriptionDataFragment) => void,
  customer: CustomerResponseFragment,
  search: string,
): Array<HeadCell<CustomerSubscriptionDataFragment, any>> => [
  {
    id: 'planName',
    alignment: 'left',
    label: t('customers.subscriptionPlanColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      return (
        <GridFlex.RowSpaceBetween container wrap="nowrap" py={1}>
          <GridFlex.Column data-testid="customer-subscription-item" container wrap="nowrap">
            <GridFlex.RowCenter item container>
              <LongText variant="body2" sx={{ marginRight: 2 }}>
                <HighlightText
                  highlight={search}
                  text={`${entity.plan.product.displayName} / ${entity.plan.displayName}`}
                />{' '}
              </LongText>
              <TrialSubscriptionIndicatorIcon subscription={entity} />
              <OutdatedSubscriptionSign subscription={entity} disabled={!isActiveSubscriptionUtil(entity.status)} />
              {entity.experimentInfo && <SubscriptionAtExperiment status={entity.experimentInfo.status} />}
            </GridFlex.RowCenter>
            <Grid item>
              <Text.Sub2>
                <HighlightText
                  highlight={search}
                  text={`${entity.addons?.length ? `${entity.addons.length} add-ons` : ''}`}
                />
              </Text.Sub2>
            </Grid>
            {entity.resource && (
              <Grid item mt={2}>
                <SubscriptionResourceLabel resource={entity.resource} highlight={search} />
              </Grid>
            )}
          </GridFlex.Column>

          {isAwsMarketplaceManagedSubscription(entity) ? <AwsChip size="medium" logoOnly /> : null}
        </GridFlex.RowSpaceBetween>
      );
    },
  },
  {
    id: 'subscriptionStatus',
    alignment: 'left',
    label: t('customers.subscriptionStatusColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      return (
        <GridFlex.Column
          my={4}
          gap={2}
          data-testid={`customer-subscriptions-table-statuschip-${entity.status}-planrefid-${entity.plan.refId}${
            entity.resource ? `-resourcerefid-${entity.resource.resourceId}` : ''
          }`}>
          {entity.status !== SubscriptionStatus.PaymentPending && (
            <GridFlex.Item>
              <SubscriptionStatusChip hasPointer status={entity.status} cancellationDate={entity.cancellationDate} />
            </GridFlex.Item>
          )}
          {entity.paymentCollection !== PaymentCollection.NotRequired && (
            <GridFlex.Item>
              <SubscriptionStatusChip
                hasPointer
                status={
                  entity.paymentCollection === PaymentCollection.Processing ||
                  isInvoicePendingPayment(entity.latestInvoice?.errorMessage)
                    ? PaymentCollection.Processing
                    : ExtraSubscriptionStatuses.PaymentError
                }
              />
            </GridFlex.Item>
          )}
          {hasFutureUpdates(entity, SubscriptionScheduleStatus.Scheduled) && (
            <GridFlex.Item>
              <SubscriptionStatusChip
                hasPointer
                status={ExtraSubscriptionStatuses.ScheduledUpdate}
                tooltip={<Text.B2>{t('customers.scheduleLeft')}</Text.B2>}
              />
            </GridFlex.Item>
          )}
        </GridFlex.Column>
      );
    },
  },
  {
    id: 'subscriptionPrice',
    alignment: 'left',
    label: t('customers.subscriptionPriceColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      const planPrices = entity.plan.prices;
      const subscriptionPrices = entity.prices || [];
      const hasPrices =
        !isEmpty(subscriptionPrices) && subscriptionPrices?.some((subscriptionPrice) => subscriptionPrice.price);

      if (entity.pricingType === PricingType.Free) {
        return <Text.B2>{t('subscriptions.freePricing')}</Text.B2>;
      }
      if (entity.pricingType === PricingType.Custom || !hasPrices) {
        return <Text.B2>{t('subscriptions.customPricing')}</Text.B2>;
      }

      const billableFeatures = mapSubscriptionToBillableFeatures(entity);

      const subscriptionAddons = (entity.addons || []).map((addon) => ({
        quantity: addon.quantity,
        displayName: addon.addon.displayName,
        price: addon.price,
        addonId: addon.addon.refId,
      }));
      const priceBreakdown = getPriceBreakdown({
        planPrices: sortCharges(compact(subscriptionPrices.map((sp) => sp.price))),
        addonsPrices: subscriptionAddons,
        billableFeatures,
        coupon: customer.coupon,
        subscriptionCoupon: entity.coupon,
        withZeroQuantityPrices: undefined,
        paidOneOffAddons: undefined,
        freeItems: entity.freeItems,
        minimumSpend: entity.minimumSpend?.minimum?.amount,
      });
      const hasMonthlyPrice = planPrices?.some((price) => price.billingPeriod === BillingPeriod.Monthly);
      const showMonthlyPriceVariation = !!(
        hasMonthlyPrice && subscriptionPrices?.[0]?.price?.billingPeriod === BillingPeriod.Annually
      );

      return (
        hasPrices && (
          <SubscriptionPrice
            priceBreakdown={priceBreakdown}
            coupon={customer.coupon}
            subscriptionCoupon={entity.coupon}
            showMonthlyPriceVariation={showMonthlyPriceVariation}
            showBillingPeriod
          />
        )
      );
    },
  },
  {
    id: 'startDate',
    alignment: 'left',
    label: t('customers.subscriptionStartDateColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => <DateText date={entity.startDate} withTime />,
  },
  {
    id: 'endDate',
    alignment: 'left',
    label: t('customers.subscriptionEndDateColumn'),
    render: (entity: CustomerSubscriptionDataFragment) => {
      return entity.effectiveEndDate ? (
        <DateText date={entity.effectiveEndDate} withTime isEndDate={entity.status === SubscriptionStatus.InTrial} />
      ) : null;
    },
  },
  {
    id: 'options',
    alignment: 'right',
    label: '',
    width: 60,
    maxWidth: 36 + 8,
    disablePadding: true,
    disableClick: true,
    render: (entity: CustomerSubscriptionDataFragment) => {
      return (
        <SubscriptionsOptionsDropdown
          subscription={entity}
          isAwsMarketplaceManagedSubscription={isAwsMarketplaceManagedSubscription(entity)}
          onCancelSubscriptionClick={() => onCancelSubscriptionClick(entity)}
          onUpdateSubscriptionClick={() => onUpdateSubscriptionClick(entity)}
          onCancelSubscriptionScheduleClick={() => onCancelSubscriptionScheduleClick(entity)}
          onTriggerSubscriptionUsageSync={() => onTriggerSubscriptionUsageSync(entity)}
        />
      );
    },
  },
];

export const CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE = 5;

export function CustomerSubscriptions({ customer }: { customer: CustomerResponseFragment }) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [search, setSearch] = useState('');
  const [loadHistory, setLoadHistory] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const isLoadingCustomerSubscriptions = useSelector((root: RootState) => root.customersReducer.isLoadingSubscriptions);

  const isLoadingNumberOfInactiveSubscriptions = useSelector(
    (root: RootState) => root.customersReducer.isLoadingNonActiveSubscriptionsCount,
  );
  const numberOfInactiveSubscriptions =
    useSelector((root: RootState) => root.customersReducer.nonActiveSubscriptionsCount) || 0;

  const { id: customerId, customerId: customerRefId } = customer;
  const products = useSelector((state: RootState) => state.productReducer.products);
  const hasMultiSubscriptionProduct = products.some((product) => product.multipleSubscriptions);
  const { pageInfo, totalCount, edges } = useSelector(
    (state: RootState) => state.customersReducer.customerSubscriptions,
  );
  const [createSubscriptionOpen, setCreateSubscriptionOpen] = useState(false);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState<CustomerSubscriptionDataFragment | null | undefined>();
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<CustomerSubscriptionDataFragment | null>(null);
  const [subscriptionToCancelScheduledUpdates, setSubscriptionToCancelScheduledUpdates] =
    useState<CustomerSubscriptionDataFragment | null>(null);
  const [CancelScheduledUpdatesDialog, showCancelScheduledUpdatesDialog, CancelScheduledUpdatesDialogProps] =
    useCancelFutureUpdatesDialog({
      subscription: subscriptionToCancelScheduledUpdates as unknown as SubscriptionDataFragment,
      futureUpdateStatus: SubscriptionScheduleStatus.Scheduled,
    });
  const { customerSubscriptionsPageSizeOptions: customerSubscriptionsPageSizeOptionsEnabled } =
    useFlags<FeatureFlags>();
  const [pageSize, setPageSize] = useState(CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE);

  useEffect(() => {
    if (!isEmpty(customerId)) {
      void dispatch(fetchCustomerSubscriptionsAction({ customerId, search, loadHistory, paging: { first: pageSize } }));
      void dispatch(fetchNonActiveSubscriptionsCountAction({ customerId, search }));
    }
  }, [dispatch, customerId, search, loadHistory, pageSize]);

  const triggerFetchCustomerSubscriptions = useCallback(() => {
    setPageNum(0);

    void dispatch(fetchCustomerSubscriptionsAction({ customerId, isSilentLoading: true, paging: { first: pageSize } }));
  }, [customerId, dispatch, pageSize]);
  usePollCustomerSubscriptionPaymentProcessing(triggerFetchCustomerSubscriptions);

  const pageChangeFunc = createPageChangeFunc(
    dispatch,
    pageNum,
    pageInfo,
    setPageNum,
    fetchCustomerSubscriptionsAction,
    {
      customerId,
      search,
      loadHistory,
    },
    pageSize,
  );

  const closeCreateSubscriptionDialog = () => {
    setSubscriptionToEdit(null);
    setCreateSubscriptionOpen(false);
  };

  const handleCreateSubscription = (
    { entitlements, ...values }: SubscriptionFormFields,
    plan?: PlanListFragment | PlanFragment,
    isAllowEmptyResourceIdEnabled?: boolean,
  ) => {
    const subscriptionNewEntitlements = getConfirmedEntitlements(entitlements).map<SubscriptionEntitlementInput>(
      (entitlement) => ({
        featureId: entitlement.feature.id,
        description: entitlement.description,
        hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
        hasSoftLimit: entitlement.hasSoftLimit,
        usageLimit: !isNil(entitlement.usageLimit) ? Math.floor(entitlement.usageLimit) : null,
        ...getResetPeriodData(entitlement.resetPeriod, entitlement.resetPeriodConfiguration),
      }),
    );

    const addons: SubscriptionAddonInput[] = values.addons.map((addon) => ({
      addonId: addon.refId,
      quantity: addon.quantity || 1,
    }));
    // If there is no trial set, currently the endDate is never set (from UI)
    if (!values.isTrial) {
      values.endDate = undefined;
    }
    if (values.paymentCollectionMethod === PaymentCollectionMethod.None) {
      values.billingPeriod = undefined;
      values.billingCountryCode = undefined;
    }

    const resolveResourceId = (
      resourceId: string,
      isMultipleSubscriptions?: boolean,
      allowEmptyResourceId?: boolean,
    ) => {
      if (!isMultipleSubscriptions || (allowEmptyResourceId && !resourceId)) {
        return undefined;
      }
      return resourceId;
    };

    const resourceId = resolveResourceId(
      values.resourceId,
      plan?.product.multipleSubscriptions,
      isAllowEmptyResourceIdEnabled,
    );
    const isBackdated = moment(values.startDate).startOf('day').isBefore(moment().startOf('day'));
    const budget: BudgetConfigurationInput | null = values.budget
      ? {
          limit: values.budget.limit || 0,
          hasSoftLimit: !!values.budget.hasSoftLimit,
        }
      : null;

    let billingInfo = {};
    billingInfo = isBackdated ? { ...billingInfo, isBackdated } : billingInfo;
    billingInfo = values.isInvoicePaid ? { ...billingInfo, isInvoicePaid: values.isInvoicePaid } : billingInfo;
    billingInfo = values.invoiceDaysUntilDue
      ? { ...billingInfo, invoiceDaysUntilDue: values.invoiceDaysUntilDue }
      : billingInfo;

    const chargesOverrides = convertPriceOverrideToInput(values.billableFeaturesPriceOverride);
    const addonOverrides = convertAddonPriceOverrideToInput(values.addonsPriceOverride);
    const priceOverrides =
      chargesOverrides || addonOverrides ? [...(chargesOverrides || []), ...(addonOverrides || [])] : undefined;

    const minimumSpend = values.isTrial
      ? undefined
      : mapMinimumSpendInput(values.minimumSpend, values.billingPeriod, plan);

    return dispatch(
      createSubscriptionAction({
        ...omit(
          values,
          'productId',
          'isInvoicePaid',
          'billableFeaturesPriceOverride',
          'addonsPriceOverride',
          'minimumSpend',
          'invoiceDaysUntilDue',
        ),
        subscriptionId: generateSubscriptionRefId(values.planId),
        addons,
        billingPeriod: values.billingPeriod || null,
        customerId: customerRefId,
        resourceId,
        budget,
        billableFeatures: mapBillableFeaturesToBillableFeaturesInput(values.billableFeatures),
        isOverridingTrialConfig: values.isTrial === isEmpty(plan?.defaultTrialConfig),
        awaitPaymentConfirmation: false,
        subscriptionEntitlements: subscriptionNewEntitlements,
        ...(!isEmpty(billingInfo) ? { billingInformation: billingInfo } : {}),
        subscriptionPricingType:
          values.paymentCollectionMethod === PaymentCollectionMethod.None ? PricingType.Custom : plan!.pricingType!,
        priceOverrides,
        minimumSpend,
      }),
    );
  };

  const handleUpdateSubscription = async (values: SubscriptionFormFields) => {
    if (!subscriptionToEdit?.subscriptionId) {
      return;
    }

    const subscriptionEntitlements = mapSubscriptionFormEntitlementsToSubscriptionEntitlementsUpdate(
      values.entitlements,
    );

    const addons: SubscriptionAddonInput[] = values.addons.map((addon) => ({
      addonId: addon.refId,
      quantity: addon.quantity || 1,
    }));

    const billableFeatures = mapBillableFeaturesToBillableFeaturesInput(values.billableFeatures);

    let billingInfo = values.isInvoicePaid ? { isInvoicePaid: values.isInvoicePaid } : {};
    billingInfo = values.invoiceDaysUntilDue ? { ...billingInfo, invoiceDaysUntilDue: values.invoiceDaysUntilDue } : {};

    const budget: BudgetConfigurationInput | null = values.budget
      ? {
          limit: values.budget.limit || 0,
          hasSoftLimit: !!values.budget.hasSoftLimit,
        }
      : null;

    const chargesOverrides = convertPriceOverrideToInput(values.billableFeaturesPriceOverride);
    const addonOverrides = convertAddonPriceOverrideToInput(values.addonsPriceOverride);
    const priceOverrides =
      chargesOverrides || addonOverrides ? [...(chargesOverrides || []), ...(addonOverrides || [])] : undefined;

    await dispatch(
      updateSubscriptionAction({
        subscriptionId: subscriptionToEdit.subscriptionId,
        addons,
        subscriptionEntitlements,
        budget,
        ...(billableFeatures ? { billableFeatures } : {}),
        ...(values.isTrial && values.endDate ? { trialEndDate: values.endDate } : {}),
        ...(!isEmpty(billingInfo) ? { billingInformation: billingInfo } : {}),
        ...(priceOverrides ? { priceOverrides } : {}),
      }),
    );

    await dispatch(
      fetchCustomerSubscriptionsAction({ customerId, isSilentLoading: true, paging: { first: pageSize } }),
    );
    await dispatch(fetchEntitlementsByRefIdAction({ customerRefId, resourceRefId: undefined }));
  };

  const onCancelSubscriptionClick = (subscription: CustomerSubscriptionDataFragment) => {
    setCancelSubscriptionDialogOpen(true);
    setSubscriptionToDelete(subscription);
  };

  const closeCancelSubscriptionDialog = () => {
    setCancelSubscriptionDialogOpen(false);
  };

  const onUpdateSubscriptionClick = (subscription: CustomerSubscriptionDataFragment) => {
    setCreateSubscriptionOpen(true);
    setSubscriptionToEdit(subscription);
  };

  const onCancelSubscriptionScheduleClick = (subscription: CustomerSubscriptionDataFragment) => {
    showCancelScheduledUpdatesDialog(true);
    setSubscriptionToCancelScheduledUpdates(subscription);
  };

  const onTriggerSubscriptionUsageSync = (subscription: CustomerSubscriptionDataFragment) => {
    void dispatch(
      triggerSubscriptionUsageSyncAction({
        customerId: customerRefId,
        resourceId: subscription.resource?.resourceId,
      }),
    );
  };

  const shouldHighlightRow = useCallback(
    (current: CustomerSubscriptionDataFragment, prev: CustomerSubscriptionDataFragment) =>
      prev.status !== current.status,
    [],
  );

  const shouldAllowSearch = isLoadingCustomerSubscriptions || totalCount > 0 || !isEmpty(search);

  return (
    <>
      <PageCard>
        <Grid container justifyContent="space-between">
          <Text.H3 mb={2}>{t('customers.subscriptionsSectionTitle')}</Text.H3>
          <Button
            color="primary"
            $outlined
            onClick={() => setCreateSubscriptionOpen(true)}
            data-testid="button-add-subscription">
            <AddIcon />
            {t('customers.addSubscription')}
          </Button>
        </Grid>
        <GridFlex.Column>
          <Text.Sub2 mb={2}>{t('customers.subscriptionsSectionSubtitle')}</Text.Sub2>
          {shouldAllowSearch || numberOfInactiveSubscriptions > 0 ? (
            <GridFlex.RowSpaceBetween height={48} mb={4}>
              {shouldAllowSearch ? (
                <GridFlex.Item flex={1} maxWidth={420}>
                  <Search
                    variant="outlined"
                    handleSearchFunc={(searchTerm) => {
                      setSearch(searchTerm);
                      if (!isEmpty(searchTerm)) {
                        setPageNum(0);
                      }
                    }}
                    placeholder={
                      hasMultiSubscriptionProduct
                        ? t('customers.subscriptionSearchPlaceholder')
                        : t('customers.subscriptionWithoutResourceSearchPlaceholder')
                    }
                    searchedStr={search}
                    isLoading={isLoadingNumberOfInactiveSubscriptions}
                    searchOnChange
                  />
                </GridFlex.Item>
              ) : (
                <div id="blalala" />
              )}
              {numberOfInactiveSubscriptions > 0 && (
                <Button
                  variant="text"
                  onClick={() => setLoadHistory((prev) => !prev)}
                  disabled={isLoadingCustomerSubscriptions}
                  data-testid="show-hide-history">
                  <GridFlex.RowCenter gap={1}>
                    {loadHistory && <Icon icon="Clear" />}
                    {t(loadHistory ? 'customers.hideHistory' : 'customers.loadHistory', {
                      numberOfSubscriptions: numberOfInactiveSubscriptions,
                    })}
                  </GridFlex.RowCenter>
                </Button>
              )}
            </GridFlex.RowSpaceBetween>
          ) : undefined}
          {!isLoadingCustomerSubscriptions && isEmpty(edges) ? (
            <EmptyCardContent>
              <Text.Sub2>
                {isEmpty(search) ? (
                  <GridFlex.Row>
                    <Text.Sub2>{t('customers.subscriptionsSectionEmpty')}</Text.Sub2>
                    <Link variant="body2" ml={1} onClick={() => setCreateSubscriptionOpen(true)}>
                      {t('customers.subscriptionsSectionEmptyCTA')}
                    </Link>
                  </GridFlex.Row>
                ) : (
                  <Text.Sub2>{t('customers.noActiveSubscriptions')}</Text.Sub2>
                )}
              </Text.Sub2>
            </EmptyCardContent>
          ) : (
            <Table
              rowHeight={82}
              isLoading={isLoadingCustomerSubscriptions}
              label={t('customers.subscriptionsTableLabel')}
              skeletonOptions={{ rowsCount: 3 }}
              headCells={createHeadCells(
                onCancelSubscriptionClick,
                onUpdateSubscriptionClick,
                onCancelSubscriptionScheduleClick,
                onTriggerSubscriptionUsageSync,
                customer,
                search,
              )}
              data={edges?.map((x) => x.node as CustomerSubscriptionDataFragment) || []}
              pageNum={pageNum}
              pageSize={pageSize}
              totalCount={totalCount}
              pageChangeFunc={pageChangeFunc}
              shouldHighlightRow={shouldHighlightRow}
              onRowClick={(subscription: CustomerSubscriptionDataFragment) =>
                navigation.navigateTo(`/subscriptions/${subscription.subscriptionId}`)
              }
              rowsPerPageOptions={
                customerSubscriptionsPageSizeOptionsEnabled
                  ? [CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE, 25, 50]
                  : [CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE]
              }
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          )}
        </GridFlex.Column>
      </PageCard>
      <CancelScheduledUpdatesDialog {...CancelScheduledUpdatesDialogProps} />

      <Dialog
        disableCloseOnEscapeKey
        fullScreen
        padding={0}
        content={
          <SubscriptionForm
            customer={customer}
            onCancel={closeCreateSubscriptionDialog}
            handleSubmit={subscriptionToEdit ? handleUpdateSubscription : handleCreateSubscription}
            subscription={subscriptionToEdit}
          />
        }
        isOpen={createSubscriptionOpen}
        onCancel={closeCreateSubscriptionDialog}
        aria-labelledby="add-customer-subscription"
      />
      <Dialog
        content={
          <CancelSubscriptionForm
            subscription={subscriptionToDelete}
            onCancel={closeCancelSubscriptionDialog}
            customerId={customerId}
          />
        }
        titleText={t('subscriptions.cancelSubscriptionDialogTitle')}
        isOpen={cancelSubscriptionDialogOpen}
        onCancel={closeCancelSubscriptionDialog}
        aria-labelledby="edit-customer-dialog"
      />
    </>
  );
}
