import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import { FetchSlimPlansQuery, FetchSlimPlansQueryVariables, PackageStatus, PlanFilter } from '@stigg-types/apiTypes';
import { applyEnvironmentFilter } from '@stigg-common';
import { Navigation } from '../navigation/useNavigation';
import { apolloClient } from '../../ApolloClient';
import { FETCH_SLIM_PLANS } from '../packages/plans/queries/fetchPlans';
import { MAX_RESULTS_PER_COMMANDBAR_SEARCH } from './useCommandBar';
import { executeSafely } from './utils';
import { getCommandBarIcon } from './icons/getCommandBarIcon';
import plansIcon from './icons/plans.svg';

export function setPlansSearch(environmentId: string, navigation: Navigation) {
  const doFetchPlans: SearchFunction = async (search: string) => {
    const filter: PlanFilter = {
      environmentId: { eq: environmentId },
      status: { neq: PackageStatus.Archived },
      isLatest: { is: true },
    };

    if (search) {
      filter.or = [
        { displayName: { iLike: `%${search}%` } },
        { refId: { iLike: `%${search}%` } },
        { billingId: { iLike: `%${search}%` } },
      ].map((searchFilter) => applyEnvironmentFilter<PlanFilter>(searchFilter, environmentId));
    }

    const response = await apolloClient.query<FetchSlimPlansQuery, FetchSlimPlansQueryVariables>({
      query: FETCH_SLIM_PLANS,
      fetchPolicy: 'network-only',
      variables: {
        paging: { first: MAX_RESULTS_PER_COMMANDBAR_SEARCH },
        sorting: [],
        filter,
      },
    });

    const plans = response.data.plans.edges;
    return plans.map((plan) => {
      const { refId: planId, displayName } = plan.node;
      return { planId, label: displayName || planId, description: displayName ? planId : '' };
    });
  };

  window.CommandBar.addRecords('plans', [], {
    onInputChange: executeSafely(doFetchPlans),
    defaultIcon: getCommandBarIcon(plansIcon),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.planPage('{{record.planId}}'),
    },
  });
}
