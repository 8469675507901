import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { t } from 'i18next';
import { TextField, Box, Grid, Text } from '@stigg-components';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { slackUrlValidation, urlValidation } from '../../../common/urlValidation';
import { DialogActionsButtons } from '../../../../components/Dialog';
import { createHookAction } from '../../hooksSlice';
import EventsSelector from './EventsSelector';
import { CreateHookFormFields, handleHookConfiguration } from './hooks.utils';

const initialValues = {
  endpoint: '',
  description: '',
  eventLogTypes: [],
} as CreateHookFormFields;

const validationSchema = (isSlackIntegrationHook?: boolean) =>
  isSlackIntegrationHook
    ? Yup.object().shape({
        endpoint: slackUrlValidation(),
        eventLogTypes: Yup.array().min(1, t('hooks.eventTypeArrayEmpty')),
      })
    : Yup.object().shape({
        description: Yup.string().required(t('hooks.descriptionRequiredError')),
        endpoint: urlValidation(),
        eventLogTypes: Yup.array().min(1, t('hooks.eventTypeArrayEmpty')),
      });

export default function CreateHookForm({
  onCancel,
  isSlackIntegrationHook,
}: {
  onCancel: () => void;
  isSlackIntegrationHook?: boolean;
}) {
  const createHookLoading = useSelector((root: RootState) => root.hooksReducer.isLoading);

  const dispatch = useAppDispatch();
  const handleSubmit = async (values: CreateHookFormFields) => {
    const configuration = handleHookConfiguration({ values });
    await dispatch(createHookAction({ ...values, configuration }));
    onCancel();
  };

  return (
    <Formik
      validationSchema={validationSchema(isSlackIntegrationHook)}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
        return (
          <Box>
            {createHookLoading ? (
              <Loader />
            ) : (
              <form onSubmit={handleSubmit}>
                <FieldArray name="eventLogTypes">
                  {({ push, remove }) => (
                    <Grid flexDirection="column" container>
                      {!isSlackIntegrationHook && (
                        <Grid item>
                          <TextField
                            name="description"
                            label={t('hooks.description')}
                            value={values.description}
                            touched={!!touched.description}
                            error={!!errors.description}
                            errorText={errors.description}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      )}
                      <Grid item pt={4}>
                        <TextField
                          name="endpoint"
                          label={t('hooks.endpoint')}
                          value={values.endpoint}
                          touched={!!touched.endpoint}
                          error={!!errors.endpoint}
                          errorText={errors.endpoint}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={
                            isSlackIntegrationHook
                              ? t('hooks.slackEndpointPlaceholder')
                              : t('hooks.endpointPlaceholder')
                          }
                        />
                      </Grid>
                      <Grid container flexDirection="column" rowSpacing={4} pt={10}>
                        <Grid item>
                          <Text.H6>{t('hooks.triggerEventsTitle')}</Text.H6>
                        </Grid>
                        <Grid item>
                          <EventsSelector
                            selectedEventLogTypes={values.eventLogTypes}
                            push={push}
                            remove={remove}
                            setFieldValue={setFieldValue}
                            isSlackIntegrationHook={isSlackIntegrationHook}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container alignItems="baseline" justifyContent="space-between">
                        <Grid item>
                          {values.eventLogTypes.length > 0 ? (
                            <Text.Sub2>
                              {values.eventLogTypes.length} event{values.eventLogTypes.length > 1 ? 's' : ''} selected
                            </Text.Sub2>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <DialogActionsButtons saveDisabled={!isValid || !dirty} saveText="Add" onCancel={onCancel} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </FieldArray>
              </form>
            )}
          </Box>
        );
      }}
    </Formik>
  );
}
