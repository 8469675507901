import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { useIntercom } from 'react-use-intercom';
import {
  Plan,
  Checkout,
  CustomerPortal,
  OnManageSubscriptionFn,
  OnPlanSelectedCallbackFn,
  Paywall,
  useStiggContext,
  PricingType,
  BillingPeriod,
  SubscribeIntentionType,
  OnCheckoutCompletedParams,
} from '@stigg/react-sdk';
import { useModal } from '../../common';

const StyledStiggCheckout = styled.div`
  .stigg-checkout-layout {
    border-color: transparent;
    padding: ${({ theme }) => theme.spacing(4)};
    min-height: 600px;
  }
`;

const StyledStiggCustomerPortal = styled.div<{ $isPaidSubscription: boolean }>`
  .stigg-plan-offering-container {
    min-width: 383px;
  }
  .stigg-entitlement-usage-feature-seats {
    order: 1;
  }
  .stigg-entitlement-usage-feature-paid-subscriptions {
    order: 2;
  }
  .stigg-entitlement-usage-feature-custom-subscriptions {
    order: 3;
  }
  .stigg-entitlement-usage-feature-free-subscriptions {
    order: 4;
  }
  .stigg-entitlement-usage-feature-products {
    order: 5;
  }
  .stigg-entitlement-usage-feature-development-environments {
    order: 6;
  }
  .stigg-entitlement-usage-feature-production-environments {
    order: 7;
  }

  ${({ $isPaidSubscription }) =>
    $isPaidSubscription &&
    css`
      .stigg-entitlement-usage-feature-seats .MuiLinearProgress-bar {
        background-color: rgba(50, 126, 238, 0.4);
      }
      .stigg-charge-list-item-feature-seats .MuiLinearProgress-bar {
        background-color: rgba(50, 126, 238, 0.4);
      }
      .stigg-entitlement-usage-feature-production-environments .MuiLinearProgress-bar {
        background-color: rgba(50, 126, 238, 0.4);
      }
    `}
`;

const HIDDEN_ENTITLEMENTS = ['feature-event-metering', 'feature-environments', 'feature-entitlements'];

export function BillingCustomerPortal() {
  const [CheckoutModal, setCheckoutModal] = useModal({ height: 800, width: 1000 });
  const intercom = useIntercom();
  const { stigg, refreshData, customerPortalUpdatedAt } = useStiggContext();
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [currentBillingPeriod, setCurrentBillingPeriod] = useState(BillingPeriod.Annually);

  const onPaywallPlanSelected: OnPlanSelectedCallbackFn = (args) => {
    const { plan, intentionType } = args;
    if (intentionType === SubscribeIntentionType.REQUEST_CUSTOM_PLAN_ACCESS) {
      intercom.showNewMessage('I’d like to learn more about Stigg’s Scale plan');
    } else {
      setSelectedPlan(plan);
      setCheckoutModal(true);
    }
  };

  const onManageSubscription: OnManageSubscriptionFn = (args) => {
    const { customerSubscriptions } = args;
    const paidSubscription = customerSubscriptions.find((sub) => sub.pricingType === PricingType.Paid);
    if (paidSubscription) {
      setCheckoutModal(true);
      if (paidSubscription.prices[0].billingPeriod) {
        // We have an assumption that all prices in a subscription have the same billing period
        setCurrentBillingPeriod(paidSubscription.prices[0].billingPeriod);
      }
    } else {
      document
        .getElementsByClassName('stigg-customer-portal-paywall-section')[0]
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onCheckoutCompleted = async ({ success }: OnCheckoutCompletedParams) => {
    if (!success) {
      return;
    }

    setCheckoutModal(false);
    await refreshData();
  };

  const onContactSupport = () => {
    intercom.showNewMessage();
  };

  useEffect(() => {
    void stigg.getActiveSubscriptions().then((subscriptions) => {
      const paidSubscription = subscriptions.find((sub) => sub.pricingType === PricingType.Paid);
      if (paidSubscription) {
        setSelectedPlan(paidSubscription.plan);
      }
    });
  }, [stigg, customerPortalUpdatedAt]);

  return (
    <>
      <CheckoutModal>
        {selectedPlan?.id && (
          <StyledStiggCheckout>
            <Checkout
              planId={selectedPlan.id}
              preferredBillingPeriod={currentBillingPeriod}
              onChangePlan={() => setCheckoutModal(false)}
              onCheckoutCompleted={onCheckoutCompleted}
            />
          </StyledStiggCheckout>
        )}
      </CheckoutModal>
      <StyledStiggCustomerPortal $isPaidSubscription={selectedPlan?.pricingType === PricingType.Paid}>
        <CustomerPortal
          filterEntitlements={(entitlements) =>
            entitlements.filter((entitlement) => !HIDDEN_ENTITLEMENTS.includes(entitlement.feature!.refId))
          }
          onManageSubscription={onManageSubscription}
          onContactSupport={onContactSupport}
          paywallComponent={
            <Paywall onBillingPeriodChange={setCurrentBillingPeriod} onPlanSelected={onPaywallPlanSelected} />
          }
        />
      </StyledStiggCustomerPortal>
    </>
  );
}
