import {
  LongText,
  Select,
  ButtonGroup,
  Button,
  Box,
  Text,
  InformationTooltip,
  GridFlex,
  MenuItem,
  useMediaQuery,
} from '@stigg-components';
import { styled } from '@stigg-theme';
import { Theme } from '@mui/system';
import { Feature, Plan } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useTheme } from '@mui/material/styles';
import { SERIES_COLORS } from '../../subscriptionsOverview/components/constants';
import { hasEntitlement } from '../helpers/plan.helper';

const PLAN_BUTTON_MAX_WIDTH = 200;

const PlansButtonGroup = styled(ButtonGroup)`
  padding: 2px;
  background-color: ${({ theme }) => theme.itamar.palette.grey[10]};
`;

const StyledButton = styled(Button)<{ $isSelected: boolean }>`
  max-width: ${() => `${PLAN_BUTTON_MAX_WIDTH}px`};
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? `${theme.itamar.palette.white} !important` : `${theme.itamar.palette.grey[10]} !important`};
`;

function PlanButton({
  color,
  plan,
  selectedPlan,
  onPlanSelected,
  selectedFeature,
}: {
  color: string;
  plan: Plan;
  selectedPlan: Plan | undefined;
  selectedFeature: Feature | undefined;
  onPlanSelected: (plan: Plan) => void;
}) {
  const theme = useTheme();

  const isSelected = plan.refId === selectedPlan?.refId;
  const disabled = !selectedFeature || !hasEntitlement(plan, selectedFeature);
  const boxColor = disabled ? theme.itamar.palette.text.disabled : color;
  const textColor = disabled ? 'disabled' : isSelected ? 'primary' : 'secondary';

  return (
    <InformationTooltip
      title={
        disabled ? (
          <Text.B2>
            {t('dashboards.featureInspector.planDoesNotIncludeFeature', { planName: plan.displayName })}
          </Text.B2>
        ) : (
          ''
        )
      }
      $maxWidth={230}
      arrow
      placement="top">
      <Box>
        <StyledButton
          onClick={() => onPlanSelected(plan)}
          disabled={disabled}
          $isSelected={plan.refId === selectedPlan?.refId}
          variant="contained">
          <Box height={14} width={14} sx={{ flexShrink: 0, backgroundColor: boxColor }} mr={2} />
          <LongText variant="body2" color={textColor}>
            {t('sharedComponents.planSuffix', { planName: plan.displayName })}
          </LongText>
        </StyledButton>
      </Box>
    </InformationTooltip>
  );
}

type PlansSelectProps = {
  availablePlans: Plan[];
  selectedPlan: Plan | undefined;
  onPlanSelected: (plan: Plan) => void;
  selectedFeature: Feature | undefined;
};

function PlansSelect({ availablePlans, selectedPlan, selectedFeature, onPlanSelected }: PlansSelectProps) {
  return (
    <Select
      width={220}
      value={selectedPlan}
      onChange={(e) => {
        const planId = e.target.value as string;
        const plan = availablePlans.find((plan) => plan.id === planId);
        return onPlanSelected(plan!);
      }}
      renderValue={(selectedPlan) => {
        const planIndex = availablePlans.findIndex((plan) => plan.id === selectedPlan.id);
        return (
          <GridFlex.RowCenter>
            <Box
              height={14}
              width={14}
              sx={{ backgroundColor: SERIES_COLORS[planIndex % SERIES_COLORS.length] }}
              mr={2}
            />
            <Text.B2 mx={2}>{selectedPlan?.displayName || '-'}</Text.B2>
          </GridFlex.RowCenter>
        );
      }}>
      {availablePlans.map((plan, index) => {
        const disabled = !selectedFeature || !hasEntitlement(plan, selectedFeature);

        return (
          <MenuItem key={plan.id} value={plan.id} disabled={disabled}>
            <Box height={14} width={14} sx={{ backgroundColor: SERIES_COLORS[index % SERIES_COLORS.length] }} mr={2} />
            <Text.B2 color="primary">{plan.displayName}</Text.B2>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export function PlanSelection({
  availablePlans,
  selectedPlan,
  onPlanSelected,
  selectedFeature,
}: {
  availablePlans: Plan[];
  selectedPlan: Plan | undefined;
  selectedFeature: Feature | undefined;
  onPlanSelected: (plan: Plan) => void;
}) {
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  if (!isMdUp) {
    return (
      <PlansSelect
        availablePlans={availablePlans}
        onPlanSelected={onPlanSelected}
        selectedPlan={selectedPlan}
        selectedFeature={selectedFeature}
      />
    );
  }

  return (
    <PlansButtonGroup>
      {availablePlans.map((plan, index) => (
        <PlanButton
          key={plan.refId}
          color={SERIES_COLORS[index % SERIES_COLORS.length]}
          plan={plan}
          selectedPlan={selectedPlan}
          onPlanSelected={onPlanSelected}
          selectedFeature={selectedFeature}
        />
      ))}
    </PlansButtonGroup>
  );
}
