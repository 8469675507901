import { PropsWithChildren } from 'react';
import { ButtonProps } from '@stigg-components';
import { ButtonGroupPosition, StyledSelectableButton } from './SelectableButton.style';

export function SelectableButton({
  onClick,
  isSelected,
  sx,
  className,
  children,
  disabled,
  buttonGroupPosition = 'middle',
  dataTestId,
}: PropsWithChildren<{
  onClick: () => void;
  isSelected?: boolean;
  sx?: ButtonProps['sx'];
  className?: string;
  disabled?: boolean;
  buttonGroupPosition?: ButtonGroupPosition;
  dataTestId?: string;
}>) {
  return (
    <StyledSelectableButton
      $buttonGroupPosition={buttonGroupPosition}
      $isSelected={isSelected}
      disabled={disabled}
      className={className}
      onClick={onClick}
      data-testid={dataTestId}
      sx={sx}>
      {children}
    </StyledSelectableButton>
  );
}
