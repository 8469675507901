import { BoundedSearchableSelect, FormControl, OutlinedFormFieldLabel } from '@stigg-components';
import React, { useMemo } from 'react';

export type SearchableSelectOption = {
  id: string;
  render: (search: string, disabled) => string | React.ReactNode;
  isInSearch: (search: string) => boolean;
};

export type SearchableSelectProps = {
  label?: string;
  options: SearchableSelectOption[];
  selectedOptionId: string | undefined;
  placeholder?: string;
  maxOptionsToShow?: number;
  onChange: (optionId: string) => void;
  isOptionDisabled?: (optionId: string) => boolean;
  dataTestId?: string;
};

export function SearchableSelect({
  label,
  options,
  selectedOptionId,
  placeholder,
  maxOptionsToShow,
  onChange,
  isOptionDisabled = () => false,
  dataTestId,
}: SearchableSelectProps) {
  const [search, setSearch] = React.useState('');

  const selectedOption = useMemo(
    () => options.find((option) => option.id === selectedOptionId),
    [options, selectedOptionId],
  );

  const filteredOptions = useMemo(() => options.filter((option) => option.isInSearch(search)), [options, search]);
  const optionsToShow = useMemo(() => filteredOptions.slice(0, maxOptionsToShow), [filteredOptions, maxOptionsToShow]);

  return (
    <FormControl sx={{ display: 'inline-flex' }} variant="outlined" fullWidth>
      <OutlinedFormFieldLabel label={label} />
      <BoundedSearchableSelect
        onSearch={setSearch}
        isSearching={false}
        totalItems={filteredOptions.length}
        placeholder={placeholder}
        value={selectedOption}
        items={optionsToShow}
        itemSerializer={({ id }) => id}
        onChange={({ id }) => onChange(id)}
        renderItem={({ id, render }) => render(search, isOptionDisabled(id))}
        isOptionDisabled={({ id }) => isOptionDisabled(id)}
        dataTestId={dataTestId}
      />
    </FormControl>
  );
}
