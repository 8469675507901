import { useEffect } from 'react';
import { CustomerListFragment } from '@stigg-types/apiTypes';
import { GridFlex, LongText, HighlightText, Text, Box, BoundedSearchableSelect } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchCustomersAction } from '../customers/customersSlice';

function renderSelectedItem(customer: CustomerListFragment) {
  const { name, customerId } = customer;
  return (
    <GridFlex.RowCenter>
      <Text.B2 mr={1}>{t('usageEvents.filterCustomerLabel')}</Text.B2>
      <LongText variant="body2" color="primary">
        {name ? `${name} (${customerId})` : customerId}
      </LongText>
    </GridFlex.RowCenter>
  );
}

function renderItem(customer: CustomerListFragment, search?: string) {
  const { customerId, name } = customer;

  return (
    <GridFlex.Column>
      <LongText variant="body2" color="primary">
        <HighlightText highlight={search} text={name || ''} />
      </LongText>
      <LongText variant="body2" color="secondary">
        <HighlightText highlight={search} text={customerId} />
      </LongText>
    </GridFlex.Column>
  );
}

type EventsCustomerFilterProps = {
  onChange: (customer: CustomerListFragment) => void;
  selectedCustomer: CustomerListFragment | undefined;
};

export function UsageEventsCustomerFilter({ onChange, selectedCustomer }: EventsCustomerFilterProps) {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoadingCustomers = useSelector((state: RootState) => state.customersReducer.isLoading);
  const { totalCount: totalCustomersCount, edges: customers } = useSelector(
    (state: RootState) => state.customersReducer.customers,
  );

  useEffect(() => {
    void dispatch(
      fetchCustomersAction({
        environmentId: currentEnvironmentId || '',
      }),
    );
  }, [currentEnvironmentId, dispatch]);

  const onSearch = (search: string) => {
    void dispatch(
      fetchCustomersAction({
        search,
        environmentId: currentEnvironmentId || '',
      }),
    );
  };

  return (
    <Box width={400} ml={4}>
      <BoundedSearchableSelect
        placeholder={t('usageEvents.searchCustomer')}
        maxWidth={400}
        height={36}
        onSearch={onSearch}
        isSearching={isLoadingCustomers}
        totalItems={totalCustomersCount}
        items={(customers || []).map((x) => x.node)}
        itemSerializer={(item: CustomerListFragment) => item.customerId}
        onChange={onChange}
        value={selectedCustomer}
        renderItem={renderItem}
        renderSelectedItem={renderSelectedItem}
      />
    </Box>
  );
}
