import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Button, Text, Box } from '.';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

function ErrorPage() {
  return (
    <Wrapper>
      <Text.H1 align="center" gutterBottom>
        {t('sharedComponents.somethingWentWrong')}
      </Text.H1>
      <Text.B2 align="center" gutterBottom>
        {t('sharedComponents.errorNotified')}
      </Text.B2>
      <Button href="/" variant="contained" color="primary" sx={{ mt: 2 }}>
        {t('sharedComponents.return')}
      </Button>
    </Wrapper>
  );
}

export default ErrorPage;
