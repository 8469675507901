import { Users, Zap, PlayCircle, ToggleLeft } from 'react-feather';
import { DashboardOutlined } from '@mui/icons-material';
import { Icon } from '@stigg-components';
import { t } from 'i18next';
import { SidebarItemsType } from '@stigg-types/sidebar';
import { ProductListItemFragment } from '../../../types/apiTypes';
import { appRoutes } from '../../../modules/navigation/useNavigation';

type GetSidebarItemsProps = {
  products: ProductListItemFragment[];
  withGettingStartedPage: boolean;
  withUsageEventViewer: boolean;
  withEventLog: boolean;
};

function CatalogIcon() {
  return <Icon type="custom" icon="Catalog" color="active" />;
}

function EventsIcon() {
  return <Icon type="custom" icon="HamburgerMenu" color="active" overrideStroke />;
}

function EventLog() {
  return <Icon type="custom" icon="EventLog" color="active" />;
}

function Experiments() {
  return <Icon type="custom" icon="Experiments" color="active" overrideStroke />;
}

const getSidebarItems = ({
  products,
  withGettingStartedPage,
  withUsageEventViewer,
  withEventLog,
}: GetSidebarItemsProps) => {
  let productRefId;
  if (products?.length === 1) {
    productRefId = products[0].refId;
  }
  const integrations = [
    {
      href: '/integrations',
      title: t('sidenav.thirdPartyIntegrations'),
      requiresEnvironment: true,
    },
    {
      href: '/webhooks',
      title: t('sidenav.hooks'),
      requiresEnvironment: true,
    },
  ];

  return [
    {
      pages: [
        ...(withGettingStartedPage
          ? [
              {
                href: '/getting-started',
                requiresEnvironment: true,
                title: t('sidenav.gettingStarted'),
                icon: PlayCircle,
              },
            ]
          : []),
        {
          href: '',
          title: t('sidenav.dashboard'),
          icon: DashboardOutlined,
          open: !withGettingStartedPage,
          children: [
            {
              href: '/dashboards/subscriptions',
              title: t('sidenav.subscriptionsOverview'),
              requiresEnvironment: true,
            },
            {
              href: '/dashboards/feature-inspector',
              title: t('sidenav.featureInspector'),
              requiresEnvironment: true,
            },
          ],
        },
        {
          href: '',
          icon: CatalogIcon,
          title: t('sidenav.productCatalog'),
          open: withGettingStartedPage,
          children: [
            {
              href: appRoutes.productPage(productRefId),
              title: t('sidenav.products'),
              requiresEnvironment: true,
              dataTestId: 'sidenav-products',
            },
            {
              href: '/plans',
              title: t('sidenav.plans'),
              requiresEnvironment: true,
              dataTestId: 'sidenav-plans',
            },
            {
              href: '/addons',
              title: t('sidenav.addons'),
              requiresEnvironment: true,
            },
            {
              href: '/features',
              title: t('sidenav.features'),
              requiresEnvironment: true,
            },
            {
              href: '/coupons',
              title: t('sidenav.coupons'),
              requiresEnvironment: true,
            },
          ],
        },
        {
          href: '/customers',
          icon: Users,
          title: t('sidenav.customers'),
          requiresEnvironment: true,
          dataTestId: 'sidenav-customers',
        },
        {
          href: '/experiments',
          icon: Experiments,
          title: t('sidenav.experiments'),
          requiresEnvironment: true,
        },
        {
          href: '',
          icon: Zap,
          title: t('sidenav.integrations'),
          children: integrations,
        },
        ...(withUsageEventViewer
          ? [
              {
                href: '/usage-events',
                icon: EventsIcon,
                title: t('sidenav.usageEvents'),
                requiresEnvironment: true,
              },
            ]
          : []),
        {
          href: '/widgets',
          icon: ToggleLeft,
          title: t('sidenav.widgets'),
          requiresEnvironment: true,
        },
        ...(withEventLog
          ? [
              {
                href: '/activity-log',
                icon: EventLog,
                title: t('sidenav.eventLog'),
                requiresEnvironment: true,
              },
            ]
          : []),
      ] as SidebarItemsType[],
    },
  ];
};

export default getSidebarItems;
