import moment from 'moment-timezone';
import { useTheme } from '@mui/material/styles';
import { formatDate } from '@stigg-common';
import {
  Box,
  Text,
  GridFlex,
  ReloadButton,
  EmptyCardContent,
  PageCard,
  TwoLinesLayout,
  Icon,
  InformationalTooltipIcon,
} from '@stigg-components';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import {
  CustomerSubscriptionResourceFragment,
  EntitlementDataFragment,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import Table, { HeadCell } from '../../../../../components/table/Table';
import { EntitlementUsageBox } from './EntitlementUsageBox';
import { SelectCustomerResource } from '../SelectCustomerResource';
import { isMetered } from '../../../../features/utils/isMetered';
import { getFeatureTypeIcon } from '../../../../../components/getFeatureTypeIcon';
import { ExpandedEntitlementUsage } from './ExpandedEntitlementUsage';
import { isEntitlementBudgetExceeded } from '../customerEntitlementSummary/CustomerEntitlementsSummary';

const headCells: () => Array<HeadCell<EntitlementDataFragment, any>> = () => [
  {
    disablePadding: true,
    width: 50,
    maxWidth: 50,
    id: 'feature_type',
    alignment: 'center',
    label: t('entitlements.featureType'),
    render: (entity: EntitlementDataFragment) => {
      return entity.feature ? (
        <Box px={4}>
          {getFeatureTypeIcon({ ...entity.feature, hasMeter: !!entity.meterId }, { entitlementsTooltip: true })}
        </Box>
      ) : null;
    },
  },
  {
    id: 'feature',
    alignment: 'left',
    label: t('customers.entitlementUsageTableFeatureColumn'),
    render: (entity: EntitlementDataFragment) => (
      <TwoLinesLayout firstRow={entity.feature?.displayName} secondRow={entity.feature?.refId} />
    ),
  },
  {
    width: 25,
    id: 'value-icon',
    alignment: 'right',
    disablePadding: true,
    render: (entity) => {
      const isBudgetExceeded = isEntitlementBudgetExceeded(entity);
      if (!isBudgetExceeded) {
        return null;
      }

      return (
        <GridFlex.RowMiddle>
          <InformationalTooltipIcon icon={<Icon icon="BudgetExceeded" />} text={t('entitlements.budgetExceeded')} />
        </GridFlex.RowMiddle>
      );
    },
  },
  {
    id: 'usage',
    width: 466, // account for 16px padding instead of 8px like the design
    alignment: 'left',
    label: t('customers.entitlementUsageTableUsageColumn'),
    render: (entity: EntitlementDataFragment) => <EntitlementUsageBox entitlement={entity} />,
  },
];

export function CustomerEntitlementsUsage({
  entitlements,
  isLoading,
  reloadEntitlementsAction,
  entitlementsLastUpdated,
  customerRefId,
  selectedResource,
  onUpdateResource,
}: {
  entitlements: EntitlementDataFragment[];
  customerRefId: string;
  isLoading: boolean;
  reloadEntitlementsAction: () => void;
  entitlementsLastUpdated?: Date | null;
  selectedResource?: CustomerSubscriptionResourceFragment;
  onUpdateResource?: (resource: CustomerSubscriptionResourceFragment) => void;
}) {
  const theme = useTheme();
  const timezone = moment.tz.guess();
  const meteredEntitlements = entitlements.filter(
    (entitlement) => entitlement.feature && isMetered(entitlement.feature),
  );

  const isSubscriptionActive =
    selectedResource && [SubscriptionStatus.Active, SubscriptionStatus.InTrial].includes(selectedResource.status);

  const formattedLastUpdated = entitlementsLastUpdated
    ? formatDate(entitlementsLastUpdated, { timezone, withTime: true })
    : null;

  return (
    <PageCard cardProps={{ paddingTop: 0 }}>
      <GridFlex.Row gap={2} alignItems="center" mb={1}>
        {formattedLastUpdated && (
          <Text.Sub2 fontSize={12} color="tertiary">
            {t('customers.entitlementUsageSectionLastUpdated', { lastUpdated: formattedLastUpdated })}
          </Text.Sub2>
        )}
        <ReloadButton
          isLoading={isLoading && !!entitlementsLastUpdated}
          onClick={reloadEntitlementsAction}
          variant="inline"
        />
      </GridFlex.Row>

      {onUpdateResource && selectedResource && (
        <SelectCustomerResource selectedResource={selectedResource} onUpdateResource={onUpdateResource} />
      )}

      {!isLoading && (!isSubscriptionActive || !selectedResource) ? (
        <EmptyCardContent>
          <Text.Sub2>{t('customers.entitlementUsageNoActiveSubscription')}</Text.Sub2>
        </EmptyCardContent>
      ) : !isLoading && isEmpty(meteredEntitlements) ? (
        <EmptyCardContent>
          <Text.Sub2>{t('customers.entitlementUsageSectionEmpty')}</Text.Sub2>
        </EmptyCardContent>
      ) : (
        <GridFlex.Column>
          <Table
            label={t('customers.subscriptionsTableLabel')}
            headCells={headCells()}
            isLoading={isEmpty(meteredEntitlements)}
            skeletonOptions={{
              rowsCount: 3,
            }}
            rowColor={(entitlement) => {
              if (entitlement && isEntitlementBudgetExceeded(entitlement)) {
                return theme.itamar.palette.error.background;
              }

              return null;
            }}
            data={meteredEntitlements || []}
            collapsableComponentOptions={{
              collapsableComponent: (entity: EntitlementDataFragment) => (
                <ExpandedEntitlementUsage
                  customerRefId={customerRefId}
                  entity={entity}
                  selectedResource={selectedResource}
                />
              ),
            }}
          />
        </GridFlex.Column>
      )}
    </PageCard>
  );
}
