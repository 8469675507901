import { FeatureType, MeterType } from '@stigg-types/apiTypes';
import { ExternalLink, GridFlex, Text, InformationTooltip, Box } from '@stigg-components';
import { t } from 'i18next';
import { Icon } from './icon';

type FeatureTypeFields = {
  featureType: FeatureType;
  meterType?: MeterType | null;
  hasMeter?: boolean | null;
};
type FeatureTypeIconOptions = { size?: number; entitlementsTooltip?: boolean };

function EntitlementsTooltip({ title, content }) {
  return (
    <GridFlex.Column rowGap={2} p={1}>
      <Text.B2 $bold>{title}</Text.B2>
      <Text.B2>
        {content} <ExternalLink label={t('sharedComponents.learnMore')} url={t('features.featureOverviewDocsUrl')} />
      </Text.B2>
    </GridFlex.Column>
  );
}

export const getFeatureTypeIcon = (
  feature: FeatureTypeFields,
  { size, entitlementsTooltip }: FeatureTypeIconOptions = { size: 20 },
) => {
  let icon: React.ReactElement;
  let tooltip: string | React.ReactNode;
  const { featureType, meterType, hasMeter } = feature;
  if (featureType === FeatureType.Boolean) {
    icon = <Icon icon="Check" color="active" size={size} />;
    tooltip = <Text.B2>{t('features.booleanIconTooltip')}</Text.B2>;
  } else if (!meterType || meterType === MeterType.None) {
    icon = <Icon type="custom" icon="AiSettingsSpark" color="active" size={size} />;
    tooltip = <Text.B2>{t('features.configurationIconTooltip')}</Text.B2>;
  } else if (hasMeter) {
    icon = <Icon type="custom" icon="MeteredEvents" color="active" size={size} />;
    tooltip = entitlementsTooltip ? (
      <EntitlementsTooltip
        title={t('features.meteredFeatureEventsTitle')}
        content={t('features.meteredFeatureEventsContent')}
      />
    ) : (
      <Text.B2>{t('features.eventsMeterIconTooltip')}</Text.B2>
    );
  } else {
    icon = <Icon type="custom" icon="MeteredHash" color="active" size={size} />;
    tooltip = entitlementsTooltip ? (
      <EntitlementsTooltip
        title={t('features.meteredFeatureUsageTitle')}
        content={t('features.meteredFeatureUsageContent')}
      />
    ) : (
      <Text.B2>{t('features.meterIconTooltip')}</Text.B2>
    );
  }

  return (
    <InformationTooltip arrow $padding={2} placement="top" title={tooltip}>
      <Box display="flex" alignItems="center">
        {icon}
      </Box>
    </InformationTooltip>
  );
};
