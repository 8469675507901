import { palette, border } from './itamar';
import { VariantType } from './variants.types';

export const THEMES = {
  DEFAULT: 'DEFAULT',
};

const defaultVariant: VariantType = {
  name: THEMES.DEFAULT,
  itamar: { palette, border },

  header: {
    color: palette.grey[500],
    background: palette.white,
    search: {
      color: palette.grey[800],
    },
    indicator: {
      background: palette.grey[600],
    },
  },
  footer: {
    color: palette.grey[500],
    background: palette.white,
  },
  sidebar: {
    color: palette.sidebarText,
    inactiveColor: palette.grey[200],
    background: palette.background.darkBackground2,
    activeBackground: palette.background.fadedBackground,
    header: {
      color: palette.sidebarText,
      background: palette.background.darkBackground2,
      brand: {
        color: palette.white,
      },
    },
  },
  chip: {
    default: palette.grey[300],
    primary: palette.primary.main,
    secondary: palette.secondary.main,
  },
};

export default [defaultVariant];
