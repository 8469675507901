import { DottedText, Grid, GridFlex, InformationTooltip, Text } from '@stigg-components';
import { CouponFragment, SubscriptionCouponDataFragment } from '@stigg-types/apiTypes';
import countryList from 'country-list';
import { t } from 'i18next';
import { CountryFlag } from '../../../../packages/pricing/components/currency/CountryWithFlagByCode';
import { PriceBreakdown, useTotalPrice } from './priceBreakdown';
import { SubscriptionPriceBreakdownTooltip } from './SubscriptionPriceBreakdown';

export function SubscriptionPrice({
  priceBreakdown,
  showMonthlyPriceVariation,
  showBillingPeriod,
  coupon,
  subscriptionCoupon,
}: {
  priceBreakdown: PriceBreakdown;
  showMonthlyPriceVariation: boolean;
  showBillingPeriod?: boolean;
  coupon?: CouponFragment | null;
  subscriptionCoupon?: SubscriptionCouponDataFragment | null;
}) {
  const { total } = useTotalPrice({ priceBreakdown, withCodePostfix: true });
  if (!priceBreakdown) {
    return <Text.B2>{t('subscriptions.customPricing')}</Text.B2>;
  }
  const { price } = priceBreakdown?.planBreakdown?.prices?.[0];
  const { billingCountryCode } = price || {};

  return (
    <GridFlex.RowCenter gap={4}>
      {billingCountryCode && (
        <InformationTooltip
          placement="top"
          title={
            <Text.B2>
              {`${t('pricing.currency.countryFlagTooltip')} ${countryList.getName(billingCountryCode)}`}
            </Text.B2>
          }>
          <Grid>
            <CountryFlag countryCode={billingCountryCode} />
          </Grid>
        </InformationTooltip>
      )}

      <SubscriptionPriceBreakdownTooltip
        coupon={coupon}
        subscriptionCoupon={subscriptionCoupon}
        priceBreakdown={priceBreakdown}
        showMonthlyPriceVariation={showMonthlyPriceVariation}>
        <GridFlex.Column container display="inline-flex" width="auto">
          <DottedText display="inline">{total}</DottedText>
          {showBillingPeriod && (
            <Text.B2 mt={1} color="secondary" display="inline">
              {t(`pricing.billedPeriod.${priceBreakdown.billingPeriod}`)}
            </Text.B2>
          )}
        </GridFlex.Column>
      </SubscriptionPriceBreakdownTooltip>
    </GridFlex.RowCenter>
  );
}
