import {
  CustomerSubscriptionFilter,
  SearchSubscriptionsQuery,
  SearchSubscriptionsQueryVariables,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { applyEnvironmentFilter } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';

const SEARCH_SUBSCRIPTIONS = gql`
  query SearchSubscriptions($filter: CustomerSubscriptionFilter!, $paging: CursorPaging) {
    customerSubscriptions(filter: $filter, paging: $paging) {
      edges {
        node {
          subscriptionId
          customer {
            customerId
            name
            email
          }
        }
      }
    }
  }
`;

export async function searchSubscription(environmentId: string, search: string, resultLimit?: number) {
  const filter: CustomerSubscriptionFilter = {
    environmentId: { eq: environmentId },
  };

  if (search) {
    filter.or = [{ refId: { iLike: `%${search}%` } }, { billingId: { iLike: `%${search}%` } }].map((searchFilter) =>
      applyEnvironmentFilter<CustomerSubscriptionFilter>(searchFilter, environmentId),
    );
  }

  const res = await apolloClient.query<SearchSubscriptionsQuery, SearchSubscriptionsQueryVariables>({
    query: SEARCH_SUBSCRIPTIONS,
    fetchPolicy: 'network-only',
    variables: {
      paging: { first: resultLimit },
      filter,
    },
  });

  return res.data.customerSubscriptions.edges;
}
