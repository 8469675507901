import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  SubscriptionDataFragment,
  FetchSubscriptionByRefIdQuery,
  FetchSubscriptionByRefIdQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { PRICE_FRAGMENT } from '../../packages/plans/queries/priceFragment';
import { COUPON_FRAGMENT, SUBSCRIPTION_COUPON_FRAGMENT } from '../../coupons/queries/fetchCoupons';
import { RESET_PERIOD_CONFIGURATION } from '../../entitlements/queries/resetPeriodConfigurationFragment';
import { SUBSCRIPTION_FUTURE_UPDATE_FRAGMENT } from './subscriptionFutureUpdateFragment';
import { CUSTOMER_RESOURCE_FRAGMENT } from './customerResourceFragment';
import { SUBSCRIPTION_INVOICE } from './subscriptionInvoice';
import { OVERAGE_PRICE_FRAGMENT } from '../../packages/plans/queries/overagePriceFragment';
import { MINIMUM_SPEND_FRAGMENT } from '../../packages/plans/queries/minimumSpendFragment';

const FETCH_SUBSCRIPTION = gql`
  query FetchSubscriptionByRefId($filter: CustomerSubscriptionFilter!) {
    customerSubscriptions(filter: $filter) {
      edges {
        node {
          ...SubscriptionData
        }
      }
    }
  }
  fragment SubscriptionData on CustomerSubscription {
    id
    refId
    startDate
    endDate
    trialEndDate
    cancellationDate
    effectiveEndDate
    currentBillingPeriodEnd
    billingCycleAnchor
    status
    cancelReason
    subscriptionId
    wasInTrial
    outdatedPricePackages
    additionalMetaData
    salesforceId
    budget {
      limit
      hasSoftLimit
    }
    budgetExceeded
    resource {
      ...CustomerResourceFragment
    }
    futureUpdates {
      ...SubscriptionFutureUpdateData
    }
    coupon {
      ...SubscriptionCouponData
    }
    freeItems {
      addonId
      quantity
    }
    minimumSpend {
      minimum {
        amount
        currency
      }
      isOverride
    }
    subscriptionEntitlements {
      id
      usageLimit
      featureId
      hasUnlimitedUsage
      hasSoftLimit
      resetPeriod
      resetPeriodConfiguration {
        __typename
        ... on YearlyResetPeriodConfig {
          ...YearlyResetPeriodConfigFragment
        }
        ... on MonthlyResetPeriodConfig {
          ...MonthlyResetPeriodConfigFragment
        }
        ... on WeeklyResetPeriodConfig {
          ...WeeklyResetPeriodConfigFragment
        }
      }
      feature {
        id
        displayName
        description
        updatedAt
        refId
        featureStatus
        environmentId
        featureType
        meterType
        featureUnits
        featureUnitsPlural
      }
    }
    plan {
      id
      refId
      displayName
      awsMarketplacePlanDimension
      entitlements {
        id
        usageLimit
        packageId
        featureId
        hasUnlimitedUsage
        hasSoftLimit
        isCustom
        resetPeriod
        resetPeriodConfiguration {
          __typename
          ... on YearlyResetPeriodConfig {
            ...YearlyResetPeriodConfigFragment
          }
          ... on MonthlyResetPeriodConfig {
            ...MonthlyResetPeriodConfigFragment
          }
          ... on WeeklyResetPeriodConfig {
            ...WeeklyResetPeriodConfigFragment
          }
        }
        feature {
          id
          displayName
          description
          updatedAt
          refId
          featureStatus
          environmentId
          featureType
          meterType
          featureUnits
          featureUnitsPlural
        }
      }
      product {
        refId
        displayName
        productSettings {
          subscriptionEndSetup
          subscriptionCancellationTime
          downgradePlan {
            refId
            displayName
          }
        }
      }
      prices {
        ...PriceFragment
      }
      overagePrices {
        ...OveragePriceFragment
      }
      compatibleAddons {
        refId
        displayName
      }
      versionNumber
      isLatest
      minimumSpend {
        ...MinimumSpendFragment
      }
    }
    addons {
      ...SubscriptionAddonData
    }
    customer {
      id
      customerId
      coupon {
        ...CouponFragment
      }
    }
    prices {
      usageLimit
      featureId
      billingModel
      price {
        ...PriceFragment
      }
    }
    billingId
    oldBillingId
    billingLinkUrl
    crmId
    crmLinkUrl
    syncStates {
      vendorIdentifier
      status
      error
    }
    pricingType
    experimentInfo {
      id
      status
    }
    paymentCollection
    latestInvoice {
      ...SubscriptionInvoiceFragment
    }
    paymentCollectionMethod
  }
  fragment SubscriptionAddonData on SubscriptionAddon {
    updatedAt
    quantity
    addon {
      id
      refId
      displayName
      description
      versionNumber
      entitlements {
        id
        feature {
          refId
          displayName
          featureUnits
          featureUnitsPlural
          featureType
          meterType
        }
        usageLimit
      }
      prices {
        ...PriceFragment
      }
      versionNumber
      isLatest
    }
    price {
      ...PriceFragment
    }
  }
  ${PRICE_FRAGMENT}
  ${OVERAGE_PRICE_FRAGMENT}
  ${COUPON_FRAGMENT}
  ${SUBSCRIPTION_COUPON_FRAGMENT}
  ${RESET_PERIOD_CONFIGURATION}
  ${SUBSCRIPTION_FUTURE_UPDATE_FRAGMENT}
  ${CUSTOMER_RESOURCE_FRAGMENT}
  ${SUBSCRIPTION_INVOICE}
  ${MINIMUM_SPEND_FRAGMENT}
`;

export type FetchSubscriptionByRefIdProps = {
  refId: string;
  isSilentLoading?: boolean;
};

async function fetchSubscriptionByRefId(
  { refId }: FetchSubscriptionByRefIdProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<SubscriptionDataFragment | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<FetchSubscriptionByRefIdQuery, FetchSubscriptionByRefIdQueryVariables>({
        query: FETCH_SUBSCRIPTION,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            refId: {
              eq: refId,
            },
            environmentId: { eq: accountReducer.currentEnvironmentId || '' },
          },
        },
      });

      const subscriptions = res.data.customerSubscriptions.edges;
      return subscriptions.length > 0 ? subscriptions[0].node : null;
    },
    {
      failureMessageHandler: () => t('customers.api.failSubscriptionFetch'),
    },
    dispatch,
  );
}

export { fetchSubscriptionByRefId };
