import {
  CustomerSubscriptionFilter,
  CustomerSubscriptionSort,
  CustomerSubscriptionSortFields,
  FetchCustomerSubscriptionsResourcesQuery,
  FetchCustomerSubscriptionsResourcesQueryVariables,
  ProductListItemFragment,
  SortDirection,
  SortNulls,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { Paging } from '../../../components/table/gqlTableHelper';

const FETCH_CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE = 25;

const FETCH_CUSTOMER_SUBSCRIPTIONS_RESOURCES = gql`
  query FetchCustomerSubscriptionsResources(
    $paging: CursorPaging
    $sorting: [CustomerSubscriptionSort!]
    $filter: CustomerSubscriptionFilter
  ) {
    customerSubscriptions(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CustomerSubscriptionResourceFragment
        }
      }
    }
  }
  fragment CustomerSubscriptionResourceFragment on CustomerSubscription {
    resource {
      resourceId
    }
    status
    plan {
      refId
      displayName
      product {
        refId
        displayName
      }
    }
  }
`;

export function composeSubscriptionsFilter(
  customerId: string,
  environmentId: string,
  products: ProductListItemFragment[],
  statues: SubscriptionStatus[],
  search?: string,
): CustomerSubscriptionFilter {
  const baseFilter: CustomerSubscriptionFilter = {
    customerId: { eq: customerId },
    environmentId: { eq: environmentId },
    status: isEmpty(statues) ? undefined : { in: statues },
  };

  if (!search || !search.trim()) {
    return baseFilter;
  }

  const searchFilter: CustomerSubscriptionFilter = {
    or: [
      {
        resource: {
          resourceId: {
            iLike: `%${search}%`,
          },
        },
      },
      {
        plan: {
          displayName: {
            iLike: `%${search}%`,
          },
        },
      },
    ],
  };

  // Since we can't search for product as it's a nested relation,
  // we search display names here, and then filter by IDs.
  // this is possible, since we load all products in advance.
  const productIds = products
    .filter((x) => (x.displayName || '').toLowerCase().includes(search.toLowerCase()))
    .map((x) => x.id);

  if (productIds.length > 0) {
    searchFilter.or!.push({
      plan: {
        productId: {
          in: productIds,
        },
      },
    });
  }

  return {
    and: [baseFilter, searchFilter],
  };
}

export type FetchCustomerSubscriptionsProps = {
  customerId: string;
  search?: string;
};

async function fetchCustomerSubscriptionsResources(
  { customerId, search = '' }: FetchCustomerSubscriptionsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer,
        productReducer: { products },
      } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const paging: Paging = { first: FETCH_CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE };
      const filter = composeSubscriptionsFilter(
        customerId,
        accountReducer.currentEnvironmentId,
        products,
        [SubscriptionStatus.Active, SubscriptionStatus.InTrial],
        search,
      );
      const sorting: CustomerSubscriptionSort[] = [
        {
          field: CustomerSubscriptionSortFields.ResourceId,
          direction: SortDirection.Desc,
          nulls: SortNulls.NullsFirst,
        },
        {
          field: CustomerSubscriptionSortFields.CreatedAt,
          direction: SortDirection.Desc,
        },
      ];
      const res = await apolloClient.query<
        FetchCustomerSubscriptionsResourcesQuery,
        FetchCustomerSubscriptionsResourcesQueryVariables
      >({
        query: FETCH_CUSTOMER_SUBSCRIPTIONS_RESOURCES,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          filter,
          sorting,
        },
      });

      return res.data.customerSubscriptions;
    },
    {
      failureMessageHandler: () => t('customers.api.failFetchOneSubscriptions'),
    },
    dispatch,
  );
}

export { fetchCustomerSubscriptionsResources };
