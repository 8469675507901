import { t } from 'i18next';
import { Text, Grid, LongText, InformationTooltip, Icon, GridFlex } from '@stigg-components';
import { ProductPackageEntitlementFragment, ProductPlanFragment, WidgetType } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { getFeatureTypeIcon } from '../../../../components/getFeatureTypeIcon';
import * as S from './PlanOffering.style';
import { HiddenIcon } from '../../../packages/common/components/hiddenFromWidgets/HiddenIcon';
import { formatEntitlementDisplayText } from '../../../entitlements/formatEntitlementDisplayText';
import { getConnectedEntityByFeatureId } from '../../../packages/common/overageCharges.utils';

export function FeatureCard({
  entitlement,
  plan,
}: {
  entitlement: ProductPackageEntitlementFragment;
  plan: ProductPlanFragment;
}) {
  const { overagePricing: isOveragePricingEnabled } = useFlags<FeatureFlags>();
  const featureIcon = getFeatureTypeIcon(entitlement.feature);
  const isHiddenFromPaywall = (entitlement.hiddenFromWidgets || []).includes(WidgetType.Paywall);
  const displayName = formatEntitlementDisplayText({
    feature: entitlement.feature,
    hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
    isCustom: entitlement.isCustom,
    resetPeriod: entitlement.resetPeriod,
    usageLimit: entitlement.usageLimit,
  });

  const hasOverage = !!getConnectedEntityByFeatureId(entitlement.featureId, plan.overagePrices);
  const shouldShowOverageIndication = !entitlement.displayNameOverride && isOveragePricingEnabled && hasOverage;

  return (
    <S.WrapperGridFlexRowSpaceBetween $isHidden={isHiddenFromPaywall} container wrap="nowrap">
      <Grid item>{featureIcon}</Grid>

      <GridFlex.Column item container ml={4}>
        <LongText tooltipPlacement="top" variant="body2" maxLines={1} wordBreak>
          {entitlement.displayNameOverride || displayName}
        </LongText>
        {shouldShowOverageIndication && <Text.Sub2>{t('entitlements.hasOverageCharge')}</Text.Sub2>}
      </GridFlex.Column>

      {entitlement.displayNameOverride && (
        <InformationTooltip
          arrow
          placement="top"
          $padding={2}
          title={
            <>
              <Text.B2>{t('widgets.displayNameOverride.tooltipText.title')}</Text.B2>
              <Text.B2 color="secondary">
                {t('widgets.displayNameOverride.tooltipText.body', { overrideText: displayName })}
              </Text.B2>
            </>
          }>
          <Grid item mr={isHiddenFromPaywall ? 2 : 0}>
            <Icon icon="ChatBubbleTextSquare" type="custom" color="default" size={20} />
          </Grid>
        </InformationTooltip>
      )}

      {isHiddenFromPaywall && (
        <Grid item>
          <HiddenIcon isHidden size={20} />
        </Grid>
      )}
    </S.WrapperGridFlexRowSpaceBetween>
  );
}
