import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import pluralize from 'pluralize';
import { Text, GridFlex, Form } from '@stigg-components';
import { t } from 'i18next';
import { FormikHelpers, FormikTouched } from 'formik';
import { FeatureFragment, FeatureType, MeterType } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { updateFeatureAction } from '../../featuresSlice';
import { FeatureTypeSelectionBox, ReportUsageType } from '../createFeature/FeatureTypeSelectionBox';
import { isMetered } from '../../utils/isMetered';
import { Aggregation, Filter, onSubmitFiltersAndAggregation } from '../createFeature/SidenavCreateFeature';
import { Dialog } from '../../../../components/Dialog';
import { FiltersAndAggregation } from '../createFeature/FiltersAndAggregation';
import { validationSchema } from '../createFeature/featureFormValidation';
import { UnitTransformationSwitch } from '../UnitTransformationSwitch';
import { FeatureUnitLabels } from '../FeatureUnitLabels';
import { FeatureUnitTransformation } from '../FeatureUnitTransformation';

export type UpdateFeatureFields = {
  displayName: string;
  refId: string;
  featureType: FeatureType;
  featureUnits?: string | null;
  featureUnitsPlural?: string | null;
  transformedUnits?: string | null;
  transformedUnitsPlural?: string | null;
  meterType?: MeterType | null;
  featureRefId: string;
  featureDescription?: string | null;
  reportUsageType: ReportUsageType | undefined;
  filters: Filter[];
  aggregation: Aggregation | null;
  divideBy?: number | null;
  isUnitTransformationOpen?: boolean;
};

export function FeatureDetailsTab({ feature, onClose }: { feature: FeatureFragment; onClose: () => void }) {
  const dispatch = useAppDispatch();
  const { usageTransformation: usageTransformationEnabled } = useFlags<FeatureFlags>();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [defaultFiltersStep, setDefaultFiltersStep] = useState<number | undefined>(0);
  const initialValues: UpdateFeatureFields = {
    displayName: feature.displayName,
    refId: feature.refId,
    featureType: feature.featureType,
    featureUnits: feature.unitTransformation ? feature.unitTransformation.featureUnits : feature.featureUnits,
    featureUnitsPlural: feature.unitTransformation
      ? feature.unitTransformation.featureUnitsPlural
      : feature.featureUnitsPlural,
    meterType: feature.meterType,
    featureRefId: feature.refId,
    featureDescription: feature.description,
    reportUsageType: isMetered(feature)
      ? feature.meter
        ? ReportUsageType.REPORT_EVENTS
        : ReportUsageType.REPORT_USAGE
      : undefined,
    divideBy: feature.unitTransformation?.divide,
    transformedUnits: feature.unitTransformation ? feature.featureUnits : undefined,
    transformedUnitsPlural: feature.unitTransformation ? feature.featureUnitsPlural : undefined,
    isUnitTransformationOpen: !!feature.unitTransformation,
    filters:
      feature.meter?.filters?.map((filter) => ({
        conditions: filter.conditions.map((condition) => ({
          field: condition.field,
          operation: condition.operation,
          value: condition.value,
        })),
      })) || [],
    aggregation: feature.meter?.aggregation
      ? {
          function: feature.meter.aggregation.function,
          field: feature.meter.aggregation.field,
        }
      : null,
  };

  const onOpenFilters = (defaultStep?: number) => {
    setDefaultFiltersStep(defaultStep);
    setIsFiltersOpen(true);
  };

  const handleSubmit = async (values: UpdateFeatureFields) => {
    const { filters, aggregation } = onSubmitFiltersAndAggregation(values);
    await dispatch(
      updateFeatureAction({
        featureRefId: feature.refId,
        updatePayload: {
          displayName: values.displayName,
          featureUnits: values.isUnitTransformationOpen ? values.transformedUnits : values.featureUnits,
          featureUnitsPlural: values.isUnitTransformationOpen
            ? values.transformedUnitsPlural
            : values.featureUnitsPlural,
          description: values.featureDescription,
          meter: filters && aggregation ? { filters, aggregation } : undefined,
          unitTransformation:
            values.isUnitTransformationOpen && values.divideBy
              ? {
                  divide: values.divideBy,
                  featureUnits: values.featureUnits,
                  featureUnitsPlural: values.featureUnitsPlural,
                }
              : null,
        },
      }),
    );
  };

  const onCloseFiltersDialog = (setFieldValue: FormikHelpers<UpdateFeatureFields>['setFieldValue']) => {
    setIsFiltersOpen(false);
    // Reset filters and aggregation values when closing the dialog
    setFieldValue('filters', initialValues.filters);
    setFieldValue('aggregation', initialValues.aggregation);
  };

  const handleSingularUnitsChange = (
    e: any,
    setFieldValue: FormikHelpers<UpdateFeatureFields>['setFieldValue'],
    touched: FormikTouched<UpdateFeatureFields>,
  ) => {
    setFieldValue('featureUnits', e.target.value);
    if (!touched.featureUnitsPlural && !isEmpty(e.target.value)) {
      setFieldValue('featureUnitsPlural', pluralize.plural(e.target.value));
    }
  };

  const handleTransformedSingularUnitsChange = (
    e: any,
    setFieldValue: FormikHelpers<UpdateFeatureFields>['setFieldValue'],
    touched: FormikTouched<UpdateFeatureFields>,
  ) => {
    setFieldValue('transformedUnits', e.target.value);
    if (!touched.featureUnitsPlural && !isEmpty(e.target.value)) {
      setFieldValue('transformedUnitsPlural', pluralize.plural(e.target.value));
    }
  };

  return (
    <GridFlex.Column pt={3}>
      <Form
        key={feature.id}
        disableSaveOnNoChanges
        rowSpacing={4}
        enableReinitialize
        submitButtonText={t('sharedComponents.editSaveButton')}
        initialValues={initialValues}
        onCancel={onClose}
        validationSchema={validationSchema(isFiltersOpen)}
        onSubmit={handleSubmit}
        fields={({ values, setFieldValue }) => [
          {
            id: 'displayName',
            type: 'text',
            label: t('features.name'),

            placeholder: t('features.name'),
            helpTooltipText: t('features.featureNameHelpTooltip'),
          },
          {
            id: 'featureDescription',
            type: 'text',
            optional: true,
            label: 'Description',

            placeholder: 'Description',
            textFieldProps: { multiline: true, maxRows: 2 },
          },
          {
            type: 'custom',
            disabled: true,
            render: ({ values }) => {
              let description = '';
              switch (values.featureType) {
                case FeatureType.Boolean:
                  description = t('features.booleanDescription');
                  break;
                case FeatureType.Number:
                  description = isMetered({ featureType: values.featureType, meterType: values.meterType })
                    ? t('features.meteredDescription')
                    : t('features.configurationDescription');
                  break;
                default:
                  description = t('features.meteredDescription');
              }

              return (
                <GridFlex.Column $fullWidth>
                  <Text.B2 mb={2} color="secondary">
                    {t('features.FeatureTypeSelect')}
                  </Text.B2>
                  <FeatureTypeSelectionBox
                    key={values.featureRefId}
                    featureType={values.featureType}
                    meterType={values.meterType || undefined}
                    description={description}
                    reportUsageType={values.reportUsageType}
                    filters={values.filters}
                    aggregation={values.aggregation}
                    readonly={!!feature.hasEntitlements}
                    openEditFilters={(defaultStep?: number) => onOpenFilters(defaultStep)}
                    isSelected
                    isViewMode
                  />
                </GridFlex.Column>
              );
            },
          },
          {
            type: 'custom',
            hide: (values) => values.featureType === FeatureType.Boolean,
            render: ({ renderTextField, setFieldValue, touched }) => (
              <GridFlex.Column $fullWidth>
                <Text.B1 color="primary" $medium mb={2}>
                  {t('features.unitLabel')}
                </Text.B1>
                <GridFlex.RowCenter alignItems="flex-start" $fullWidth justifyContent="space-between" columnGap={4}>
                  {renderTextField('featureUnits', t('features.unitsLong'), {
                    placeholder: t('features.unitsPlaceholder'),
                    onChange: (e) => handleSingularUnitsChange(e, setFieldValue, touched),
                  })}
                  {renderTextField('featureUnitsPlural', t('features.unitsPluralLong'), {
                    placeholder: t('features.unitsPluralPlaceholder'),
                  })}
                </GridFlex.RowCenter>
              </GridFlex.Column>
            ),
          },
          {
            type: 'collapse',
            open: !!values.isUnitTransformationOpen,
            hideIcon: true,
            titleTextProps: {
              sx: { width: '100%' },
            },
            hide: () => !usageTransformationEnabled || !isMetered(feature),
            title: (
              <UnitTransformationSwitch
                checked={values.isUnitTransformationOpen}
                onChange={(isChecked) => setFieldValue('isUnitTransformationOpen', isChecked)}
              />
            ),
            fields: [
              {
                type: 'custom',
                render: ({ renderTextField, setFieldValue, touched }) => (
                  <FeatureUnitLabels
                    renderTextField={renderTextField}
                    handleSingularChange={(e) => handleTransformedSingularUnitsChange(e, setFieldValue, touched)}
                  />
                ),
              },
              {
                type: 'custom',
                render: ({ renderTextField, values }) => (
                  <FeatureUnitTransformation
                    renderTextField={renderTextField}
                    divideBy={values.divideBy}
                    featureUnits={values.featureUnits}
                    featureUnitsPlural={values.featureUnitsPlural}
                    transformedUnits={values.transformedUnits}
                  />
                ),
              },
            ],
          },
          {
            type: 'custom',
            render: (formRenderProps) => (
              <Dialog
                disableCloseOnEscapeKey
                fullScreen
                padding={0}
                content={
                  <FiltersAndAggregation
                    onSubmit={async () => {
                      await handleSubmit(formRenderProps.values);
                      formRenderProps.resetForm();
                      setIsFiltersOpen(false);
                    }}
                    onCancel={() => onCloseFiltersDialog(formRenderProps.setFieldValue)}
                    formRenderProps={formRenderProps}
                    readonly={!!feature.hasEntitlements}
                    isUpdate
                    defaultStep={defaultFiltersStep}
                  />
                }
                isOpen={isFiltersOpen}
                onCancel={() => onCloseFiltersDialog(formRenderProps.setFieldValue)}
                aria-labelledby="filters-and-aggregations"
              />
            ),
          },
        ]}
      />
    </GridFlex.Column>
  );
}
