// eslint-disable-next-line no-restricted-imports
import {
  Autocomplete as MuiAutoComplete,
  AutocompleteInputChangeReason,
  TextField as MuiTextField,
} from '@mui/material';
import React, { ReactElement, ReactNode, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getIconColor, ItamarTheme } from '@stigg-theme';
import debounce from 'lodash/debounce';
import { t } from 'i18next';
import { Box, CircularProgress, EndAdornment, Icon, StartAdornment, Text } from '../index';
import { Select, SelectProps } from '../Select';
import {
  ClearIconButton,
  MINIMAL_REQUIRED_HEIGHT,
  VerticalBorderBox,
  StyledList,
  calculateListBoxMaxHeight,
} from './BoundedSearchableSelect.style';
import { useElementPosition } from './useElementPosition';
import { numberFullFormatter } from '../../modules/dashboards/common/formatters';

export type BoundedSearchableSelectProps<T> = {
  onSearch: (search: string) => void;
  isSearching: boolean;
  totalItems: number;
  placeholder?: string;
  value: T | undefined;
  items: T[];
  itemSerializer: (item: T) => string;
  onChange: (item: T) => void;
  renderItem: (item: T, search?: string) => ReactNode;
  renderSelectedItem?: (item: T) => ReactNode;
  isOptionDisabled?: (item: T) => boolean;
  height?: number;
  maxWidth?: number;
  disablePaddingMenu?: boolean;
  dataTestId?: string;
};

export function BoundedSearchableSelect<T>({
  onSearch,
  isSearching,
  totalItems,
  placeholder,
  value,
  items,
  itemSerializer,
  onChange,
  isOptionDisabled,
  renderItem,
  renderSelectedItem,
  height,
  maxWidth,
  disablePaddingMenu,
  dataTestId,
}: BoundedSearchableSelectProps<T>) {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const { isPlacementTop, elementPositionRef, availableSpace } = useElementPosition(MINIMAL_REQUIRED_HEIGHT);
  const maxListBoxHeight = calculateListBoxMaxHeight(availableSpace);

  const selectPlacementProps: Pick<SelectProps, 'anchorOrigin' | 'transformOrigin'> = {};

  if (isPlacementTop) {
    selectPlacementProps.anchorOrigin = { vertical: -32, horizontal: 'left' };
    selectPlacementProps.transformOrigin = { vertical: 'bottom', horizontal: 'left' };
  }

  const clearSearch = () => {
    setSearchTerm('');
    onSearch('');
  };

  const openSelect = () => setOpen(true);
  const closeSelect = () => {
    setOpen(false);
    clearSearch();
  };

  const onSelectElement = (_, item: T | null) => {
    if (item) {
      closeSelect();
      onChange(item);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((search: string) => onSearch(search), 350),
    [onSearch],
  );

  const onSearchInputChange = (newSearch: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset') {
      setSearchTerm(newSearch);
      delayedSearch(newSearch);
    }
  };

  const onSearchKeyUp = (e) => {
    if (e.key === 'Escape') {
      if (!searchTerm) {
        closeSelect();
      } else {
        clearSearch();
      }
    }
  };
  const renderValue = renderSelectedItem ?? renderItem;

  let endAdornment: ReactElement | undefined;
  if (isSearching) {
    endAdornment = <CircularProgress size={20} sx={{ color: getIconColor('active') }} />;
  } else if (!isEmpty(searchTerm)) {
    endAdornment = (
      <ClearIconButton onClick={clearSearch}>
        <Icon icon="Close" size={20} />
      </ClearIconButton>
    );
  }

  return (
    <Select
      disablePaddingMenu={disablePaddingMenu}
      ref={elementPositionRef}
      maxWidth={maxWidth}
      height={height}
      renderValue={() => value && renderValue(value)}
      value={value && itemSerializer(value)}
      open={open}
      placeholder={placeholder}
      onClose={closeSelect}
      onOpen={openSelect}
      menuAutoFocus={false}
      dataTestId={dataTestId}
      {...selectPlacementProps}>
      <Box display="flex" flexDirection={isPlacementTop ? 'column-reverse' : 'column'}>
        <MuiAutoComplete
          options={items}
          noOptionsText={<Text.B2 color="secondary">{t('sharedComponents.noResultsFound')}</Text.B2>}
          filterOptions={(x) => x}
          value={value}
          open
          autoHighlight
          getOptionDisabled={isOptionDisabled}
          forcePopupIcon={false}
          getOptionLabel={itemSerializer}
          inputValue={searchTerm}
          onKeyUp={onSearchKeyUp}
          onInputChange={(e, string, reason) => onSearchInputChange(string, reason)}
          onChange={onSelectElement}
          renderOption={(props: any, item) => (
            <Box {...props} data-value={itemSerializer(item)}>
              {renderItem(item, searchTerm)}
            </Box>
          )}
          renderInput={(params) => (
            <VerticalBorderBox ref={params.InputProps.ref} $isBorderTop={isPlacementTop}>
              <MuiTextField
                variant="standard"
                autoFocus
                placeholder={t('sharedComponents.search')}
                sx={{ paddingX: 3, paddingY: 2 }}
                {...params}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <StartAdornment position="start">
                      <Icon icon="Search" type="materialIcons" color="secondary" size={24} />
                    </StartAdornment>
                  ),
                  endAdornment: endAdornment ? <EndAdornment position="end">{endAdornment}</EndAdornment> : undefined,
                  sx: {
                    color: (theme: ItamarTheme) => theme.itamar.palette.text.primary,
                    fontSize: '14px',
                  },
                }}
              />
            </VerticalBorderBox>
          )}
          ListboxComponent={StyledList}
          PopperComponent={Box}
          PaperComponent={({ children, ...props }) => (
            <Box
              {...props}
              display="flex"
              flexDirection={isPlacementTop ? 'column-reverse' : 'column'}
              maxHeight={maxListBoxHeight}>
              {children}
              {totalItems > items.length && (
                <VerticalBorderBox px={4} py={2} $isBorderTop={!isPlacementTop}>
                  <Text.Sub2>
                    {t('customers.additionalResults', {
                      additionalResultsCount: numberFullFormatter(totalItems - items.length),
                    })}
                  </Text.Sub2>
                </VerticalBorderBox>
              )}
            </Box>
          )}
        />
      </Box>
    </Select>
  );
}
