import {
  Grid,
  GridFlex,
  Text,
  OptionsDropdown,
  PackageChip,
  LocalDate,
  InformationalTooltipIcon,
  Icon,
  EntityValueCell,
} from '@stigg-components';
import { AwsChip, formatTrialPeriod } from '@stigg-common';
import { t } from 'i18next';
import { DefaultTrialConfig, PackageStatus, Plan, PlanFragment, PricingType, WidgetType } from '@stigg-types/apiTypes';
import { AlertTriangle, Edit2, Trash2 } from 'react-feather';
import * as React from 'react';
import { HeadCell } from '../../../../components/table/Table';
import { packageVersionFormat } from './packageVersionFormat';
import { PackageListFragment } from './Packages';
import { CantDeletePlanTooltipContent, isDefaultStartPlan, isDowngradePlan } from './PlanTooltipsCollection';
import { HiddenIcon } from './hiddenFromWidgets/HiddenIcon';
import { isPackageSingleInstanceAddon } from '../packageUtils';
import { getBillingPeriodText } from './getBillingPeriodText';

export const headCells = (
  onDeleteClick: (aPackage: PackageListFragment) => void,
  onEditClick: (aPackage: PackageListFragment) => void,
  packageType: 'Plan' | 'Add-on',
  additionalCells?: Array<HeadCell<PackageListFragment, any>>,
  showPaywallVisibilityControl?: boolean,
): Array<HeadCell<PackageListFragment, any>> => {
  const isPlanType = packageType === 'Plan';

  return [
    {
      id: 'name',
      alignment: 'left',
      label: isPlanType ? t('packages.plan') : t('packages.addon'),
      render: (entity) => {
        const dataTestId = `${isPlanType ? 'plan' : 'addon'}-name-${entity.displayName}`;
        const icon = isPlanType ? null : isPackageSingleInstanceAddon(entity) ? (
          <Icon icon="SingleAddonType" type="custom" size={25} />
        ) : (
          <Icon icon="MultiAddonType" type="custom" size={25} />
        );

        return (
          <EntityValueCell
            icon={icon}
            entity={entity}
            suffix={entity.product?.awsMarketplaceProductId ? <AwsChip logoOnly /> : null}
            dataTestId={dataTestId}
          />
        );
      },
    },
    {
      id: 'product',
      alignment: 'left',
      label: t('packages.product'),
      render: (entity) => (
        <Grid gap={2}>
          <Text.B2>{entity.product?.displayName}</Text.B2>
        </Grid>
      ),
    },
    {
      width: 225,
      id: 'price',
      alignment: 'left',
      label: t('packages.priceColumnTitle', { packageType }),
      render: (entity) => {
        let defaultTrialPeriod: DefaultTrialConfig | null = null;
        if (entity.type === 'Plan' && (entity as Plan)?.defaultTrialConfig?.duration) {
          defaultTrialPeriod = (entity as Plan).defaultTrialConfig!;
        }

        let priceString = '-';
        if (entity.pricingType === PricingType.Free) {
          priceString = 'Free';
        } else if (entity.pricingType === PricingType.Custom) {
          priceString = 'Custom';
        } else if (entity.pricingType === PricingType.Paid) {
          priceString = 'Paid';
        }

        const billingPeriodText = getBillingPeriodText(entity.prices || []);

        return (
          <GridFlex.Column>
            <Text.B2 display="inline-flex" gap={1}>
              {priceString}
              {!!defaultTrialPeriod && (
                <InformationalTooltipIcon
                  icon={
                    <Icon type="custom" icon="HourglassEmpty" size={18} color="other.outlineBorder" overrideStroke />
                  }
                  text={`${formatTrialPeriod(defaultTrialPeriod.duration, defaultTrialPeriod.units)} trial period`}
                />
              )}
            </Text.B2>
            {!!billingPeriodText && <Text.B2 color="secondary">{billingPeriodText}</Text.B2>}
          </GridFlex.Column>
        );
      },
    },
    {
      id: 'packageStatus',
      alignment: 'center',
      label: t('packages.status'),
      render: (entity) => <PackageChip hasPointer status={entity.status} />,
    },
    ...(additionalCells || []),
    {
      width: 100,
      id: 'version',
      alignment: 'left',
      label: t('packages.versionNumber'),
      render: (entity) => <Text.B2>{packageVersionFormat(entity.versionNumber)}</Text.B2>,
    },
    {
      id: 'lastUpdated',
      alignment: 'left',
      label: t('packages.lastUpdated'),
      render: (entity) => <LocalDate date={entity.updatedAt} formatOptions={{ withTime: true }} />,
    },
    {
      id: 'options',
      alignment: 'right',
      label: '',
      disableClick: true,
      width: 128,
      render: (entity) => {
        let deleteDisabledTooltip: React.ReactNode;

        if (isDowngradePlan(entity)) {
          deleteDisabledTooltip = (
            <CantDeletePlanTooltipContent productRefId={(entity as PlanFragment).product.refId} isDowngradePlan />
          );
        } else if (isDefaultStartPlan(entity)) {
          deleteDisabledTooltip = (
            <CantDeletePlanTooltipContent
              productRefId={(entity as PlanFragment).product.refId}
              isDowngradePlan={false}
            />
          );
        }

        const isPaywallHidden = (entity?.hiddenFromWidgets || []).some((value) => value === WidgetType.Paywall);

        return (
          <GridFlex.RowCenter justifyContent="flex-end">
            {entity.draftSummary && entity.status === PackageStatus.Published ? (
              <InformationalTooltipIcon
                icon={<AlertTriangle style={{ marginRight: 8 }} color="#ED6C02" />}
                text={t('packages.pendingChanges', { packageType: entity.type?.toLowerCase() }) || ''}
              />
            ) : null}
            {showPaywallVisibilityControl && <HiddenIcon isHidden={isPaywallHidden} />}
            <OptionsDropdown
              options={[
                {
                  icon: Edit2,
                  text: entity.draftSummary ? t('sharedComponents.keepEditing') : t('sharedComponents.edit'),
                  onClick: () => onEditClick(entity),
                },
                {
                  icon: Trash2,
                  text: t('sharedComponents.archive'),
                  onClick: () => onDeleteClick(entity),
                  disabled: Boolean(deleteDisabledTooltip),
                  tooltip: {
                    placement: 'left',
                    title: deleteDisabledTooltip || '',
                  },
                },
              ]}
            />
          </GridFlex.RowCenter>
        );
      },
    },
  ];
};
