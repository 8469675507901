import { Box, InformationTooltip, Text } from '@stigg-components';
import React from 'react';
import { TextColor } from '../theme';
import { InformationTooltipProps } from './InformationTooltip';

export type InformationalTooltipIconProps = {
  icon: React.ReactElement;
  text: string | React.ReactNode;
  placement?: InformationTooltipProps['placement'];
  $maxWidth?: number;
  $padding?: number;
  textColor?: TextColor;
};

export function InformationalTooltipIcon({
  icon,
  text,
  textColor,
  placement = 'top',
  $maxWidth,
  $padding = 2,
}: InformationalTooltipIconProps) {
  return (
    <InformationTooltip
      arrow
      placement={placement}
      title={<Text.B2 color={textColor}>{text}</Text.B2>}
      $padding={$padding}
      $maxWidth={$maxWidth}>
      <Box display="flex" alignItems="center">
        {icon}
      </Box>
    </InformationTooltip>
  );
}
