import { useState, useMemo } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Link,
  Text,
  ConfirmationDialog,
  EmptyCardContent,
  PageCard,
  Divider,
  TableReorderingProps,
  FormRenderProps,
} from '@stigg-components';
import {
  AddonFragment,
  PlanFragment,
  BillingModel,
  PackageEntitlement,
  UpdatePackageEntitlementOrderInput,
  PricingType,
} from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import Table from '../../../../../components/table/Table';
import {
  createPackageEntitlementsAction,
  deleteEntitlementByPackageIdAction,
  updatePackageEntitlementAction,
} from '../../../../entitlements/entitlementsSlice';
import { AddEntitlementsDrawer } from '../../../plans/components/addEntitlement/AddEntitlementsDrawer';
import {
  EntitlementFormFields,
  getConfirmedEntitlements,
} from '../../../../entitlements/components/entitlementSettings/types';
import { EditEntitlementDrawer } from '../../../plans/components/EditEntitlementDrawer';
import { BasePlanProps, PlanEntitlement } from './PackageGrantedEntitlements.types';
import { Header, BasePlanGrantedEntitlements } from './components';
import { getResetPeriodData } from './PackageGrantedEntitlements.utils';
import { createHeadCells } from './createHeadCells';
import {
  BasePlanSwitch,
  BasePlanSwitchProps,
} from './components/basePlanGrantedEntitlements/BasePlanGrantedEntitlements';
import { OverrideEntitlementDrawer } from '../../../plans/components/OverrideEntitlementDrawer';
import {
  EditEntitlementDisplayOptionsDialog,
  EditEntitlementDisplayOptionsFormFields,
} from './EditEntitlementDisplayOptionsDialog';
import { toPriceGroups } from '../../../pricing/utils/priceGroups';
import { normalizedPackageName } from '../../../../../i18n/utils';
import { SectionIdentifier } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';

const reorder = (array: any[], startIndex: number, endIndex: number) => {
  const [removed] = array.splice(startIndex, 1);
  array.splice(endIndex, 0, removed);

  return array;
};

export function PackageGrantedEntitlements({
  aPackage,
  basePlan,
  inheritedEntitlements,
  onEntitlementsReorder,
  onEntitlementsChanged,
  readonly,
  withReordering = false,
  openSetPriceWizard,
}: {
  aPackage: PlanFragment | AddonFragment;
  basePlan?: BasePlanProps | null;
  inheritedEntitlements?: PackageEntitlement[];
  onEntitlementsReorder?: (entitlements: UpdatePackageEntitlementOrderInput['entitlements']) => Promise<void>;
  onEntitlementsChanged: () => Promise<void>;
  readonly: boolean;
  withReordering?: boolean;
  openSetPriceWizard: (
    section?: SectionIdentifier,
    modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
  ) => void;
}) {
  const packageType = normalizedPackageName(aPackage.type);
  const dispatch = useAppDispatch();
  const { overagePricing: isOveragePricingEnabled } = useFlags<FeatureFlags>();

  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [entitlementToDelete, setEntitlementToDelete] = useState<PlanEntitlement | null>(null);
  const [entitlementToEdit, setEntitlementToEdit] = useState<PlanEntitlement | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [forceEntitlementsCalculation, setForceEntitlementsCalculation] = useState(1);

  const [editDisplayOptionsDialogOpen, setEditDisplayOptionsDialogOpen] = useState(false);

  const [entitlementToOverride, setEntitlementToOverride] = useState<PlanEntitlement | null>(null);
  const [overrideDialogOpen, setOverrideDialogOpen] = useState(false);

  const { entitlements, priceEntitlementsIds } = useMemo(() => {
    const priceEntitlementsIds = new Set<string>();
    const entitlements: (PlanEntitlement & { isDraggable?: boolean })[] = aPackage.entitlements
      ? [...aPackage.entitlements]
      : [];

    if (!isEmpty(aPackage.prices)) {
      const prices = aPackage.prices?.filter(
        (price) =>
          price.billingModel === BillingModel.PerUnit ||
          (price.billingModel === BillingModel.UsageBased && !price.billingCountryCode),
      );

      if (prices?.length) {
        const priceGroups = toPriceGroups(prices);
        for (const priceGroup of priceGroups) {
          const price = priceGroup?.[0];
          if (price) {
            const { feature, featureId, billingModel } = price;
            if (feature && featureId) {
              priceEntitlementsIds.add(price.id);
              entitlements.unshift({
                id: price.id,
                featureId,
                feature,
                hasUnlimitedUsage: billingModel === BillingModel.UsageBased,
                price,
                isDraggable: false,
              });
            }
          }
        }
      }
    }

    return { entitlements, priceEntitlementsIds };
    // Only calculate entitlements once per mount or by force
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceEntitlementsCalculation, aPackage.prices]);

  const handleDeleteDialogClose = (entitlementId: string | undefined) => async (shouldDelete: boolean) => {
    if (shouldDelete && entitlementId) {
      await dispatch(deleteEntitlementByPackageIdAction({ entitlementId }));
      await onEntitlementsChanged();

      setForceEntitlementsCalculation((value) => value + 1);
    }

    setDeleteDialogOpen(false);
  };

  const handleEditSubmit = async (values: EntitlementFormFields) => {
    const { entitlements } = values;
    const entitlementToUpdate = entitlements[0];
    if (!entitlementToUpdate.id) {
      return;
    }
    await dispatch(
      updatePackageEntitlementAction({
        packageEntitlementId: entitlementToUpdate.id,
        updatePayload: {
          usageLimit:
            entitlementToUpdate.isCustom || entitlementToUpdate.hasUnlimitedUsage
              ? null
              : entitlementToUpdate.usageLimit,
          hasUnlimitedUsage: entitlementToUpdate.hasUnlimitedUsage,
          hasSoftLimit: entitlementToUpdate.hasSoftLimit,
          behavior: entitlementToUpdate.behavior,
          isCustom: entitlementToUpdate.isCustom,
          ...getResetPeriodData(entitlementToUpdate.resetPeriod, entitlementToUpdate.resetPeriodConfiguration),
        },
      }),
    );

    await onEntitlementsChanged();

    setForceEntitlementsCalculation((value) => value + 1);
    setEditDialogOpen(false);
  };

  const handleEditDisplayOptionsSubmit = async (entitlementToUpdate: EditEntitlementDisplayOptionsFormFields) => {
    const { hiddenFromWidgets, displayNameOverride } = entitlementToUpdate;
    await dispatch(
      updatePackageEntitlementAction({
        packageEntitlementId: entitlementToUpdate.id,
        updatePayload: {
          hiddenFromWidgets,
          displayNameOverride,
        },
      }),
    );

    await onEntitlementsChanged();

    setForceEntitlementsCalculation((value) => value + 1);
    setEditDisplayOptionsDialogOpen(false);
  };

  const onSubmitEntitlements = async (values: EntitlementFormFields) => {
    const nextOrder = Math.max(0, ...entitlements.map((x) => x.order || 0)) + 1;
    await dispatch(
      createPackageEntitlementsAction({
        packageEntitlements: getConfirmedEntitlements(values.entitlements).map((ent, i) => ({
          packageId: aPackage.id,
          featureId: ent.feature.id,
          description: ent.description?.slice(0, 256),
          environmentId: currentEnvironmentId,
          usageLimit: ent.isCustom || ent.hasUnlimitedUsage ? null : ent.usageLimit,
          hasSoftLimit: ent.hasSoftLimit,
          isCustom: ent.isCustom,
          hasUnlimitedUsage: ent.hasUnlimitedUsage,
          behavior: ent.behavior,
          ...getResetPeriodData(ent.resetPeriod, ent.resetPeriodConfiguration),
          order: +nextOrder + i,
        })),
      }),
    );

    await onEntitlementsChanged();

    setForceEntitlementsCalculation((value) => value + 1);
    setOverrideDialogOpen(false);
    setAddDialogOpen(false);
  };

  const triggerOverrideEntitlement = (entitlement: PlanEntitlement) => {
    setEntitlementToOverride(entitlement);
    setOverrideDialogOpen(true);
  };

  const triggerEditEntitlement = (entitlement: PlanEntitlement) => {
    setEntitlementToEdit(entitlement);
    setEditDialogOpen(true);
  };

  const triggerDeleteEntitlement = (entitlement: PlanEntitlement) => {
    setEntitlementToDelete(entitlement);
    setDeleteDialogOpen(true);
  };

  const triggerEditEntitlementDisplayOptions = (entitlement: PlanEntitlement) => {
    setEntitlementToEdit(entitlement);
    setEditDisplayOptionsDialogOpen(true);
  };

  const basePlanEntitlementsByFeatureId = new Map(
    inheritedEntitlements?.map((inheritedEntitlement) => [inheritedEntitlement.feature.id, inheritedEntitlement]),
  );
  const childPlanEntitlementsByFeatureId = new Map(
    entitlements.map((entitlement) => [entitlement.feature.id, entitlement]),
  );

  const basePlanSwitchProps: BasePlanSwitchProps = {
    packageEntitlements: inheritedEntitlements || [],
    currentPlan: aPackage as PlanFragment,
    readonly,
  };

  const showBasePlanComponents = aPackage.type === 'Plan' && (!readonly || !!basePlanSwitchProps.currentPlan?.basePlan);

  const onDragEnd: TableReorderingProps['onDragEnd'] = ({ source, destination }) => {
    if (!destination || !onEntitlementsReorder) {
      return;
    }

    const updatedEntitlements = reorder(entitlements, source.index, destination?.index)
      .filter(({ id }) => !priceEntitlementsIds.has(id))
      .map(({ id }, index) => ({ id, order: index }));

    void onEntitlementsReorder(updatedEntitlements);
  };
  const isCustomPlan = packageType === 'plan' && aPackage.pricingType === PricingType.Custom;
  const isAddon = packageType === 'add-on';

  return (
    <PageCard>
      <Header readonly={readonly} setAddDialogOpen={setAddDialogOpen} packageType={packageType} />
      {isEmpty(entitlements) && isEmpty(inheritedEntitlements) ? (
        <>
          {showBasePlanComponents && (
            <>
              <BasePlanSwitch {...basePlanSwitchProps} />
              <Divider my={4} />
            </>
          )}
          <EmptyCardContent>
            <Text.B2>{t('entitlements.grantedEntitlementsEmpty', { packageType })}</Text.B2>
            {!readonly && (
              <Link variant="body2" ml={1} onClick={() => setAddDialogOpen(true)}>
                {t('entitlements.grantedEntitlementsEmptySubtitle')}
              </Link>
            )}
          </EmptyCardContent>
        </>
      ) : (
        <Table
          readonly={readonly}
          onDragEnd={withReordering ? onDragEnd : undefined}
          label={t('entitlements.table')}
          tableLayout="auto"
          headCells={createHeadCells(
            basePlanEntitlementsByFeatureId,
            triggerDeleteEntitlement,
            triggerEditEntitlement,
            triggerEditEntitlementDisplayOptions,
            readonly,
            aPackage,
            openSetPriceWizard,
            isOveragePricingEnabled,
            basePlan,
          )}
          headerComponent={
            showBasePlanComponents ? (
              <BasePlanGrantedEntitlements
                withReordering={!readonly && withReordering}
                triggerOverrideEntitlement={triggerOverrideEntitlement}
                triggerDeleteEntitlement={triggerDeleteEntitlement}
                childPlanEntitlementsByFeatureId={childPlanEntitlementsByFeatureId}
                {...basePlanSwitchProps}
              />
            ) : null
          }
          headerComponentOptions={{ backgroundColorActive: !isEmpty(basePlan) }}
          data={entitlements}
          rowColor={(entitlement) => (entitlement?.price ? '#E9F4FE' : null)}
        />
      )}

      {addDialogOpen && (
        <AddEntitlementsDrawer
          excludedFeaturesIds={entitlements.map((entitlement) => entitlement.featureId)}
          basePlanEntitlementsByFeatureId={basePlanEntitlementsByFeatureId}
          basePlan={basePlan}
          onSubmit={onSubmitEntitlements}
          open={addDialogOpen}
          setDialogOpen={setAddDialogOpen}
          withCustomEntitlementOption={isCustomPlan}
          withEntitlementBehaviourOption={isAddon}
        />
      )}
      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose(entitlementToDelete?.id)}
        confirmationButtonText={t('entitlements.removeEntitlement')}
        title={
          entitlementToDelete && basePlanEntitlementsByFeatureId.get(entitlementToDelete?.feature?.id)
            ? t('entitlements.revokeOverride')
            : t('entitlements.revoke')
        }
        content={t('entitlements.deleteDialog', { packageType })}
      />
      <EditEntitlementDrawer
        open={editDialogOpen}
        entitlement={entitlementToEdit}
        handleClose={() => setEditDialogOpen(false)}
        handleSubmit={handleEditSubmit}
        withCustomEntitlementOption={isCustomPlan}
        withEntitlementBehaviourOption={isAddon}
      />
      <EditEntitlementDisplayOptionsDialog
        open={editDisplayOptionsDialogOpen}
        entitlement={entitlementToEdit}
        handleClose={() => setEditDisplayOptionsDialogOpen(false)}
        handleSubmit={handleEditDisplayOptionsSubmit}
      />
      <OverrideEntitlementDrawer
        open={overrideDialogOpen}
        entitlement={entitlementToOverride}
        handleClose={() => setOverrideDialogOpen(false)}
        handleSubmit={onSubmitEntitlements}
        withCustomEntitlementOption={isCustomPlan}
      />
    </PageCard>
  );
}
