import { Chip, Popper, Backdrop } from '@stigg-components';
import { styled as muiStyled } from '@stigg-theme';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

export const StyledChip = muiStyled(Chip)`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground};
  }

  border: ${({ theme }) => `1px solid ${theme.itamar.palette.other.outlineBorder}`};
  border-radius: 10px;
`;

export const StyledPopper = muiStyled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 101};
`;

export const StyledBackdrop = muiStyled(Backdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 100};
`;

export const StyledChevronDown = muiStyled(ChevronDown)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

export const StyledPopperContainer = styled.div`
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  width: 282px;
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: 16px;
  z-index: 2;
`;
