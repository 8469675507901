import { DialogActionsButtons, FormRenderProps, Grid, FullScreenDialogLayout, Text } from '@stigg-components';
import { t } from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TestFunction } from 'yup';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import isEqual from 'lodash/isEqual';
import { pick } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { ExperimentTypeCard } from './ExperimentTypeCard';
import { ExperimentType } from '../commom/ExperimentType';
import { DetailsCard } from './DetailsCard';
import { VariationCard } from './variation/VariationCard';
import {
  calculateInitialCustomerJourneyFormValues,
  EditProductSettingsFormFieldsNames,
  prepareProductSettings,
} from '../../../products/components/productPage/customerJourney/CustomerJourney';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { fetchProductByRefIdAction } from '../../../products/productsSlice';
import { RatioCard } from './RatioCard';
import { createExperimentAction, updateExperimentAction } from '../../experimentsSlice';
import { composeEmptyExperimentPreset, composeExperimentPreset, computeExperimentType } from './ExperimentWizard.utils';
import { isExperimentNameUnique } from '../../queries/isExperimentNameUnique';
import { ProductSettingsVariationEditor } from './variation/ProductSettingsVariationEditor';
import { PlanEntitlementsVariationEditor } from './variation/PlanEntitlementsVariationEditor';
import { customerJourneyValidationJson } from '../../../products/components/productPage/customerJourney/customerJourneyValidation';
import { EditProductSettingsFormFields } from '../../../products/components/productPage/customerJourney/types';

export type ExperimentPreset = {
  name: string;
  description: string;
  variantGroupName: string;
  controlGroupName: string;
  variantPercentage: number;
  controlPercentage: number; // is used solely for nicer ui and should always be 100 - variantPercentage
  productId: string;
} & EditProductSettingsFormFields;

export function ExperimentWizard({
  closeWizard,
  requestedExperimentType,
  experiment,
  editMode,
}: {
  closeWizard: () => void;
  requestedExperimentType?: ExperimentType;
  experiment?: ExperimentFragment;
  editMode?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { experimentEntitlements: experimentEntitlementsFeatureEnabled } = useFlags<FeatureFlags>();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoadingProduct = useSelector((root: RootState) => root.productReducer.isLoading);
  const products = useSelector((state: RootState) => state.productReducer.products);
  const product = useSelector((state: RootState) => state.productReducer.product);

  const [isSaving, setIsSaving] = useState(false);
  const variationRef = useRef<HTMLDivElement | null>(null);
  const ratioRef = useRef<HTMLDivElement | null>(null);

  // TODO: use the experiment type from UI input
  const experimentType = computeExperimentType(
    Boolean(experimentEntitlementsFeatureEnabled),
    experiment,
    requestedExperimentType,
  );
  const hasInitialValues = !!experiment;
  const [variationCardIsOpen, setVariationCardIsOpen] = useState(hasInitialValues);
  const [ratioCardIsOpen, setRatioCardIsOpen] = useState(hasInitialValues);
  const initialCustomerJourney = !isEmpty(product)
    ? calculateInitialCustomerJourneyFormValues(product.plans, product.productSettings)
    : undefined;
  const initialValues: ExperimentPreset =
    editMode && experiment
      ? composeExperimentPreset(experiment, product)
      : composeEmptyExperimentPreset(products, product);

  const isNameUnique: TestFunction<string | undefined> = useCallback(
    (name: string | undefined) => {
      if (!currentEnvironmentId || !name) {
        return true;
      }
      const promise = isExperimentNameUnique(
        {
          environmentId: currentEnvironmentId,
          name,
          excludeRedId: (editMode && experiment?.refId) || undefined,
        },
        { dispatch },
      );

      // in case of a failure don't show any error
      return promise.catch(() => true);
    },
    [currentEnvironmentId, editMode, experiment?.refId, dispatch],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('experiments.wizard.nameIsRequired'))
      .test('checkNameUnique', t('experiments.nameAlreadyUsed'), isNameUnique),
    variantGroupName: Yup.string().required(t('experiments.required')),
    controlGroupName: Yup.string().required(t('experiments.required')),
    productId: Yup.string()
      .required(t('experiments.required'))
      .oneOf(
        products.map((x) => x.id),
        t('experiments.required'),
      ),
    description: Yup.string().nullable(true),
    variantPercentage: Yup.number()
      .required(t('experiments.required'))
      .min(1, t('experiments.min'))
      .max(99, t('experiments.max')),
    controlPercentage: Yup.number()
      .required(t('experiments.required'))
      .min(1, t('experiments.min'))
      .max(99, t('experiments.max')),
    ...customerJourneyValidationJson,
  });
  const onSubmit = async (values: ExperimentPreset) => {
    setIsSaving(true);

    const experimentData = {
      productId: values.productId,
      controlGroupName: values.controlGroupName,
      description: values.description,
      name: values.name,
      productSettings: prepareProductSettings(values),
      variantGroupName: values.variantGroupName,
      variantPercentage: +values.variantPercentage,
    };

    if (editMode && experiment) {
      await dispatch(updateExperimentAction({ refId: experiment.refId, ...experimentData }));
    } else {
      await dispatch(createExperimentAction(experimentData));
    }

    setIsSaving(false);
    closeWizard();
  };
  const {
    dirty,
    isValid,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setTouched,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
  } = useFormik<ExperimentPreset>({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (values.productId !== '' && isEmpty(product) && !isEmpty(products)) {
      const refId = products.find((x) => x.id === values.productId)?.refId;
      if (refId) {
        void dispatch(fetchProductByRefIdAction({ refId }));
      }
    }
  }, [products, dispatch, values.productId, product]);

  // setting it outside the initial values so the form
  // will be considered dirty, hence possible to submit
  const valuesSetFlag = useRef(false);
  useEffect(() => {
    if (!valuesSetFlag.current && product) {
      if (!editMode && experiment) {
        const formValues = composeExperimentPreset(experiment, product);
        void setValues({
          ...formValues,
          name: t('experiments.wizard.experimentCopy', { name: experiment.name }),
        });
      }
      valuesSetFlag.current = true;
    }
  }, [editMode, experiment, product, setValues]);

  const formRenderProps: FormRenderProps<ExperimentPreset> = {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
    setValues,
    setTouched,
  };

  // TODO: calculate based on experiment type
  const noChangesInVariant = isEqual(
    pick(formRenderProps.values, EditProductSettingsFormFieldsNames),
    pick({ ...initialValues, ...initialCustomerJourney }, EditProductSettingsFormFieldsNames),
  );

  const openVariationCard = () => {
    if (isEmpty(product)) {
      return;
    }
    setVariationCardIsOpen(true);
    // mandatory timeout as otherwise opening the card in th same time doesn't work well with scrolling
    setTimeout(() => {
      variationRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };
  const openRatioCard = () => {
    setRatioCardIsOpen(true);
    setTimeout(() => {
      ratioRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  if (isLoadingProduct) {
    return <Loader />;
  }
  return (
    <FullScreenDialogLayout title={editMode ? t('experiments.wizard.editHeader') : t('experiments.wizard.header')}>
      <Grid
        flexDirection="column"
        container
        item
        alignSelf="center"
        justifyContent="center"
        sx={{ width: 912 }}
        flexWrap="nowrap"
        gap={4}
        mt={11}>
        <Text.H3>{editMode ? t('experiments.wizard.updateTitle') : t('experiments.wizard.title')}</Text.H3>
        <ExperimentTypeCard experimentType={experimentType} />
        <DetailsCard
          setVariationCardOpen={openVariationCard}
          products={products}
          variationCardOpen={variationCardIsOpen}
          formRenderProps={formRenderProps}
        />
        <Grid ref={variationRef}>
          <VariationCard
            open={variationCardIsOpen}
            ratioCardOpen={ratioCardIsOpen}
            setRatioCardOpen={openRatioCard}
            noChangesInVariant={noChangesInVariant}>
            {experimentType === ExperimentType.CUSTOMER_JOURNEY && (
              <ProductSettingsVariationEditor
                product={product}
                formRenderProps={formRenderProps}
                initialValues={{ ...initialValues, ...initialCustomerJourney }}
              />
            )}
            {experimentType === ExperimentType.PLAN_ENTITLEMENTS && (
              <PlanEntitlementsVariationEditor formRenderProps={formRenderProps} />
            )}
          </VariationCard>
        </Grid>
        <RatioCard formRenderProps={formRenderProps} open={ratioCardIsOpen} />
        <Grid item alignSelf="end" ref={ratioRef} mb={12}>
          <DialogActionsButtons
            saveDisabled={!dirty || !isValid || noChangesInVariant}
            isSaveLoading={isSaving}
            saveText={editMode ? t('experiments.updateExperimentCTA') : t('experiments.createExperimentCTA')}
            cancelText={t('sharedComponents.cancel')}
            onCancel={closeWizard}
            onSave={handleSubmit}
          />
        </Grid>
      </Grid>
    </FullScreenDialogLayout>
  );
}
