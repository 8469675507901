import { OptionsDropdownType } from '@stigg-components/types';
import { Archive, Edit2 } from 'react-feather';
import { t } from 'i18next';
import { Text } from '@stigg-components';
import { Duplicate } from '../../../../components/icon/CustomIcons';

export function getProductMenuOptions({
  setEditDialogOpen,
  setDuplicateDialogOpen,
  setDeleteDialogOpen,
  isAWSMarketplaceProduct,
  duplicateProductEnabled,
}: {
  setEditDialogOpen: (open: boolean) => void;
  setDuplicateDialogOpen: (open: boolean) => void;
  setDeleteDialogOpen: (open: boolean) => void;
  isAWSMarketplaceProduct: boolean;
  duplicateProductEnabled: boolean;
}) {
  let productCardOptions: OptionsDropdownType;

  if (duplicateProductEnabled) {
    productCardOptions = [
      {
        icon: Edit2,
        text: t('products.options.edit'),
        onClick: () => setEditDialogOpen(true),
      },
      {
        icon: Duplicate,
        text: t('products.options.duplicate'),
        onClick: () => setDuplicateDialogOpen(true),
        disabled: isAWSMarketplaceProduct,
        tooltip: isAWSMarketplaceProduct
          ? {
              placement: 'left',
              title: <Text.B2 color="primary">{t('products.cantDuplicateAwsMarkteplaceProductTooltipText')}</Text.B2>,
            }
          : undefined,
      },
      {
        icon: Archive,
        text: t('products.options.delete'),
        onClick: () => setDeleteDialogOpen(true),
      },
    ];
  } else {
    productCardOptions = [
      {
        icon: Edit2,
        text: t('products.options.edit'),
        onClick: () => setEditDialogOpen(true),
      },
      {
        icon: Archive,
        text: t('products.options.delete'),
        onClick: () => setDeleteDialogOpen(true),
      },
    ];
  }

  return productCardOptions;
}
