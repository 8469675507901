import React from 'react';

import { t } from 'i18next';

import { ReactComponent as PayWallSvg } from '@assets/images/widgets/paywall.svg';
import { ReactComponent as CustomerPortalSvg } from '@assets/images/widgets/customerPortal.svg';
import { ReactComponent as Checkout } from '@assets/images/widgets/checkout.svg';
import { ReactComponent as FeatureComperison } from '@assets/images/widgets/featureComperison.svg';
import { ReactComponent as PowerUp } from '@assets/images/widgets/powerUp.svg';
import { ReactComponent as TrialBanner } from '@assets/images/widgets/trialBanner.svg';
import { ReactComponent as UsageEstimator } from '@assets/images/widgets/usageEstimator.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';

type UseWidgetsDataResult = WidgetProps[];

export enum WidgetIdentifier {
  CustomerPortal = 'CUSTOMERPORTAL',
  Paywall = 'PAYWALL',
  Checkout = 'CHECKOUT',
  FeatureComparison = 'FEATURECOMPARISON',
  UsagePricingEstimator = 'USAGEPRICINGESTIMATOR',
  TrialBanner = 'TRIALBANNER',
  PowerUpBadge = 'POWERUPBADGE',
}

export type WidgetProps = {
  name: string;
  widgetIdentifier: WidgetIdentifier;
  description: string;
  svg: React.ReactNode;
  isComingSoon?: boolean;
  docsUrl: string;
  enabled?: boolean;
};
export function useWidgetsData(): UseWidgetsDataResult {
  const {
    widgetLibraryPaywall: widgetLibraryPaywallEnabled,
    widgetLibraryCustomerPortal: widgetLibraryCustomerPortalEnabled,
    widgetLibraryCheckout: widgetLibraryCheckoutEnabled,
  } = useFlags<FeatureFlags>();

  return [
    {
      name: t('widgets.planPicker'),
      description: t('widgets.paywallDescription'),
      widgetIdentifier: WidgetIdentifier.Paywall,
      docsUrl: 'https://docs.stigg.io/docs/pricing-table',
      svg: <PayWallSvg />,
      enabled: widgetLibraryPaywallEnabled,
    },
    {
      name: t('widgets.customerPortal'),
      description: t('widgets.customerPortalDescription'),
      docsUrl: 'https://docs.stigg.io/docs/customer-portal',
      widgetIdentifier: WidgetIdentifier.CustomerPortal,
      svg: <CustomerPortalSvg />,
      enabled: widgetLibraryCustomerPortalEnabled,
    },
    {
      name: t('widgets.checkout.title'),
      description: t('widgets.checkout.description'),
      isComingSoon: !widgetLibraryCheckoutEnabled,
      docsUrl: 'https://docs.stigg.io/docs/checkout',
      widgetIdentifier: WidgetIdentifier.Checkout,
      svg: <Checkout />,
      enabled: widgetLibraryCheckoutEnabled,
    },
    {
      name: t('widgets.planComparisonTable'),
      description: t('widgets.comparisonTableDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.FeatureComparison,
      svg: <FeatureComperison />,
      docsUrl: 'https://docs.stigg.io/',
    },
    {
      name: t('widgets.usagePriceEstimator'),
      description: t('widgets.usagePricingEstimatorDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.UsagePricingEstimator,
      svg: <UsageEstimator />,
      docsUrl: 'https://docs.stigg.io/',
    },
    {
      name: t('widgets.trialBanner'),
      description: t('widgets.trialBannerDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.TrialBanner,
      svg: <TrialBanner />,
      docsUrl: 'https://docs.stigg.io/',
    },
    {
      name: t('widgets.powerupBadge'),
      description: t('widgets.powerUpDescription'),
      isComingSoon: true,
      widgetIdentifier: WidgetIdentifier.PowerUpBadge,
      svg: <PowerUp />,
      docsUrl: 'https://docs.stigg.io/',
    },
  ];
}
