import {
  CustomerFilter,
  CustomerFilterCustomerSubscriptionFilter,
  PaymentCollection,
  PricingType,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { ExtraSubscriptionStatuses } from '../components/customerPage/customerSubscriptions/SubscriptionStatusChip';
import type { FetchCustomersProps } from './fetchCustomers';

interface SubscriptionFilterHandler {
  handle: () => CustomerFilterCustomerSubscriptionFilter[];
}

const pricingTypeFilterHandler = (pricingType?: PricingType[]): SubscriptionFilterHandler => ({
  handle: () =>
    pricingType?.length
      ? [
          {
            pricingType: {
              in: pricingType,
            },
          },
        ]
      : [],
});

const subscriptionStatusFilterHandler = (
  subscriptionStatuses?: (SubscriptionStatus | ExtraSubscriptionStatuses)[],
): SubscriptionFilterHandler => ({
  handle: () => {
    if (!subscriptionStatuses?.length) {
      return [];
    }

    const plainStatuses = subscriptionStatuses.filter((status) =>
      [
        SubscriptionStatus.Active,
        SubscriptionStatus.InTrial,
        SubscriptionStatus.NotStarted,
        SubscriptionStatus.PaymentPending,
        SubscriptionStatus.Expired,
      ].includes(status as SubscriptionStatus),
    );

    const filters: CustomerFilterCustomerSubscriptionFilter[] = [];
    if (plainStatuses.length) {
      filters.push({
        cancellationDate: { is: null },
        status: { in: plainStatuses as SubscriptionStatus[] },
      });
    }

    if (subscriptionStatuses.includes(SubscriptionStatus.Canceled)) {
      filters.push({ status: { eq: SubscriptionStatus.Canceled } });
    }

    if (subscriptionStatuses.includes(ExtraSubscriptionStatuses.ScheduledCancellation)) {
      filters.push({ cancellationDate: { isNot: null }, status: { eq: SubscriptionStatus.Active } });
    }

    if (subscriptionStatuses.includes(ExtraSubscriptionStatuses.PaymentError)) {
      filters.push({ paymentCollection: { in: [PaymentCollection.Failed, PaymentCollection.ActionRequired] } });
    }

    return filters;
  },
});

export const generateFetchCustomersFilters = (props: FetchCustomersProps) => {
  const { pricingTypes, subscriptionStatuses, search: rawSearch } = props;
  const search = rawSearch?.trim() || null; // Convert empty string '' or undefined to null

  const environmentFilter: CustomerFilter = {
    and: [
      {
        environmentId: {
          eq: props.environmentId,
        },
        // the deleted as filter is to filter archived customer which is for now the default and only behaviour
        // archive customers are not exposed in the UI
        deletedAt: {
          is: null,
        },
      },
    ],
  };

  const searchFilters: CustomerFilter = search ? { searchQuery: { iLike: `%${search}%` } } : {};

  const subscriptionFilterHandlers = [
    pricingTypeFilterHandler(pricingTypes),
    subscriptionStatusFilterHandler(subscriptionStatuses),
  ];

  const subscriptionFilters = subscriptionFilterHandlers.reduce<CustomerFilterCustomerSubscriptionFilter[]>(
    (acc, handler) => {
      const filtersToApply = handler.handle();

      return filtersToApply?.length
        ? filtersToApply.reduce<CustomerFilterCustomerSubscriptionFilter[]>(
            (applyAcc, subscriptionFilter) => [
              ...applyAcc,
              ...(acc.length === 0
                ? [subscriptionFilter]
                : acc.map((currentSubscriptionFilter) => ({
                    ...currentSubscriptionFilter,
                    ...subscriptionFilter,
                  }))),
            ],
            [],
          )
        : acc;
    },
    [],
  );

  const subscriptionFilter: CustomerFilter = subscriptionFilters.length
    ? {
        subscriptions: subscriptionFilters.length === 1 ? subscriptionFilters[0] : { or: subscriptionFilters },
      }
    : {};

  const filter: CustomerFilter = {
    ...searchFilters,
    ...environmentFilter,
    ...subscriptionFilter,
  };

  return filter;
};
