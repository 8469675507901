import styled from 'styled-components/macro';
import { IconButton } from '../../buttons';

export const ThreeDotsIconContainer = styled(IconButton)`
  color: ${({ theme }) => theme.itamar.palette.action.active};

  &:hover {
    color: ${({ theme }) => theme.itamar.palette.text.primary};
    border-radius: ${({ theme }) => theme.itamar.border.radius};
    background-color: ${({ theme }) => theme.itamar.palette.action.focus};
  }
`;
