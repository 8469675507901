import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Text, GridFlex, OptionsDropdown } from '@stigg-components';
import { ImportIntegrationTaskResultFragment, TaskStatus, TaskType } from '@stigg-types/apiTypes';
import { Download, Upload, AlertCircle } from 'react-feather';
import { CSVLink } from 'react-csv';
import { getIconColor } from '../../../../theme';
import { formatDate } from '../../../common';

const ImportTaskWrapper = styled(GridFlex.RowCenter)<{ $taskStatus: TaskStatus }>`
  border-radius: 4px;
  border: ${({ theme, $taskStatus }) => {
    switch ($taskStatus) {
      case TaskStatus.Failed:
      case TaskStatus.PartiallyFailed:
        return `1px solid ${theme.itamar.palette.error.outlinedRestingBackground}`;
      default:
        return `1px solid ${theme.itamar.border.borderColor}`;
    }
  }};
  padding: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme, $taskStatus }) => {
    switch ($taskStatus) {
      case TaskStatus.Failed:
      case TaskStatus.PartiallyFailed:
        return theme.itamar.palette.error.background;
      default:
        return theme.itamar.palette.white;
    }
  }};
  height: 64px;
`;

export function ImportStripeStatus({ task }: { task: ImportIntegrationTaskResultFragment }) {
  switch (task.status) {
    case TaskStatus.PartiallyFailed:
    case TaskStatus.Failed: {
      const csvData = task.importErrors.map(({ id, error }) => ({ productId: id, error }));
      return (
        <ImportTaskWrapper key={task.id} $taskStatus={task.status} justifyContent="space-between" mb={2}>
          <GridFlex.RowCenter>
            <AlertCircle color={getIconColor('error')} />
            <Text.B2 color="error.content" ml={4}>
              {task.status === TaskStatus.Failed
                ? t('integrations.productsImportFailedMessage', {
                    taskItemsType: task.taskType === TaskType.ImportIntegrationCatalog ? 'products' : 'customers',
                  })
                : t('integrations.productsImportPartiallyFailedMessage', {
                    count: task.importErrors.length,
                    total: task.totalSubtasksCount,
                  })}
            </Text.B2>
          </GridFlex.RowCenter>
          <GridFlex.RowCenter>
            <Text.B2 color="error.content" ml={4}>
              Imported on: {formatDate(task.createdAt)}
            </Text.B2>
            <OptionsDropdown
              options={[
                {
                  icon: Download,
                  text: (
                    <CSVLink
                      style={{ textDecoration: 'none' }}
                      data={csvData}
                      filename="stripe-products-import-troubleshoot.csv"
                      target="_blank">
                      <Text.B2 color="primary">{t('integrations.downloadTroubleshootCSV')}</Text.B2>
                    </CSVLink>
                  ),
                },
              ]}
            />
          </GridFlex.RowCenter>
        </ImportTaskWrapper>
      );
    }
    default: {
      const { productsCount, customersCount, createdAt } = task;
      return (
        <ImportTaskWrapper key={task.id} $taskStatus={task.status} justifyContent="space-between" mb={2}>
          <GridFlex.RowCenter>
            <Upload color={getIconColor('active')} />
            <Text.B2 ml={4}>
              {productsCount ? `${productsCount} products` : ''}
              {customersCount ? `${customersCount} customers` : ''}
            </Text.B2>
          </GridFlex.RowCenter>
          <Text.B2 ml={4}>Imported on: {formatDate(createdAt)}</Text.B2>
        </ImportTaskWrapper>
      );
    }
  }
}
