import { Alert, Icon, Text } from '@stigg-components';
import { t } from 'i18next';

export default function BetaIndicator() {
  return (
    <Alert severity="info" icon={<Icon icon="Experiments" type="custom" />}>
      <Text.B2 color="info.dark">{t('eventsLog.betaIndication')}</Text.B2>
    </Alert>
  );
}
