import { SxProps } from '@mui/system';
import { ItamarTheme } from '@stigg-theme';
import { useSelector } from 'react-redux';
import { Alert, Button, Icon, ExternalLink, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { RootState } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';

interface ClientSideSecurityAlertProps {
  sx?: SxProps<ItamarTheme>;
  variant?: 'link' | 'button';
}

export const ClientSideSecurityAlert = ({ sx, variant }: ClientSideSecurityAlertProps) => {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((env) => env.id === currentEnvironmentId);
  const { navigateTo, appRoutes, composeUrl } = useNavigation();

  if (!currentEnvironment || currentEnvironment.hardenClientAccessEnabled || currentEnvironment.isSandbox) {
    return null;
  }

  return (
    <Alert
      icon={<div>💡</div>}
      sx={{ ...sx, alignItems: 'center' }}
      severity="info"
      action={
        variant === 'link' ? undefined : (
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              navigateTo(appRoutes.settingsPage({ clientSideSecurityEnvironmentSlug: currentEnvironment.slug }), {
                isGlobal: true,
              })
            }
            endIcon={<Icon type="reactFeather" icon="ArrowRight" size={18} />}>
            {t('widgets.envHardeningButton')}
          </Button>
        )
      }>
      <Text.B2>
        {t('widgets.envHardeningAlert')}
        {variant === 'link' && (
          <>
            {'. '}
            <ExternalLink
              url={composeUrl(appRoutes.settingsPage({ clientSideSecurityEnvironmentSlug: currentEnvironment.slug }), {
                isGlobal: true,
              })}>
              {t('widgets.envHardeningLink')}
            </ExternalLink>
          </>
        )}
      </Text.B2>
    </Alert>
  );
};
