import { ResultSet } from '@cubejs-client/core';
import { Flex, Grid, Text } from '@stigg-components';
import '../../../common/chartjs/chartjs.plugins';
import { ChartData, ChartOptions } from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import React, { useCallback, useMemo } from 'react';
import { lighten } from '@mui/system/colorManipulator';
import { dateFormatter, numberFormatter, numberFullFormatter } from '../../../common/formatters';
import { SERIES_COLORS } from '../constants';
import { CountText } from './base';
import { COLORS } from '../../../../../theme/colors';
import { ColorsSchema } from '../../../../../theme/colors.types';
import {
  AXIS_TICK_COLOR,
  AXIS_TICK_FONT,
  GRID_BORDER_DASH,
  LABEL_COLOR,
  LABEL_FONT,
} from '../../../common/chartjs/chartjs.theme';
import {
  DefaultChartTooltip,
  useDefaultChartTooltip,
  useDoughnutChartTooltip,
} from '../../../common/chartjs/DefaultChartTooltip';

export const AreaChartRenderer = ({ resultSet }: { resultSet: ResultSet }) => {
  const { tooltipInfo, tooltipHandler } = useDefaultChartTooltip();

  const computeChartData = useCallback(
    (resultSet: ResultSet) => {
      const labels = resultSet
        .categories()
        .map((category) => dateFormatter(category.x, resultSet.query().timeDimensions?.[0]?.granularity === 'hour'));

      const datasets = resultSet.series().map((series, i) => {
        const seriesColor = SERIES_COLORS[i % SERIES_COLORS.length];
        return {
          label: series.title.split(',')[0],
          data: series.series.map((r) => r.value),
          borderColor: seriesColor,
          tension: 0,
          pointHoverRadius: 1,
          fill: { value: 0 },
          gradient: {
            backgroundColor: {
              axis: 'y' as const,
              colors: {
                0: lighten(seriesColor, 1),
                100: lighten(seriesColor, 0.8),
              },
            },
          },
        };
      });

      const data: ChartData<'line'> = {
        labels,
        // filtering out all-zero series:
        datasets: datasets.filter((x) => !x.data.every((d) => d === 0)),
      };

      const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: LABEL_COLOR,
              font: LABEL_FONT,
              boxWidth: 20,
              boxHeight: 1,
            },
            align: 'start',
          },
          tooltip: {
            enabled: false,
            external: tooltipHandler,
            mode: 'nearest',
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          line: {
            borderWidth: 3,
          },
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            ticks: {
              maxTicksLimit: 5,
              autoSkip: true,
              maxRotation: 0,
              padding: 12,
              minRotation: 0,
              font: AXIS_TICK_FONT,
              color: AXIS_TICK_COLOR,
            },
          },
          y: {
            stacked: true,
            grid: {
              borderDash: GRID_BORDER_DASH,
            },
            ticks: {
              maxTicksLimit: 5,
              precision: 0,
              callback: (value) => numberFormatter(value as number),
              font: AXIS_TICK_FONT,
              color: AXIS_TICK_COLOR,
            },
          },
        },
      };

      return { data, options };
    },
    [tooltipHandler],
  );

  const { options, data } = useMemo(() => computeChartData(resultSet), [resultSet, computeChartData]);
  return (
    <Grid mt={8} flex={1}>
      <DefaultChartTooltip tooltipInfo={tooltipInfo} />
      <Line width="100%" options={options} data={data} height={400} />
    </Grid>
  );
};

export const PieChartRenderer = ({
  resultSet,
  units,
  total,
  totalText,
  tooltipLabel,
  renderLegend,
}: {
  resultSet: ResultSet;
  units: string;
  total: number;
  totalText: string;
  tooltipLabel: string;
  renderLegend?: (data: ChartData<'doughnut', number[]>) => React.ReactNode;
}) => {
  const { tooltipInfo, tooltipHandler } = useDoughnutChartTooltip(tooltipLabel);

  const computeChartData = useCallback(
    (resultSet: ResultSet) => {
      const labels: string[] = [];

      const datasets = resultSet.series({ x: [units] }).map((series) => {
        // filtering out zero series
        const nonZeroSeries = series.series.filter((r) => r.value > 0);
        const seriesColor = nonZeroSeries.map((_, i) => SERIES_COLORS[i % SERIES_COLORS.length]);

        labels.push(...nonZeroSeries.map((r) => r.x.split(',')[0]));

        return {
          data: nonZeroSeries.map((r) => r.value),
          yValues: [series.key],
          borderColor: seriesColor,
          backgroundColor: seriesColor,
          borderWidth: 1,
        };
      });

      const data: ChartData<'doughnut'> = {
        labels,
        datasets,
      };

      const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 80,
        interaction: {
          intersect: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            // showing the tooltip makes sense only if there's more than item in the doughnut chart
            external: (resultSet.series()[0]?.series.length || 0) > 1 ? tooltipHandler : undefined,
            mode: 'nearest',
            intersect: false,
          },
          datalabels: {
            display: false,
          },
        },
      };

      return { data, options };
    },
    [tooltipHandler, units],
  );

  const { options, data } = useMemo(() => computeChartData(resultSet), [resultSet, computeChartData]);

  return (
    <>
      <Grid mt={21} position="relative">
        <Flex.Column
          position="absolute"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ pointerEvents: 'none' }}>
          <CountText>{numberFullFormatter(total)}</CountText>
          <Text.B2 color="secondary">{totalText}</Text.B2>
        </Flex.Column>
        <DefaultChartTooltip tooltipInfo={tooltipInfo} />
        <Doughnut options={options} data={data} height={200} />
      </Grid>
      {renderLegend?.(data)}
    </>
  );
};

export const SparklineChartRenderer = ({ resultSet, color }: { resultSet: ResultSet; color: string }) => {
  const computeChartData = (resultSet: ResultSet) => {
    const seriesColor = COLORS[color as keyof ColorsSchema];

    const labels = resultSet.categories().map((category) => dateFormatter(category.x));

    const datasets = resultSet.series().map((series) => {
      return {
        label: series.title.split(',')[0],
        data: series.series.map((r) => r.value),
        pointRadius: 0,
        borderColor: seriesColor,
        tension: 0.25,
        pointHoverRadius: 1,
        fill: true,
        gradient: {
          backgroundColor: {
            axis: 'y' as const,
            colors: {
              0: lighten(seriesColor, 1),
              100: lighten(seriesColor, 0.8),
            },
          },
        },
      };
    });

    const data: ChartData<'line'> = {
      labels,
      datasets,
    };

    const options: ChartOptions<'line'> = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 4,
      },
      interaction: {
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          display: false,
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    };

    return { data, options };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { options, data } = useMemo(() => computeChartData(resultSet), [resultSet, color]);
  return (
    <Grid flex={1}>
      <Line width="100%" options={options} data={data} height={48} />
    </Grid>
  );
};
