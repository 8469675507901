import React from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { t } from 'i18next';
import { TextField, Box, Grid, Text } from '@stigg-components';
import { merge } from 'lodash';
import { HookDataFragment } from '@stigg-types/apiTypes';
import { slackUrlValidation, urlValidation } from '../../../common/urlValidation';
import { DialogActionsButtons } from '../../../../components/Dialog';
import { updateHookAction } from '../../hooksSlice';
import EventsSelector from './EventsSelector';
import { useAppDispatch } from '../../../../redux/store';
import { CreateHookFormFields, handleHookConfiguration } from './hooks.utils';

const validationSchema = (
  shouldIncludeDetails: boolean,
  shouldIncludeEvents: boolean,
  isSlackIntegrationHook: boolean,
) => {
  let validationSchema = {};
  if (shouldIncludeDetails) {
    validationSchema = {
      description: Yup.string().required(t('hooks.descriptionRequiredError')),
      endpoint: urlValidation(),
    };
  }
  if (shouldIncludeEvents) {
    merge(validationSchema, { eventLogTypes: Yup.array().min(1, t('hooks.eventTypeArrayEmpty')) });
  }
  if (isSlackIntegrationHook) {
    merge(validationSchema, { endpoint: slackUrlValidation(), description: Yup.string().optional() });
  }
  return Yup.object().shape(validationSchema);
};

export default function UpdateHookForm({
  hook,
  onCancel,
  shouldIncludeDetails = true,
  shouldIncludeEvents = true,
  isSlackIntegrationHook = false,
}: {
  hook: HookDataFragment;
  onCancel: () => void;
  shouldIncludeDetails?: boolean;
  shouldIncludeEvents?: boolean;
  isSlackIntegrationHook?: boolean;
}) {
  const dispatch = useAppDispatch();

  const initialValues = {
    description: hook.description,
    endpoint: hook.endpoint,
    eventLogTypes: hook.eventLogTypes,
  } as CreateHookFormFields;

  const handleSubmit = async (values: CreateHookFormFields) => {
    const configuration = handleHookConfiguration({ currentHook: hook, values });
    await dispatch(updateHookAction({ hookId: hook.id, updatePayload: { ...values, configuration } }));
    onCancel();
  };

  return (
    <Formik
      validationSchema={validationSchema(shouldIncludeDetails, shouldIncludeEvents, isSlackIntegrationHook)}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
        return (
          <Box>
            <form onSubmit={handleSubmit}>
              <FieldArray name="eventLogTypes">
                {({ push, remove }) => (
                  <Grid flexDirection="column" container>
                    {shouldIncludeDetails && (
                      <Grid flexDirection="column" container>
                        {!isSlackIntegrationHook && (
                          <Grid item>
                            <TextField
                              name="description"
                              label={t('hooks.description')}
                              value={values.description}
                              touched={!!touched.description}
                              error={!!errors.description}
                              errorText={errors.description}
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        )}
                        <Grid item pt={4}>
                          <TextField
                            name="endpoint"
                            label={t('hooks.endpoint')}
                            value={values.endpoint}
                            touched={!!touched.endpoint}
                            error={!!errors.endpoint}
                            errorText={errors.endpoint}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={
                              isSlackIntegrationHook
                                ? t('hooks.slackEndpointPlaceholder')
                                : t('hooks.endpointPlaceholder')
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                    {shouldIncludeEvents && (
                      <Grid container rowSpacing={4} pt={shouldIncludeDetails ? 10 : 0} flexDirection="column">
                        <Grid item>
                          <Text.H6>{t('hooks.triggerEventsTitle')}</Text.H6>
                        </Grid>
                        <Grid item>
                          <EventsSelector
                            selectedEventLogTypes={values.eventLogTypes}
                            push={push}
                            remove={remove}
                            setFieldValue={setFieldValue}
                            isSlackIntegrationHook={isSlackIntegrationHook}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container alignItems="baseline" justifyContent="space-between">
                      <Grid item>
                        {values.eventLogTypes.length > 0 && shouldIncludeEvents ? (
                          <Text.Sub2>
                            {values.eventLogTypes.length} event{values.eventLogTypes.length > 1 ? 's' : ''} selected
                          </Text.Sub2>
                        ) : null}
                      </Grid>
                      <Grid item>
                        <DialogActionsButtons
                          saveDisabled={!isValid || !dirty}
                          saveText={t('sharedComponents.editSaveButton')}
                          onCancel={onCancel}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </FieldArray>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
}
