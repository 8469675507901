import {
  FetchNonActiveSubscriptionsCountQuery,
  FetchNonActiveSubscriptionsCountQueryVariables,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { composeSubscriptionsFilter } from './fetchCustomerSubscriptionsResources';

const FETCH_NON_ACTIVE_SUBSCRIPTIONS_COUNT = gql`
  query FetchNonActiveSubscriptionsCount($filter: CustomerSubscriptionFilter) {
    customerSubscriptions(filter: $filter) {
      totalCount
    }
  }
`;

export type FetchNonActiveSubscriptionsCountProps = {
  search?: string;
  customerId: string;
};

async function fetchNonActiveSubscriptionsCount(
  { customerId, search }: FetchNonActiveSubscriptionsCountProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer,
        productReducer: { products },
      } = getState();

      const environmentId = accountReducer.currentEnvironmentId || '';

      const nonActiveCountFilter = composeSubscriptionsFilter(
        customerId,
        environmentId,
        products,
        [SubscriptionStatus.Canceled, SubscriptionStatus.Expired],
        search,
      );

      const res = await apolloClient.query<
        FetchNonActiveSubscriptionsCountQuery,
        FetchNonActiveSubscriptionsCountQueryVariables
      >({
        query: FETCH_NON_ACTIVE_SUBSCRIPTIONS_COUNT,
        fetchPolicy: 'network-only',
        variables: {
          filter: nonActiveCountFilter,
        },
      });

      return res.data.customerSubscriptions;
    },
    {
      failureMessageHandler: () => t('customers.api.failFetchOneSubscriptions'),
    },
    dispatch,
  );
}

export { fetchNonActiveSubscriptionsCount };
