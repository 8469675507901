import { useCallback, useEffect, useState } from 'react';
import { UsageEventFragment, AggregatedUsageByCustomerFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';
import { Icon, LoadingButton, Button, GridFlex, Text, CustomRenderProps } from '@stigg-components';
import { ContentRendererProps, Step } from '../../../../components/Stepper';
import { Filter, CreateFeatureFormFields } from './SidenavCreateFeature';
import { MeterFilters } from './MeterFilters';
import { MeterAggregation } from './MeterAggregation';
import { MeterPreviewSidenav } from './MeterPreviewSidenav';
import { MeterUnitLabels } from './MeterUnitLabels';
import { UpdateFeatureFields } from '../featureDetails/FeatureDetailsTab';
import { useAppDispatch } from '../../../../redux/store';
import {
  fetchUsageEventsAction,
  fetchAggregatedUsageByCustomerAction,
  fetchEventsFieldsAction,
} from '../../featuresSlice';
import { EventsFieldsResult } from '../../queries/fetchEventsFields';

const StepContentBox = styled(GridFlex.Column)`
  padding: 32px;
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  background-color: white;
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

function StepButtons({
  isFirstStep,
  isNextValid,
  isLastStep,
  isNextLoading,
  onBack,
  onNext,
  readonly,
  isUpdate,
}: ContentRendererProps & { isUpdate?: boolean; readonly?: boolean }) {
  return (
    <GridFlex.RowCenter
      sx={{ pt: 8, mt: 8, borderTop: (theme) => `1px solid ${theme.itamar.border.borderColor}` }}
      justifyContent="space-between">
      {isFirstStep ? <div /> : <Button onClick={onBack}>Back</Button>}
      <GridFlex.RowCenter columnGap={4} alignSelf="flex-end">
        <LoadingButton
          disabled={!isNextValid}
          onClick={onNext}
          variant="contained"
          color="primary"
          loadingPosition="start"
          endIcon={isLastStep ? null : <Icon type="reactFeather" icon="ArrowRight" size={18} />}
          loading={isNextLoading}>
          {isLastStep
            ? readonly
              ? t('sharedComponents.close')
              : isUpdate
              ? t('sharedComponents.editSaveButton')
              : t('sharedComponents.createSaveButton')
            : t('sharedComponents.nextButton')}
        </LoadingButton>
      </GridFlex.RowCenter>
    </GridFlex.RowCenter>
  );
}

function StepContent<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  title,
  subtitle,
  onNext,
  onBack,
  isNextLoading,
  isLastStep,
  isFirstStep,
  isNextValid,
  content,
  activeStep,
  readonly,
  formRenderProps,
  isUpdate,
}: ContentRendererProps & {
  title: string;
  subtitle: string;
  content: ({ eventsFields }: { eventsFields: EventsFieldsResult }) => React.ReactNode;
  readonly?: boolean;
  isUpdate?: boolean;
  formRenderProps: CustomRenderProps<T>;
}) {
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [usageEvents, setUsageEvents] = useState<UsageEventFragment[] | null>(null);
  const [eventsFields, setEventsFields] = useState<EventsFieldsResult>({});
  const [aggregatedUsageByCustomer, setAggregatedUsageByCustomer] = useState<
    AggregatedUsageByCustomerFragment[] | null
  >(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [withFiltersMode, setWithFiltersMode] = useState(true);
  const dispatch = useAppDispatch();

  const togglePreview = () => {
    setIsPreviewExpanded((prev) => !prev);
  };

  const loadPreviewEvents = useCallback(
    async (filters?: Filter[]) => {
      setWithFiltersMode(!isEmpty(filters));
      setIsPreviewLoading(true);
      const { events } = await dispatch(
        fetchUsageEventsAction({
          filters,
        }),
      ).unwrap();
      setIsPreviewLoading(false);
      setUsageEvents(events);
    },
    [dispatch],
  );

  const loadEventsFields = useCallback(
    async (filters?: Filter[]) => {
      try {
        const { fields } = await dispatch(
          fetchEventsFieldsAction({
            filters,
          }),
        ).unwrap();
        setEventsFields(fields);
      } catch (err) {
        console.log('Failed to get autocomplete event fields', err);
      }
    },
    [dispatch],
  );

  const loadAggregatedUsageByCustomer = useCallback(async () => {
    const { filters, aggregation } = formRenderProps.values;
    if (!filters || !aggregation) {
      return;
    }

    setIsPreviewLoading(true);
    const aggregatedUsageByCustomer = await dispatch(
      fetchAggregatedUsageByCustomerAction({
        filters,
        aggregation,
      }),
    ).unwrap();
    setIsPreviewLoading(false);
    setAggregatedUsageByCustomer(aggregatedUsageByCustomer);
  }, [dispatch, formRenderProps.values]);

  useEffect(() => {
    const load = async () => {
      const errors = await formRenderProps.validateForm();
      if ((activeStep === 0 || activeStep === 1) && isEmpty(errors)) {
        void loadPreviewEvents(formRenderProps.values.filters);
      }
    };
    void load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, loadPreviewEvents, formRenderProps.values.filters]);

  useEffect(() => {
    const load = async () => {
      const errors = await formRenderProps.validateForm();
      if (activeStep === 0 && !readonly) {
        void loadEventsFields();
        if (!isUpdate && !isEmpty(errors)) {
          void loadPreviewEvents();
        }
      }
    };
    void load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, loadEventsFields, loadPreviewEvents, isUpdate, readonly]);

  useEffect(() => {
    if (activeStep === 1) {
      void loadEventsFields(formRenderProps.values.filters);
    }
  }, [activeStep, loadEventsFields, formRenderProps.values.filters]);

  useEffect(() => {
    const load = async () => {
      const errors = await formRenderProps.validateForm();
      if (activeStep === 1 && isEmpty(errors)) {
        void loadAggregatedUsageByCustomer();
      }
    };

    void load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, formRenderProps.values.aggregation]);

  const onPreview = useCallback(() => {
    if (activeStep === 0) {
      void loadPreviewEvents(formRenderProps.isValid ? formRenderProps.values.filters : undefined);
    } else if (activeStep === 1) {
      void loadAggregatedUsageByCustomer();
    }
  }, [
    activeStep,
    loadAggregatedUsageByCustomer,
    loadPreviewEvents,
    formRenderProps.values.filters,
    formRenderProps.isValid,
  ]);

  return (
    <GridFlex.Row $fullHeight $fullWidth sx={{ position: 'relative' }}>
      <GridFlex.Column
        sx={{
          overflow: 'auto',
          position: 'relative',
          transition: 'width 0.3s ease-in-out',
          width: isLastStep ? '100%' : '65%',
        }}
        alignItems="center">
        <GridFlex.Column py={8} px={10} width={800}>
          <Text.H3>{title}</Text.H3>
          <Text.B2 mt={2} mb={8} color="secondary">
            {subtitle}
          </Text.B2>
          <StepContentBox>
            {content({ eventsFields })}
            {!readonly && (
              <StepButtons
                activeStep={activeStep}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                isNextValid={isNextValid}
                isNextLoading={isNextLoading}
                onBack={onBack}
                onNext={onNext}
                readonly={readonly}
                isUpdate={isUpdate}
              />
            )}
          </StepContentBox>
        </GridFlex.Column>
      </GridFlex.Column>

      {!isLastStep && (
        <MeterPreviewSidenav
          usageEvents={usageEvents}
          aggregatedUsageByCustomer={aggregatedUsageByCustomer}
          isPreviewLoading={isPreviewLoading}
          isPreviewExpanded={isPreviewExpanded}
          togglePreview={togglePreview}
          activeStep={activeStep}
          onRefresh={onPreview}
          withFiltersMode={withFiltersMode}
          isRefreshDisabled={withFiltersMode && !isNextValid}
        />
      )}
    </GridFlex.Row>
  );
}

export const STEP_TITLES = () => [
  t('features.filtersTitle'),
  t('features.aggregationTitle'),
  t('features.unitLabelsTitle'),
];

type StepsProps<T extends CreateFeatureFormFields | UpdateFeatureFields> = {
  filters: Filter[];
  formRenderProps: CustomRenderProps<T>;
  readonly?: boolean;
  isUpdate?: boolean;
};

export const Steps: <T extends CreateFeatureFormFields | UpdateFeatureFields>({
  filters,
  formRenderProps,
  readonly,
  isUpdate,
}: StepsProps<T>) => (Step & { title: string; isNextValid: boolean })[] = ({
  filters,
  formRenderProps,
  readonly,
  isUpdate,
}) => [
  {
    title: t('features.filtersTitle'),
    content: (contentProps) => (
      <StepContent
        title={readonly ? t('features.viewFilters') : t('features.filtersTitle')}
        subtitle={readonly ? t('features.viewFiltersSubtitle') : t('features.filtersSubtitle')}
        content={({ eventsFields }) => (
          <MeterFilters
            filters={filters}
            formRenderProps={formRenderProps}
            readonly={readonly}
            eventsFields={eventsFields}
          />
        )}
        readonly={readonly}
        formRenderProps={formRenderProps}
        isUpdate={isUpdate}
        {...contentProps}
      />
    ),
    isNextValid: isEmpty(formRenderProps.errors.filters),
    hideFooter: true,
  },
  {
    title: t('features.aggregationTitle'),
    content: (contentProps) => (
      <StepContent
        title={t('features.aggregationTitle')}
        subtitle={t('features.aggregationSubtitle')}
        content={({ eventsFields }) => (
          <MeterAggregation formRenderProps={formRenderProps} readonly={readonly} eventsFields={eventsFields} />
        )}
        readonly={readonly}
        formRenderProps={formRenderProps}
        isUpdate={isUpdate}
        {...contentProps}
      />
    ),
    isNextValid: isEmpty(formRenderProps.errors.aggregation),
    hideFooter: true,
  },
  {
    title: t('features.unitLabelsTitle'),
    content: (contentProps) => (
      <StepContent
        title={t('features.unitLabelsTitle')}
        subtitle={t('features.unitLabelsSubtitle')}
        content={() => <MeterUnitLabels formRenderProps={formRenderProps} readonly={readonly} />}
        readonly={readonly}
        formRenderProps={formRenderProps}
        isUpdate={isUpdate}
        {...contentProps}
      />
    ),
    isNextValid: isEmpty(formRenderProps.errors.featureUnits) && isEmpty(formRenderProps.errors.featureUnitsPlural),
    hideFooter: true,
  },
];
