import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { GridFlex, FormRenderProps } from '@stigg-components';
import { Currency, PlanFragment, PlanListFragment, TrialPeriodUnits } from '@stigg-types/apiTypes';
import { calculateTrialEndDate, TrialConfigPresentationModel } from '@stigg-common';
import { SubscriptionFormFields } from '../subscriptionForm/SubscriptionForm.types';
import { TrialDuration } from './TrialDuration';
import { AdditionalInputLayout, BudgetSpendingConfiguration } from '../subscriptionForm/components';
import { TrialFootnotes } from './TrialFootnotes';

type TrialSubscriptionConfigProps = {
  currentCurrency: Currency;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
  currentPlan?: PlanListFragment | PlanFragment;
  trialEndDate?: Date;
  isInTrial?: boolean;
  disabled?: boolean;
  isBudgetConfigurationEnabled?: boolean;
};

export const TrialSubscriptionConfig = ({
  currentCurrency,
  formRenderProps,
  currentPlan,
  trialEndDate,
  isInTrial,
  disabled,
  isBudgetConfigurationEnabled = false,
}: TrialSubscriptionConfigProps) => {
  const {
    setFieldValue,
    values: { isTrial, startDate },
  } = formRenderProps;
  const [duration, setDuration] = useState<number>(30);
  const [units, setUnits] = useState<TrialPeriodUnits.Day | TrialPeriodUnits.Month>(TrialPeriodUnits.Day);

  const trialConfigModel = useMemo(
    () =>
      new TrialConfigPresentationModel(
        {
          currentPlan,
          isTrialEnabled: !!isTrial,
          startDate,
          trialEndDate,
        },
        {
          setUnits,
          setDuration,
          setEndDate: (value: any) => setFieldValue('endDate', value),
        },
      ),
    [currentPlan, isTrial, startDate, trialEndDate, setFieldValue],
  );

  useEffect(() => {
    trialConfigModel.populateTrialSettings();
  }, [currentPlan, trialConfigModel]);

  const handleDurationChange = (duration: number) => {
    const resolvedDuration = duration > 0 ? duration : 0;
    setFieldValue('endDate', calculateTrialEndDate(startDate, resolvedDuration, TrialPeriodUnits.Day));
    setDuration(resolvedDuration);
  };

  const handleEndDateChange = (value: Date) => {
    setFieldValue('endDate', value);
    const duration = moment(value).diff(startDate, 'days');
    handleDurationChange(duration);
  };

  const handleAddTrial = () => {
    if (!trialConfigModel.cannotStartTrial()) {
      setFieldValue('isTrial', true);
      setFieldValue('budget', undefined);
      setFieldValue('minimumSpend', undefined);
    }
  };

  const handleRemoveTrial = () => {
    setFieldValue('endDate', null);
    setFieldValue('isTrial', false);
    setFieldValue('budget', undefined);
    setFieldValue('minimumSpend', undefined);
  };

  const isDisabled = trialConfigModel.isTrialDisabled();

  return (
    <GridFlex.Column pt={6}>
      <AdditionalInputLayout
        isSet={!!isTrial}
        title={t('subscriptionForm.trialSectionTitle')}
        setIcon="HourglassEmpty"
        unsetIcon="HourglassEmptyDisabled"
        setIconType="custom"
        unsetIconType="custom"
        setLabel={t('subscriptionForm.freeTrial.label')}
        buttonLabel={t('sharedComponents.add')}
        formRenderProps={formRenderProps}
        onSet={disabled ? undefined : handleAddTrial}
        onUnset={isInTrial ? undefined : handleRemoveTrial}
        inputComponent={
          <TrialDuration
            isDisabled={isDisabled}
            duration={duration}
            handleDurationChange={handleDurationChange}
            endDateUnits={units}
            handleEndDateChange={handleEndDateChange}
            startDate={startDate}
          />
        }
      />
      {isTrial && isBudgetConfigurationEnabled && (
        <BudgetSpendingConfiguration
          currentCurrency={currentCurrency}
          formRenderProps={formRenderProps}
          withMinimumSpend={false}
          withHeader={false}
          maxSpendEndAdornment="currency"
          maxSpendPlaceholder={t('subscriptionForm.trialMaximumSpendPlaceholder')}
        />
      )}
      {isTrial && <TrialFootnotes trialConfigModel={trialConfigModel} currentPlan={currentPlan} />}
    </GridFlex.Column>
  );
};
