import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import { AddonFilter, FetchSlimAddonsQuery, FetchSlimAddonsQueryVariables } from '@stigg-types/apiTypes';
import { applyEnvironmentFilter } from '@stigg-common';
import { Navigation } from '../navigation/useNavigation';
import { apolloClient } from '../../ApolloClient';
import { MAX_RESULTS_PER_COMMANDBAR_SEARCH } from './useCommandBar';
import { executeSafely } from './utils';
import { FETCH_SLIM_ADDONS } from '../packages/addons/queries/fetchAddons';
import { getCommandBarIcon } from './icons/getCommandBarIcon';
import addonsIcon from './icons/addons.svg';

export function setAddonsSearch(environmentId: string, navigation: Navigation) {
  const doFetchAddons: SearchFunction = async (search: string) => {
    const filter: AddonFilter = {
      environmentId: { eq: environmentId },
      isLatest: { is: true },
    };
    if (search) {
      filter.or = [
        { displayName: { iLike: `%${search}%` } },
        { refId: { iLike: `%${search}%` } },
        { billingId: { iLike: `%${search}%` } },
      ].map((searchFilter) => applyEnvironmentFilter<AddonFilter>(searchFilter, environmentId));
    }

    const response = await apolloClient.query<FetchSlimAddonsQuery, FetchSlimAddonsQueryVariables>({
      query: FETCH_SLIM_ADDONS,
      fetchPolicy: 'network-only',
      variables: {
        paging: { first: MAX_RESULTS_PER_COMMANDBAR_SEARCH },
        filter,
      },
    });

    const addons = response.data.addons.edges;
    return addons.map((addon) => {
      const { refId, displayName } = addon.node;
      return { label: displayName || refId, description: displayName ? refId : '' };
    });
  };

  window.CommandBar.addRecords('addons', [], {
    onInputChange: executeSafely(doFetchAddons),
    defaultIcon: getCommandBarIcon(addonsIcon),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.addonPage('{{record.addonId}}'),
    },
  });
}
