import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
  BillingPeriod,
  CustomerResponseFragment,
  CustomerSubscriptionDataFragment,
  PaymentCollectionMethod,
  PlanFragment,
  PlanListFragment,
  PricingType,
  ProrationBehavior,
  SubscriptionAddonInput,
} from '@stigg-types/apiTypes';
import { Alert, Grid, GridFlex, Skeleton, Text } from '@stigg-components';
import { FormikState } from 'formik';
import moment from 'moment';
import isNil from 'lodash/isNil';
import { useIntegrations } from '@stigg-common';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import { SubscriptionAddon, SubscriptionFormFields } from '../SubscriptionForm.types';
import { previewSubscriptionAction } from '../../../../../customersSlice';
import { mapBillableFeaturesToBillableFeaturesInput } from '../SubscriptionForm.utils';
import { currencyPriceFormatter } from '../../../../../../packages/pricing/components/currency/currencyUtils';
import { mapSubscriptionToBillableFeatures, useSubscriptionPriceBreakdown } from '../../priceBreakdown';

export type SubscriptionUpdateEstimationsAlertProps = {
  customer: CustomerResponseFragment;
  values: FormikState<SubscriptionFormFields>['values'];
  plan: PlanListFragment | PlanFragment;
  subscription: CustomerSubscriptionDataFragment | null | undefined;
  isNewSubscription: boolean;
};

export const SubscriptionUpdateEstimationsAlert = ({
  plan,
  customer,
  values,
  subscription,
  isNewSubscription,
}: SubscriptionUpdateEstimationsAlertProps) => {
  const dispatch = useAppDispatch();
  const { hasBillingIntegration } = useIntegrations();
  const isLoading = useSelector((state: RootState) => state.customersReducer.isLoadingPreviewSubscriptionAction);
  const previewSubscriptionResult = useSelector((state: RootState) => state.customersReducer.previewSubscriptionResult);
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const { billableFeatures, addons, planId, billingCountryCode, billingPeriod } = values;
  useEffect(() => {
    const addonsInput: SubscriptionAddonInput[] = (addons || []).map((addon) => ({
      addonId: addon.refId,
      quantity: addon.quantity,
    }));

    void dispatch(
      previewSubscriptionAction({
        customerId: customer.customerId,
        planId,
        addons: addonsInput,
        billableFeatures: mapBillableFeaturesToBillableFeaturesInput(billableFeatures),
        billingCountryCode,
        billingPeriod,
      }),
    );
  }, [dispatch, addons, billableFeatures, customer, planId, billingCountryCode, billingPeriod]);

  const { priceBreakdown: originalPriceBreakdown } = useSubscriptionPriceBreakdown({
    plan: subscription?.plan,
    billableFeatures: mapSubscriptionToBillableFeatures(subscription || {}),
    addons: subscription?.addons?.map((addon) => ({ ...addon, ...addon.addon } as SubscriptionAddon)) || [],
    billingPeriod,
    billingCountryCode,
    coupon: customer?.coupon,
    subscriptionCoupon: subscription?.coupon,
    minimumSpend: subscription?.minimumSpend?.minimum?.amount,
  });

  const { priceBreakdown: updatedPriceBreakdown } = useSubscriptionPriceBreakdown({
    plan,
    billableFeatures,
    addons,
    billingPeriod,
    billingCountryCode,
    coupon: customer?.coupon,
    subscriptionCoupon: subscription?.coupon,
    minimumSpend: subscription?.minimumSpend?.minimum?.amount,
  });

  if (
    !hasBillingIntegration ||
    plan?.pricingType === PricingType.Custom ||
    values?.paymentCollectionMethod === PaymentCollectionMethod.None
  ) {
    return null;
  }

  if (!previewSubscriptionResult) {
    return null;
  }

  const { immediateInvoice, billingPeriodRange, recurringInvoice } = previewSubscriptionResult;

  if ((!originalPriceBreakdown && !isNewSubscription) || !plan) {
    return null;
  }

  const totalDiff =
    !isNewSubscription && updatedPriceBreakdown
      ? updatedPriceBreakdown.total - (originalPriceBreakdown?.total || 0)
      : immediateInvoice.proration
      ? immediateInvoice.proration.netAmount.amount
      : 0;

  const hasImmediateInvoice = (immediateInvoice?.total?.amount || 0) > 0;
  const receivedCredits =
    immediateInvoice.proration &&
    immediateInvoice.proration.hasProrations &&
    immediateInvoice.proration.netAmount.amount < 0;
  const hasIncreaseOrDecrease = totalDiff !== 0;

  if ((!hasImmediateInvoice && !hasIncreaseOrDecrease && !receivedCredits) || isNil(account)) {
    return null;
  }

  const { subscriptionProrationBehavior } = account;
  const shouldProrateAtEndOfBillingPeriod = subscriptionProrationBehavior === ProrationBehavior.CreateProrations;
  const prorateAtNextPeriodCurrentPeriodLeftover = shouldProrateAtEndOfBillingPeriod
    ? (immediateInvoice.proration?.netAmount.amount || 0) - (recurringInvoice?.total?.amount || 0)
    : 0;

  return (
    <Grid item my={4}>
      <Alert
        icon={<></>}
        severity="info"
        sx={{ width: '100%', ...(isLoading ? { [`> div[class$="message"]`]: { width: '100%' } } : {}) }}>
        {isLoading ? (
          <>
            <Skeleton variant="text" animation="wave" width="100%" />
            <Skeleton variant="text" animation="wave" width="100%" />
          </>
        ) : (
          <GridFlex.Column>
            {recurringInvoice && recurringInvoice.total.amount > 0 && (
              <GridFlex.Item>
                <Trans
                  t={t}
                  i18nKey="subscriptionForm.subscriptionUpdateEstimationsDumbCalcCharge"
                  values={{
                    billingPeriod: t(`pricing.shortBillingPeriodPriceDescription.${billingPeriod}`),
                    total: currencyPriceFormatter({
                      amount: Math.abs(recurringInvoice.total.amount),
                      currency: recurringInvoice.total.currency,
                      options: { withCodePostfix: true },
                    }),
                    startDate: `${
                      billingPeriod === BillingPeriod.Monthly
                        ? `the ${moment(billingPeriodRange?.end).format('Do')}`
                        : moment(billingPeriodRange?.end).format('MMMM Do')
                    }
                `.trimEnd(),
                  }}
                  components={[<Text.B2 $bold display="inline" />]}
                />
              </GridFlex.Item>
            )}
            <GridFlex.Item>
              {shouldProrateAtEndOfBillingPeriod &&
              immediateInvoice.proration &&
              immediateInvoice.proration.hasProrations ? (
                prorateAtNextPeriodCurrentPeriodLeftover > 0 ? (
                  <Trans
                    t={t}
                    i18nKey="subscriptionForm.subscriptionUpdateProrationCharge"
                    values={{
                      charge: currencyPriceFormatter({
                        amount: Math.abs(prorateAtNextPeriodCurrentPeriodLeftover),
                        currency: immediateInvoice.proration?.netAmount.currency,
                        options: { withCodePostfix: true },
                      }),
                    }}
                    components={[<Text.B2 $bold display="inline" />]}
                  />
                ) : (
                  <Trans
                    t={t}
                    i18nKey="subscriptionForm.subscriptionUpdateRefund"
                    values={{
                      refund: currencyPriceFormatter({
                        amount: Math.abs(prorateAtNextPeriodCurrentPeriodLeftover),
                        currency: immediateInvoice.proration?.netAmount.currency,
                        options: { withCodePostfix: true },
                      }),
                    }}
                    components={[<Text.B2 $bold display="inline" />]}
                  />
                )
              ) : immediateInvoice?.total?.amount > 0 ? (
                <Trans
                  t={t}
                  i18nKey="subscriptionForm.subscriptionUpdateEstimationsImmediateCharge"
                  values={{
                    total: currencyPriceFormatter({
                      amount: Math.abs(immediateInvoice?.total?.amount),
                      currency: immediateInvoice?.total?.currency,
                      options: { withCodePostfix: true },
                    }),
                  }}
                  components={[<Text.B2 $bold display="inline" />]}
                />
              ) : receivedCredits ? (
                <Trans
                  t={t}
                  i18nKey="subscriptionForm.subscriptionUpdateRefund"
                  values={{
                    refund: currencyPriceFormatter({
                      amount: Math.abs(immediateInvoice?.proration?.netAmount?.amount || 0),
                      currency: immediateInvoice?.proration?.netAmount?.currency,
                      options: { withCodePostfix: true },
                    }),
                  }}
                  components={[<Text.B2 $bold display="inline" />]}
                />
              ) : null}
            </GridFlex.Item>
          </GridFlex.Column>
        )}
      </Alert>
    </Grid>
  );
};
