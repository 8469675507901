import { FieldArray } from 'formik';
import { t } from 'i18next';
import get from 'lodash/get';
import { ConditionOperation } from '@stigg-types/apiTypes';
import { Icon, Button, GridFlex, Box, Text, CustomRenderProps } from '@stigg-components';
import { Filter, Condition, CreateFeatureFormFields, DEFAULT_CONDITION } from './SidenavCreateFeature';
import { UpdateFeatureFields } from '../featureDetails/FeatureDetailsTab';
import { EventsFieldsResult } from '../../queries/fetchEventsFields';
import { FieldAutocomplete } from './FieldAutocomplete';

type ConditionsBoxProps<T extends CreateFeatureFormFields | UpdateFeatureFields> = {
  formRenderProps: CustomRenderProps<T>;
  conditions: Condition[];
  index: number;
  onRemoveRule: () => void;
  hideRemoveRule: boolean;
  readonly?: boolean;
  eventsFields: EventsFieldsResult;
};

function ConditionsBox<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  formRenderProps,
  conditions,
  index,
  onRemoveRule,
  hideRemoveRule,
  readonly,
  eventsFields,
}: ConditionsBoxProps<T>) {
  const defaultPossibleFields = Object.keys(eventsFields).map((key) => ({
    displayName: key,
    value: key,
  }));
  return (
    <GridFlex.Column sx={{ p: 4, borderRadius: '10px', backgroundColor: (theme) => theme.itamar.palette.grey[25] }}>
      <Text.B2 mb={1}>Events with</Text.B2>
      <FieldArray name={`filters[${index}].conditions`}>
        {({ push, remove }) => (
          <GridFlex.Column rowGap={2}>
            {conditions.map((condition, conditionIndex) => {
              const meterFieldKey = `filters[${index}].conditions[${conditionIndex}].field`;
              const meterFieldValue = `filters[${index}].conditions[${conditionIndex}].value`;
              const isFirst = conditionIndex === 0;
              const shouldHideValue = [ConditionOperation.IsNull, ConditionOperation.IsNotNull].includes(
                condition.operation,
              );
              return (
                <GridFlex.RowCenter key={conditionIndex} columnGap={2} $fullWidth alignItems="flex-start">
                  <GridFlex.RowCenter columnGap={2} sx={{ flex: 1 }}>
                    {!isFirst && <Text.Caption>{t('features.meterFilterAnd')}</Text.Caption>}
                    <FieldAutocomplete
                      defaultValue="eventName"
                      formRenderProps={formRenderProps}
                      readonly={readonly}
                      fieldKey={meterFieldKey}
                      possibleOptions={defaultPossibleFields}
                    />
                  </GridFlex.RowCenter>
                  <GridFlex.RowCenter sx={{ flex: 1 }}>
                    {formRenderProps.renderSelect(
                      `filters[${index}].conditions[${conditionIndex}].operation`,
                      '',
                      Object.values(ConditionOperation).map((operation) => ({
                        value: operation,
                        displayValue: t(`features.conditionOperation.${operation}`),
                      })),
                      {
                        disabled: readonly,
                      },
                    )}
                  </GridFlex.RowCenter>
                  <GridFlex.RowCenter sx={{ flex: 1 }}>
                    {!shouldHideValue ? (
                      <FieldAutocomplete
                        formRenderProps={formRenderProps}
                        readonly={readonly}
                        fieldKey={meterFieldValue}
                        possibleOptions={
                          eventsFields[get(formRenderProps.values, meterFieldKey)]?.map((value) => ({
                            displayName: value,
                            value,
                          })) || []
                        }
                      />
                    ) : null}
                  </GridFlex.RowCenter>
                  <GridFlex.RowCenter sx={{ width: 24 }} alignSelf="center">
                    {!isFirst && !readonly && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => remove(conditionIndex)}
                        sx={{ cursor: 'pointer' }}>
                        <Icon type="reactFeather" icon="X" color="active" />
                      </Box>
                    )}
                  </GridFlex.RowCenter>
                </GridFlex.RowCenter>
              );
            })}
            {!readonly && (
              <GridFlex.RowCenter justifyContent="space-between">
                <Button
                  startIcon={<Icon type="reactFeather" icon="Plus" color="primary.main" size={16} />}
                  onClick={() => push(DEFAULT_CONDITION)}>
                  {t('features.meterFilterAnd')}
                </Button>
                {!hideRemoveRule && (
                  <Button
                    endIcon={<Icon type="reactFeather" icon="X" color="primary.main" size={16} />}
                    onClick={onRemoveRule}>
                    Remove rule
                  </Button>
                )}
              </GridFlex.RowCenter>
            )}
          </GridFlex.Column>
        )}
      </FieldArray>
    </GridFlex.Column>
  );
}

type FiltersProps<T extends CreateFeatureFormFields | UpdateFeatureFields> = {
  filters: Filter[];
  formRenderProps: CustomRenderProps<T>;
  readonly?: boolean;
  eventsFields: EventsFieldsResult;
};

export function MeterFilters<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  filters,
  formRenderProps,
  readonly,
  eventsFields,
}: FiltersProps<T>) {
  return (
    <FieldArray name="filters">
      {({ push, remove }) => {
        return (
          <GridFlex.Column>
            {filters.map((filter, index) => (
              <GridFlex.Column key={index}>
                <ConditionsBox
                  conditions={filter.conditions}
                  eventsFields={eventsFields}
                  index={index}
                  onRemoveRule={() => remove(index)}
                  formRenderProps={formRenderProps}
                  hideRemoveRule={filters.length === 1}
                  readonly={readonly}
                />
                {index !== filters.length - 1 && <Text.Caption my={4}>{t('features.meterFilterOr')}</Text.Caption>}
              </GridFlex.Column>
            ))}
            {!readonly && (
              <GridFlex.RowCenter mt={2}>
                <Button
                  startIcon={<Icon type="reactFeather" icon="Plus" color="primary.main" size={16} />}
                  onClick={() => push({ conditions: [DEFAULT_CONDITION] })}>
                  {t('features.meterFilterOr')}
                </Button>
              </GridFlex.RowCenter>
            )}
          </GridFlex.Column>
        );
      }}
    </FieldArray>
  );
}
