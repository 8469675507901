import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import {
  FeatureFilter,
  FeatureStatus,
  FetchSlimFeaturesQuery,
  FetchSlimFeaturesQueryVariables,
} from '@stigg-types/apiTypes';
import { applyEnvironmentFilter } from '@stigg-common';
import { Navigation } from '../navigation/useNavigation';
import { apolloClient } from '../../ApolloClient';
import { executeSafely } from './utils';
import { MAX_RESULTS_PER_COMMANDBAR_SEARCH } from './useCommandBar';
import { FETCH_SLIM_FEATURES } from '../features/queries/fetchFeatures';
import { getCommandBarIcon } from './icons/getCommandBarIcon';
import featuresIcon from './icons/features.svg';

export function setFeaturesFetch(environmentId: string, navigation: Navigation) {
  const doFetchFeatures: SearchFunction = async (search: string) => {
    const filter: FeatureFilter = {
      environmentId: { eq: environmentId },
      featureStatus: { neq: FeatureStatus.Suspended },
    };

    if (search) {
      filter.or = [
        { displayName: { iLike: `%${search}%` } },
        { refId: { iLike: `%${search}%` } },
        { billingId: { iLike: `%${search}%` } },
      ].map((searchFilter) => applyEnvironmentFilter<FeatureFilter>(searchFilter, environmentId));
    }

    const response = await apolloClient.query<FetchSlimFeaturesQuery, FetchSlimFeaturesQueryVariables>({
      query: FETCH_SLIM_FEATURES,
      fetchPolicy: 'network-only',
      variables: {
        paging: { first: MAX_RESULTS_PER_COMMANDBAR_SEARCH },
        filter,
      },
    });
    const features = response.data.features.edges;
    return features.map((feature) => {
      const { refId: featureId, displayName } = feature.node;
      return { featureId, label: displayName || featureId, description: displayName ? featureId : '' };
    });
  };

  window.CommandBar.addRecords('features', [], {
    onInputChange: executeSafely(doFetchFeatures),
    defaultIcon: getCommandBarIcon(featuresIcon),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.featurePage('{{record.featureId}}'),
    },
  });
}
