import { EventLog } from '@stigg-types/apiTypes';
import { GridFlex, Link, Text } from '@stigg-components';
import { useNavigation } from '../../../navigation/useNavigation';
import { getEntityDetails } from '../../utils/entity.utils';

export default function EventEntity({ event, ignoreRoute }: { event: EventLog; ignoreRoute?: boolean }) {
  const navigation = useNavigation();

  const { primary, secondary, subtitle, route } = getEntityDetails(event) || {};

  if (!primary) {
    return null;
  }

  const shouldLink = !!route && !ignoreRoute;

  const primaryText = <Text.B2 color={shouldLink ? 'primary.main' : undefined}>{primary}</Text.B2>;

  return (
    <GridFlex.Column gap={1}>
      <GridFlex.Row gap={1}>
        {shouldLink ? (
          <Link onClick={() => navigation.navigateTo(route)}>{primaryText}</Link>
        ) : (
          <Text.B2>{primaryText}</Text.B2>
        )}
        {secondary && <Text.B2>({secondary})</Text.B2>}
      </GridFlex.Row>
      {subtitle && <Text.Sub2 color="secondary">{subtitle}</Text.Sub2>}
    </GridFlex.Column>
  );
}
