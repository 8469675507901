import _ from 'lodash';
import { save, load, clear } from 'redux-localstorage-simple';
import { initialState as accountInitialState } from './modules/accounts/accountsSlice';
import { initialState as applicationInitialState } from './modules/application/applicationSlice';

const namespace = 'stigg';
const accountReducerName = 'accountReducer';
const appReducerName = 'application';

function loadStateFromLocalStorage() {
  return load({
    namespace,
    states: [`${accountReducerName}.currentEnvironmentId`, `${appReducerName}.isDrawerExpanded`],
    // If we don't set preload state for the rest of the state it will be overridden with undefined values :/
    preloadedState: {
      [accountReducerName]: _.omit(accountInitialState, 'currentEnvironmentId'),
      [appReducerName]: _.omit(applicationInitialState, 'isDrawerExpanded'),
    },
  });
}

function saveStateToLocalStorage() {
  return save({
    namespace,
    states: [`${accountReducerName}.currentEnvironmentId`, `${appReducerName}.isDrawerExpanded`],
  });
}
// currently not clearing state on log out. Beware of adding more states here as we always want
// to keep the currentEnvironmentId
const clearStateFromLocalStorage = () => {
  clear({ namespace });
};

export { loadStateFromLocalStorage, saveStateToLocalStorage, clearStateFromLocalStorage };
