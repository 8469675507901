import { t } from 'i18next';
import { EventLog } from '@stigg-types/apiTypes';
import { GridFlex, ClipboardJSON, CustomDrawer, ClipboardChip } from '@stigg-components';
import { getEventName } from '../../utils/eventName.utils';
import EventActorSection from './EventActorSection';
import EventDetailsSection from './EventDetailsSection';
import EventLogDetailsFooter from './EventLogDetailsFooter';

export type EventLogDetailsProps = {
  event: EventLog | null | undefined;
  onClose: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
};

export default function EventLogDetails({ event, onClose, onPrevious, onNext }: EventLogDetailsProps) {
  const title = event?.eventLogType ? getEventName(event.eventLogType) : undefined;
  const copyEventButton = <ClipboardChip copy={window.location.href} tooltipSuffix="event link" variant="button" />;

  return (
    <CustomDrawer
      title={title}
      additionalHeaderComponents={copyEventButton}
      isOpen={!!event}
      onClose={onClose}
      variant="persistent"
      noOverlay>
      <GridFlex.Column $fullHeight>
        <GridFlex.Column flex={1} gap={6}>
          <EventDetailsSection event={event} />
          <EventActorSection actor={event?.actor} />
          <ClipboardJSON
            title={t('eventsLog.detailsDrawer.payload')}
            json={event?.payload}
            style={{
              paddingRight: 18, // prevent horizontal scrollbar
              maxHeight: 350, // TODO: try to make this span all available height
            }}
          />
        </GridFlex.Column>
        <EventLogDetailsFooter onClose={onClose} onPrevious={onPrevious} onNext={onNext} />
      </GridFlex.Column>
    </CustomDrawer>
  );
}
