import { t } from 'i18next';
import { ResetPeriodConfiguration as ApiResetPeriodConfiguration } from '@stigg-types/apiTypes';
import { CustomDrawer, Field, Form, useExitConfirmationDialog } from '@stigg-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { generateRandomSlug } from '@stigg-common';
import { useState } from 'react';
import { EntitlementsTable } from './EntitlementsTable';
import { EntitlementSettings } from '../../../entitlements/components/entitlementSettings/EntitlementSettings';
import { entitlementsValidationSchema } from './EntitlementsForm';
import { PlanEntitlement } from '../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { EntitlementFormFields } from '../../../entitlements/components/entitlementSettings/types';
import { useSubStepsState } from '../../pricing/components/SetPriceWizard/form/utils/useSubStepsState';

type EditEntitlementDialogProps = {
  open: boolean;
  handleClose: () => void;
  entitlement: PlanEntitlement | null;
  handleSubmit: (values: any) => void;
  withCustomEntitlementOption?: boolean;
  withEntitlementBehaviourOption?: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
};

export function getResetPeriodConfig(resetPeriodConfiguration: ApiResetPeriodConfiguration) {
  switch (resetPeriodConfiguration.__typename) {
    case 'YearlyResetPeriodConfig':
      return resetPeriodConfiguration.yearlyAccordingTo;
    case 'MonthlyResetPeriodConfig':
      return resetPeriodConfiguration.monthlyAccordingTo;
    case 'WeeklyResetPeriodConfig':
      return resetPeriodConfiguration.weeklyAccordingTo;
    default:
      return null;
  }
}

function EditEntitlementForm({
  handleClose,
  entitlement,
  handleSubmit,
  withCustomEntitlementOption,
  withEntitlementBehaviourOption,
  setHasUnsavedChanges,
}: Omit<EditEntitlementDialogProps, 'open'>) {
  const { entitlementsSettingsImprovedUi } = useFlags<FeatureFlags>();
  const entitlementsState = useSubStepsState();

  if (!entitlement) {
    return null;
  }

  const initialValues: EntitlementFormFields = {
    entitlements: [
      {
        ...entitlement,
        uuid: generateRandomSlug(),
        isConfirmed: true,
        resetPeriodConfiguration: entitlement.resetPeriodConfiguration
          ? getResetPeriodConfig(entitlement.resetPeriodConfiguration)
          : null,
      },
    ],
  };

  return (
    <Form
      withStickyFooter
      initialValues={initialValues}
      submitButtonText={t('sharedComponents.editSaveButton')}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      validationSchema={entitlementsValidationSchema}
      setHasUnsavedChanges={setHasUnsavedChanges}
      fields={(formRenderProps) =>
        entitlementsSettingsImprovedUi
          ? formRenderProps.values.entitlements.map(
              (_, index): Field<EntitlementFormFields> => ({
                type: 'custom',
                render: () => (
                  <EntitlementSettings
                    index={index}
                    excludedFeaturesIds={[]}
                    entitlementsState={entitlementsState}
                    withCustomEntitlementOption={withCustomEntitlementOption}
                    withEntitlementBehaviourOption={withEntitlementBehaviourOption}
                    formRenderProps={formRenderProps}
                    permanent
                  />
                ),
              }),
            )
          : [
              {
                type: 'custom',
                render: () => (
                  <EntitlementsTable
                    errors={formRenderProps.errors}
                    touched={formRenderProps.touched}
                    handleBlur={formRenderProps.handleBlur}
                    entitlements={formRenderProps.values.entitlements}
                    handleChange={formRenderProps.handleChange}
                    height={330}
                    setFieldValue={formRenderProps.setFieldValue}
                    setFieldTouched={formRenderProps.setFieldTouched}
                    withCustomEntitlementOption={withCustomEntitlementOption}
                  />
                ),
              },
            ]
      }
    />
  );
}

export function EditEntitlementDrawer({
  open,
  handleClose,
  entitlement,
  handleSubmit,
  withCustomEntitlementOption,
  withEntitlementBehaviourOption,
}: Omit<EditEntitlementDialogProps, 'setHasUnsavedChanges'>) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { exitConfirmationDialog, onExit } = useExitConfirmationDialog({
    onClose: handleClose,
    shouldConfirm: open && hasUnsavedChanges,
  });

  return (
    <>
      <CustomDrawer title={t('entitlements.editEntitlement')} onClose={onExit} isOpen={open}>
        <EditEntitlementForm
          handleClose={onExit}
          entitlement={entitlement}
          handleSubmit={handleSubmit}
          withCustomEntitlementOption={withCustomEntitlementOption}
          withEntitlementBehaviourOption={withEntitlementBehaviourOption}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      </CustomDrawer>

      {exitConfirmationDialog}
    </>
  );
}
