import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem, Text, Fade, Divider, Button, Checkbox, GridFlex } from '@stigg-components';
import isEqual from 'lodash/isEqual';
import { t } from 'i18next';
import {
  StyledChip,
  StyledPopper,
  StyledBackdrop,
  StyledChevronDown,
  StyledPopperContainer,
} from './CustomerFilterChip.style';
import type { CustomerFilterChipOption, CustomerFilterChipProps } from './CustomerFilterChip.types';

const POPPER_MODIFIERS = [{ name: 'offset', options: { offset: [0, 10] } }];

const MAX_ITEMS_IN_CHIP_LABEL = 2;

const CustomerFilterChipOptionMenuItem = <T extends string>({
  option,
  toggleValue,
  checkedValuesSet,
}: {
  option: CustomerFilterChipOption<T>;
  toggleValue: (value: T) => void;
  checkedValuesSet: Set<T>;
}): JSX.Element => (
  <MenuItem key={option.value} onClick={() => toggleValue(option.value)}>
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      onChange={() => toggleValue(option.value)}
      checked={checkedValuesSet.has(option.value)}
      label={option.checkboxLabel ?? <Text.B2 color="primary">{option.label()}</Text.B2>}
    />
  </MenuItem>
);

export const CustomerFilterChip = <T extends string>({
  label,
  value,
  options,
  onChange,
}: CustomerFilterChipProps<T>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [chipAnchorEl, setChipAnchorEl] = useState<null | HTMLElement>(null);

  const [checkedValues, setCheckedValues] = useState([...value]);
  const checkedValuesSet = useMemo(() => new Set(checkedValues), [checkedValues]);

  useEffect(() => {
    if (!open) {
      return;
    }

    setCheckedValues([...value]);
  }, [value, open]);

  const onChipClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onCancelClick = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmitClick = useCallback(() => {
    setOpen(false);

    if (isEqual(checkedValues, value)) {
      return;
    }

    onChange(checkedValues);
  }, [checkedValues, onChange, value]);

  const clearFilters = useCallback(() => {
    if (checkedValues.length === 0) {
      return;
    }

    setCheckedValues([]);
  }, [checkedValues]);

  const optionValueToLabel = useMemo<Record<T, CustomerFilterChipOption<T>['label']>>(
    () =>
      options.reduce<Record<T, CustomerFilterChipOption<T>['label']>>(
        (acc, option) => ({
          ...acc,
          ...(option.type === 'group'
            ? {
                ...option.options.reduce<Record<T, CustomerFilterChipOption<T>['label']>>(
                  (groupAcc, groupOption) => ({
                    ...groupAcc,
                    [groupOption.value]: groupOption.label,
                  }),
                  {} as Record<T, CustomerFilterChipOption<T>['label']>,
                ),
              }
            : { [option.value]: option.label }),
        }),
        {} as Record<T, CustomerFilterChipOption<T>['label']>,
      ),
    [options],
  );

  const [valueLabel, extraValuesAmount] = useMemo(() => {
    if (!value.length) {
      return ['any', 0];
    }

    const valuesToDisplay: string[] = [];
    let newExtraValuesAmount = 0;

    if (value.length > MAX_ITEMS_IN_CHIP_LABEL) {
      for (let i = 0; i < MAX_ITEMS_IN_CHIP_LABEL; i += 1) {
        valuesToDisplay.push(value[i]);
      }

      newExtraValuesAmount = value.length - MAX_ITEMS_IN_CHIP_LABEL;
    } else {
      valuesToDisplay.push(...value);
    }

    return [valuesToDisplay.map((value) => optionValueToLabel[value]?.() ?? value).join(', '), newExtraValuesAmount];
  }, [value, optionValueToLabel]);

  const toggleValue = useCallback(
    (valueToToggle: T) => {
      if (checkedValuesSet.has(valueToToggle)) {
        setCheckedValues((values) => values.filter((optionValue) => optionValue !== valueToToggle));
        return;
      }

      setCheckedValues((values) => [...values, valueToToggle]);
    },
    [checkedValuesSet],
  );

  return (
    <>
      <StyledChip
        ref={setChipAnchorEl}
        size="medium"
        label={
          <GridFlex.Row>
            <Text.B2 color="primary">{`${label}: ${valueLabel}`}</Text.B2>
            {extraValuesAmount > 0 && <Text.B2 color="secondary" ml={1}>{`(+${extraValuesAmount} more)`}</Text.B2>}
            <GridFlex.Row ml={1} alignItems="center">
              <StyledChevronDown size={16} />
            </GridFlex.Row>
          </GridFlex.Row>
        }
        color="background.lightBackground2"
        onClick={onChipClick}
        textColor="primary"
      />

      <StyledBackdrop invisible open={open} onClick={onCancelClick} />
      <StyledPopper
        open={open}
        anchorEl={chipAnchorEl}
        placement="bottom-start"
        modifiers={POPPER_MODIFIERS}
        transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <StyledPopperContainer>
              <GridFlex.RowSpaceBetween mb={1}>
                <Text.B2 $medium color="primary">
                  {label}
                </Text.B2>
                <Button color="white" onClick={clearFilters}>
                  {t('customers.filters.clearAllLabel')}
                </Button>
              </GridFlex.RowSpaceBetween>
              {options.map((option) =>
                option.type === 'option' ? (
                  <CustomerFilterChipOptionMenuItem
                    key={option.value}
                    option={option}
                    toggleValue={toggleValue}
                    checkedValuesSet={checkedValuesSet}
                  />
                ) : (
                  <GridFlex.Column key={option.label()} mt={3.5}>
                    <Text.B2 color="secondary">{option.label()}</Text.B2>
                    {option.options.map((optionInGroup) => (
                      <CustomerFilterChipOptionMenuItem
                        key={optionInGroup.value}
                        option={optionInGroup}
                        toggleValue={toggleValue}
                        checkedValuesSet={checkedValuesSet}
                      />
                    ))}
                  </GridFlex.Column>
                ),
              )}
              <Divider />
              <GridFlex.Row gap={2} justifyContent="center" mt={3.5}>
                <Button size="small" $outlined color="primary" onClick={onCancelClick}>
                  {t('customers.filters.cancelLabel')}
                </Button>
                <Button size="small" color="primary" variant="contained" onClick={onSubmitClick}>
                  {t('customers.filters.submitLabel')}
                </Button>
              </GridFlex.Row>
            </StyledPopperContainer>
          </Fade>
        )}
      </StyledPopper>
    </>
  );
};
