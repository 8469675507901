import React, { useMemo } from 'react';
import { t } from 'i18next';
import { Feature, Plan } from '@stigg-types/apiTypes';
import { DateRange, Filter, TimeDimensionGranularity } from '@cubejs-client/core';
import '../../../../common/chartjs/chartjs.plugins';
import { useCubeQuery } from '@cubejs-client/react';
import { Line } from 'react-chartjs-2';
import {
  composeFeatureDailyUsageQuery,
  getGranularity,
  getTimeDimension,
} from '../../../queries/medianFeatureUsageQuery';
import { WidgetCard } from '../WidgetCard';
import { mapMockDataTemplate } from './mapMockDataTemplate';
import { getEntitlement } from '../../../helpers/plan.helper';
import { ChartErrorMessage, ChartNoDataMessage, ChartPlaceholder } from '../ChartPlaceholders';
import { composeTimeBasedWidgetSubtitle } from '../../../helpers/text.helper';
import { computeChartData } from './MedianFeatureUsage.chartjs';
import { MedianFeatureUsageChartTooltip, useMedianFeatureUsageChartTooltip } from './MedianFeatureUsageChartTooltip';
import { useCubeFeatureStaticData } from '../useCubeFeatureStaticData';
import sandboxStaticCubeData from './sandbox-median-feature-query.json';
import { useIsShowMockData } from '../../../../common/hooks';
import { useSetChartLastUpdate } from '../../../../common/chartsLastUpdate/useSetChartLastUpdate';

const useCubeRealData = ({
  environmentId,
  feature,
  granularity,
  cubeName,
  dateRange,
  additionalFilters,
  skipCubeQuery,
}: {
  feature: Feature;
  environmentId: string;
  cubeName: string;
  dateRange: DateRange;
  granularity: TimeDimensionGranularity;
  additionalFilters: Filter[];
  skipCubeQuery?: boolean;
}) => {
  const query = useMemo(
    () =>
      composeFeatureDailyUsageQuery(environmentId, feature.refId, granularity, cubeName, dateRange, additionalFilters),
    [environmentId, feature.refId, granularity, cubeName, dateRange, additionalFilters],
  );

  return useCubeQuery(query, { skip: skipCubeQuery });
};

export const MedianFeatureUsageWidget = React.memo(
  ({
    environmentId,
    feature,
    plan,
    daysBack,
    showUncompletedPeriod,
    color,
  }: {
    environmentId: string;
    feature: Feature;
    plan: Plan;
    daysBack: number;
    showUncompletedPeriod: boolean;
    color: string;
  }) => {
    const isShowMockData = useIsShowMockData();
    const { tooltipInfo, tooltipHandler } = useMedianFeatureUsageChartTooltip(feature);
    const entitlement = getEntitlement(plan, feature)!;
    const cubeName = entitlement?.resetPeriod
      ? `FeatureUsageBreakdownWithResetPeriod_${entitlement.resetPeriod.toLowerCase()}`
      : 'FeatureUsageBreakdownNoResetPeriod';
    const granularity = getGranularity(entitlement);

    const { dateRange, additionalFilters, baseTime } = getTimeDimension(
      cubeName,
      entitlement,
      daysBack,
      showUncompletedPeriod,
    );

    const realData = useCubeRealData({
      environmentId,
      feature,
      granularity,
      cubeName,
      dateRange,
      additionalFilters,
      skipCubeQuery: isShowMockData,
    });

    const mappedMockData = mapMockDataTemplate(sandboxStaticCubeData);

    const mockData = useCubeFeatureStaticData({
      featureRefId: feature.refId,
      planRefId: plan.refId,
      mock: mappedMockData,
    });

    const { error, isLoading, resultSet } = isShowMockData ? mockData : realData;

    const { data, options } = useMemo(
      () => computeChartData(resultSet, feature, plan, baseTime, granularity, color, entitlement, tooltipHandler),
      [resultSet, feature, plan, baseTime, granularity, color, entitlement, tooltipHandler],
    );

    const title = t('dashboards.featureInspector.medianFeatureUse');
    const subtitle = composeTimeBasedWidgetSubtitle(entitlement, showUncompletedPeriod, daysBack);

    useSetChartLastUpdate(title, resultSet);

    let content: JSX.Element;

    if (isLoading) {
      content = <ChartPlaceholder />;
    } else if (error) {
      content = <ChartErrorMessage error={error} />;
    } else if (!resultSet || !data || !options || !entitlement) {
      content = <ChartNoDataMessage />;
    } else {
      content = (
        <>
          <MedianFeatureUsageChartTooltip tooltipInfo={tooltipInfo} />
          <Line options={options} data={data} />
        </>
      );
    }

    return (
      <WidgetCard isLoading={isLoading} height={455} span={8} title={title} subtitle={subtitle}>
        {content}
      </WidgetCard>
    );
  },
);
