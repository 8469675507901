import { theme } from '@stigg-theme';
import { ItamarPalette } from './itamar.types';

// todo: remove the extra colors options other then TextColor
export type TextColor =
  | keyof ItamarPalette['text']
  // content & main of a theme
  | 'inherit'
  | '#0d3c61'
  | 'error'
  | 'warning'
  | 'secondary.main'
  | 'text.warning'
  | 'success.main'
  | 'success.content'
  | 'info.main'
  | 'info.content'
  | 'info.dark'
  | 'error.main'
  | 'error.content'
  | 'warning.main'
  | 'warning.content'
  | 'primary.main'
  | '#4CAF50'
  | 'default'
  | '#F44336'
  | 'tertiary'
  | 'other.outlineBorder'
  | 'action.active';

export const getTextColor = (color: TextColor) => {
  switch (color) {
    case 'primary':
      return theme.itamar.palette.text.primary;
    case 'secondary':
      return theme.itamar.palette.text.secondary;
    case 'tertiary':
      return theme.itamar.palette.text.tertiary;
    case 'secondary.main':
      return theme.itamar.palette.secondary.main;
    case 'disabled':
      return theme.itamar.palette.text.disabled;
    case 'white':
      return theme.itamar.palette.text.white;
    case 'success.content':
      return theme.itamar.palette.success.content;
    case 'success.main':
      return theme.itamar.palette.success.main;
    case 'info.main':
      return theme.itamar.palette.info.main;
    case 'info.content':
      return theme.itamar.palette.info.content;
    case 'info.dark':
      return theme.itamar.palette.info.dark;
    case 'error':
    case 'error.main':
      return theme.itamar.palette.error.main;
    case 'error.content':
      return theme.itamar.palette.error.content;
    case 'warning.main':
      return theme.itamar.palette.warning.main;
    case 'warning.content':
      return theme.itamar.palette.warning.content;
    case 'primary.main':
      return theme.itamar.palette.primary.main;
    case 'other.outlineBorder':
      return theme.itamar.palette.other.outlineBorder;
    case 'action.active':
      return theme.itamar.palette.action.active;
    default:
      return color;
  }
};
