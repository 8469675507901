import { useState } from 'react';
import pluralize from 'pluralize';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Box, Divider, GridFlex, Icon, IconColor, Text } from '@stigg-components';
import { Feature, FeatureType, MeterType } from '@stigg-types/apiTypes';
import { AutocompleteSearch } from '../../../../../../plans/components/AutoCompleteSearch';
import { useLoadFeatures } from '../../../../../../../features/hooks/useLoadFeatures';
import { getFeatureDisplayName } from '../../../../../../../features/utils/getFeatureDisplayName';
import { getFeatureTypeIcon } from '../../../../../../../../components/getFeatureTypeIcon';
import { createFeatureAction } from '../../../../../../../features/featuresSlice';
import { generateRefIdFromName } from '../../../../../../../../components/DisplayNameTextField';
import { useAppDispatch } from '../../../../../../../../redux/store';
import Loader from '../../../../../../../../components/Loader';

export const USAGE_BASED_FEATURE_TYPES = [FeatureType.Number];
export const USAGE_BASED_METER_TYPES = [MeterType.Incremental, MeterType.Fluctuating];

enum FeatureOptionType {
  FEATURE = 'FEATURE',
  ADD_FEATURE = 'ADD_FEATURE',
  NO_RESULTS = 'NO_RESULTS',
}

type FeatureOption = Feature & { optionType?: FeatureOptionType };

export type FeatureAutocompleteProps = {
  chooseFeature: (feature: Feature) => void;
  isFeatureDisabled: (feature: Feature) => { isDisabled: boolean; disabledReasonText?: string };
};

export const AddFeature = ({
  featureName,
  optionProps,
  disabled,
  onAddFeature,
  isSaving,
}: {
  featureName: string;
  optionProps: any;
  disabled?: boolean;
  isSaving?: boolean;
  onAddFeature: () => void;
}) => {
  let color: IconColor = 'primary.main';
  let addFeatureTextKey = 'features.featuresAutocomplete.addANewMeteredFeature';

  if (disabled) {
    color = 'disabled';
    addFeatureTextKey = 'features.featuresAutocomplete.addANewMeteredFeatureDisabled';
  }

  const handleAddFeatureClick = () => {
    if (!disabled && !isSaving) {
      onAddFeature();
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        width: '100%',
        zIndex: '1',
        ...(disabled ? { cursor: 'not-allowed' } : {}),
      }}>
      <Divider />

      <Box
        component="li"
        {...optionProps}
        aria-disabled={false}
        key="add-feature-option"
        data-testid="add-feature-option"
        onClick={handleAddFeatureClick}>
        <GridFlex.RowCenter container>
          {isSaving && (
            <Text.B2 color="secondary">
              <Loader
                size={18}
                textPosition="right"
                textMargin={2}
                text={t('features.featuresAutocomplete.addingNewFeature')}
              />
            </Text.B2>
          )}
          {!isSaving && (
            <>
              <Icon icon="Plus" type="reactFeather" color={color} />
              <Text.B2 mx={2} color={color}>
                <Trans i18nKey={addFeatureTextKey} values={{ featureName }} />
              </Text.B2>
            </>
          )}
        </GridFlex.RowCenter>
      </Box>
    </Box>
  );
};

export function FeatureAutocomplete({ chooseFeature, isFeatureDisabled }: FeatureAutocompleteProps) {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { features, isLoading } = useLoadFeatures({
    featureTypes: USAGE_BASED_FEATURE_TYPES,
    meterTypes: USAGE_BASED_METER_TYPES,
    search,
  });

  const addNewFeatureOption: any = { optionType: FeatureOptionType.ADD_FEATURE };
  const options: FeatureOption[] = features?.length
    ? [...features, addNewFeatureOption]
    : [
        { optionType: FeatureOptionType.NO_RESULTS, displayName: t('features.featuresAutocomplete.noResults') },
        addNewFeatureOption,
      ];

  const onSearchKeyUp = (event: any) => setSearch(event.target.value);

  const onCreateNewFeature = async () => {
    const featureDisplayName = search;
    if (!featureDisplayName) {
      return;
    }

    setIsSaving(true);

    try {
      const newFeature = await dispatch(
        createFeatureAction({
          featureData: {
            displayName: featureDisplayName,
            refId: generateRefIdFromName(featureDisplayName, t('features.refIdPrefix')),
            featureUnits: pluralize.singular(featureDisplayName).toLowerCase(),
            featureUnitsPlural: pluralize.plural(featureDisplayName).toLowerCase(),
            featureType: FeatureType.Number,
            meterType: MeterType.Incremental,
          },
          navigateToEntity: false,
          silentFetch: true,
          withSuccessMessage: false,
        }),
      ).unwrap();

      if (newFeature) {
        features.push(newFeature as Feature);
        chooseFeature(newFeature as Feature);
      }
    } catch (error) {
      console.error('failed to create a feature', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <AutocompleteSearch
      chooseOption={(feature) => feature && chooseFeature(feature)}
      isOptionDisabled={(feature: FeatureOption) => isFeatureDisabled(feature).isDisabled}
      searchOptions={options}
      onSearchKeyUp={onSearchKeyUp}
      isFetching={isLoading}
      dataTestId="feature-autocomplete"
      placeholder={t('entitlements.searchPlaceholder')}
      getOptionLabel={(feature: FeatureOption) => `${feature.displayName} (${feature.refId})`}
      renderOption={(props: any, featureOption: FeatureOption) => {
        const { optionType, ...feature } = featureOption;
        const { isDisabled, disabledReasonText } = isFeatureDisabled(feature);

        if (optionType === FeatureOptionType.ADD_FEATURE) {
          return (
            <AddFeature
              isSaving={isSaving}
              optionProps={props}
              onAddFeature={onCreateNewFeature}
              disabled={
                !search || options.some((option) => option.displayName?.toLowerCase() === search?.trim()?.toLowerCase())
              }
              featureName={search || ''}
            />
          );
        }

        if (optionType === FeatureOptionType.NO_RESULTS) {
          return (
            <GridFlex.RowCenter
              gap={2}
              key={feature.displayName}
              {...props}
              component="li"
              sx={{ ':last-of-type': { paddingBottom: 9 } }}>
              <Text.B2>{feature.displayName}</Text.B2>
            </GridFlex.RowCenter>
          );
        }

        return (
          <GridFlex.RowCenter
            gap={2}
            key={feature.id}
            {...props}
            component="li"
            sx={{ ':last-of-type': { paddingBottom: 9 } }}>
            {getFeatureTypeIcon(feature)}
            <GridFlex.Column>
              <Text.B2>{getFeatureDisplayName(feature)}</Text.B2>
              <Text.Caption>
                {feature.refId}
                {isDisabled && ` - ${disabledReasonText}`}
              </Text.Caption>
            </GridFlex.Column>
          </GridFlex.RowCenter>
        );
      }}
      isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
      inputProps={{ autoFocus: true }}
    />
  );
}
