import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import {
  SalesforceCredentialsFragment,
  IntegrationFragment,
  VendorIdentifier,
  ApiKeyType,
} from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createIntegrationAction, updateIntegrationAction } from '../../integrationsSlice';
import { IntegrationContactUsState } from '../IntegrationContactUsState';
import { useSalesforceIntegrationWizardSteps } from './useSalesforceIntegrationWizardSteps';
import { Optional } from '../../../../types/primitives';
import { fetchEnvironmentsAction } from '../../../accounts/accountsSlice';

type SalesforceIntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function SalesforceIntegrationPage({ integration, headerComponent }: SalesforceIntegrationPageProps) {
  const dispatch = useAppDispatch();
  const hasIntegration = !!integration?.credentials;
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const { salesforceIntegrationGate } = useFlags<FeatureFlags>();
  const credentials = integration?.credentials as Optional<SalesforceCredentialsFragment>;
  const salesforceApiKey = currentEnvironment?.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Salesforce);

  const steps = useSalesforceIntegrationWizardSteps({
    hasIntegration,
    credentials,
    salesforceApiKey,
    onAddIntegration: async (credentials) => {
      try {
        if (hasIntegration) {
          await dispatch(
            updateIntegrationAction({
              integrationId: integration.id,
              integrationData: {
                vendorIdentifier: VendorIdentifier.Salesforce,
                salesforceCredentials: credentials,
              },
            }),
          ).unwrap();
        } else {
          await dispatch(
            createIntegrationAction({
              vendorIdentifier: VendorIdentifier.Salesforce,
              salesforceCredentials: credentials,
              skipFetch: true,
            }),
          ).unwrap();
          if (currentEnvironment) {
            await dispatch(fetchEnvironmentsAction({ environmentSlug: currentEnvironment.slug }));
          }
        }
      } catch (e) {
        // silent catch
      }
    },
  });

  if (!hasIntegration && salesforceIntegrationGate) {
    return (
      <IntegrationContactUsState headerComponent={headerComponent} integrationType={VendorIdentifier.Salesforce} />
    );
  }

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      <Text.H6 mb={4}>{t('integrations.salesforce.integrateWithStiggTitle')}</Text.H6>

      <WizardAccordion
        layoutProps={{ maxWidth: '100%' }}
        uniqueFlowId="salesforce-connect-stigg"
        steps={steps}
        isStepByStep={!hasIntegration}
        showContinueButtonForLastStep
      />
    </>
  );
}
