import { useEffect, useState } from 'react';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { Account, User } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import config from '../../../env.config';
import { RootState } from '../../../redux/store';

export const useCubejsApi = () => {
  const [cubejsApi, setCubejsApi] = useState<CubejsApi | null>(null);

  const account: Account | null = useSelector((state: RootState) => state.accountReducer.account);
  const user: User | null = useSelector((state: RootState) => state.authReducer.user);
  const member = user?.memberships?.find((x) => x.account.id === account?.id);
  const cubejsToken = member?.cubejsToken;

  useEffect(() => {
    if (cubejsToken) {
      const cubejsApi = cubejs(cubejsToken, {
        apiUrl: config.cubejsApiUrl,
      });
      setCubejsApi(cubejsApi);
    }
  }, [cubejsToken]);

  return cubejsApi;
};
