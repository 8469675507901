import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Environment, EnvironmentType } from '@stigg-types/apiTypes';
import { Icon, Icons, InformationalTooltipIcon } from '@stigg-components';
import { t } from 'i18next';

const ENVIRONMENT_TYPES: () => Record<EnvironmentType, { tooltip: string; icon: Icons }> = () => ({
  [EnvironmentType.Production]: {
    tooltip: t('accounts.prodEnvironmentTooltip'),
    icon: 'ProductionEnvironmentIcon',
  },
  [EnvironmentType.Development]: {
    tooltip: t('accounts.devEnvironmentTooltip'),
    icon: 'DevEnvironmentIcon',
  },
  [EnvironmentType.Sandbox]: {
    tooltip: t('accounts.sandboxEnvironmentTooltip'),
    icon: 'SandboxEnvironmentIcon',
  },
});

export function EnvironmentTypeIcon({ environment }: { environment: Environment }) {
  const { stiggForStiggWeb } = useFlags<FeatureFlags>();
  if (!stiggForStiggWeb) {
    return null;
  }

  const { tooltip, icon } = ENVIRONMENT_TYPES()[environment.type];
  return <InformationalTooltipIcon icon={<Icon icon={icon} type="custom" />} text={tooltip} />;
}
