import { t } from 'i18next';
import { Currency, CustomerResponseFragment } from '@stigg-types/apiTypes';
import {
  FormRenderProps,
  Text,
  GridFlex,
  InformationTooltip,
  OutlinedFormFieldLabel,
  Box,
  ExternalLink,
} from '@stigg-components';
import { useCurrencySelector } from '../../../../../../packages/pricing/components/currency/useCurrencySelector';
import { SubscriptionFormFields } from '../SubscriptionForm.types';
import {
  DEFAULT_COUNTRY_CODE,
  getCurrencyText,
} from '../../../../../../packages/pricing/components/currency/currencyUtils';

type LocalizationSelectProps = {
  plansCountryList: string[];
  selectedCountryCode?: string | null;
  disabled?: boolean;
  setFieldValue: FormRenderProps<SubscriptionFormFields>['setFieldValue'];
  customer: CustomerResponseFragment;
  countryCodeToCurrencyMap?: Map<string, Currency>;
};

const popoverProps = {
  popperOptions: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -10],
        },
      },
    ],
  },
};

export const LocalizationSelect = ({
  plansCountryList,
  setFieldValue,
  selectedCountryCode,
  disabled,
  customer,
  countryCodeToCurrencyMap,
}: LocalizationSelectProps) => {
  const [SelectComponent] = useCurrencySelector({
    countryCodesList: plansCountryList,
    countryCodeToCurrencyMap,
    selectedCountryCode,
    disabled,
    disableCaption: true,
    defaultCountryText: t('subscriptionForm.defaultLocalizationText'),
    onCountryCodeChange: (countryCode) => {
      setFieldValue('billingCountryCode', countryCode === DEFAULT_COUNTRY_CODE ? null : countryCode);
    },
  });

  return (
    <InformationTooltip
      arrow
      $maxWidth={325}
      placement="top"
      title={
        !!customer.billingCurrency && (
          <GridFlex.Column gap={2}>
            <Text.B2>
              {t('subscriptionForm.userCurrencyAlreadySet', {
                customerCurrency: getCurrencyText(customer.billingCurrency),
              })}
            </Text.B2>
            <ExternalLink
              label={t('sharedComponents.learnMore')}
              url={t('subscriptionForm.userCurrencyAlreadySetLearnMoreUrl')}
            />
          </GridFlex.Column>
        )
      }
      PopperProps={popoverProps}>
      <Box>
        <GridFlex.Column>
          <OutlinedFormFieldLabel label={t('subscriptionForm.priceLocalizationTitle')} />
          <GridFlex.Row>
            <SelectComponent />
          </GridFlex.Row>
        </GridFlex.Column>
      </Box>
    </InformationTooltip>
  );
};
