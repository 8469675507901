import { FeatureType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Box, Divider, GridFlex, Icon, Text } from '@stigg-components';
import {
  BasePlanProps,
  PlanEntitlement,
} from '../../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { formatQuantityAndUnits } from '../../../../entitlements/formatQuantityAndUnits';
import { getFeatureTypeIcon } from '../../../../../components/getFeatureTypeIcon';
import { FeatureOptionType, NewEntitlementsOption } from './types';

export function FeatureOption({
  optionProps,
  option,
  onAddFeatureClick,
  overriddenEntitlement,
  basePlan,
}: {
  optionProps: any;
  option: NewEntitlementsOption;
  onAddFeatureClick: () => void;
  overriddenEntitlement?: PlanEntitlement;
  basePlan?: BasePlanProps | null;
}) {
  if (option.optionType === FeatureOptionType.ADD_FEATURE) {
    return (
      <Box sx={{ position: 'fixed', bottom: 0, backgroundColor: 'white', width: '100%', zIndex: '1' }}>
        <Divider />
        <Box component="li" {...optionProps} aria-disabled={false} key="add-feature-option" onClick={onAddFeatureClick}>
          <GridFlex.RowCenter container>
            <Icon icon="Plus" type="reactFeather" color="primary.main" />
            <Text.B2 mx={2} color="primary.main">
              {t('entitlements.addANewFeature')}
            </Text.B2>
          </GridFlex.RowCenter>
        </Box>
      </Box>
    );
  }

  let suffix: string | undefined;

  if (option.refId && optionProps['aria-disabled']) {
    suffix = t('sharedComponents.alreadyAdded');
  } else if (overriddenEntitlement && basePlan) {
    suffix = t('entitlements.includedInPlan', { planName: basePlan.displayName });

    if (overriddenEntitlement.feature.featureType !== FeatureType.Boolean) {
      suffix += ` (${formatQuantityAndUnits(overriddenEntitlement)})`;
    }
  }

  return (
    <Box component="li" sx={{ ':last-of-type': { paddingBottom: 9 } }} {...optionProps} key={option.refId}>
      <GridFlex.RowCenter gap={4} data-testid={`entitlements-dialog-option-${option.refId}`}>
        {option.featureType ? getFeatureTypeIcon(option) : null}
        <GridFlex.Column flex={1}>
          <Text.B2>{option.displayName}</Text.B2>
          <Text.Caption color="secondary">
            {option.refId}{' '}
            {suffix && (
              <>
                {' - '}
                {suffix}
              </>
            )}
          </Text.Caption>
        </GridFlex.Column>
      </GridFlex.RowCenter>
    </Box>
  );
}
