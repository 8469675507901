import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { browserHistory } from '../browserHistory';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '../localStorage';
import { rootReducer } from './rootReducer';

export const store = configureStore({
  preloadedState: loadStateFromLocalStorage(),
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(browserHistory), saveStateToLocalStorage()),
});

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  module.hot?.accept(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();
