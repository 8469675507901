import { ButtonColor, getButtonColor, getTextColor, css } from '@stigg-theme';
import { buttonClasses } from '@mui/material/Button';

export const buttonStyle = (
  color?: ButtonColor,
  outlined?: boolean,
  disabled?: boolean,
  textColor?: ButtonColor,
  hoverColor?: ButtonColor | 'disabled',
  withDisabledBackground = true,
  display: 'flex' | 'inline' = 'flex',
  fontSize?: '12px' | '14px' | undefined,
) => {
  let style;
  if (disabled && withDisabledBackground) {
    style = css`
      border: none;
      color: ${getTextColor('disabled')};
      background: ${hoverColor ? 'transparent' : getButtonColor('disabled')};
    `;
  } else if (color) {
    style = outlined
      ? css`
          border: 1px solid ${getButtonColor(color)};
          color: ${getButtonColor(color)};

          &:hover {
            border-color: ${getButtonColor(color)};
          }
        `
      : css`
          background: ${getButtonColor(color)};

          &:hover {
            background: ${getButtonColor(color)};
          }
        `;
  } else if (hoverColor) {
    style = css`
      &:hover {
        background: ${hoverColor === 'disabled' ? 'transparent' : getButtonColor(hoverColor)};
      }
    `;
  }

  return css`
    align-items: center;
    align-self: center;
    display: ${display};
    font-size: ${fontSize || '14px'};
    opacity: 1;
    transition: opacity 0.3s;
    ${style}
    ${textColor
      ? css`
          color: ${getButtonColor(textColor)};
        `
      : ''}
    
    // for disabling gradient background
    &.${buttonClasses.disabled} {
      background-image: unset;
    }

    &:hover {
      opacity: 0.8;
    }
  `;
};
