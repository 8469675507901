import * as materialIcons from './MuiIcons';
import * as reactFeatherIcons from './ReactFeatherIcons';
import { getIconColor } from '../../theme';
import * as customIcons from './CustomIcons';
import { IconStyler, IconStylerProps } from './IconStyler';

type MaterialUiIcons = keyof typeof materialIcons;
type ReactFeatherIcons = keyof typeof reactFeatherIcons;
export type CustomIcons = keyof typeof customIcons;
export type Icons = MaterialUiIcons | ReactFeatherIcons | CustomIcons;
export type IconType = 'materialIcons' | 'reactFeather' | 'custom';

export type { IconColor } from './IconStyler';

export type IconProps = {
  icon: Icons;
  type?: IconType;
  overrideFill?: boolean;
  overrideStroke?: boolean;
  display?: string;
  style?: React.CSSProperties;
} & IconStylerProps;

const getIconComponent = (icon: Icons, type?: IconType) => {
  switch (type) {
    case 'materialIcons': {
      return (materialIcons as any)[icon];
    }
    case 'reactFeather': {
      return (reactFeatherIcons as any)[icon];
    }
    case 'custom': {
      return (customIcons as any)[icon];
    }
    default: {
      return (materialIcons as any)[icon] || (reactFeatherIcons as any)[icon] || (customIcons as any)[icon];
    }
  }
};

export function Icon({ style, icon, type, overrideFill, overrideStroke, ...styleProps }: IconProps) {
  const IconComponent = getIconComponent(icon, type);

  if (!IconComponent) {
    return null; // temp so the app won't collapse
  }
  const { color, size, height, width, withHoverEffect, display } = styleProps;
  const iconColor = color ? getIconColor(color) : '';
  const iconStyle = {
    ...style,
    ...(overrideFill ? { fill: iconColor } : {}),
    ...(overrideStroke ? { stroke: iconColor } : {}),
  };

  return (
    <IconStyler
      style={iconStyle}
      $color={color}
      $size={size}
      $width={width}
      $height={height}
      $withHoverEffect={withHoverEffect}
      $overrideStroke={overrideStroke}
      $display={display}>
      <IconComponent />
    </IconStyler>
  );
}
