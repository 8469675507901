import { t } from 'i18next';
import { CardHeaderLayout, DetailsLayout, PageCard, Divider } from '@stigg-components';
import { CouponFragment, CouponStatus } from '@stigg-types/apiTypes';
import { DetailsLayoutType } from '@stigg-components/types';
import { useIntegrations } from '@stigg-common';
import { IntegrationIdLink } from '../../../../../components/IntegrationIdLink';
import { CouponStatusChip } from '../../CouponStatusChip';
import { CouponOptionsDropdown } from './CouponOptionsDropdown';
import { usePollCouponSyncStates } from '../../usePollCouponSyncStates';
import { formatDiscountValue } from '../../utils';

type CouponDetailsProps = {
  coupon: CouponFragment;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export function CouponDetails({ coupon, onEditClick, onDeleteClick }: CouponDetailsProps) {
  const { hasCRMIntegration, hasBillingIntegration } = useIntegrations();
  const { refId, discountValue, name, description, syncStates, billingId, billingLinkUrl, status } = coupon;

  usePollCouponSyncStates(coupon.id);

  const details: DetailsLayoutType = [
    {
      title: t('coupons.refIdForm'),
      content: refId,
      mask: true,
      copyableChip: true,
    },
    {
      title: t('coupons.discount'),
      content: formatDiscountValue(discountValue, coupon.type),
      mask: true,
    },
    {
      title: t('coupons.descriptionForm'),
      content: description,
      mask: false,
    },
    {
      title: t('customers.billingId'),
      content: (
        <IntegrationIdLink
          vendorCategory="BILLING"
          entityType="COUPON"
          refId={refId}
          syncStates={syncStates}
          to={{ integrationId: billingId, integrationLinkUrl: billingLinkUrl }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: hasBillingIntegration,
    },
    {
      title: t('customers.crmId'),
      content: (
        <IntegrationIdLink
          vendorCategory="CRM"
          entityType="COUPON"
          refId={refId}
          syncStates={syncStates}
          to={{
            integrationId: undefined,
            integrationLinkUrl: undefined,
          }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: hasCRMIntegration,
    },
  ];

  return (
    <PageCard>
      <CardHeaderLayout
        title={t('coupons.details')}
        chip={{ component: <CouponStatusChip status={coupon.status} /> }}
        hideOptions={status === CouponStatus.Archived}
        displayName={name}
        options={{ component: <CouponOptionsDropdown onDeleteClick={onDeleteClick} onEditClick={onEditClick} /> }}
      />

      <Divider mt={4} mb={4} />

      <DetailsLayout details={details} />
    </PageCard>
  );
}
