import { CouponType, Currency } from '@stigg-types/apiTypes';
import getSymbolFromCurrency from 'currency-symbol-map';

export type BaseCoupon = {
  name: string;
  discountValue: number;
  type: CouponType;
  refId: string;
};

export const formatDiscountValue = (
  discountValue: number,
  discountType: CouponType,
  currency: Currency | undefined = Currency.Usd,
) => `${discountValue}${discountType === CouponType.Percentage ? '%' : getSymbolFromCurrency(currency)} off`;

export const formatCoupon = ({ name, discountValue, type }: BaseCoupon) => {
  return `${name} (${formatDiscountValue(discountValue, type)})`;
};
