import styled from 'styled-components';
import { AppBar } from '@stigg-components';
import { Edit as BaseEdit } from 'react-feather';
import { TOP_BANNER_HEIGHT } from '../../../../../components/layout/TopBanner';
import { SANDBOX_ENVIRONMENT_BANNER_HEIGHT } from '../../../../../components/layout/SandboxEnvironmentBanner';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from '../../../../../components/layout/AppLayout.style';

export const Edit = styled(BaseEdit)`
  color: ${({ theme }) => theme.itamar.palette.primary.main};
`;

export const EditModeBar = styled(AppBar)<{
  $isDrawerExpanded: boolean;
  $topBannerOpen: boolean;
  $isSandboxBannerOpen: boolean;
}>`
  background-color: white;
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  top: ${({ $topBannerOpen, $isSandboxBannerOpen }) =>
    $isSandboxBannerOpen ? `${SANDBOX_ENVIRONMENT_BANNER_HEIGHT}px` : $topBannerOpen ? `${TOP_BANNER_HEIGHT}px` : '0'};
  left: ${({ $isDrawerExpanded }) => ($isDrawerExpanded ? `${DRAWER_WIDTH}px` : `${DRAWER_WIDTH_CLOSED}px`)};
  height: 64px;
  width: ${({ $isDrawerExpanded }) =>
    $isDrawerExpanded ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`};
  z-index: ${({ theme }) => theme.zIndex.appBar};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: ${({ theme }) => (theme.breakpoints.up('md') ? '40px' : '20px')};
  padding-right: 40px;
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;
