import React from 'react';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { InformationTooltip, Icon, IconButton, Text, GridFlex } from '@stigg-components';
import { WidgetIdentifier } from '../../useWidgetsData';

const ICON_SIZE = 26;

const CodeIconWrapper = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  cursor: pointer;
  border-radius: 10px;
`;

function CodePlatformIcon({ icon, tooltip, docsUrl }: { icon: React.ReactNode; tooltip: string; docsUrl: string }) {
  const onClick = () => {
    window.open(docsUrl, '_blank');
  };

  return (
    <InformationTooltip $padding={2} arrow placement="top" title={<Text.B2 color="primary">{tooltip}</Text.B2>}>
      <CodeIconWrapper onClick={onClick}>{icon}</CodeIconWrapper>
    </InformationTooltip>
  );
}

export function AdditionalSupportedLanguages({ widgetIdentifier }: { widgetIdentifier: WidgetIdentifier }) {
  const shouldShowHtml = widgetIdentifier === WidgetIdentifier.Paywall;
  return (
    <GridFlex.Column>
      <Text.B2 mb={2} $bold>
        {t('widgets.additionalSupportedLanguages')}
      </Text.B2>
      <GridFlex.Column gap={2}>
        <GridFlex.Row gap={2}>
          <CodePlatformIcon
            tooltip={t('widgets.jsTooltip')}
            icon={<Icon type="custom" icon="JavascriptIcon" size={ICON_SIZE} />}
            docsUrl={t('widgets.jsDocsUrl')}
          />
          <CodePlatformIcon
            tooltip={t('widgets.vueTooltip')}
            icon={<Icon type="custom" icon="VueIcon" size={ICON_SIZE} />}
            docsUrl={t('widgets.vueDocsUrl')}
          />
          <CodePlatformIcon
            tooltip={t('widgets.nextJsTooltip')}
            icon={<Icon icon="NexjsIcon" type="custom" size={ICON_SIZE} />}
            docsUrl={t('widgets.nextjsDocsUrl')}
          />
          {shouldShowHtml && (
            <CodePlatformIcon
              tooltip={t('widgets.htmlTooltip')}
              icon={<Icon icon="HtmlIcon" type="custom" size={ICON_SIZE} />}
              docsUrl={t('widgets.embedSdkDocsUrl')}
            />
          )}
        </GridFlex.Row>
        {shouldShowHtml && (
          <GridFlex.Row gap={2}>
            <CodePlatformIcon
              tooltip={t('widgets.webflowTooltip')}
              icon={<Icon icon="WebflowIcon" type="custom" size={ICON_SIZE} />}
              docsUrl={t('widgets.embedSdkDocsUrl')}
            />
            <CodePlatformIcon
              tooltip={t('widgets.wordpressTooltip')}
              icon={<Icon icon="WordpressIcon" type="custom" size={ICON_SIZE} />}
              docsUrl={t('widgets.embedSdkDocsUrl')}
            />
          </GridFlex.Row>
        )}
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
