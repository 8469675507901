/* eslint-disable no-restricted-imports */
import '@mui/lab/themeAugmentation';
import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';
import variants from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows from './shadows';
import components from './components';
import shape from './shape';
import { Itamar } from './itamar';

const createTheme = (name = 'DEFAULT') => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    const [defaultThemeConfig] = variants;
    themeConfig = defaultThemeConfig;
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      typography,
      shadows,
      components,
      shape,
      itamar: themeConfig.itamar,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
      chip: themeConfig.chip,
    },
  );
};

export { css } from 'styled-components';
export { styled } from '@mui/material';
export { spacing } from '@mui/system';
export * from './itamar';

export const theme = createTheme();

type ItamarTheme = Theme & {
  itamar: Itamar;
};
export type { ItamarTheme };
