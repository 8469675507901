import { styled } from '@stigg-theme';
import { GridFlex, Box } from '@stigg-components';
import { Close, ErrorOutline as BaseErrorOutline } from '@mui/icons-material';
import { CheckCircle as BaseCheckCircle } from 'react-feather';

export const TooltipClose = styled(Close)`
  margin-top: 6px;
  color: #808eb5;
  cursor: pointer;
`;

export const ErrorOutline = styled(BaseErrorOutline)`
  color: ${({ theme }) => theme.itamar.palette.error.main};
`;

export const CheckCircle = styled(BaseCheckCircle)`
  margin-right: 8px;
`;

export const TaskBox = styled(GridFlex.Column)<{ $color: string }>`
  padding: 16px;
  min-height: 98px;
  background: ${({ $color }) => $color};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

export const TaskTitleBox = styled(Box)`
  &,
  & > * {
    display: inline;
  }
`;
