import { GridFlex, Icon, OptionsDropdown, Text } from '@stigg-components';
import {
  AddonFragment,
  BillingCadence,
  BillingModel,
  BillingPeriod,
  PlanFragment,
  PriceFragment,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Edit2 } from 'react-feather';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import Table, { HeadCell } from '../../../../../components/table/Table';
import { getPriceAndPeriodFormat } from '../../../pricing/utils/priceFormatUtils';
import {
  getBillingCadence,
  getGroupFeature,
  getPriceGroupId,
  PriceGroup,
  toPriceGroups,
} from '../../../pricing/utils/priceGroups';
import { DEFAULT_COUNTRY_CODE } from '../../../pricing/components/currency/currencyUtils';
import { getFeatureTypeIcon } from '../../../../../components/getFeatureTypeIcon';
import TieredPricePackageCell from './TieredPricePackageCell';
import ChargeTypeCell from './ChargeTypeCell';
import { FeatureDisplayNameAndId } from '../../../../features/components/FeatureDisplayNameAndId';
import { getBillingCadenceIcon } from '../../../pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';

function Price({ price, reversedPeriod }: { price: PriceFragment; reversedPeriod?: boolean }) {
  return price.tiers ? (
    <TieredPricePackageCell price={price} reversedPeriod={!!reversedPeriod} />
  ) : (
    <Text.B2>{getPriceAndPeriodFormat(price, { reversedPeriod, unitFormat: true, shortFormat: true })}</Text.B2>
  );
}

export function Charge({ priceGroup }: { priceGroup: PriceGroup }) {
  const feature = getGroupFeature(priceGroup);
  return (
    <GridFlex.RowCenter gap={2}>
      {feature ? getFeatureTypeIcon(feature, { size: 24 }) : <Icon icon="BasePrice" color="active" />}
      <Text.B2 color="primary">
        {feature ? <FeatureDisplayNameAndId feature={feature} /> : t(`pricing.basePrice`)}
      </Text.B2>
    </GridFlex.RowCenter>
  );
}

function AddonCharge({ priceGroup }: { priceGroup: PriceGroup }) {
  const billingCadence = getBillingCadence(priceGroup);
  return (
    <GridFlex.RowCenter gap={2}>
      {getBillingCadenceIcon({ billingCadence, selected: false })}
      <Text.B2 color="primary">{t(`pricing.billingCadenceDescriptions.${billingCadence}`).toString()}</Text.B2>
    </GridFlex.RowCenter>
  );
}

function isAddonPackageType(packageType: string) {
  return packageType === 'Addon';
}

const headCells = ({
  readonly,
  hasUsageBasedPrice,
  hasAnnuallyPrice,
  hasMonthlyPrice,
  onEditPriceGroup,
  isOneOff,
  packageType,
}: {
  readonly: boolean;
  hasUsageBasedPrice: boolean;
  hasAnnuallyPrice: boolean;
  hasMonthlyPrice: boolean;
  onEditPriceGroup: (priceGroupId: string) => void;
  isOneOff: boolean;
  packageType: string;
}): Array<HeadCell<PriceGroup, any>> => [
  {
    id: 'charge',
    alignment: 'left',
    label: t('pricing.charge'),
    render: (priceGroup: PriceGroup) =>
      isAddonPackageType(packageType) ? <AddonCharge priceGroup={priceGroup} /> : <Charge priceGroup={priceGroup} />,
  },
  {
    id: 'monthly_pricing_period',
    alignment: 'left',
    visible: hasMonthlyPrice,
    label: isOneOff ? t('pricing.price') : t('pricing.monthlyBillingPeriod'),
    render: (priceGroup: PriceGroup) => {
      const monthlyPrice = priceGroup.find((x) => x.billingPeriod === BillingPeriod.Monthly);
      if (!monthlyPrice) {
        return <Text.B2 color="disabled">—</Text.B2>;
      }
      return <Price price={monthlyPrice} />;
    },
  },
  {
    id: 'annually_pricing_period',
    alignment: 'left',
    label: hasUsageBasedPrice ? '' : isOneOff ? t('pricing.price') : t('pricing.annuallyBillingPeriod'),
    visible: hasAnnuallyPrice,
    render: (priceGroup: PriceGroup) => {
      // we do not support annually pricing for usage based pricing
      if (hasUsageBasedPrice) {
        return null;
      }
      const annuallyPrice = priceGroup.find((x) => x.billingPeriod === BillingPeriod.Annually);
      const monthlyPrice = priceGroup.find((x) => x.billingPeriod === BillingPeriod.Monthly);

      return annuallyPrice ? (
        <Price price={annuallyPrice} reversedPeriod={!!monthlyPrice} />
      ) : (
        <Text.B2 color="disabled">—</Text.B2>
      );
    },
  },
  {
    id: 'pricing_model',
    alignment: 'left',
    label: t('pricing.pricingModel'),
    visible: !isAddonPackageType(packageType),
    render: (priceGroup: PriceGroup) => <ChargeTypeCell priceGroup={priceGroup} />,
  },
  {
    id: 'options',
    alignment: 'center',
    label: '',
    width: 36,
    disablePadding: true,
    visible: !readonly,
    render: (priceGroup: PriceGroup) => {
      const options = [
        {
          icon: Edit2,
          text: t('pricing.editCharge'),
          onClick: () => onEditPriceGroup(getPriceGroupId(priceGroup)),
        },
      ];

      return <OptionsDropdown options={options} />;
    },
  },
];

type PaidPricingViewProps = {
  aPackage: PlanFragment | AddonFragment;
  readonly: boolean;
  onEditPriceGroup: (priceGroupId: string, billingCountryCode: string) => void;
  billingCountryCode: string;
};

export function PaidPricingView({
  aPackage,
  readonly,
  onEditPriceGroup: onEditPriceGroupOrig,
  billingCountryCode,
}: PaidPricingViewProps) {
  const { overagePricing: isOveragePricingEnabled } = useFlags<FeatureFlags>();

  const { prices, type: packageType } = aPackage;
  const countryCodePrices = prices?.filter((price) =>
    billingCountryCode === DEFAULT_COUNTRY_CODE
      ? price.billingCountryCode === null
      : price.billingCountryCode === billingCountryCode,
  );

  const priceGroups = toPriceGroups(countryCodePrices);
  const hasUsageBasedPrice = !!prices?.find((x) => x.billingModel === BillingModel.UsageBased);

  const onEditPriceGroup = (priceGroupId: string) => onEditPriceGroupOrig(priceGroupId, billingCountryCode);

  const isOneOff = priceGroups.some((priceGroup) =>
    priceGroup.some((price) => price.billingCadence === BillingCadence.OneOff),
  );
  const hasAnnuallyPrice =
    !isOneOff &&
    priceGroups.some((priceGroup) => priceGroup.some((price) => price.billingPeriod === BillingPeriod.Annually));
  const hasMonthlyPrice = priceGroups.some((priceGroup) =>
    priceGroup.some((price) => price.billingPeriod === BillingPeriod.Monthly),
  );
  return (
    <GridFlex.Column gap={4}>
      {isOveragePricingEnabled && packageType !== 'Addon' && <Text.H6>{t('pricing.chargesTitle')}</Text.H6>}
      <Table
        label={t('pricing.pricingTableLabel')}
        headCells={headCells({
          readonly,
          hasUsageBasedPrice,
          onEditPriceGroup,
          hasAnnuallyPrice,
          hasMonthlyPrice,
          isOneOff,
          packageType,
        })}
        data={priceGroups}
      />
    </GridFlex.Column>
  );
}
