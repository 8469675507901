import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import {
  CouponFilter,
  CouponStatus,
  FetchSlimCouponsQuery,
  FetchSlimCouponsQueryVariables,
} from '@stigg-types/apiTypes';
import { applyEnvironmentFilter } from '@stigg-common';
import { Navigation } from '../navigation/useNavigation';
import { apolloClient } from '../../ApolloClient';
import { executeSafely } from './utils';
import { FETCH_SLIM_COUPONS } from '../coupons/queries/fetchCoupons';
import { MAX_RESULTS_PER_COMMANDBAR_SEARCH } from './useCommandBar';
import { getCommandBarIcon } from './icons/getCommandBarIcon';
import couponsIcon from './icons/coupons.svg';

export function setCouponsFetch(environmentId: string, navigation: Navigation) {
  const doFetchCoupons: SearchFunction = async (search: string) => {
    const filter: CouponFilter = {
      environmentId: { eq: environmentId },
      status: { eq: CouponStatus.Active },
    };

    if (search) {
      filter.or = [
        { refId: { iLike: `%${search}%` } },
        { name: { iLike: `%${search}%` } },
        { billingId: { iLike: `%${search}%` } },
      ].map((searchFilter) => applyEnvironmentFilter<CouponFilter>(searchFilter, environmentId));
    }

    const response = await apolloClient.query<FetchSlimCouponsQuery, FetchSlimCouponsQueryVariables>({
      query: FETCH_SLIM_COUPONS,
      fetchPolicy: 'network-only',
      variables: {
        paging: { first: MAX_RESULTS_PER_COMMANDBAR_SEARCH },
        filter,
      },
    });

    const coupons = response.data.coupons.edges.map((edge) => edge.node);

    return coupons.map((coupon) => {
      const { refId: couponId, name: displayName } = coupon;
      return { couponId, label: displayName || couponId, description: displayName ? couponId : '' };
    });
  };

  window.CommandBar.addRecords('coupons', [], {
    onInputChange: executeSafely(doFetchCoupons),
    defaultIcon: getCommandBarIcon(couponsIcon),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.couponPage('{{record.couponId}}'),
    },
  });
}
