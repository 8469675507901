import { FC } from 'react';
import { t } from 'i18next';
import { InformationTooltip, Icon, IconProps, Text, GridFlex, Link, Box } from '@stigg-components';
import { BaseCoupon, formatCoupon } from './utils';
import { useNavigation } from '../../navigation/useNavigation';

export const CouponSummary: FC<{ coupon?: BaseCoupon | null; withLink?: boolean } & Partial<IconProps>> = ({
  coupon,
  withLink,
  ...iconProps
}) => {
  const { navigateTo } = useNavigation();

  return (
    <InformationTooltip arrow placement="top" $padding={2} title={<Text.B2>{t('coupons.coupon')}</Text.B2>}>
      <GridFlex.RowCenter>
        <GridFlex.RowCenter>
          <Icon icon="Tag" type="reactFeather" color="default" size={22} {...iconProps} />
        </GridFlex.RowCenter>

        {coupon && (
          <Box mx={2}>
            {withLink ? (
              <Link onClick={() => navigateTo(`/coupons/${coupon?.refId}`)} display="flex">
                {formatCoupon(coupon)}
              </Link>
            ) : (
              <Text.B2 color="primary">{formatCoupon(coupon)}</Text.B2>
            )}
          </Box>
        )}
      </GridFlex.RowCenter>
    </InformationTooltip>
  );
};
