import { ExternalLink, Button, ButtonGroup, GridFlex, Icon, Text, TextWithHelpTooltip } from '@stigg-components';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@stigg-theme';
import { useQueryParam } from '@stigg-common';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useCurrentClientApiKey } from '../../hooks/useCurrentClientApiKey';
import { WidgetIdentifier } from '../../useWidgetsData';
import { WidgetCodeBlock } from './WidgetCodeBlock';
import { WidgetStyle } from './WidgetStyle';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { CustomerPortalSection } from '../previewOptionsPanel/PreviewOptionsSelectBox';
import { ClientSideSecurityAlert } from '../ClientSideSecurityAlert';
import WidgetContent from './WidgetContent';
import MissingBillingIntegration from '../MissingBillingIntegrationAlert';
import { VendorIdentifier } from '../../../integrations/constants';

enum SelectedTab {
  Style = 'style',
  Content = 'content',
  Code = 'code',
}

const REQUIRE_BILLING_INTEGRATION = [WidgetIdentifier.Checkout];

const GroupButton = styled(Button)<{ $selected: boolean }>`
  transition: background 0.15s ease-in-out;
  flex-grow: 1;
  border-radius: 4px;
  background: ${({ theme, $selected }) => ($selected ? 'white' : theme.itamar.palette.background.emptyState)};
  color: ${({ theme, $selected }) =>
    $selected ? theme.itamar.palette.text.primary : theme.itamar.palette.action.active};
  &:hover {
    ${({ $selected }) => ($selected ? '' : `background-color: white`)};
  }
`;

const widgetTooltipInfo = (identifier: WidgetIdentifier) => {
  switch (identifier) {
    case WidgetIdentifier.Paywall:
      return {
        description: t('widgets.paywallDescription'),
        learnMoreLink: t('widgets.paywallExternalLearnMoreLink'),
      };
    case WidgetIdentifier.CustomerPortal:
      return {
        description: t('widgets.customerPortalDescription'),
        learnMoreLink: t('widgets.customerPortalExternalLearnMoreLink'),
      };
    case WidgetIdentifier.Checkout:
      return {
        description: t('widgets.checkout.description'),
        learnMoreLink: t('widgets.checkout.learnMoreLink'),
      };
    default:
      return undefined;
  }
};

export function LeftPanel({
  widgetName,
  widgetIdentifier,
  customerPortalHiddenSections,
  customerId,
  widgetConfig,
  productId,
  countryCode,
}: {
  widgetName: string;
  widgetIdentifier: WidgetIdentifier;
  customerPortalHiddenSections: CustomerPortalSection[];
  customerId?: string;
  widgetConfig: WidgetConfig;
  productId?: string;
  countryCode?: string;
}) {
  const integrations = useSelector((state: RootState) => state.integrations.integrations);
  const { value: showCodeQueryParam } = useQueryParam('showcode');
  const { value: tabQueryParam } = useQueryParam('tab');
  const history = useHistory();

  const openTab = useCallback(() => {
    if (showCodeQueryParam === 'true') return SelectedTab.Code;
    if (tabQueryParam && Object.values(SelectedTab).includes(tabQueryParam as SelectedTab)) {
      return tabQueryParam as SelectedTab;
    }
    return SelectedTab.Style;
  }, [showCodeQueryParam, tabQueryParam]);

  const [selectedTab, setSelectedTab] = useState(openTab);
  useEffect(() => {
    history.push({ search: `?tab=${selectedTab}` });
  }, [history, selectedTab]);

  const clientApiKey = useCurrentClientApiKey();
  if (!clientApiKey) {
    return <></>;
  }

  // hack to allow checking if configuration.content exist
  const configuration = widgetConfig.configuration as any;
  const { description, learnMoreLink } = widgetTooltipInfo(widgetIdentifier) || {};

  const requireBillingIntegrationAlert =
    REQUIRE_BILLING_INTEGRATION.includes(widgetIdentifier) &&
    !integrations.some((integration) => integration.vendorIdentifier === VendorIdentifier.Stripe);

  return (
    <GridFlex.Column>
      <TextWithHelpTooltip
        textSize="H3"
        text={widgetName}
        textColor="primary"
        tooltipText={
          <>
            <Text.B2>{description}</Text.B2>
            <ExternalLink label={t('sharedComponents.learnMore')} url={learnMoreLink} />
          </>
        }
      />
      {requireBillingIntegrationAlert ? (
        <MissingBillingIntegration sx={{ mt: 8 }} />
      ) : (
        <ClientSideSecurityAlert sx={{ mt: 8 }} variant="link" />
      )}
      <ButtonGroup
        sx={{
          cursor: 'pointer',
          padding: '2px',
          background: (theme) => theme.itamar.palette.grey[50],
          borderRadius: '4px',
          my: 8,
        }}>
        <GroupButton
          disableRipple
          $selected={selectedTab === SelectedTab.Style}
          startIcon={
            <Icon
              type="materialIcons"
              color={selectedTab === SelectedTab.Style ? 'primary.main' : 'disabled'}
              icon="TextFormat"
              size={20}
            />
          }
          onClick={() => setSelectedTab(SelectedTab.Style)}
          color={selectedTab === SelectedTab.Style ? 'white' : 'disabled'}
          variant="contained">
          <Text.B1 color={selectedTab === SelectedTab.Style ? 'primary.main' : 'action.active'}>Style</Text.B1>
        </GroupButton>
        {configuration.content ? (
          <GroupButton
            disableRipple
            $selected={selectedTab === SelectedTab.Content}
            startIcon={
              <Icon
                type="materialIcons"
                color={selectedTab === SelectedTab.Content ? 'primary.main' : 'disabled'}
                icon="ContentPaste"
                size={20}
              />
            }
            onClick={() => setSelectedTab(SelectedTab.Content)}
            color={selectedTab === SelectedTab.Content ? 'white' : 'disabled'}
            variant="contained">
            <Text.B1 color={selectedTab === SelectedTab.Content ? 'primary.main' : 'action.active'}>Content</Text.B1>
          </GroupButton>
        ) : null}
        <GroupButton
          disableRipple
          $selected={selectedTab === SelectedTab.Code}
          startIcon={
            <Icon
              type="reactFeather"
              color={selectedTab === SelectedTab.Code ? 'primary.main' : 'disabled'}
              icon="Code"
              size={18}
            />
          }
          onClick={() => setSelectedTab(SelectedTab.Code)}
          color={selectedTab === SelectedTab.Code ? 'white' : 'disabled'}
          variant="contained">
          <Text.B1 color={selectedTab === SelectedTab.Code ? 'primary.main' : 'action.active'}>Code</Text.B1>
        </GroupButton>
      </ButtonGroup>
      {selectedTab === SelectedTab.Code ? (
        <WidgetCodeBlock
          clientApiKey={clientApiKey}
          widgetIdentifier={widgetIdentifier}
          customerId={customerId}
          productId={productId}
          customerPortalHiddenSections={customerPortalHiddenSections}
          countryCode={countryCode}
        />
      ) : selectedTab === SelectedTab.Content ? (
        <WidgetContent widgetConfig={widgetConfig} />
      ) : (
        <WidgetStyle widgetIdentifier={widgetIdentifier} widgetConfig={widgetConfig} />
      )}
    </GridFlex.Column>
  );
}
