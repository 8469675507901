import { GridFlex, Icon, Text, Link, Drawer } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { theme } from '@stigg-theme';
import { RootState, useAppDispatch } from '../../redux/store';
import { useNavigation } from '../../modules/navigation/useNavigation';
import { setIsTopBannerOpen } from '../../modules/application/applicationSlice';

export const TOP_BANNER_HEIGHT = 36;

const PaperProps: DrawerProps['PaperProps'] = {
  style: {
    zIndex: theme.zIndex.drawer + 1,
    border: 'none',
    height: TOP_BANNER_HEIGHT,
    background: theme.itamar.palette.background.runningFlow,
  },
};

export function TopBanner({ open }: { open: boolean }) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { activeExperiments } = useSelector((state: RootState) => state.experimentsReducer.global);

  const count = activeExperiments.length;

  useEffect(() => {
    dispatch(setIsTopBannerOpen(count > 0));
  }, [dispatch, count]);

  return (
    <Drawer anchor="top" variant="persistent" open={open} transitionDuration={150} PaperProps={PaperProps}>
      <GridFlex.RowCenter columnGap={2} $fullHeight paddingX={4}>
        <Icon icon="ScienceOutlined" type="materialIcons" color="white" />
        <Text.B2 color="white" display="inline">
          {count > 1
            ? t('experiments.activeExperimentRunningPlural', { count })
            : t('experiments.activeExperimentRunning')}{' '}
          {activeExperiments.map((experiment, index) => (
            <>
              {index > 0 && ', '}
              <Link
                color="white"
                underline="always"
                onClick={() => navigation.navigateTo(navigation.appRoutes.experimentPage(experiment.refId))}>
                {experiment.name}
              </Link>
            </>
          ))}
        </Text.B2>
      </GridFlex.RowCenter>
    </Drawer>
  );
}
