import {
  FormControl,
  TextFieldProps as MuiTextFieldProps,
  EndAdornment,
  StartAdornment,
  Text,
} from '@stigg-components';
import { toNumber } from 'lodash';
import { ReactNode } from 'react';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
// eslint-disable react/jsx-no-duplicate-props
// eslint-disable-next-line no-restricted-imports
import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@stigg-theme';
import { OutlinedFormFieldLabel } from '../form/OutlinedFormFieldLabel';

const StyledFormControl = styled(FormControl)`
  &:focus-within {
    .form-label {
      color: ${({ theme }) => theme.itamar.palette.primary.main};
    }
  }
`;
/**
 This component should never have spreads of TextFieldProps or anything else as it is shared everywhere.
 If you need another props Pick it and think well how it will impact other places/
 Style of this component is controlled in components.ts
 Changes:
 1. No more `(optional)` texts they all have the proper caption
 2. All textfields we currently have are 40px (small)
 3. shouldMaskFromHjAndFs is now a prop that can be passed to text field
 4. error texts and helper text are passed independantly and the textField component controls what is shown when
 5. extraLabel for spacial marking for example when we have "calculated" label
 Don't do:
 1. Use props to pass something else then stated. if it's called "optionalLabel" shouldn't pass override
 text for the label.
 2. dont spread unknown types in.
 * */
type TextFieldProps = Pick<
  MuiTextFieldProps,
  | 'type'
  | 'label'
  | 'value'
  | 'fullWidth'
  | 'onBlur'
  | 'onChange'
  | 'placeholder'
  | 'autoFocus'
  | 'disabled'
  | 'InputProps'
  | 'maxRows'
  | 'onKeyUp'
  | 'onKeyDown'
  | 'name'
  | 'sx'
  | 'inputRef'
  | 'inputProps'
> & {
  textFieldProps?: Partial<MuiTextFieldProps>;
  extraLabelText?: string;
  captionText?: string;
  error?: boolean | string;
  touched: boolean;
  shouldMaskFromHj?: boolean;
  errorText?: React.ReactNode;
  helpTooltipText?: React.ReactNode;
  helpTooltipMaxWidth?: number;
  optional?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  minWidth?: number;
  width?: number;
  withAbsoluteErrorMessage?: boolean;
  isNumberWithoutSigns?: boolean;
  isNumberWithoutFraction?: boolean;
  dataTestId?: string;
  errorTextStyle?: React.CSSProperties;
};

export function TextField({
  type,
  label,
  value: val,
  fullWidth = true,
  onBlur,
  onChange,
  placeholder,
  startAdornment,
  endAdornment,
  autoFocus,
  error,
  disabled,
  helpTooltipText,
  helpTooltipMaxWidth,
  optional,
  InputProps,
  name,
  errorText = typeof error === 'string' ? error : undefined,
  touched,
  shouldMaskFromHj,
  maxRows,
  onKeyUp,
  onKeyDown,
  inputProps,
  inputRef,
  captionText,
  extraLabelText,
  minWidth,
  width,
  withAbsoluteErrorMessage,
  isNumberWithoutSigns,
  isNumberWithoutFraction,
  dataTestId,
  textFieldProps = {}, // please do not populate this field. it should be used only for autocomplete props
  errorTextStyle = {},
}: TextFieldProps): JSX.Element {
  // parse text by type
  let value = val;
  switch (type) {
    case 'number':
      if (value !== null && value !== '') {
        value = toNumber(value);
      }
      break;
    default:
  }
  const onKeyPress = (event) => {
    if (isNumberWithoutSigns && (event?.key === '-' || event?.key === '+')) {
      event.preventDefault();
    }
    if (isNumberWithoutFraction && event?.key === '.') {
      event.preventDefault();
    }
  };
  const isInErrorState = error && touched;
  const onWheel =
    type === 'number' ? (e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLElement).blur() : undefined;
  return (
    <StyledFormControl sx={{ position: 'relative' }} fullWidth={fullWidth} error={error}>
      <OutlinedFormFieldLabel
        disabled={disabled}
        extraLabelText={extraLabelText}
        label={label}
        helpTooltipText={helpTooltipText}
        helpTooltipMaxWidth={helpTooltipMaxWidth}
        optional={optional}
        isInErrorState={isInErrorState}
      />
      <MuiTextField
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        maxRows={maxRows}
        name={name}
        autoFocus={autoFocus}
        type={type}
        label=""
        value={value !== null && value !== undefined ? value : inputProps?.value}
        error={isInErrorState}
        fullWidth
        onBlur={onBlur}
        onChange={onChange}
        onWheel={onWheel}
        variant="outlined"
        placeholder={placeholder}
        disabled={disabled}
        inputRef={inputRef}
        data-testid={dataTestId}
        {...textFieldProps}
        InputProps={{
          startAdornment: startAdornment && <StartAdornment position="start">{startAdornment}</StartAdornment>,
          endAdornment: endAdornment && <EndAdornment position="end">{endAdornment}</EndAdornment>,
          ...omit(InputProps, ['sx', 'className']),
          sx: merge(
            {
              minHeight: 40,
              height: 40,
              minWidth,
              width,
              color: (theme: Theme) => theme.itamar.palette.text.primary,
              backgroundColor: 'white',
            },
            InputProps?.sx,
          ),
        }}
        className={shouldMaskFromHj ? 'fs-mask' : undefined}
      />

      {isInErrorState && errorText && (
        <Text.B2
          color="error"
          sx={{
            ...(withAbsoluteErrorMessage ? { position: 'absolute', top: '100%' } : {}),
            ...errorTextStyle,
          }}
          mt={1}>
          {errorText}
        </Text.B2>
      )}

      {!isInErrorState && captionText && (
        <Text.Caption color={disabled ? 'disabled' : 'secondary'} mt={1}>
          {captionText}
        </Text.Caption>
      )}
    </StyledFormControl>
  );
}
