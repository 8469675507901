import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import { Checkbox, Icon, Box } from '@stigg-components';
import { useHover } from '@stigg-common';
import * as S from '../Table.style';
import { shouldAllowCollapseForCurrentRow } from '../Table.utils';
import { CollapsableTableRow } from './CollapsableTableRow';
import { LAST_CELL_WIDTH } from './index';
import { TableItem, TableRowComponentProps } from '../Table.types';
import { useHighlightRow } from './useHighlightRow';
import { SELECTION_COLUMN_WIDTH } from '../Table.style';

export const TableRow = <TEntity extends TableItem, TCell extends keyof TEntity>({
  isHeaderComponentChild,
  rowHeight,
  withRowHover,
  headCells,
  collapsableComponentOptions,
  onRowClick,
  rowColor,
  shouldHighlightRow,
  highlighDelay,
  item,
  rowIndex,
  enableRowSelection,
  onToggleItemSelection,
  isRowDisabled,
  isCellDisabled,
  isDraggingEnabled: isTableDraggingEnabled,
  withDragHandle,
  disableBorder,
}: TableRowComponentProps<TEntity, TCell>) => {
  const [hoverRef, hovering] = useHover();
  const isDraggingEnabled = item.isDraggable ?? isTableDraggingEnabled;
  const rowDisabled = isRowDisabled ? isRowDisabled(item) : false;
  const { isHighLight, isLongBackgroundTransition } = useHighlightRow(item, shouldHighlightRow, highlighDelay || 1500);

  const renderCells = (isDragging?: boolean) =>
    headCells.map((header, index) => {
      const cellDisabled = isCellDisabled?.(item, header);
      const isCellVisible = isFunction(header.visible) ? header.visible(item) : header.visible;
      return isNil(isCellVisible) || isCellVisible ? (
        <S.TableCell
          width={header.width}
          sx={{
            minWidth: header.minWidth,
            whiteSpace: header.noWrap ? 'nowrap' : undefined,
            maxWidth: header.maxWidth,
          }}
          $disablePadding={header.disablePadding}
          $disableVerticalPadding={header.disableVerticalPadding}
          $disableBorder={isDragging || !!disableBorder}
          key={`${rowIndex}-${index}`}
          align={header.alignment}
          onClick={(e) => {
            if (header.disableClick) {
              e.stopPropagation();
            }
          }}
          $disable={cellDisabled}>
          {header.render(item, rowIndex, hovering)}
        </S.TableCell>
      ) : null;
    });

  const shouldAllowCollapse = shouldAllowCollapseForCurrentRow(item, collapsableComponentOptions);

  function renderRow(provided?: DraggableProvided, snapshot?: DraggableStateSnapshot) {
    const { innerRef, dragHandleProps = {}, draggableProps = {} } = provided || {};
    const { isDragging } = snapshot || {};

    const cells = renderCells(isDragging);

    return shouldAllowCollapse && collapsableComponentOptions?.collapsableComponent ? (
      <CollapsableTableRow<TEntity>
        ref={hoverRef}
        sx={{ cursor: 'pointer' }}
        rowColor={rowColor && rowColor(item) ? rowColor(item) : undefined}
        rowHeight={rowHeight}
        hover={withRowHover}
        disableBorder={disableBorder}
        onClick={() => onRowClick && onRowClick(item)}
        collapsableComponent={collapsableComponentOptions?.collapsableComponent}
        expandOnClick
        entity={item}>
        {cells}
      </CollapsableTableRow>
    ) : (
      <S.TableRow
        ref={isDraggingEnabled ? innerRef : hoverRef} // support either drag or hover hooks atm
        $rowColor={rowColor && rowColor(item) ? rowColor(item) : undefined}
        $isHighlight={isHighLight}
        $isLongBackgroundTransition={isLongBackgroundTransition}
        $rowHeight={rowHeight}
        sx={{
          cursor: onRowClick ? 'pointer' : 'default',
          ...(isDragging
            ? {
                display: 'table',
                backgroundColor: (theme) => theme.itamar.palette.white,
                boxShadow: (theme) => theme.itamar.palette.shadow.popover,
                borderRadius: (theme) => theme.itamar.border.radius,
              }
            : {}),
        }}
        hover={withRowHover}
        $withRowHover={withRowHover}
        $isHeaderComponent={isHeaderComponentChild}
        onClick={() => onRowClick && onRowClick(item)}
        {...draggableProps}>
        {enableRowSelection && (
          <S.TableCell align="left" $disablePadding width={SELECTION_COLUMN_WIDTH} $disableBorder={isDragging}>
            <Checkbox
              disabled={rowDisabled}
              color="primary"
              checked={item.isSelected}
              onChange={(event) => onToggleItemSelection && onToggleItemSelection(event, rowIndex)}
            />
          </S.TableCell>
        )}

        {(isTableDraggingEnabled || withDragHandle) && (
          <S.TableDragHandleCell align="left">
            {isDraggingEnabled && (
              <Box sx={{ display: 'flex' }} {...dragHandleProps}>
                <Icon icon="DragIndicator" type="materialIcons" color="active" withHoverEffect={!isDragging} />
              </Box>
            )}
          </S.TableDragHandleCell>
        )}

        {cells}
        {
          // Adding some space to regular row with the size of the head cell in case we don't wont
          // this specific row to collapse
          collapsableComponentOptions && !shouldAllowCollapse && (
            <S.TableCell key="collapsable" width={LAST_CELL_WIDTH} />
          )
        }
      </S.TableRow>
    );
  }

  if (isDraggingEnabled) {
    return (
      <Draggable key={item.id} index={rowIndex} draggableId={item.id}>
        {(provided, snapshot) => renderRow(provided, snapshot)}
      </Draggable>
    );
  }

  return renderRow();
};
