import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as CarouselComponent } from 'react-responsive-carousel';
import styled, { css } from 'styled-components/macro';

export const StyledCarousel = styled(CarouselComponent)`
  > div {
    display: flex;
    align-items: center;
  }

  .carousel .control-dots {
    margin: 20px 0;
  }
`;

export const StyledIndicator = styled.div<{ $isSelected?: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.itamar.palette.white};
  background: ${({ theme }) => theme.itamar.palette.white};
  border: 1px solid rgba(0, 30, 108, 0.15);
  transition: background-color 0.2s ease-in-out;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background-color: #8fcbff;
          border: none;
        `
      : ''}
`;

export const StyledArrow = styled.div<{ $right?: boolean; $left?: boolean; $arrowPadding?: number }>`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  opacity: 0.4;
  z-index: 1;
  width: 30px;
  cursor: pointer;
  justify-content: center;

  ${({ $right, $left, $arrowPadding }) => {
    return css`
      ${$right ? 'right: 0' : ''};
      ${$right && $arrowPadding ? `margin-right: ${$arrowPadding}px` : ''};
      ${$left ? 'left: 0' : ''};
      ${$left && $arrowPadding ? `margin-left: ${$arrowPadding}px` : ''};
    `;
  }}

  > svg {
    transition: all 0.2s ease-in-out;
    transform-origin: 50% 50%;
    color: ${({ theme }) => theme.itamar.palette.grey[500]};
  }

  :hover {
    opacity: 0.8;
    > svg {
      filter: drop-shadow(1px 1px 1.5px rgba(102, 120, 167, 0.4));
      transform: scale(1.1);
    }
  }
`;
