import { TextColor } from '@stigg-theme';
import { Divider, GridFlex, Icon, IconProps, Text } from '@stigg-components';
import React from 'react';

export function CustomerJourneySectionTitle({
  title,
  titleColor = 'primary',
  icon,
  iconType,
  iconSize,
  readonly,
  children,
  lastChild = false,
  minHeight = 80,
  mt = 0,
}: {
  title: string;
  titleColor?: TextColor;
  icon: IconProps['icon'];
  iconType?: IconProps['type'];
  iconSize?: IconProps['size'];
  readonly?: boolean;
  lastChild?: boolean;
  children?: React.ReactNode;
  minHeight?: number;
  mt?: number;
}) {
  return (
    <GridFlex.Row minHeight={minHeight} mt={mt}>
      <GridFlex.ColumnMiddle gap={2} justifyContent="flex-start">
        <Icon icon={icon} type={iconType} size={iconSize} color="active" />
        {!lastChild && (
          <Divider
            orientation="vertical"
            style={{
              borderWidth: '1px',
              height: '50%',
              borderStyle: 'dashed',
              borderColor: 'rgba(148, 161, 193, 0.4)',
            }}
          />
        )}
      </GridFlex.ColumnMiddle>
      <GridFlex.Column pl={2}>
        {readonly ? <Text.B2 color={titleColor}>{title}</Text.B2> : <Text.H6>{title}</Text.H6>}
        <GridFlex.Item alignContent="center" flex={1}>
          {children}
        </GridFlex.Item>
      </GridFlex.Column>
    </GridFlex.Row>
  );
}
