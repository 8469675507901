import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Anchors } from '../../components';
import { RootState, useAppDispatch } from '../../redux/store';
import { composeUrl, navigateTo } from './actions';
import { getEnvPathFunc } from './selectors';
import { SubscriptionPageTabs } from './tabs';
import { SettingsPageTabs } from '../accounts/types/settingsPageTabs';

export type NavigateToOptions = {
  isGlobal?: boolean;
  replace?: boolean;
  search?: Record<string, string>;
  anchor?: Anchors;
};

type AppRoutes = {
  planPage: (refId: string, version?: string | number) => string;
  plansPage: () => string;
  addonPage: (refId: string, version?: string | number) => string;
  addonGroupPage: (refId: string) => string;
  addonsPage: () => string;
  addonGroupsPage: () => string;
  featurePage: (refId: string) => string;
  featuresPage: () => string;
  createFeature: () => string;
  customerPage: (refId: string) => string;
  experimentPage: (refId: string) => string;
  couponPage: (refId: string) => string;
  productPage: (refId?: string) => string;
  createPlanPage: (productRefId?: string) => string;
  createProductPage: () => string;
  subscriptionPage: (refId: string, tab: SubscriptionPageTabs) => string;
  settingsPage: (params: { clientSideSecurityEnvironmentSlug?: string; selectedTab?: SettingsPageTabs }) => string;
};

export type Navigation = {
  navigateTo: (path: string, options?: NavigateToOptions) => void;
  composeUrl: (path: string, options?: NavigateToOptions) => string;
  getEnvPath: (endPath: string) => string;
  appRoutes: AppRoutes;
};

const withTab = (baseUrl: string, tab?: string) => {
  return `${baseUrl}${tab ? `?tab=${tab.toLowerCase()}` : ''}`;
};

export const appRoutes: AppRoutes = {
  planPage: (refId: string, version?: string | number) => `/plans/${refId}${version ? `?version=${version}` : ''}`,
  plansPage: () => `/plans`,
  addonPage: (refId: string, version?: string | number) => `/addons/${refId}${version ? `?version=${version}` : ''}`,
  addonGroupPage: (refId: string) => `/addon-groups/${refId}`,
  addonsPage: () => `/addons`,
  addonGroupsPage: () => `/addons?tab=add-on%20groups`,
  featurePage: (refId: string) => `/features/${refId}`,
  featuresPage: () => `/features`,
  createFeature: () => `/features?create=true`,
  customerPage: (refId: string) => `/customers/${refId}`,
  experimentPage: (refId: string) => `/experiments/${refId}`,
  couponPage: (refId: string) => `/coupons/${refId}`,
  productPage: (refId?: string) => `/products${refId ? `/${refId}` : ''}`,
  createPlanPage: (productId?: string) => `/plans?create=true${productId ? `&productId=${productId}` : ''}`,
  createProductPage: () => `/products?create=true`,
  subscriptionPage: (refId: string, tab?: SubscriptionPageTabs) => withTab(`/subscriptions/${refId}`, tab),
  settingsPage: ({
    clientSideSecurityEnvironmentSlug,
    selectedTab,
  }: {
    clientSideSecurityEnvironmentSlug?: string;
    selectedTab?: SettingsPageTabs;
  }) =>
    withTab(
      `/account/settings${
        clientSideSecurityEnvironmentSlug ? `?clientSideSecuritySettings=${clientSideSecurityEnvironmentSlug}` : ''
      }`,
      selectedTab,
    ),
};

export function useNavigation(): Navigation {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const getEnvPath = getEnvPathFunc({ currentEnvironmentId, environments });

  return useMemo(
    () => ({
      navigateTo: (path: string, options?: NavigateToOptions) => dispatch(navigateTo(path, options)),
      composeUrl: (path: string, options?: NavigateToOptions) => composeUrl(path, getEnvPath, options),
      getEnvPath,
      appRoutes,
    }),
    [dispatch, getEnvPath],
  );
}
