import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { Button, Icon, Text, CarouselCardProps } from '@stigg-components';
import { ReactComponent as WelcomeToStiggSvg } from '@assets/images/welcomeToStigg.svg';
import { ReactComponent as ModelYourPricingSvg } from '@assets/images/modelYourPricing.svg';
import { ReactComponent as IntegrateStiggToYourCodeSvg } from '@assets/images/integrateStiggToYourCode.svg';
import { ReactComponent as StartWithUseCaseSvg } from '@assets/images/startWithUseCase.svg';
import { externalLinks } from '../../common';
import { useInviteMembersDialog } from '../../accounts/components/InviteMembersDialog/useInviteMembersDialog';
import { useNavigation, appRoutes } from '../../navigation/useNavigation';
import { RootState } from '../../../redux/store';

const StyledStartWithUseCaseSvg = styled(StartWithUseCaseSvg)`
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.spacing(8)};
`;

const customBackgroundStyle: React.CSSProperties = {
  background: 'linear-gradient(225.2deg, rgba(191, 217, 250, 0.1) 3.06%, #D4E9FD 100%)',
  height: '425px',
};

export const useGettingStartedCarouselCards = () => {
  const { navigateTo } = useNavigation();
  const products = useSelector((state: RootState) => state.productReducer.products);
  const { inviteMembersDialog, setIsInviteDialogOpen } = useInviteMembersDialog({
    shouldNavigateToAccountsMembersPageOnSubmit: true,
  });
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const onModelYourPricingClick = () => {
    let refId;
    if (products.length === 1) {
      refId = products[0].refId;
    }

    navigateTo(appRoutes.productPage(refId));
  };

  const onCarouselChange = (index: number) => {
    if (selectedCardIndex !== index) {
      setSelectedCardIndex(index);
    }
  };

  const carouselProps = {
    selectedItem: selectedCardIndex,
    onChange: onCarouselChange,
  };

  const carouselCardsProps: CarouselCardProps[] = [
    {
      style: customBackgroundStyle,
      title: t('gettingStarted.carouselCards.welcomeCard.title'),
      content: (
        <>
          <Text.B1 color="primary" mb={4}>
            {t('gettingStarted.carouselCards.welcomeCard.content1')}
          </Text.B1>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.welcomeCard.content2')}</Text.B1>
        </>
      ),
      buttonOnClick: () => setSelectedCardIndex(1),
      buttonIcon: <Icon icon="ArrowForward" />,
      buttonIconPosition: 'end',
      buttonText: t('gettingStarted.carouselCards.welcomeCard.buttonText'),
      svg: <WelcomeToStiggSvg />,
    },
    {
      style: customBackgroundStyle,
      title: t('gettingStarted.carouselCards.modelYourPricingCard.title'),
      content: (
        <>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.modelYourPricingCard.content')}</Text.B1>
        </>
      ),
      buttonOnClick: onModelYourPricingClick,
      buttonText: t('gettingStarted.carouselCards.modelYourPricingCard.buttonText'),
      svg: <ModelYourPricingSvg width={400} height={300} />,
    },
    {
      style: customBackgroundStyle,
      title: t('gettingStarted.carouselCards.useCasesCard.title'),
      content: (
        <>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.useCasesCard.content')}</Text.B1>
        </>
      ),
      svg: <StyledStartWithUseCaseSvg />,
    },
    {
      style: customBackgroundStyle,
      label: t('gettingStarted.labels.developer'),
      title: t('gettingStarted.carouselCards.integrateStiggCard.title'),
      content: (
        <>
          <Text.B1 color="primary">{t('gettingStarted.carouselCards.integrateStiggCard.content')}</Text.B1>
        </>
      ),
      buttonOnClick: () => window.open(externalLinks.integratingStiggSdksIntoYourCodebaseLink, '_blank'),
      buttonText: t('gettingStarted.carouselCards.integrateStiggCard.buttonText'),
      buttonIcon: <Icon icon="FileText" />,
      buttonIconPosition: 'start',
      svg: <IntegrateStiggToYourCodeSvg width={400} height={300} />,
      customActions: (
        <>
          <Button
            $outlined
            color="secondary"
            sx={{ width: '100%', marginTop: 4 }}
            startIcon={<Icon icon="UserPlus" type="reactFeather" />}
            onClick={() => setIsInviteDialogOpen(true)}>
            {t('gettingStarted.carouselCards.integrateStiggCard.inviteTeamMemberButton')}
          </Button>
          {inviteMembersDialog}
        </>
      ),
    },
  ];

  return { carouselProps, carouselCardsProps };
};
