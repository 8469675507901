import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { some } from 'lodash';
import { MemberStatus } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchUserAction, registerMemberAction } from '../authSlice';
import Loader from '../../../components/Loader';
import { useNavigation } from '../../navigation/useNavigation';
import { useAnalytics } from '../../common/useAnalytics';

function ConfirmRegisterMemberPage() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const isRegistering = useSelector((state: RootState) => state.authReducer.isRegisteringMember);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const { track, group } = useAnalytics();
  const hasPendingInvitation =
    user && some(user.memberships, (membership) => membership.memberStatus === MemberStatus.Invited);

  useEffect(() => {
    const registerUser = async () => {
      if (!isRegistering && hasPendingInvitation) {
        await dispatch(registerMemberAction());
        await dispatch(fetchUserAction());
        track('Team member invitation accepted');
        group(account?.id || 'noAccount', {
          company_name: account?.displayName,
        });
        navigation.navigateTo('/');
      }
    };

    void registerUser();
  }, [dispatch, navigation, isRegistering, hasPendingInvitation, track, group, account]);

  return <Loader />;
}

export default ConfirmRegisterMemberPage;
