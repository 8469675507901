import Frame, { FrameContext } from 'react-frame-component';
import React, { useContext } from 'react';
import { StyleSheetManager } from 'styled-components';
import dottedBackground from '@assets/images/widgets/dottPattern2.png';

const InjectFrameStyles = (props: any) => {
  const { document } = useContext(FrameContext);
  const { children } = props;
  return <StyleSheetManager target={document?.head}>{children}</StyleSheetManager>;
};

export const StyledFrame = (props: any) => {
  const { style, children, ...otherProps } = props;

  return (
    <Frame
      style={{
        backgroundImage: `url(${dottedBackground})`,
        display: 'block',
        overflow: 'scroll',
        border: 0,
        ...style,
        backgroundColor: '#F2F4F8',
      }}
      {...otherProps}>
      <InjectFrameStyles>{children}</InjectFrameStyles>
    </Frame>
  );
};
