import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { FullScreenDialogLayout, GridFlex } from '@stigg-components';
import { BillingModel, PackageStatus, PricingType, ProductListItemFragment } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { useWidgetsData, WidgetIdentifier, WidgetProps } from './useWidgetsData';
import { useNavigation } from '../navigation/useNavigation';
import { Dialog } from '../../components/Dialog';
import { LeftPanel } from './components/leftSidePanel/LeftPanel';
import { PreviewOptionsPanel } from './components/previewOptionsPanel/PreviewOptionsPanel';
import { CanvasPanel } from './components/canvasPanel/CanvasPanel';
import { useWidgetConfiguration } from './hooks/useWidgetConfiguration';
import { useConfirmationDialog } from '../common';
import { calculateCustomerPortalCustomerID, calculatePaywallProductID } from './utils/calculateFields';
import { CustomerPortalSection } from './components/previewOptionsPanel/PreviewOptionsSelectBox';
import { WidgetPageHeader } from './WidgetPageHeader';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchPlansAction } from '../packages/plans/plansSlice';
import { extractCountryList } from '../packages/pricing/components/currency/currencyUtils';
import { useCurrencySelector } from '../packages/pricing/components/currency/useCurrencySelector';

type WidgetPageRouteParams = {
  widgetIdentifier: string;
};

export enum DataType {
  DemoData = 'DEMO_DATA',
  YourData = 'YOUR_DATA',
}

export type WidgetContext = {
  selectedProduct?: ProductListItemFragment;
  pricingType: PricingType;
  billingModel: BillingModel;
  setPricingType: (pricingType: PricingType) => void;
  setBillingModel: (billingModel: BillingModel) => void;
  widgetIdentifier: WidgetIdentifier;
  isTrial: boolean;
  setIsTrial: (isTrial: boolean) => void;
  customerPortalHiddenSections: CustomerPortalSection[];
  setCustomerPortalHiddenSections: (customerPortalHiddenSections: CustomerPortalSection[]) => void;
};

const LeftPanelWrapper = styled(GridFlex.Item)`
  overflow-y: auto;
  height: 100%;
`;

function WidgetPage() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { widgetIdentifier: widgetIdentifierParam } = useParams<WidgetPageRouteParams>();
  const widgetIdentifier = widgetIdentifierParam.toUpperCase() as WidgetIdentifier;
  const widget: WidgetProps | undefined = useWidgetsData().find((x) => x.widgetIdentifier === widgetIdentifier);
  const stiggProducts = useSelector((state: RootState) => state.productReducer.products);
  const widgetConfig = useWidgetConfiguration(widget?.widgetIdentifier);
  const [DiscardConfirmationDialog, setDialogOpen, discardDialogProps] = useConfirmationDialog({
    title: t('widgets.discardChangesTitle'),
    content: t('widgets.discardChangesContent'),
    confirmButtonColor: 'error',
    confirmButtonText: t('widgets.discardChangesButton'),
    handleConfirm: () => {
      navigation.navigateTo('/widgets');
    },
  });

  const [pricingType, setPricingType] = useState(PricingType.Free);
  const [billingModel, setBillingModel] = useState(BillingModel.FlatFee);
  const [isTrial, setIsTrial] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(stiggProducts[0]);
  const [customerPortalHiddenSections, setCustomerPortalHiddenSections] = useState<CustomerPortalSection[]>([]);
  // we should use "your data" when on paywall widget when we have published plans.
  const plans = useSelector((state: RootState) => state.plansReducer.plans);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const isLoadingPlans = useSelector((state: RootState) => state.plansReducer.isLoading);
  const hasPublishPlans = plans?.edges?.some(
    ({ node }) => node.product.id === selectedProduct?.id && node.status === PackageStatus.Published,
  );

  const extractedCountryList = extractCountryList(plans?.edges?.map((plan) => plan.node));
  const [CountrySelectComponent, countryCode] = useCurrencySelector({
    countryCodesList: extractedCountryList,
    hideWhenNoLocalization: true,
  });
  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchPlansAction({ environmentId: currentEnvironmentId }));
    }
  }, [dispatch, currentEnvironmentId]);
  const shouldUseYourData = widgetIdentifier === WidgetIdentifier.Paywall && hasPublishPlans;
  const [dataType, setDataType] = useState(shouldUseYourData ? DataType.YourData : DataType.DemoData);
  const [customerId, setCustomerId] = useState(
    dataType === DataType.DemoData ? 'stiggTestData-free-usage-based' : undefined,
  );
  const [mockProductId, setMockProductId] = useState(
    dataType === DataType.DemoData ? 'stiggTestData-usage-based' : undefined,
  );

  const [mockPlanId, setMockPlanId] = useState(
    dataType === DataType.DemoData ? 'stiggTestData-usage-based-essentials' : undefined,
  );

  const switchDataType = (dataType: DataType) => {
    if (dataType === DataType.DemoData) {
      setDataType(dataType);
      setCustomerId('stiggTestData-free-usage-based');
      setMockProductId('stiggTestData-usage-based');
      setMockPlanId('essentials');
    } else {
      setDataType(dataType);
      setCustomerId(undefined);
      setMockProductId(undefined);
      setMockPlanId(undefined);
    }
  };

  useEffect(() => {
    const dataType = shouldUseYourData ? DataType.YourData : DataType.DemoData;
    setDataType(dataType);
    switchDataType(dataType);
  }, [selectedProduct, shouldUseYourData]);
  useEffect(() => {
    if (dataType === DataType.YourData) {
      return;
    }
    if (widgetIdentifier === WidgetIdentifier.CustomerPortal) {
      setCustomerId(calculateCustomerPortalCustomerID(pricingType, billingModel, isTrial));
      setMockProductId(calculatePaywallProductID(billingModel));
    } else if (widgetIdentifier === WidgetIdentifier.Paywall) {
      setMockProductId(calculatePaywallProductID(billingModel));
    }
  }, [pricingType, billingModel, widgetIdentifier, isTrial, dataType]);

  useEffect(() => {
    setSelectedProduct(stiggProducts[0]);
  }, [stiggProducts]);

  const widgetContext: WidgetContext = {
    billingModel,
    pricingType,
    setBillingModel,
    setPricingType,
    widgetIdentifier,
    isTrial,
    setIsTrial,
    customerPortalHiddenSections,
    setCustomerPortalHiddenSections,
    selectedProduct,
  };

  const onCancel = useCallback(() => {
    if (widgetConfig?.hasChanges) {
      setDialogOpen(true);
    } else {
      navigation.navigateTo('/widgets');
    }
  }, [widgetConfig?.hasChanges, navigation, setDialogOpen]);

  const onSaveChanges = () => {
    widgetConfig?.onSave();
  };

  const currentProductId = mockProductId || selectedProduct?.refId;

  if (!widget || !widgetConfig || isLoadingPlans) {
    return <></>;
  }

  return (
    <>
      <Dialog
        fullScreen
        isOpen
        content={
          <FullScreenDialogLayout
            disableContentScroll
            title={<WidgetPageHeader widgetConfig={widgetConfig} onCancel={onCancel} onSaveChanges={onSaveChanges} />}
            gridProps={{ minWidth: '80rem' }}>
            <GridFlex.Row container height="100%">
              <LeftPanelWrapper
                xs={3}
                p={10}
                borderRight={(theme) => theme.itamar.border.border}
                borderColor={(theme) => theme.itamar.palette.other.outlineBorderLight}>
                <LeftPanel
                  widgetName={widget.name}
                  widgetIdentifier={widget.widgetIdentifier}
                  customerId={customerId}
                  widgetConfig={widgetConfig}
                  productId={currentProductId}
                  customerPortalHiddenSections={customerPortalHiddenSections}
                  countryCode={countryCode}
                />
              </LeftPanelWrapper>
              <GridFlex.Item container xs={9} overflow="hidden">
                <PreviewOptionsPanel
                  dataType={dataType}
                  switchDataType={switchDataType}
                  widgetContext={widgetContext}
                  stiggProducts={stiggProducts}
                  onSelectedProductChange={setSelectedProduct}
                  hasPublishPlans={hasPublishPlans}
                  countrySelectComponent={CountrySelectComponent}
                />
                <CanvasPanel
                  customerPortalHiddenSections={customerPortalHiddenSections}
                  widgetIdentifier={widget.widgetIdentifier}
                  customerId={customerId}
                  productId={currentProductId}
                  planId={mockPlanId}
                  configuration={widgetConfig.configuration}
                  countryCode={countryCode}
                />
              </GridFlex.Item>
            </GridFlex.Row>
          </FullScreenDialogLayout>
        }
        padding={0}
      />
      <DiscardConfirmationDialog {...discardDialogProps} />
    </>
  );
}

export default WidgetPage;
