import React from 'react';
import { Launcher } from 'commandbar';
import { styled } from '@stigg-theme';

const LauncherWrapper = styled('div')`
  & .commandbar-user-launcher {
    font-family: 'Avenir Next', sans-serif;
    min-width: 200px;
    width: 250px;
    position: relative;
    padding: 4px 12px;
    color: rgba(166, 166, 166);
    background: rgb(255, 255, 255);
    border: 1px solid rgba(217, 221, 233);
    border-radius: 10px;
    font-size: 14px;
    line-height: 2;
    cursor: text;
  }

  & .commandbar-user-launcher:hover {
    border: 1px solid rgb(50, 126, 238);
    transition: all 0.3s ease-in-out;
  }

  & .commandbar-user-launcher__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .commandbar-user-launcher__prefix {
    display: flex;
    align-items: center;
  }

  .commandbar-user-launcher__prefix svg {
    width: 16px;
    height: 16px;
  }

  .commandbar-user-launcher__suffix {
    font-size: 14px;
  }

  .commandbar-user-launcher__tag {
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 0 -10px;
    min-width: 1em;
  }
`;

export function CommandBarLauncher() {
  return (
    <LauncherWrapper>
      <Launcher text="Find anything" style={{}} />
    </LauncherWrapper>
  );
}
