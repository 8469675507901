import React, { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { rgba } from 'polished';
import { Chip, Collapse, ListItemProps, ListItemButton, ListItemText, Box } from '@stigg-components';
import { ChevronDown, ChevronUp } from 'react-feather';
import { isEmpty } from 'lodash';

const CustomRouterLink = forwardRef<any, any>(({ externalLink, ...props }, ref) => {
  return (
    <div ref={ref}>
      {externalLink ? <NavLink {...props} to={{ pathname: props.to }} target="_blank" /> : <NavLink {...props} />}
    </div>
  );
});

type ItemType = {
  $isSidenavOpen: boolean;
  activeClassName?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  depth: number;
  externalLink?: boolean;
  $isChildPageOpened?: boolean;
};

const Item = styled(ListItemButton)<ItemType>`
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 4px 14px;
  border-radius: 10px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.itamar.palette.action.active};
    font-size: 22px;
    width: 22px;
    height: 22px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.activeBackground};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }

  ${({ $isChildPageOpened }) =>
    $isChildPageOpened &&
    css`
      background-color: ${(props) => props.theme.sidebar.activeBackground};

      span {
        color: ${(props) => props.theme.sidebar.color};
      }
    `}

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => props.theme.sidebar.activeBackground};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
  ${({ depth, theme }) =>
    depth > 0 &&
    css`
      margin-left: ${theme.spacing(13.5)};
    `}
`;

type TitleType = {
  depth: number;
};

const Title = styled(ListItemText)<TitleType>`
  margin: 0;

  span {
    color: ${(props) =>
      props.depth && props.depth > 0 ? props.theme.sidebar.inactiveColor : props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body2.fontSize}px;
    padding: 0 ${(props) => (props.depth > 0 ? props.theme.spacing(0) : props.theme.spacing(4))};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ChevronUp)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ChevronDown)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    flex-shrink: 0;
  }
`;

type SidebarNavListItemProps = ListItemProps & {
  depth: number;
  externalLink?: boolean;
  href: string;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
  isSidenavOpen: boolean;
  isChildPageOpened?: boolean;
  dataTestId?: string;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const {
    isSidenavOpen,
    isChildPageOpened,
    title,
    href,
    externalLink,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
    dataTestId,
  } = props;

  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (depth > 0 && !isSidenavOpen) {
    return null;
  }

  if (children && isSidenavOpen) {
    return (
      <>
        <Item
          depth={depth}
          onClick={handleToggle}
          $isSidenavOpen={isSidenavOpen}
          $isChildPageOpened={isChildPageOpened && !open}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {/* todo: check correct color */}
            {badge && <Badge color="primary" label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }

  return (
    <Item
      depth={depth}
      component={!isEmpty(href) ? CustomRouterLink : undefined}
      to={href}
      externalLink={externalLink}
      activeClassName="active"
      $isChildPageOpened={isChildPageOpened}
      $isSidenavOpen={isSidenavOpen}
      data-testid={dataTestId}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      {isSidenavOpen && (
        <Title depth={depth}>
          {title}
          {/* todo: check correct color */}
          {badge && <Badge color="primary" label={badge} />}
        </Title>
      )}
    </Item>
  );
};

export default SidebarNavListItem;
