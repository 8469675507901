export enum CustomerPageTabs {
  Overview = 'overview',
  EntitlementUsage = 'entitlement-usage',
  EntitlementSummary = 'entitlement-summary',
  Activity = 'activity',
}

export enum SubscriptionPageTabs {
  Overview = 'overview',
  EntitlementUsage = 'entitlement-usage',
  EntitlementSummary = 'entitlement-summary',
  Activity = 'activity',
}
