import { theme } from '@stigg-theme';

export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'error'
  | 'success'
  | 'stripe'
  | 'slack'
  | 'hubspot'
  | 'white'
  | 'faded'
  | 'disabled'
  | 'startOperation'
  | 'outlinedRestingBackground'
  | 'otherContainedBackground'
  | 'outlineBorder';

// todo: remove string
export const getButtonColor = (color: ButtonColor) => {
  switch (color) {
    case 'primary':
      return theme.itamar.palette.primary.main;
    case 'secondary':
      return theme.itamar.palette.secondary.main;
    case 'outlinedRestingBackground':
      return theme.itamar.palette.secondary.outlinedRestingBackground;
    case 'otherContainedBackground':
      return theme.itamar.palette.other.containedBackground;
    case 'warning':
      return theme.itamar.palette.warning.main;
    case 'error':
      return theme.itamar.palette.error.main;
    case 'success':
      return theme.itamar.palette.success.main;
    case 'disabled':
      return theme.itamar.palette.action.disabled;
    case 'stripe':
      return theme.itamar.palette.integrations.stripe;
    case 'faded':
      return theme.itamar.palette.background.fadedBackground;
    case 'slack':
      return theme.itamar.palette.integrations.slack;
    case 'hubspot':
      return theme.itamar.palette.integrations.hubspot;
    case 'white':
      return theme.itamar.palette.white;
    case 'outlineBorder':
      return theme.itamar.palette.other.outlineBorder;
    case 'startOperation':
      return theme.itamar.palette.background.runningFlow;
    default:
      return color;
  }
};
