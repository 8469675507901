import { SpacingProps } from '@mui/system';
import { Paper } from '@stigg-components';
import styled, { css } from 'styled-components/macro';

export const DRAWER_WIDTH = 260;
export const DRAWER_WIDTH_CLOSED = 70;

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Drawer = styled.div<{ $open: boolean }>`
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: ${({ $open }) => ($open ? `${DRAWER_WIDTH}px` : `${DRAWER_WIDTH_CLOSED}px`)};
    flex-shrink: 0;
  }
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

export const StyledContent = styled(Paper)`
  background: ${({ theme }) => theme.itamar.palette.background.default};
`;

export const MainContent = styled(Paper)<
  SpacingProps & {
    $drawerOpen: boolean;
    $rightDrawerWidth: number | null;
    $rightDrawerOpen: boolean;
    $isEditMode: boolean;
    $isXlDown: boolean;
    $marginTop?: number;
  }
>`
  flex: 1;
  background: ${(props) =>
    props.theme.itamar.palette.background[props.$isEditMode ? 'lightBackground2' : 'lightBackground']};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  transition: all 0.15s;
  margin-left: -${DRAWER_WIDTH_CLOSED}px;
  width: calc(100% + ${DRAWER_WIDTH_CLOSED}px);

  margin-left: 0;
  width: 100%;

  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: ${props.$marginTop}px;
    `}

  ${(props) =>
    props.$rightDrawerOpen &&
    props.$drawerOpen &&
    css`
      width: calc(100% - ${props.$rightDrawerWidth ?? 0}px);
    `}
  ${(props) =>
    props.$rightDrawerOpen &&
    !props.$drawerOpen &&
    css`
      width: calc(100% - ${(props.$rightDrawerWidth ?? 0) - DRAWER_WIDTH}px);
    `}

  ${(props) =>
    props.$isXlDown &&
    props.$rightDrawerOpen &&
    css`
      width: 100%;
    `}
`;
