import styled from 'styled-components/macro';
import { Box } from '@stigg-components';

export const StyledBox = styled(Box)<{ $withReadOnly?: boolean }>`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 100%;
  background: ${({ theme, $withReadOnly }) =>
    $withReadOnly ? theme.itamar.palette.action.hover : theme.itamar.palette.white};
`;
