import { styled } from '@stigg-theme';
import { FormControlLabel } from '../index';

export const StyledFormControlLabel = styled(FormControlLabel)<{
  $selected?: boolean;
  $disabled?: boolean;
  $height?: number;
  $disableFlex?: boolean;
}>`
  margin: 0;
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};

  ${({ $disableFlex }) => !$disableFlex && `flex: 1;`}

  ${({ $height }) => $height && `height: ${$height}px;`}

  ${({ $disabled }) =>
    $disabled &&
    `
    &.Mui-disabled {
      cursor: not-allowed;
    }
  `}

  ${({ $disabled, theme }) => $disabled && `background-color: ${theme.itamar.palette.action.disabled};`}
  ${({ $selected, $disabled, theme }) =>
    $selected && !$disabled && `background-color: ${theme.itamar.palette.background.lightBackground2};`}

  ${({ $selected, $disabled, theme }) =>
    !$selected &&
    !$disabled &&
    `
    &:hover {
      background: ${theme.itamar.palette.action.hover};
    }
  `}
`;
