import React, { useMemo } from 'react';
import { Feature, Plan } from '@stigg-types/apiTypes';
import { useCubeQuery } from '@cubejs-client/react';
import { t } from 'i18next';
import { Bar } from 'react-chartjs-2';
import { WidgetCard } from '../WidgetCard';
import { useNavigation } from '../../../../../navigation/useNavigation';
import '../../../../common/chartjs/chartjs.plugins';
import { composeTopCustomersUsageQuery } from '../../../queries/topCustomersUsageQuery';
import { getEntitlement } from '../../../helpers/plan.helper';
import { composeCurrentUsageWidgetSubtitle } from '../../../helpers/text.helper';
import { ChartErrorMessage, ChartNoDataMessage, ChartPlaceholder } from '../ChartPlaceholders';
import { TopCustomersUsageChartTooltip, useTopCustomersUsageChartTooltip } from './TopCustomersUsageChartTooltip';
import { computeChartData } from './TopCustomersUsage.chartjs';
import { redrawBeforeFirstDrawPlugin } from '../../../../common/chartjs/chartjs.helpers';
import { useCubeFeatureStaticData } from '../useCubeFeatureStaticData';
import sandboxStaticCubeData from './sandbox-top-customer-query.json';
import { useIsShowMockData } from '../../../../common/hooks';
import { useSetChartLastUpdate } from '../../../../common/chartsLastUpdate/useSetChartLastUpdate';

const useCubeRealData = ({
  plan,
  feature,
  environmentId,
  numberOfCustomersToShow,
  showUncompletedPeriod,
  skipCubeQuery,
}: {
  environmentId: string;
  feature: Feature;
  numberOfCustomersToShow: number;
  showUncompletedPeriod: boolean;
  plan: Plan;
  skipCubeQuery?: boolean;
}) => {
  const entitlement = getEntitlement(plan, feature);

  const query = useMemo(
    () =>
      composeTopCustomersUsageQuery(
        environmentId,
        feature.refId,
        plan.refId,
        entitlement,
        showUncompletedPeriod,
        numberOfCustomersToShow,
      ),
    [environmentId, feature.refId, plan.refId, entitlement, showUncompletedPeriod, numberOfCustomersToShow],
  );

  return useCubeQuery(query, { skip: skipCubeQuery });
};

export const TopCustomersUsageWidget = React.memo(
  ({
    environmentId,
    feature,
    plan,
    numberOfCustomersToShow,
    showUncompletedPeriod,
    color,
  }: {
    environmentId: string;
    feature: Feature;
    plan: Plan;
    numberOfCustomersToShow: number;
    showUncompletedPeriod: boolean;
    color: string;
  }) => {
    const isShowMockData = useIsShowMockData();
    const { tooltipInfo, tooltipHandler } = useTopCustomersUsageChartTooltip(feature);
    const entitlement = getEntitlement(plan, feature)!;
    const navigation = useNavigation();

    const realData = useCubeRealData({
      plan,
      feature,
      environmentId,
      numberOfCustomersToShow,
      showUncompletedPeriod,
      skipCubeQuery: isShowMockData,
    });

    const mockData = useCubeFeatureStaticData({
      featureRefId: feature.refId,
      planRefId: plan.refId,
      mock: sandboxStaticCubeData,
    });

    const { error, isLoading, resultSet } = isShowMockData ? mockData : realData;

    const { data, options } = useMemo(
      () => computeChartData(resultSet, feature, plan, navigation, color, tooltipHandler),
      [resultSet, feature, plan, navigation, color, tooltipHandler],
    );

    const title = t('dashboards.featureInspector.topCustomersUsage');
    const subtitle = composeCurrentUsageWidgetSubtitle(entitlement, showUncompletedPeriod);

    useSetChartLastUpdate(title, resultSet);

    let content: JSX.Element;

    if (isLoading) {
      content = <ChartPlaceholder />;
    } else if (error) {
      content = <ChartErrorMessage error={error} />;
    } else if (!resultSet || !data || !options) {
      content = <ChartNoDataMessage />;
    } else {
      content = (
        <>
          <TopCustomersUsageChartTooltip tooltipInfo={tooltipInfo} />
          <Bar options={options} data={data} plugins={[redrawBeforeFirstDrawPlugin]} className="fs-mask" />
        </>
      );
    }

    return (
      <WidgetCard isLoading={isLoading} height={455} span={4} title={title} subtitle={subtitle}>
        {content}
      </WidgetCard>
    );
  },
);
