/* eslint-disable */
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { LoadingButton, Button, Grid, GridFlex, IconButton, Link, Text, useMediaQuery } from '@stigg-components';
import { AddonFragment, PlanFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import moment from 'moment';
import { Send } from 'react-feather';
import * as S from './PackageEditTopBar.style';

export function EditExistingPackageBar({
  isDrawerExpanded,
  isTopBannerOpen,
  onDrawerToggle,
  onDiscardChanges,
  onPublish,
  packageType,
  aPackage,
  setPackageChangeModalOpen,
  isLoading,
  isSandboxBannerOpen,
}: {
  isDrawerExpanded: boolean;
  isTopBannerOpen: boolean;
  onDrawerToggle: () => void;
  onDiscardChanges: () => void;
  onPublish: () => void;
  packageType: 'plan' | 'add-on';
  aPackage: PlanFragment | AddonFragment;
  setPackageChangeModalOpen: (value: boolean) => void;
  isLoading?: boolean;
  isSandboxBannerOpen: boolean;
}) {
  const { user } = useAuth0();
  const changesCount = aPackage.draftDetails?.changes?.totalChanges || 0;
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <S.EditModeBar
      $isDrawerExpanded={isDrawerExpanded}
      $topBannerOpen={isTopBannerOpen}
      $isSandboxBannerOpen={isSandboxBannerOpen}
      position="fixed">
      <GridFlex.RowSpaceBetween container wrap="nowrap">
        {!isDrawerExpanded && !isMdUp && (
          <IconButton color="white" aria-label={t('openDrawer')} onClick={onDrawerToggle} size="large">
            <MenuIcon />
          </IconButton>
        )}
        <Grid item mr={2}>
          <S.Edit size={20} strokeWidth={2.5} />
        </Grid>
        <GridFlex.RowCenter item container sm={12}>
          <Text.B2 mr={2} color="primary" fontWeight="bold">
            {t('packages.editPackage', { packageType })}
          </Text.B2>
          <GridFlex.RowCenter>
            <Text.B2 color="primary" mr={1}>
              {t('packages.unpublishedChanges', { count: changesCount })}{' '}
            </Text.B2>
            {changesCount ? (
              <>
                <Text.B2 mr={2}>
                  {moment(aPackage.updatedAt).fromNow()}
                  {aPackage.draftSummary?.updatedBy
                    ? user?.email === aPackage.draftSummary.updatedBy
                      ? t('packages.changedByYou')
                      : t('packages.changedBy', { email: aPackage.draftSummary.updatedBy })
                    : ''}
                </Text.B2>
                <Link variant="body2" color="primary.main" onClick={() => setPackageChangeModalOpen(true)}>
                  {t('packages.viewChanges')}
                </Link>
              </>
            ) : null}
          </GridFlex.RowCenter>
        </GridFlex.RowCenter>
        <Grid item container justifyContent="flex-end" wrap="nowrap" sm={6}>
          <Button
            sx={{ height: 34, minWidth: 'fit-content', mr: 2 }}
            $outlined
            color="primary"
            onClick={onDiscardChanges}>
            {changesCount ? t('packages.discardChanges') : t('packages.discardDraft')}
          </Button>
          <LoadingButton
            loading={isLoading}
            data-testid={`button-${aPackage.type === 'Plan' ? 'plans' : 'addons'}-review-and-publish`}
            sx={{ height: 34, minWidth: 'fit-content' }}
            variant="contained"
            disabled={changesCount === 0}
            color="primary"
            loadingPosition="start"
            startIcon={<Send size={18} />}
            onClick={onPublish}>
            {t('packages.reviewAndPublish')}
          </LoadingButton>
        </Grid>
      </GridFlex.RowSpaceBetween>
    </S.EditModeBar>
  );
}
