import { FullScreenDialogLayout, Box, FormRenderProps, useExitConfirmationDialog } from '@stigg-components';
import { AddonFragment, PlanFragment, PricingType } from '@stigg-types/apiTypes';
import styled from 'styled-components/macro';
import React, { useState } from 'react';
import { Dialog } from '../../../../../components/Dialog';
import { SetPriceWizardTitle } from './SetPriceWizardTitle';
import { SetPriceWizardForm } from './form/SetPriceWizardForm';
import { TooltipFields } from '../../../../../components/InformationTooltip';
import { SetPriceWizardFormFields } from './form/SetPriceWizardForm.types';
import { SectionIdentifier } from './form/SetPriceWizardForm.steps';

type SetPriceWizardProps = {
  isOpen: boolean;
  onClose: () => void;
  aPackage: PlanFragment | AddonFragment;
  setHasPublishValidationError: (hasError: boolean) => void;
  disabledPricingTypes: Map<PricingType, TooltipFields>;
  initialFocusedSection?: SectionIdentifier;
  initialFormUpdate?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void;
};

export const ContentContainer = styled(Box)`
  width: 812px;
  margin: 0 auto;
  padding: 64px 0;
`;

export function SetPriceWizard({
  aPackage,
  isOpen,
  onClose: onCloseExternal,
  setHasPublishValidationError,
  disabledPricingTypes,
  initialFocusedSection,
  initialFormUpdate,
}: SetPriceWizardProps) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const onClose = () => {
    setHasUnsavedChanges(false);
    onCloseExternal();
  };

  const { exitConfirmationDialog, onExit } = useExitConfirmationDialog({
    onClose,
    shouldConfirm: isOpen && hasUnsavedChanges,
  });

  return (
    <Dialog
      fullScreen
      isOpen={isOpen}
      onCancel={onExit}
      padding={0}
      content={
        <FullScreenDialogLayout variant="grey" title={<SetPriceWizardTitle aPackage={aPackage} />}>
          <ContentContainer>
            <SetPriceWizardForm
              aPackage={aPackage}
              setHasUnsavedChanges={setHasUnsavedChanges}
              onClose={onClose}
              onExit={onExit}
              initialFocusedSection={initialFocusedSection}
              initialFormUpdate={initialFormUpdate}
              setHasPublishValidationError={setHasPublishValidationError}
              disabledPricingTypes={disabledPricingTypes}
            />
            {exitConfirmationDialog}
          </ContentContainer>
        </FullScreenDialogLayout>
      }
    />
  );
}
