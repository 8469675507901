import SalesforceIcon from '@assets/images/integrations/salesforce.png';
import StripeIcon from '@assets/images/integrations/stripe.png';
import Auth0Icon from '@assets/images/integrations/auth0.png';
import OpenFgaIcon from '@assets/images/integrations/open-fga.png';
import AWSMarketplaceIcon from '@assets/images/integrations/aws-marketplace.png';
import StripeSmallIcon from '@assets/images/integrations/stripe-small.png';
import HubspotSmallIcon from '@assets/images/integrations/hubspot.png';
import ZuoraIcon from '@assets/images/integrations/zuora.png';
import { ReactComponent as StripeSvg } from '@assets/icons/Stripe.svg';
import { ReactComponent as ZuoraSvg } from '@assets/icons/Zuora.svg';
import { ReactComponent as SlackSvg } from '@assets/icons/SlackIcon.svg';
import { ReactComponent as HubSpotSvg } from '@assets/icons/Hubspot1.svg';
import { ReactComponent as AWSMarketplaceSvg } from '@assets/icons/AWSMarketplace.svg';
import { ReactComponent as SalesforceSvg } from '@assets/icons/Salesforce.svg';
import { ReactComponent as SnowflakeSvg } from '@assets/icons/snowflake.svg';
import { ReactComponent as BigQuerySvg } from '@assets/icons/BigQuery.svg';
import HubspotIcon from '@assets/images/integrations/hubspotWithOval.png';
import SlackIcon from '@assets/images/integrations/slack.png';
import SnowflakeIcon from '@assets/images/integrations/snowflake.png';
import BigQueryIcon from '@assets/images/integrations/bigquery.png';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { AvailableIntegration } from './models';

const StyledZuoraSvg = styled(ZuoraSvg)`
  width: 48px;
`;

const StyledSnowflakeSvg = styled(SnowflakeSvg)`
  width: 18px;
  height: 18px;
`;

const StyledBigQuerySvg = styled(BigQuerySvg)`
  width: 18px;
  height: 18px;
`;

const StyledSalesforceSvg = styled(SalesforceSvg)`
  width: 18px;
  height: 18px;
`;

export enum VendorIdentifier {
  Zuora = 'ZUORA',
  Stripe = 'STRIPE',
  Auth0 = 'AUTH0',
  Chargebee = 'CHARGEBEE',
  Salesforce = 'SALESFORCE',
  Hubspot = 'HUBSPOT',
  Slack = 'SLACK',
  AwsMarketplace = 'AWS_MARKETPLACE',
  Snowflake = 'SNOWFLAKE',
  BigQuery = 'BIG_QUERY',
  OpenFGA = 'OPEN_FGA',
}

export const BILLING_VENDORS = new Set([VendorIdentifier.Stripe, VendorIdentifier.Zuora]);
export const CRM_VENDORS = new Set([VendorIdentifier.Hubspot]);

export const availableIntegrations: ({
  slackIntegrationEnabled,
  stripeSecretBillingEnabled,
  snowflakeIntegrationEnabled,
  salesforceIntegrationEnabled,
  bigQueryIntegrationEnabled,
  auth0IntegrationEnabled,
}: {
  slackIntegrationEnabled: boolean;
  stripeSecretBillingEnabled?: boolean;
  snowflakeIntegrationEnabled?: boolean;
  salesforceIntegrationEnabled?: boolean;
  bigQueryIntegrationEnabled?: boolean;
  auth0IntegrationEnabled?: boolean;
  openFgaIntegrationEnabled?: boolean;
}) => AvailableIntegration[] = ({
  slackIntegrationEnabled,
  stripeSecretBillingEnabled,
  snowflakeIntegrationEnabled,
  salesforceIntegrationEnabled,
  bigQueryIntegrationEnabled,
  auth0IntegrationEnabled,
  openFgaIntegrationEnabled,
}) => {
  return [
    {
      vendorIdentifier: VendorIdentifier.Stripe,
      name: 'Stripe',
      getDescription: () => t('integrations.stripeDescription'),
      icon: StripeIcon,
      iconSmall: StripeSmallIcon,
      iconSvg: StripeSvg,
      docsUrl: 'https://docs.stigg.io/docs/stripe',
      isDisabled: stripeSecretBillingEnabled,
      recommended: true,
    },
    {
      vendorIdentifier: VendorIdentifier.Zuora,
      name: 'Zuora',
      beta: true,
      getDescription: () => t('integrations.zuoraDescription'),
      iconSvg: StyledZuoraSvg,
      icon: ZuoraIcon,
      isComingSoon: false,
      docsUrl: 'https://docs.stigg.io/docs/zuora',
    },
    {
      vendorIdentifier: VendorIdentifier.AwsMarketplace,
      name: 'AWS Marketplace',
      getDescription: () => t('integrations.awsMarketplaceDescription'),
      icon: AWSMarketplaceIcon,
      iconSvg: AWSMarketplaceSvg,
      docsUrl: 'https://docs.stigg.io/docs/aws-marketplace',
    },
    {
      vendorIdentifier: VendorIdentifier.Salesforce,
      name: 'Salesforce',
      getDescription: () => t('integrations.salesforceDescription'),
      icon: SalesforceIcon,
      isComingSoon: !salesforceIntegrationEnabled,
      iconSvg: StyledSalesforceSvg,
      docsUrl: 'https://docs.stigg.io/docs/salesforce',
    },
    {
      vendorIdentifier: VendorIdentifier.Hubspot,
      name: 'HubSpot',
      beta: true,
      getDescription: () => t('integrations.hubspotDescription'),
      icon: HubspotIcon,
      iconSmall: HubspotSmallIcon,
      iconSvg: HubSpotSvg,
      docsUrl: 'https://docs.stigg.io/docs/hubspot',
    },
    {
      vendorIdentifier: VendorIdentifier.Slack,
      name: 'Slack',
      getDescription: () => t('integrations.slackDescription'),
      icon: SlackIcon,
      iconSvg: SlackSvg,
      isComingSoon: !slackIntegrationEnabled,
      docsUrl: 'https://docs.stigg.io/docs/slack',
    },
    {
      vendorIdentifier: VendorIdentifier.Snowflake,
      name: 'Snowflake',
      getDescription: () => t('integrations.snowflakeDescription'),
      icon: SnowflakeIcon,
      iconSvg: StyledSnowflakeSvg,
      docsUrl: 'https://docs.stigg.io/docs/snowflake',
      isComingSoon: !snowflakeIntegrationEnabled,
    },
    {
      vendorIdentifier: VendorIdentifier.BigQuery,
      name: 'BigQuery',
      getDescription: () => t('integrations.bigQueryDescription'),
      icon: BigQueryIcon,
      iconSvg: StyledBigQuerySvg,
      docsUrl: 'https://docs.stigg.io/docs/bigquery',
      isComingSoon: !bigQueryIntegrationEnabled,
    },
    {
      vendorIdentifier: VendorIdentifier.Auth0,
      name: 'Auth0',
      getDescription: () => t('integrations.auth0Description'),
      icon: Auth0Icon,
      docsUrl: 'https://docs.stigg.io/docs/auth0',
      hidden: !auth0IntegrationEnabled,
    },
    {
      vendorIdentifier: VendorIdentifier.OpenFGA,
      name: 'OpenFGA',
      getDescription: () => t('integrations.openFga.description'),
      icon: OpenFgaIcon,
      docsUrl: 'https://docs.stigg.io/docs/open-fga',
      hidden: !openFgaIntegrationEnabled,
    },
  ];
};

export const ZUORA_DEVELOPMENT_TENANTS = [
  {
    name: 'US API Sandbox',
    baseUrl: 'https://rest.apisandbox.zuora.com',
  },
  {
    name: 'US Performance Test',
    baseUrl: 'https://rest.pt1.zuora.com',
  },
  {
    name: 'US Cloud API Sandbox',
    baseUrl: 'https://rest.sandbox.na.zuora.com',
  },
  {
    name: 'US Central Sandbox',
    baseUrl: 'https://rest.test.zuora.com',
  },
  {
    name: 'EU API Sandbox',
    baseUrl: 'https://rest.sandbox.eu.zuora.com',
  },
  {
    name: 'EU Central Sandbox',
    baseUrl: 'https://rest.test.eu.zuora.com',
  },
];

export const ZUORA_PRODUCTION_TENANTS = [
  {
    name: 'US Production',
    baseUrl: 'https://rest.zuora.com',
  },
  {
    name: 'US Cloud Production',
    baseUrl: 'https://rest.na.zuora.com',
  },
  {
    name: 'EU Production',
    baseUrl: 'https://rest.eu.zuora.com',
  },
];

export const AVAILABLE_ZUORA_TENANTS = [...ZUORA_DEVELOPMENT_TENANTS, ...ZUORA_PRODUCTION_TENANTS];
