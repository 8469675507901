import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { AddonFragment } from '@stigg-types/apiTypes';
import { EntityValueCell, Icon, IconButton } from '@stigg-components';
import React from 'react';
import head from 'lodash/head';
import { getBillingCadenceIconTooltip } from '../../../../pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';
import { CompatiblePackageGroup } from '../../../../common/packageUtils';

const SelectionCard = styled(motion.div)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  background-color: white;
`;
export function AddedAddonItem({
  addon,
  remove,
  index,
}: {
  addon: AddonFragment;
  index: number;
  remove: (index: number) => void;
}) {
  const billingCadence = head(addon.prices)?.billingCadence;

  return (
    <SelectionCard
      layout
      transition={{
        delay: 0,
        opacity: { duration: 0.15, ease: 'linear' },
        layout: { duration: 0.4, type: 'spring' },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <EntityValueCell
        entity={addon}
        icon={
          billingCadence &&
          getBillingCadenceIconTooltip({
            billingCadence,
            selected: false,
          })
        }
        key={index}
        suffix={
          <IconButton onClick={() => remove(index)}>
            <Icon icon="Trash2" type="reactFeather" color="secondary" size={18} />
          </IconButton>
        }
      />
    </SelectionCard>
  );
}

// This is required for backwards compatibility for addon group wihout rules
export function AddedAddonGroupWithoutConfiguration({
  addonGroup,
  remove,
  index,
}: {
  addonGroup: CompatiblePackageGroup;
  index: number;
  remove: (index: number) => void;
}) {
  return (
    <SelectionCard
      layout
      transition={{
        delay: 0,
        opacity: { duration: 0.15, ease: 'linear' },
        layout: { duration: 0.4, type: 'spring' },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <EntityValueCell
        entity={{ refId: addonGroup.packageGroupId, displayName: addonGroup.displayName }}
        icon={<Icon type="custom" icon="AddonGroup" />}
        key={index}
        suffix={
          <IconButton onClick={() => remove(index)}>
            <Icon icon="Trash2" type="reactFeather" color="secondary" size={18} />
          </IconButton>
        }
      />
    </SelectionCard>
  );
}
