import { t } from 'i18next';
import { ArticleCardProps } from '@stigg-components';
import { ReactComponent as TestingPriceChangesCTASvg } from '@assets/images/testingPriceChangesCTA.svg';
import { ReactComponent as AddingTrialFlowCTASvg } from '@assets/images/addingTrialFlowCTA.svg';
import { ReactComponent as GainVisibilityCTASvg } from '@assets/images/gainVisibilityCTA.svg';
import { ReactComponent as RolloutPriceChangesCTASvg } from '@assets/images/rolloutPriceChangesCTA.svg';
import { externalLinks } from '../../common';

export const getArticleCardsData = (): (ArticleCardProps & { id: string })[] => [
  {
    id: 'testing-price-changes',
    svg: <TestingPriceChangesCTASvg />,
    titleBoldText: t('gettingStarted.articleCards.testingPriceChangesCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.testingPriceChangesCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
  {
    id: 'adding-trial-flow',
    svg: <AddingTrialFlowCTASvg />,
    titleBoldText: t('gettingStarted.articleCards.addingTrialFlowCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.addingTrialFlowCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
  {
    id: 'gain-visibility',
    svg: <GainVisibilityCTASvg />,
    titleBoldText: t('gettingStarted.articleCards.gainVisibilityCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.gainVisibilityCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
  {
    id: 'rollout-price-changes',
    svg: <RolloutPriceChangesCTASvg />,
    titleBoldText: t('gettingStarted.articleCards.rolloutPriceChangesCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.rolloutPriceChangesCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
];
