import { Button, Grid, GridFlex, Text } from '@stigg-components';
import { Add as AddIcon } from '@mui/icons-material';
import { ReactNode } from 'react';

export function PageHeader({
  title,
  subtitle,
  buttonTitle,
  buttonClick,
  hideButton,
}: {
  title: string;
  subtitle?: ReactNode;
  buttonTitle?: string;
  buttonClick?: () => void;
  hideButton?: boolean;
}) {
  return (
    <GridFlex.RowSpaceBetween container spacing={12}>
      <GridFlex.Column item xs={6}>
        <Text.H1 gutterBottom display="inline" color="primary">
          {title}
        </Text.H1>
        {subtitle && <Text.B2 mt={-2}>{subtitle}</Text.B2>}
      </GridFlex.Column>
      {!hideButton && buttonClick && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={buttonClick} data-testid="button-create-new">
            <AddIcon />
            {buttonTitle}
          </Button>
        </Grid>
      )}
    </GridFlex.RowSpaceBetween>
  );
}
