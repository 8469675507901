import React from 'react';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from 'styled-components/macro';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { KoalaProvider } from '@getkoala/react';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { CookiesProvider } from 'react-cookie';
import { StiggIntercomProvider } from './components/StiggIntercom/StiggIntercomProvider';
import { theme } from './theme';
import { store } from './redux/store';
import GlobalStyle from './components/GlobalStyle';
import ErrorPage from './components/ErrorPage';
import config from './env.config';
import Router from './modules/navigation/Router';
import Notifier from './modules/notifications/components/Notifier';
import './i18n/config';
import { BreadcrumbsProvider } from './modules/common';
import { AnalyticsProvider } from './modules/common/AnalyticsProvider';
import ReactQueryProvider from './providers/ReactQueryProvider';

function App() {
  return (
    <Provider store={store}>
      <Helmet titleTemplate="%s | Stigg" defaultTitle="Stigg" />
      <CssBaseline />
      <CookiesProvider>
        <KoalaProvider publicApiKey={process.env.REACT_APP_KOALA_API_KEY}>
          <Auth0Provider {...config.auth0} redirectUri={window.location.origin}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <Sentry.ErrorBoundary fallback={ErrorPage}>
                    <BreadcrumbsProvider>
                      <SnackbarProvider
                        autoHideDuration={5000}
                        disableWindowBlurListener
                        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                        maxSnack={5}>
                        <StiggIntercomProvider>
                          <AnalyticsProvider writeKey={config.segmentKey}>
                            <ReactQueryProvider>
                              <GlobalStyle />
                              <Notifier />
                              <Router />
                            </ReactQueryProvider>
                          </AnalyticsProvider>
                        </StiggIntercomProvider>
                      </SnackbarProvider>
                    </BreadcrumbsProvider>
                  </Sentry.ErrorBoundary>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </Auth0Provider>
        </KoalaProvider>
      </CookiesProvider>
    </Provider>
  );
}

export default withLDProvider({
  context: {
    kind: 'user',
    key: 'shared-anonymous-key',
    anonymous: true,
  },
  clientSideID: config.launchDarklyKey,
})(App);
