import { ReactElement } from 'react';
import { t } from 'i18next';
import { GridFlex, Icon, LongText, Text } from '@stigg-components';
import { ChargeType, BasePriceChargeType, UsageBasedChargeType } from '../form/SetPriceWizardForm.types';
import { getFeatureDisplayName } from '../../../../../features/utils/getFeatureDisplayName';
import { getFeatureTypeIcon } from '../../../../../../components/getFeatureTypeIcon';

export type ChargeTitleProps = {
  charge: Pick<BasePriceChargeType, 'type'> | Pick<UsageBasedChargeType, 'type' | 'feature'>;
  fallbackTitle?: string;
  bold?: boolean;
  hideSubtitle?: boolean;
  disableIcon?: boolean;
};

export function ChargeTitle({ charge, fallbackTitle = '', bold, hideSubtitle, disableIcon = false }: ChargeTitleProps) {
  let icon: ReactElement;
  let title: string;
  let subtitle: string | undefined;

  if (charge.type === ChargeType.BasePrice) {
    icon = <Icon icon="BasePrice" />;
    title = t('pricing.charges.basePrice.title');
  } else {
    icon = charge.feature ? (
      getFeatureTypeIcon(charge.feature, { size: 24 })
    ) : (
      <Icon type="custom" icon="WaveSignal" color="active" />
    );
    title = charge.feature ? getFeatureDisplayName(charge.feature) : fallbackTitle;
    subtitle = charge.feature?.refId;
  }

  return (
    <GridFlex.RowCenter gap={3}>
      {!disableIcon && icon}
      <GridFlex.Column>
        <LongText color="primary" bold={bold}>
          {title}
        </LongText>
        {subtitle && !hideSubtitle && <Text.Caption>{subtitle}</Text.Caption>}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
