import { drawFormFields, Field, FormRenderProps, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { pick } from 'lodash';
import * as S from './CustomerJourneyExperimentDiff.style';
import {
  SubscriptionStartSetupConfiguration,
  SubscriptionStartSetupFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionStartSetupConfiguration';
import {
  SubscriptionEndSetupConfiguration,
  SubscriptionEndSetupFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionEndSetupConfiguration';
import {
  SubscriptionCancellationTimeConfiguration,
  SubscriptionCancellationTimeFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionCancellationTimeConfiguration';
import { ExperimentPreset } from '../../../editExperimentWizard/ExperimentWizard';
import {
  CustomerJourneyPlan,
  EditProductSettingsFormFields,
} from '../../../../../products/components/productPage/customerJourney/types';
import { CustomerJourneySectionTitle } from '../../../../../products/components/productPage/customerJourney/CustomerJourneySectionTitle';

const SUBSCRIPTION_STARTS_HEIGHT = 235;
const SECTION_GAP = 8;

export type CustomerJourneyExperimentDiffProps = {
  productId: string;
  plans: CustomerJourneyPlan[];
  controlFormRenderProps: FormRenderProps<EditProductSettingsFormFields>;
  variantRenderProps: FormRenderProps<EditProductSettingsFormFields>;
  variantReadOnly?: boolean;
  prefixFields?: {
    formRenderProps: FormRenderProps<ExperimentPreset>;
    controlField: Field<ExperimentPreset>[];
    variantField: Field<ExperimentPreset>[];
  };
};

export function CustomerJourneyExperimentDiff({
  productId,
  plans,
  prefixFields,
  controlFormRenderProps,
  variantRenderProps,
  variantReadOnly,
}: CustomerJourneyExperimentDiffProps) {
  const hasChangeInSubscriptionStartSetup = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionStartSetupFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionStartSetupFormFieldsNames),
  );

  const hasChangeInSubscriptionEndSetup = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionEndSetupFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionEndSetupFormFieldsNames),
  );

  const hasChangeInSubscriptionCancellationTime = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionCancellationTimeFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionCancellationTimeFormFieldsNames),
  );

  return (
    <GridFlex.Row mt={12} gap={10}>
      <GridFlex.Column xs={6}>
        <S.StyledBox $withReadOnly>
          {prefixFields && (
            <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
              {drawFormFields(prefixFields.controlField, prefixFields.formRenderProps)}
            </GridFlex.Item>
          )}
          <GridFlex.Item flex={1} justifyContent="top" minHeight={SUBSCRIPTION_STARTS_HEIGHT} mb={SECTION_GAP}>
            <CustomerJourneySectionTitle
              readonly
              icon="Flag"
              iconType="reactFeather"
              iconSize={24}
              title={t('products.customerJourney.subscriptionStarts')}
              titleColor="secondary"
            />
            <SubscriptionStartSetupConfiguration
              formRenderProps={controlFormRenderProps}
              productPlans={plans}
              productId={productId}
              readonly
              readonlyHideDottedText
            />
          </GridFlex.Item>
          <GridFlex.Item flex={1} justifyContent="top">
            <CustomerJourneySectionTitle
              readonly
              icon="DoorFrontOutlined"
              iconSize={24}
              title={t('products.customerJourney.subscriptionEndsOrCanceled')}
              titleColor="secondary"
            />
            <SubscriptionEndSetupConfiguration
              formRenderProps={controlFormRenderProps}
              productPlans={plans}
              productId={productId}
              readonly
              readonlyHideDottedText
            />
            <SubscriptionCancellationTimeConfiguration
              formRenderProps={controlFormRenderProps}
              readonly
              readonlyHideDottedText
            />
          </GridFlex.Item>
        </S.StyledBox>
      </GridFlex.Column>
      <GridFlex.Column xs={6}>
        <S.StyledBox>
          {prefixFields && (
            <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
              {drawFormFields(prefixFields.variantField, prefixFields.formRenderProps)}
            </GridFlex.Item>
          )}
          <GridFlex.Item flex={1} justifyContent="top" minHeight={SUBSCRIPTION_STARTS_HEIGHT} mb={SECTION_GAP}>
            <CustomerJourneySectionTitle
              icon="Flag"
              iconType="reactFeather"
              iconSize={24}
              title={t('products.customerJourney.subscriptionStarts')}
              titleColor={variantReadOnly ? 'secondary' : undefined}
              readonly={variantReadOnly}
            />
            <SubscriptionStartSetupConfiguration
              formRenderProps={variantRenderProps}
              productPlans={plans}
              productId={productId}
              readonly={variantReadOnly}
              readonlyHideDottedText={variantReadOnly}
              highlight={hasChangeInSubscriptionStartSetup}
            />
          </GridFlex.Item>
          <GridFlex.Item flex={1} justifyContent="top">
            <CustomerJourneySectionTitle
              icon="DoorFrontOutlined"
              iconSize={24}
              title={t('products.customerJourney.subscriptionEndsOrCanceled')}
              titleColor={variantReadOnly ? 'secondary' : undefined}
              readonly={variantReadOnly}
            />
            <SubscriptionEndSetupConfiguration
              formRenderProps={variantRenderProps}
              productPlans={plans}
              productId={productId}
              readonly={variantReadOnly}
              readonlyHideDottedText={variantReadOnly}
              highlight={hasChangeInSubscriptionEndSetup}
            />
            <SubscriptionCancellationTimeConfiguration
              formRenderProps={variantRenderProps}
              readonly={variantReadOnly}
              readonlyHideDottedText={variantReadOnly}
              highlight={hasChangeInSubscriptionCancellationTime}
            />
          </GridFlex.Item>
        </S.StyledBox>
      </GridFlex.Column>
    </GridFlex.Row>
  );
}
