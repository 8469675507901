import { t } from 'i18next';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import { IntegrationFragment, SnowflakeCredentialsInput, VendorIdentifier } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useAppDispatch } from '../../../../redux/store';
import { useSnowflakeIntegrationWizardSteps } from './useSnowflakeIntegrationWizardSteps';
import { createIntegrationAction, fetchIntegrationByVendorAction } from '../../integrationsSlice';
import { IntegrationContactUsState } from '../IntegrationContactUsState';
import { SETUP_SQL_VARS } from './constants';

type SnowflakeIntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function SnowflakeIntegrationPage({ integration, headerComponent }: SnowflakeIntegrationPageProps) {
  const dispatch = useAppDispatch();
  const hasIntegration = !!integration?.credentials;
  const [isLoading, setIsLoading] = useState(false);
  const { snowflakeIntegrationGate } = useFlags<FeatureFlags>();

  const credentials: SnowflakeCredentialsInput = {
    host: '',
    username: SETUP_SQL_VARS.USER,
    database: SETUP_SQL_VARS.DATABASE,
    warehouse: SETUP_SQL_VARS.WAREHOUSE,
    role: SETUP_SQL_VARS.ROLE,
    schemaName: SETUP_SQL_VARS.SCHEMA,
    ...(integration ? { ...integration.credentials, password: '********' } : { password: '' }),
  };

  const steps = useSnowflakeIntegrationWizardSteps({
    hasIntegration,
    credentials,
    isLoading,
    onAddIntegration: async (credentials) => {
      setIsLoading(true);

      try {
        await dispatch(
          createIntegrationAction({
            vendorIdentifier: VendorIdentifier.Snowflake,
            snowflakeCredentials: credentials,
          }),
        ).unwrap();

        await dispatch(
          fetchIntegrationByVendorAction({
            vendorIdentifier: VendorIdentifier.Snowflake,
          }),
        );
      } catch (e) {
        setIsLoading(false);
      }
    },
  });

  if (!hasIntegration && snowflakeIntegrationGate) {
    return <IntegrationContactUsState headerComponent={headerComponent} integrationType={VendorIdentifier.Snowflake} />;
  }

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      <Text.H6 mb={4}>{t('integrations.snowflake.integrateWithStiggTitle')}</Text.H6>

      <WizardAccordion
        layoutProps={{ maxWidth: '100%' }}
        uniqueFlowId="snowflake-connect-stigg"
        steps={steps}
        isStepByStep={!hasIntegration}
      />
    </>
  );
}
