import styled from 'styled-components/macro';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@stigg-components';

export const ContainerBox = styled(Box)`
  width: 100%;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

export const StyledAccordion = styled(Accordion)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};

  &.Mui-expanded {
    background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground};
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<{
  $minHeight?: number;
  $disablePointerEvents?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)};
  &.Mui-expanded {
    padding: ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(4)};
  }

  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight}px;`}

  ${({ $disablePointerEvents }) => $disablePointerEvents && `pointer-events: none;`}

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(8)}}`};
  padding-top: 0;
`;

export const IconContainer = styled(Box)<{ $disabled?: boolean }>`
  height: 24px;
  display: flex;
  align-items: center;
  border-right: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  padding-right: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(2.5)};
  ${({ $disabled }) => $disabled && `cursor: not-allowed;`}
`;
