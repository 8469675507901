import styled from 'styled-components/macro';
import { ReactComponent as PolygonSVG } from '@assets/images/faded-polygon.svg';
import { ReactComponent as TriangleSVG } from '@assets/images/faded-triangle.svg';

export const Container = styled.div`
  background: ${({ theme }) => theme.itamar.palette.background.lightBackground};
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const Polygon = styled(PolygonSVG)`
  position: absolute;
  top: 12.3%;
  left: 8.8%;
`;

export const Triangle = styled(TriangleSVG)`
  position: absolute;
  top: 36%;
  left: 83%;
`;

export const Rectangle = styled.div`
  position: absolute;
  top: 76%;
  left: -8.6%;
  width: 267px;
  height: 102px;
  background: linear-gradient(118.61deg, rgba(169, 193, 255, 0.408) 5.88%, rgba(169, 193, 255, 0) 89.53%);
  filter: blur(4.8px);
  border-radius: 1.94px;
`;

export const FormWrapper = styled.div`
  width: 496px;
  background: ${({ theme }) => theme.itamar.palette.white};
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.paper};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: 80px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
