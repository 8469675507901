import { Text, drawFormFields, FormRenderProps, Field } from '@stigg-components';
import { t } from 'i18next';
import { downgradeProductTimeFormat } from '@stigg-common';
import map from 'lodash/map';
import { SubscriptionCancellationTime } from '@stigg-types/apiTypes';
import React, { useMemo } from 'react';
import * as S from './CustomerJourney.style';
import { TooltipFields } from '../../../../../components/InformationTooltip';

export type SubscriptionCancellationTimeFormFields = {
  subscriptionCancellationTime?: SubscriptionCancellationTime;
};

export const SubscriptionCancellationTimeFormFieldsNames: (keyof SubscriptionCancellationTimeFormFields)[] = [
  'subscriptionCancellationTime',
];

type SubscriptionCancellationTimeConfigurationProps = {
  formRenderProps: FormRenderProps<SubscriptionCancellationTimeFormFields>;
  readonly?: boolean;
  readonlyHideDottedText?: boolean;
  readonlyTooltip?: TooltipFields;
  highlight?: boolean;
};

function computeFields(
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionCancellationTimeFormFields>[] {
  return [
    {
      type: 'inline',
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline">
              {t('products.trials.subscriptionEndsTime')}
            </Text.B2>
          ),
        },
        {
          id: 'subscriptionCancellationTime',
          type: 'select',
          variant: 'inline',
          withoutBorder: true,
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: map(
            [SubscriptionCancellationTime.EndOfBillingPeriod, SubscriptionCancellationTime.Immediate],
            (subscriptionCancellationTime) => {
              return {
                value: subscriptionCancellationTime,
                displayValue: downgradeProductTimeFormat(subscriptionCancellationTime),
              };
            },
          ),
        },
      ],
    },
  ];
}

export function SubscriptionCancellationTimeConfiguration({
  formRenderProps,
  readonly = false,
  readonlyHideDottedText = false,
  readonlyTooltip,
  highlight,
}: SubscriptionCancellationTimeConfigurationProps) {
  const fields = useMemo(
    () => computeFields(readonly, readonlyHideDottedText, readonlyTooltip),
    [readonly, readonlyHideDottedText, readonlyTooltip],
  );

  const content = <>{drawFormFields(fields, formRenderProps)}</>;
  return highlight ? (
    <S.StyledBox $isHighlight={highlight} mt={4}>
      {content}
    </S.StyledBox>
  ) : (
    content
  );
}
