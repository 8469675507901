/* eslint-disable no-restricted-imports */
import { FC } from 'react';
import { spacing } from '@mui/system';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import styled, { css } from 'styled-components/macro';
import { TextColor, getTextColor } from '@stigg-theme';
import { CustomVariants } from '@stigg-components/types';
import { linkClasses } from '@mui/material/Link';

// due the face color is a props coming from MuiLink when using it inside the styled tag
// we will have a color props that based on both ButtonColor & MuiLink['color']
// in order to make color $typesafe by our guidelines I've added the $typesafeColor prop
// and I Omit it before exporting the component, so any outside user won't know about it :)

type StyledLinkType = {
  $capitalize?: boolean;
  $bold?: boolean;
  $medium?: boolean;
  // todo: remove the extra colors options other then TextColor (view TextColor in order to understand)
  $typesafeColor?: TextColor; // look at the first comment
  color?: TextColor;
};

export type LinkProps = Omit<MuiLinkProps, 'color' | 'variant'> & { variant?: CustomVariants } & Omit<
    StyledLinkType,
    '$typesafeColor'
  >;

const SpacedLink = styled(MuiLink)(spacing);
const StyledLink = styled(SpacedLink)<StyledLinkType>`
  cursor: pointer;

  ${({ $typesafeColor }) =>
    !!$typesafeColor &&
    css`
      color: ${getTextColor($typesafeColor)};
    `}

  &.${linkClasses.underlineAlways} {
    text-decoration-color: inherit;
  }
`;

// todo: no variant
const TypesafeLink: FC<LinkProps> = ({ color, ...props }) => (
  <StyledLink sx={{ pointerEvents: 'auto' }} $typesafeColor={color} underline="hover" {...props} />
);

export const Link = TypesafeLink;
