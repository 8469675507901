import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CustomerSortFields,
  FetchCustomersQuery,
  FetchCustomersQueryVariables,
  PricingType,
  SortDirection,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { Paging } from '../../../components/table/gqlTableHelper';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';
import { ExtraSubscriptionStatuses } from '../components/customerPage/customerSubscriptions/SubscriptionStatusChip';
import { generateFetchCustomersFilters } from './generateFetchCustomersFilters';

const CUSTOMER_LIST_FRAGMENT = gql`
  fragment CustomerListFragment on Customer {
    id
    customerId
    name
    email
    updatedAt
    totalActiveSubscription
    totalActivePromotionalEntitlements
    billingId
    billingLinkUrl
    defaultPaymentMethodId
    defaultPaymentMethodType
    defaultPaymentMethodLast4Digits
  }
`;

export const getFetchCustomersQuery = (withTotalCount: boolean) => gql`
  query FetchCustomers($paging: CursorPaging, $sorting: [CustomerSort!], $filter: CustomerFilter!) {
    customers(paging: $paging, sorting: $sorting, filter: $filter) {
      ${withTotalCount ? 'totalCount' : ''}
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...CustomerListFragment
        }
        cursor
      }
    }
  }
  ${CUSTOMER_LIST_FRAGMENT}
`;

export type FetchCustomersProps = {
  paging?: Paging;
  search?: string | null;
  environmentId?: string;
  pricingTypes?: PricingType[];
  subscriptionStatuses?: (SubscriptionStatus | ExtraSubscriptionStatuses)[];
};

async function fetchCustomers(props: FetchCustomersProps, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const filter = generateFetchCustomersFilters(props);
      const emptySearchQuery = !filter.searchQuery;

      let { paging } = props;
      if (!paging) {
        paging = { first: DEFAULT_TABLE_PAGE_SIZE };
      }

      const response = await apolloClient.query<FetchCustomersQuery, FetchCustomersQueryVariables>({
        query: getFetchCustomersQuery(emptySearchQuery),
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: { field: CustomerSortFields.CreatedAt, direction: SortDirection.Desc },
          filter,
        },
      });

      return response.data.customers;
    },
    {
      failureMessageHandler: () => t('customers.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchCustomers };
