import React, { useState } from 'react';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { isEmpty, take } from 'lodash';
import { StyledSwitch, Text, Grid, GridFlex, Icon, Button, Collapse, LongText } from '@stigg-components';
import { ProductPlanFragment, ProductFragment, BillingPeriod, PackageStatus } from '@stigg-types/apiTypes';
import entitlementsEmptyPlaceholder from '@assets/images/entitlements-empty-placeholder.png';
import { useNavigation } from '../../../navigation/useNavigation';
import { PlanOffering } from './PlanOffering';
import { PlansEmptyState } from './PlansEmptyState';
import { FeatureCard } from './FeatureCard';
import { PriceEntitlement } from './PriceEntitlementCard';
import { computeDefaultBillingPeriod } from './ProductPlans.utils';
import { useCreatePlan } from '../../../packages/plans/components/useCreatePlan';
import { fetchProductByRefIdAction } from '../../productsSlice';
import { useAppDispatch } from '../../../../redux/store';
import { AddPlanCard } from './AddPlanCard';
import {
  DEFAULT_COUNTRY_CODE,
  extractCountryList,
  extractCountryWithCurrencyMap,
} from '../../../packages/pricing/components/currency/currencyUtils';
import { useCurrencySelector } from '../../../packages/pricing/components/currency/useCurrencySelector';
import { useSubscriptionPriceBreakdown } from '../../../customers/components/customerPage/customerSubscriptions/priceBreakdown';

const MAX_VISIBLE_ENTITLEMENTS_ROWS = 5;

const ShowHiddenItemsButton = styled(Button)`
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

function EmptyPlanEntitlements() {
  return (
    <GridFlex.Column alignItems="center" justifyContent="center" sx={{ minHeight: 325 }}>
      <img src={entitlementsEmptyPlaceholder} width={202} alt="Empty plan entitlements" />
      <Text.B2 color="secondary">{t('packages.noEntitlements')}</Text.B2>
    </GridFlex.Column>
  );
}

function PlanEntitlements({
  plan,
  basePlan,
  selectedBillingPeriod,
  countryCode,
}: {
  selectedBillingPeriod: BillingPeriod;
  plan: ProductPlanFragment;
  basePlan: ProductPlanFragment | null;
  countryCode: string;
}) {
  const [showAllEntitlements, setShowAllEntitlements] = useState(false);
  const planEntitlements = plan.entitlements || [];
  const { priceBreakdown } = useSubscriptionPriceBreakdown({
    plan,
    addons: [],
    billingPeriod: selectedBillingPeriod,
    billingCountryCode: countryCode === DEFAULT_COUNTRY_CODE ? undefined : countryCode,
    minimumSpend: plan.minimumSpend?.find((s) => s.billingPeriod === selectedBillingPeriod)?.minimum?.amount,
  });
  const selectedPrices = priceBreakdown?.planBreakdown?.prices.map((price) => price.price);
  const navigation = useNavigation();
  const showPriceByMonth =
    plan.prices?.some((price) => price.billingPeriod === BillingPeriod.Monthly) &&
    selectedBillingPeriod === BillingPeriod.Annually;
  const pricesEntitlements = selectedPrices?.filter((price) => price.feature) || [];
  const hasPricesEntitlements = pricesEntitlements.length > 0;
  const hasEntitlements = planEntitlements.length > 0 || hasPricesEntitlements;
  const hasBasePlanEntitlements = basePlan && !isEmpty(basePlan.entitlements);
  const entitlementsToShowCount =
    MAX_VISIBLE_ENTITLEMENTS_ROWS +
    (hasBasePlanEntitlements ? 0 : 1) +
    (hasPricesEntitlements ? 0 : pricesEntitlements.length);
  const entitlementsToShow = take(planEntitlements, entitlementsToShowCount);
  const hiddenEntitlements = planEntitlements.slice(entitlementsToShowCount);
  const hiddenEntitlementsCount = hiddenEntitlements.length;

  return (
    <PlanOffering
      plan={plan}
      onPlanClick={() => navigation.navigateTo(navigation.appRoutes.planPage(plan.refId))}
      showPriceByMonth={!!showPriceByMonth}
      priceBreakdown={priceBreakdown}
      selectedBillingPeriod={selectedBillingPeriod}
      hasPendingChanges={plan.status !== PackageStatus.Draft && Boolean(plan.draftSummary)}>
      {basePlan || hasEntitlements ? (
        <GridFlex.Column p={4} container rowSpacing={2} flexWrap="nowrap">
          <Grid container mb={2}>
            {hasBasePlanEntitlements && (
              <LongText bold>
                {hasEntitlements
                  ? t('products.basePlanTitlePlus', { basePlanName: basePlan.displayName })
                  : t('products.basePlanTitle', { basePlanName: basePlan.displayName })}
              </LongText>
            )}
            {!hasBasePlanEntitlements && (
              <LongText bold>{t('products.planIncludes', { planName: plan.displayName })}</LongText>
            )}
          </Grid>

          {hasPricesEntitlements &&
            pricesEntitlements.map((priceEntitlement) => (
              <Grid item key={priceEntitlement.featureId}>
                <PriceEntitlement pricing={priceEntitlement} plan={plan} />
              </Grid>
            ))}
          {entitlementsToShow.map((entitlement) => (
            <Grid item key={entitlement.id}>
              <FeatureCard entitlement={entitlement} plan={plan} />
            </Grid>
          ))}
          {hiddenEntitlementsCount > 0 && (
            <>
              <Grid item>
                <Collapse in={showAllEntitlements} sx={{ width: '100%' }}>
                  <GridFlex.Column container rowSpacing={2} flexWrap="nowrap">
                    {hiddenEntitlements.map((entitlement) => (
                      <Grid item key={entitlement.id}>
                        <FeatureCard entitlement={entitlement} plan={plan} />
                      </Grid>
                    ))}
                  </GridFlex.Column>
                </Collapse>
              </Grid>

              <Grid item>
                <ShowHiddenItemsButton
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllEntitlements((prevState) => !prevState);
                  }}>
                  <Grid gap={2}>
                    <Icon icon={showAllEntitlements ? 'Minus' : 'Plus'} />
                    {showAllEntitlements
                      ? t('sharedComponents.showLess')
                      : t('sharedComponents.showXMore', {
                          count: hiddenEntitlementsCount,
                        })}
                  </Grid>
                </ShowHiddenItemsButton>
              </Grid>
            </>
          )}
        </GridFlex.Column>
      ) : (
        <EmptyPlanEntitlements />
      )}
    </PlanOffering>
  );
}

const StyledSwitcher = styled(StyledSwitch)`
  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.itamar.palette.primary.main};
    opacity: 0.5;
  }

  & .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.itamar.palette.primary.main};
  }
`;

function BillingPeriodSwitch({
  billingPeriod,
  onChange,
}: {
  billingPeriod: BillingPeriod;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const isBilledMonthly = billingPeriod === BillingPeriod.Monthly;
  return (
    <GridFlex.RowCenter container width="auto">
      <Grid item>
        <Text.B2 color={isBilledMonthly ? 'primary' : 'disabled'}>{t('products.monthlyBillingPeriod')}</Text.B2>
      </Grid>
      <Grid item>
        <StyledSwitcher size="small" checked={billingPeriod === BillingPeriod.Annually} onChange={onChange} />
      </Grid>
      <Grid item>
        <Text.B2 color={isBilledMonthly ? 'disabled' : 'primary'}>{t('products.annuallyBillingPeriod')}</Text.B2>
      </Grid>
    </GridFlex.RowCenter>
  );
}

export function ProductPlans({ product }: { product: ProductFragment }) {
  const dispatch = useAppDispatch();
  const { createPlanComponent, setIsCreatePlanDialogOpen } = useCreatePlan({
    simplifiedForm: true,
    selectedProduct: product,
    navigateToEntity: false,
    withSuccessMessage: false,
    onCreatePlan: async () => {
      await dispatch(fetchProductByRefIdAction({ refId: product.refId, silentFetch: true }));
    },
  });
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(() => computeDefaultBillingPeriod(product.plans));
  const plans = product.plans || ([] as ProductPlanFragment[]);

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBillingPeriod(event.target.checked ? BillingPeriod.Annually : BillingPeriod.Monthly);
  };

  const onAddPlan = () => {
    setIsCreatePlanDialogOpen(true);
  };

  const hasPlans = !isEmpty(plans);
  const hasPlansPricing = hasPlans && plans.some((plan) => !!plan.prices?.length);
  const countryCodesList = extractCountryList(plans);
  const countryCodeToCurrencyMap = extractCountryWithCurrencyMap(plans);
  const [SelectComponent, countryCode] = useCurrencySelector({
    countryCodesList,
    countryCodeToCurrencyMap,
  });
  return (
    <>
      <GridFlex.Column>
        {hasPlans && (
          <GridFlex.Column $fullWidth>
            <GridFlex.RowCenter mb={hasPlansPricing ? 0 : 8}>
              <Icon icon="Package" type="custom" color="default" size={20} />
              <Text.H6 ml={2}>{t('products.plans')}</Text.H6>
            </GridFlex.RowCenter>

            {hasPlansPricing && (
              <GridFlex.RowSpaceBetween mt={2} mb={8}>
                <GridFlex.Item>
                  <BillingPeriodSwitch billingPeriod={selectedBillingPeriod} onChange={onSwitchChange} />
                </GridFlex.Item>
                {countryCodesList.length > 1 && (
                  <GridFlex.Item>
                    <SelectComponent />
                  </GridFlex.Item>
                )}
              </GridFlex.RowSpaceBetween>
            )}
          </GridFlex.Column>
        )}
      </GridFlex.Column>

      {hasPlans ? (
        <Grid container wrap="nowrap" pb={4} sx={{ overflowX: 'auto' }} columnGap={4}>
          {plans.map((plan) => (
            <Grid key={plan.id} item minWidth={300} xs={12} lg={3} xl={3}>
              <PlanEntitlements
                countryCode={countryCode}
                selectedBillingPeriod={selectedBillingPeriod}
                plan={plan}
                basePlan={plans.filter((x) => x.id === plan.basePlan?.id)[0]}
              />
            </Grid>
          ))}
          <Grid item minWidth={110} xs={1} lg={1} xl={1}>
            <AddPlanCard onClick={onAddPlan}>
              <GridFlex.ColumnMiddle height="100%" justifyContent="center">
                <Icon type="reactFeather" color="default" icon="Plus" size={50} />
                <Text.B2 color="action.active">Add Plan</Text.B2>
              </GridFlex.ColumnMiddle>
            </AddPlanCard>
          </Grid>
        </Grid>
      ) : (
        <PlansEmptyState onCreatePlan={onAddPlan} />
      )}

      {createPlanComponent}
    </>
  );
}
