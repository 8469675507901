import { UsageEventWithCustomerFragment } from '@stigg-types/apiTypes';
import moment from 'moment-timezone';
import {
  LongText,
  DateText,
  Box,
  Icons,
  Icon,
  Button,
  Text,
  GridFlex,
  CustomDrawer,
  Link,
  ClipboardJSON,
} from '@stigg-components';
import { t } from 'i18next';
import { useNavigation } from '../navigation/useNavigation';

function getEventDataJSON(event: UsageEventWithCustomerFragment): Record<string, any> {
  const jsonObject = {
    eventName: event.eventName,
    customerId: event.customerId,
    resourceId: event.resourceId || undefined,
    timestamp: event.timestamp,
    idempotencyKey: event.idempotencyKey,
    dimensions: event.dimensions,
  };

  return jsonObject;
}

function EventDetailsRow({
  event,
  icon,
  children,
  timestamp,
}: {
  icon: Icons;
  event: UsageEventWithCustomerFragment | null;
  children: React.ReactNode;
  timestamp?: string;
}) {
  return (
    <GridFlex.RowCenter>
      <Box
        display="flex"
        mr={2}
        p={2}
        sx={{
          borderRadius: '10px',
          backgroundColor: (theme) =>
            event?.customer ? theme.itamar.palette.background.lightBackground2 : theme.itamar.palette.grey[50],
        }}>
        <Icon icon={icon} type="reactFeather" color={event?.customer ? 'active' : 'disabled'} size={24} />
      </Box>
      <GridFlex.RowSpaceBetween $fullWidth>
        {children}
        {timestamp && (
          <GridFlex.RowCenter>
            <DateText color="secondary" date={moment.utc(timestamp).toDate()} withTime formatType="longDate" />
          </GridFlex.RowCenter>
        )}
      </GridFlex.RowSpaceBetween>
    </GridFlex.RowCenter>
  );
}

type EventsDetailsProps = {
  currentEvent: UsageEventWithCustomerFragment | null;
  onClose: () => void;
};

export function UsageEventsDetails({ currentEvent, onClose }: EventsDetailsProps) {
  const navigation = useNavigation();

  return (
    <CustomDrawer
      title={currentEvent?.idempotencyKey}
      pretitle={t('usageEvents.detailsSidenav.pretitle')}
      isOpen={!!currentEvent}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      variant="persistent">
      <GridFlex.Column pt={9} rowGap={6} height="100%">
        <EventDetailsRow event={currentEvent} icon="Inbox" timestamp={currentEvent?.timestamp}>
          <Text.H6 mr={2}>{t('usageEvents.ingestedLabel')}</Text.H6>
        </EventDetailsRow>

        <EventDetailsRow
          event={currentEvent}
          icon="Users"
          timestamp={currentEvent?.customer ? currentEvent?.timestamp : undefined}>
          <GridFlex.RowCenter flex={2} mr={4}>
            <LongText variant="h6">
              {currentEvent?.customer ? (
                <>
                  {t('usageEvents.attributedToCustomer')}{' '}
                  <Link
                    onClick={() => navigation.navigateTo(navigation.appRoutes.customerPage(currentEvent.customerId))}>
                    {currentEvent.customer.name || currentEvent.customer.email || currentEvent.customerId}
                  </Link>
                </>
              ) : (
                t('usageEvents.notAttributedToCustomer')
              )}
            </LongText>
          </GridFlex.RowCenter>
        </EventDetailsRow>

        <GridFlex.Column flex={1}>
          <ClipboardJSON
            title={t('usageEvents.detailsSidenav.eventDataTitle')}
            json={currentEvent ? getEventDataJSON(currentEvent) : {}}
          />
        </GridFlex.Column>

        <GridFlex.RowCenter justifyContent="flex-end">
          <Button variant="outlined" onClick={onClose}>
            {t('sharedComponents.close')}
          </Button>
        </GridFlex.RowCenter>
      </GridFlex.Column>
    </CustomDrawer>
  );
}
